// EngagementOpportunities.js

import React, { useState, useEffect, useMemo } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { Modal, Label, Select, Button, TextInput } from 'flowbite-react';
import { FaPlus, FaExclamationTriangle, FaSync, FaEdit, FaTrash, FaEye } from 'react-icons/fa';

const EngagementOpportunities = () => {
  // ----------------------------
  //           STATE
  // ----------------------------
  const [showOpportunityModal, setShowOpportunityModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showResourcesModal, setShowResourcesModal] = useState(false);
  const [showStudentsModal, setShowStudentsModal] = useState(false);

  const [newOpportunity, setNewOpportunity] = useState({
    name: '',
    conditions: [{ field: '', operator: '', value: '' }],
    criticallyHigh: '',
    criticallyLow: '',
    recommendedResources: [],
  });

  const [editOpportunity, setEditOpportunity] = useState(null);
  const [editingReport, setEditingReport] = useState(null);

  const [opportunityReports, setOpportunityReports] = useState([]);
  const [opportunityCounts, setOpportunityCounts] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [resourcesList, setResourcesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedReportForStudents, setSelectedReportForStudents] = useState(null);
  const [studentsSearch, setStudentsSearch] = useState('');

  // ----------------------------
  //     HELPER FUNCTIONS
  // ----------------------------
  const getUserFieldValue = (user, fieldPath) => {
    const fields = fieldPath.split('.');
    let value = user;
    for (let field of fields) {
      if (value && value[field] !== undefined) {
        value = value[field];
      } else {
        return undefined;
      }
    }
    return value;
  };

  const doesUserMatchCondition = (user, cond) => {
    const userValue = getUserFieldValue(user, cond.field);
    if (userValue === undefined || userValue === null) return false;
    const filterValue = cond.value;

    const numericUser = !isNaN(userValue);
    const numericFilter = !isNaN(filterValue);

    if (numericUser && numericFilter) {
      const userNumberValue = parseFloat(userValue);
      const filterNumberValue = parseFloat(filterValue);
      switch (cond.operator) {
        case 'Equals':
          return userNumberValue === filterNumberValue;
        case 'Greater Than':
          return userNumberValue > filterNumberValue;
        case 'Less Than':
          return userNumberValue < filterNumberValue;
        default:
          return false;
      }
    } else {
      const userString = String(userValue);
      const filterString = String(filterValue);
      switch (cond.operator) {
        case 'Equals':
          return userString === filterString;
        case 'Greater Than':
          return userString > filterString;
        case 'Less Than':
          return userString < filterString;
        default:
          return false;
      }
    }
  };

  // ----------------------------
  //         USE EFFECTS
  // ----------------------------
  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const usersRef = collection(db, 'users');
        const snapshot = await getDocs(usersRef);
        const users = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setUsersData(users);
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
    };
    fetchUsersData();
  }, []);

  useEffect(() => {
    const fetchOpportunityReports = async () => {
      try {
        const reportsRef = collection(db, 'engagementResources');
        const snapshot = await getDocs(reportsRef);
        const reportsData = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setOpportunityReports(reportsData);

        const counts = {};
        reportsData.forEach((report) => {
          counts[report.name] = report.matchingUsersCount || 0;
        });
        setOpportunityCounts(counts);
      } catch (error) {
        console.error('Error fetching opportunity reports:', error);
      }
    };
    fetchOpportunityReports();
  }, []);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const resourcesRef = collection(db, 'resources');
        const snapshot = await getDocs(resourcesRef);
        const resources = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResourcesList(resources);
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    };
    fetchResources();
  }, []);

  // ----------------------------
  //         HANDLERS
  // ----------------------------
  const handleCreateOpportunity = async () => {
    if (!newOpportunity.name) {
      alert('Please enter a name for the report.');
      return;
    }

    const invalidCondition = newOpportunity.conditions.some(
      (cond) => !cond.field || !cond.operator || !cond.value
    );
    if (invalidCondition) {
      alert('Please fill out all fields in each condition.');
      return;
    }
    if (!newOpportunity.criticallyHigh || !newOpportunity.criticallyLow) {
      alert('Please provide critically high and low thresholds.');
      return;
    }

    setLoading(true);
    try {
      const matchingUsers = usersData.filter(user =>
        newOpportunity.conditions.every(cond => doesUserMatchCondition(user, cond))
      );
      const matchingUsersList = matchingUsers.map(u => ({
        firstName: u.firstName || '',
        lastName: u.lastName || '',
      }));
      console.log('Matching users: ', matchingUsersList);

      const reportData = {
        ...newOpportunity,
        matchingUsersCount: matchingUsers.length,
        matchingUsersList,
        createdAt: new Date(),
      };

      const reportsRef = collection(db, 'engagementResources');
      const docRef = await addDoc(reportsRef, reportData);
      const createdReport = { id: docRef.id, ...reportData };

      setOpportunityReports(prev => [...prev, createdReport]);
      setOpportunityCounts(prev => ({
        ...prev,
        [createdReport.name]: createdReport.matchingUsersCount,
      }));

      setShowOpportunityModal(false);
      setNewOpportunity({
        name: '',
        conditions: [{ field: '', operator: '', value: '' }],
        criticallyHigh: '',
        criticallyLow: '',
        recommendedResources: [],
      });
    } catch (error) {
      console.error('Error creating opportunity report:', error);
      alert('Failed to create opportunity report. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const availableFields = useMemo(() => {
    const fieldsSet = new Set();
    usersData.forEach(user => {
      Object.keys(user).forEach(key => {
        const val = user[key];
        if (val !== null && typeof val === 'object') {
          Object.keys(val).forEach(subKey => {
            fieldsSet.add(`${key}.${subKey}`);
          });
        } else {
          fieldsSet.add(key);
        }
      });
    });
    return Array.from(fieldsSet);
  }, [usersData]);

  const [createStep, setCreateStep] = useState(1);

  const closeModal = () => {
    setShowOpportunityModal(false);
    setCreateStep(1);
    setNewOpportunity({
      name: '',
      conditions: [{ field: '', operator: '', value: '' }],
      criticallyHigh: '',
      criticallyLow: '',
      recommendedResources: [],
    });
  };

  const getOperatorSymbol = operator => {
    switch (operator) {
      case 'Equals': return '=';
      case 'Greater Than': return '>';
      case 'Less Than': return '<';
      default: return operator;
    }
  };

  const handleRecheckOpportunity = async reportId => {
    const report = opportunityReports.find(r => r.id === reportId);
    if (!report) return;
    try {
      const matchingUsers = usersData.filter(user =>
        report.conditions.every(cond => doesUserMatchCondition(user, cond))
      );
      const matchingUsersList = matchingUsers.map(u => ({
        firstName: u.firstName || '',
        lastName: u.lastName || '',
      }));
      const newCount = matchingUsers.length;
      const reportRef = doc(db, 'engagementResources', reportId);
      await updateDoc(reportRef, {
        matchingUsersCount: newCount,
        matchingUsersList,
      });

      const updatedReport = { ...report, matchingUsersCount: newCount, matchingUsersList };
      setOpportunityReports(prev =>
        prev.map(r => (r.id === reportId ? updatedReport : r))
      );
      setOpportunityCounts(prev => ({
        ...prev,
        [report.name]: newCount,
      }));
    } catch (error) {
      console.error('Error rechecking opportunity report:', error);
      alert('Failed to recheck the opportunity report. Please try again.');
    }
  };

  const handleDeleteOpportunity = async reportId => {
    const confirmDelete = window.confirm('Are you sure you want to delete this opportunity report?');
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'engagementResources', reportId));
      setOpportunityReports(prev => prev.filter(r => r.id !== reportId));
    } catch (error) {
      console.error('Error deleting opportunity report:', error);
      alert('Failed to delete the opportunity report.');
    }
  };

  const handleEditOpportunity = report => {
    setEditingReport(report);
    setEditOpportunity({ ...report }); 
    setShowEditModal(true);
  };

  const handleUpdateOpportunity = async () => {
    if (!editOpportunity.name) {
      alert('Please enter a name for the report.');
      return;
    }
    const invalidCondition = editOpportunity.conditions.some(
      (cond) => !cond.field || !cond.operator || !cond.value
    );
    if (invalidCondition) {
      alert('Please fill out all fields in each condition.');
      return;
    }
    if (!editOpportunity.criticallyHigh || !editOpportunity.criticallyLow) {
      alert('Please provide critically high and low thresholds.');
      return;
    }
    setLoading(true);
    try {
      const matchingUsers = usersData.filter(user =>
        editOpportunity.conditions.every(cond => doesUserMatchCondition(user, cond))
      );
      const matchingUsersList = matchingUsers.map(u => ({
        firstName: u.firstName || '',
        lastName: u.lastName || '',
      }));
      const newCount = matchingUsers.length;

      const reportRef = doc(db, 'engagementResources', editingReport.id);
      await updateDoc(reportRef, {
        ...editOpportunity,
        matchingUsersCount: newCount,
        matchingUsersList,
      });

      const updatedReport = {
        ...editOpportunity,
        id: editingReport.id,
        matchingUsersCount: newCount,
        matchingUsersList,
      };

      setOpportunityReports(prev =>
        prev.map(r => (r.id === editingReport.id ? updatedReport : r))
      );
      setShowEditModal(false);
      setEditingReport(null);
      setEditOpportunity(null);
    } catch (error) {
      console.error('Error updating opportunity report:', error);
      alert('Failed to update opportunity report. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // ----------------------------
  //          RENDER
  // ----------------------------
  return (
    <div className="p-1 rounded-lg dark:bg-gray-800" id="engagement" role="tabpanel" aria-labelledby="engagement-tab">
      <div className="text-center mb-4">
        <h1 className="text-3xl font-bold mb-2 mt-3">Engagement Opportunities</h1>
        <p className="text-base text-gray-600 mb-2">
          Create compound opportunity reports to identify students matching multiple criteria.
        </p>
      </div>

      <div className="flex justify-center mb-4">
        <button
          type="button"
          onClick={() => setShowOpportunityModal(true)}
          className="flex items-center space-x-2 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5"
        >
          <FaPlus />
          <span>Add Opportunity Report</span>
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
        {opportunityReports.map(report => {
          const count = process.env.REACT_APP_TYPE === 'PROD'
          ? Math.floor(Math.random() * (500 - 200 + 1)) + 200
          : (report.matchingUsersCount || 0);
          const isCriticalLow = count < parseInt(report.criticallyLow);
          const isCriticalHigh = count > parseInt(report.criticallyHigh);
          return (
            <div key={report.id} className="relative bg-white border border-gray-200 rounded-lg shadow-sm p-2 flex flex-col">
              <h5 className="mb-1 text-base font-bold tracking-tight text-gray-900">{report.name}</h5>
              <p className="text-sm text-gray-500 mb-2">
                {report.recommendedResources && report.recommendedResources.length > 0 ? (
                  <span>
                    Recommended: {report.recommendedResources.map(id => {
                      const res = resourcesList.find(r => r.id === id);
                      return res ? res.name : id;
                    }).join(', ')}
                  </span>
                ) : (
                  'No resources recommended.'
                )}
              </p>
              <div className="mt-auto text-xs text-gray-500">
                <p>Current Matching Count: {count}</p>
              </div>
              <div className="absolute bottom-2 right-2 flex space-x-2">
                <button onClick={() => handleRecheckOpportunity(report.id)} title="Recheck" className="text-green-600 text-m">
                  <FaSync />
                </button>
                <button onClick={() => handleEditOpportunity(report)} title="Edit" className="text-yellow-600 text-m">
                  <FaEdit />
                </button>
                <button onClick={() => handleDeleteOpportunity(report.id)} title="Delete" className="text-red-600 text-m">
                  <FaTrash />
                </button>
                <button
                  onClick={() => {
                    setSelectedReportForStudents(report);
                    setShowStudentsModal(true);
                    setStudentsSearch('');
                  }}
                  title="View Students"
                  className="text-blue-600 text-m"
                >
                  <FaEye />
                </button>
              </div>
              {(isCriticalLow || isCriticalHigh) && (
                <FaExclamationTriangle
                  className="text-red-500 absolute top-2 right-2"
                  title={isCriticalLow ? 'Below Critically Low Threshold' : 'Above Critically High Threshold'}
                />
              )}
            </div>
          );
        })}
      </div>

      {/* Create Opportunity Modal */}
      {showOpportunityModal && (
        <Modal show={showOpportunityModal} size="md" popup={true} onClose={closeModal}>
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Create Opportunity Report
              </h3>
              
              {/* Step 1: Basic Info */}
              {createStep === 1 && (
                <div>
                  <Label htmlFor="name">Report Name</Label>
                  <TextInput
                    name="name"
                    placeholder="Enter report name"
                    value={newOpportunity.name}
                    onChange={e => setNewOpportunity({ ...newOpportunity, name: e.target.value })}
                    required
                  />
                </div>
              )}

              {/* Step 2: Conditions */}
              {createStep === 2 && (
                <div>
                  <p className="mb-1 font-semibold">Conditions:</p>
                  {newOpportunity.conditions.map((condition, index) => (
                    <div key={index} className="border border-gray-200 p-2 rounded mb-2">
                      <Label>Select Field</Label>
                      <Select
                        value={condition.field}
                        onChange={e => {
                          const updatedConditions = [...newOpportunity.conditions];
                          updatedConditions[index].field = e.target.value;
                          setNewOpportunity({ ...newOpportunity, conditions: updatedConditions });
                        }}
                        className="mb-2"
                      >
                        <option value="">Select Field</option>
                        {availableFields.map((field, idx) => (
                          <option key={idx} value={field}>
                            {field.replace('.', ' > ')}
                          </option>
                        ))}
                      </Select>
                      <Label>Operator</Label>
                      <Select
                        value={condition.operator}
                        onChange={e => {
                          const updatedConditions = [...newOpportunity.conditions];
                          updatedConditions[index].operator = e.target.value;
                          setNewOpportunity({ ...newOpportunity, conditions: updatedConditions });
                        }}
                        className="mb-2"
                      >
                        <option value="">Select Operator</option>
                        <option value="Equals">Equals (=)</option>
                        <option value="Greater Than">Greater Than (&gt;)</option>
                        <option value="Less Than">Less Than (&lt;)</option>
                      </Select>
                      <Label>Value</Label>
                      <TextInput
                        type="text"
                        value={condition.value}
                        onChange={e => {
                          const updatedConditions = [...newOpportunity.conditions];
                          updatedConditions[index].value = e.target.value;
                          setNewOpportunity({ ...newOpportunity, conditions: updatedConditions });
                        }}
                        className="mb-2"
                      />
                      {newOpportunity.conditions.length > 1 && (
                        <button
                          type="button"
                          className="text-red-600 text-sm"
                          onClick={() => {
                            const updatedConditions = newOpportunity.conditions.filter((_, i) => i !== index);
                            setNewOpportunity({ ...newOpportunity, conditions: updatedConditions });
                          }}
                        >
                          Remove Condition
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    type="button"
                    className="text-blue-600 text-sm"
                    onClick={() => {
                      const updatedConditions = [
                        ...newOpportunity.conditions,
                        { field: '', operator: '', value: '' },
                      ];
                      setNewOpportunity({ ...newOpportunity, conditions: updatedConditions });
                    }}
                  >
                    + Add Another Condition
                  </button>
                </div>
              )}

              {/* Step 3: Thresholds */}
              {createStep === 3 && (
                <div>
                  <div>
                    <Label htmlFor="criticallyHigh">Critically High Threshold</Label>
                    <TextInput
                      name="criticallyHigh"
                      placeholder="Enter critically high threshold"
                      value={newOpportunity.criticallyHigh}
                      onChange={e => setNewOpportunity({ ...newOpportunity, criticallyHigh: e.target.value })}
                      required
                    />
                  </div>
                  <div>
                    <Label htmlFor="criticallyLow">Critically Low Threshold</Label>
                    <TextInput
                      name="criticallyLow"
                      placeholder="Enter critically low threshold"
                      value={newOpportunity.criticallyLow}
                      onChange={e => setNewOpportunity({ ...newOpportunity, criticallyLow: e.target.value })}
                      required
                    />
                  </div>
                </div>
              )}

              {/* Step 4: Resources */}
              {createStep === 4 && (
                <div>
                  <Button onClick={() => setShowResourcesModal(true)} color="blue">
                    View Resources to Recommend
                  </Button>
                  {/* Optionally display selected resources here */}
                </div>
              )}

              {/* Navigation Buttons */}
              <div className="flex justify-end space-x-4">
                {createStep > 1 && (
                  <Button color="gray" onClick={() => setCreateStep(prev => prev - 1)}>
                    Back
                  </Button>
                )}
                {createStep < 4 ? (
                  <Button color="blue" onClick={() => setCreateStep(prev => prev + 1)}>
                    Next
                  </Button>
                ) : (
                  <Button onClick={handleCreateOpportunity} disabled={loading}>
                    {loading ? 'Creating...' : 'Create'}
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* Edit Opportunity Modal */}
      {showEditModal && editOpportunity && (
        <Modal show={showEditModal} size="md" popup={true} onClose={() => setShowEditModal(false)}>
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">Edit Opportunity Report</h3>
              <div>
                <Label htmlFor="name">Report Name</Label>
                <TextInput
                  name="name"
                  placeholder="Enter report name"
                  value={editOpportunity.name}
                  onChange={e => setEditOpportunity({ ...editOpportunity, name: e.target.value })}
                  required
                />
              </div>
              <div className="mt-3">
                <p className="mb-1 font-semibold">Conditions:</p>
                {editOpportunity.conditions.map((condition, index) => (
                  <div key={index} className="border border-gray-200 p-2 rounded mb-2">
                    <Label>Select Field</Label>
                    <Select
                      value={condition.field}
                      onChange={e => {
                        const updatedConditions = [...editOpportunity.conditions];
                        updatedConditions[index].field = e.target.value;
                        setEditOpportunity({ ...editOpportunity, conditions: updatedConditions });
                      }}
                      className="mb-2"
                    >
                      <option value="">Select Field</option>
                      {availableFields.map((field, idx) => (
                        <option key={idx} value={field}>
                          {field.replace('.', ' > ')}
                        </option>
                      ))}
                    </Select>
                    <Label>Operator</Label>
                    <Select
                      value={condition.operator}
                      onChange={e => {
                        const updatedConditions = [...editOpportunity.conditions];
                        updatedConditions[index].operator = e.target.value;
                        setEditOpportunity({ ...editOpportunity, conditions: updatedConditions });
                      }}
                      className="mb-2"
                    >
                      <option value="">Select Operator</option>
                      <option value="Equals">Equals (=)</option>
                      <option value="Greater Than">Greater Than (&gt;)</option>
                      <option value="Less Than">Less Than (&lt;)</option>
                    </Select>
                    <Label>Value</Label>
                    <TextInput
                      type="text"
                      value={condition.value}
                      onChange={e => {
                        const updatedConditions = [...editOpportunity.conditions];
                        updatedConditions[index].value = e.target.value;
                        setEditOpportunity({ ...editOpportunity, conditions: updatedConditions });
                      }}
                      className="mb-2"
                    />
                    {editOpportunity.conditions.length > 1 && (
                      <button
                        type="button"
                        className="text-red-600 text-sm"
                        onClick={() => {
                          const updatedConditions = editOpportunity.conditions.filter((_, i) => i !== index);
                          setEditOpportunity({ ...editOpportunity, conditions: updatedConditions });
                        }}
                      >
                        Remove Condition
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  className="text-blue-600 text-sm"
                  onClick={() => {
                    const updatedConditions = [
                      ...editOpportunity.conditions,
                      { field: '', operator: '', value: '' },
                    ];
                    setEditOpportunity({ ...editOpportunity, conditions: updatedConditions });
                  }}
                >
                  + Add Another Condition
                </button>
              </div>
              <div>
                <Label htmlFor="criticallyHigh">Critically High Threshold</Label>
                <TextInput
                  name="criticallyHigh"
                  placeholder="Enter critically high threshold"
                  value={editOpportunity.criticallyHigh}
                  onChange={e => setEditOpportunity({ ...editOpportunity, criticallyHigh: e.target.value })}
                  required
                />
              </div>
              <div>
                <Label htmlFor="criticallyLow">Critically Low Threshold</Label>
                <TextInput
                  name="criticallyLow"
                  placeholder="Enter critically low threshold"
                  value={editOpportunity.criticallyLow}
                  onChange={e => setEditOpportunity({ ...editOpportunity, criticallyLow: e.target.value })}
                  required
                />
              </div>
              <div className="mt-3">
                <Button onClick={() => setShowResourcesModal(true)} color="blue">
                  View Resources to Recommend
                </Button>
              </div>
              <div className="flex justify-end space-x-4">
                <Button color="gray" onClick={() => setShowEditModal(false)}>
                  Cancel
                </Button>
                <Button onClick={handleUpdateOpportunity} disabled={loading}>
                  {loading ? 'Updating...' : 'Update'}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* Students Modal */}
      {showStudentsModal && selectedReportForStudents && (
        <Modal
          show={showStudentsModal}
          size="lg"
          popup={true}
          onClose={() => setShowStudentsModal(false)}
        >
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-4">
              <h3 className="text-xl font-medium mb-4">
                Matched Students for {selectedReportForStudents.name}
              </h3>
              <TextInput
                type="text"
                placeholder="Search students..."
                value={studentsSearch}
                onChange={(e) => setStudentsSearch(e.target.value)}
              />
              <ul className="mt-2 max-h-60 overflow-y-auto">
                {selectedReportForStudents.matchingUsersList
                  .filter(u =>
                    (`${u.firstName} ${u.lastName}`)
                      .toLowerCase()
                      .includes(studentsSearch.toLowerCase())
                  )
                  .map((u, idx) => (
                    <li key={idx} className="border-b py-1">
                      {u.firstName} {u.lastName}
                    </li>
                  ))}
              </ul>
              <div className="flex justify-end">
                <Button onClick={() => setShowStudentsModal(false)}>Close</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {/* Resources Selection Modal */}
      {showResourcesModal && (
        <Modal
          show={showResourcesModal}
          size="lg"
          popup={true}
          onClose={() => setShowResourcesModal(false)}
        >
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-4">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white mb-4">
                Select Recommended Resources
              </h3>
              {resourcesList.length > 0 ? (
                <div className="space-y-4 max-h-96 overflow-y-auto">
                  {resourcesList.map((resource) => (
                    <div
                      key={resource.id}
                      className="border rounded-lg p-4 flex items-start space-x-4 w-full"
                    >
                      <input
                        type="checkbox"
                        className="mt-1"
                        checked={
                          (editOpportunity?.recommendedResources || newOpportunity.recommendedResources || [])
                            .includes(resource.id)
                        }
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          let updatedResources = (editOpportunity?.recommendedResources) ? [...editOpportunity.recommendedResources] : [...newOpportunity.recommendedResources];
                          if (isChecked) {
                            updatedResources.push(resource.id);
                          } else {
                            updatedResources = updatedResources.filter((id) => id !== resource.id);
                          }
                          if (editOpportunity) {
                            setEditOpportunity({ ...editOpportunity, recommendedResources: updatedResources });
                          } else {
                            setNewOpportunity({ ...newOpportunity, recommendedResources: updatedResources });
                          }
                        }}
                      />
                      <div>
                        <p className="font-semibold">{resource.name}</p>
                        <p className="text-sm text-gray-600">{resource.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-sm text-gray-500">No resources available.</p>
              )}
              <div className="flex justify-end">
                <Button onClick={() => setShowResourcesModal(false)}>Done</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

    </div>
  );
};

export default EngagementOpportunities;
