// SuggestedEngagementCard.jsx
import React, { useEffect, useState, useCallback } from 'react';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import DashboardCard from './DashboardCard';
import { toast } from 'react-toastify';

// IMPORT THE PERSISTENCE CARD
import SuggestedStudentCard from './SuggestedStudentCard'; // Adjust path as needed

const SuggestedEngagementCard = ({ onHide }) => {
  const { currentUser } = useAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Toggle to view only your students or all students
  const [myStudentsOnly, setMyStudentsOnly] = useState(true);

  const handleToggleStudents = () => {
    setMyStudentsOnly((prev) => !prev);
  };

  const fetchSuggestedStudents = useCallback(async () => {
    try {
      setIsLoading(true);

      if (!currentUser?.uid) {
        setIsLoading(false);
        return;
      }

      let studentIds = [];

      if (myStudentsOnly) {
        // Fetch IDs of students for whom currentUser is an advisor
        const studentAdvisorsRef = collection(db, 'studentAdvisors');
        const qRef = query(
          studentAdvisorsRef,
          where('advisorIds', 'array-contains', currentUser.uid)
        );
        const snap = await getDocs(qRef);
        studentIds = snap.docs.map((docSnap) => docSnap.id);
      } else {
        // Fetch ALL students (from users where role === 'student')
        const usersSnapshot = await getDocs(collection(db, 'users'));
        studentIds = usersSnapshot.docs
          .filter((u) => u.data().role === 'student')
          .map((u) => u.id);
      }

      if (studentIds.length === 0) {
        setStudents([]);
        setIsLoading(false);
        return;
      }

      // Gather userData + sub-collection data
      const studentDataPromises = studentIds.map(async (id) => {
        const userDocRef = doc(db, 'users', id);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          return null;
        }

        const userData = userDocSnap.data() || {};

        // Attempt to fetch the "persistenceCalculations" sub-document
        const persistenceDocRef = doc(
          db,
          'users',
          id,
          'persistence',
          'persistenceCalculations'
        );
        const persistenceDocSnap = await getDoc(persistenceDocRef);
        const persistenceData = persistenceDocSnap.exists()
          ? persistenceDocSnap.data()
          : {};

        // Combine data
        let combinedData = {
          id,
          ...userData,
          ...persistenceData,
        };

        // If userData.surveyAnswers.Majors is an array, combine them
        if (
          combinedData?.surveyAnswers?.Majors &&
          Array.isArray(combinedData.surveyAnswers.Majors)
        ) {
          combinedData.major = combinedData.surveyAnswers.Majors.join(' ');
        }

        return combinedData;
      });

      const allStudentData = await Promise.all(studentDataPromises);
      const validStudents = allStudentData.filter(Boolean);

      // Sort them by ascending overallPersistenceScore (if available)
      validStudents.sort(
        (a, b) =>
          parseFloat(a.overallPersistenceScore || 0) -
          parseFloat(b.overallPersistenceScore || 0)
      );

      // Basic "low score" logic: if fewer than 3 below 2.5, fill with the next ones
      let lowPersistence = validStudents.filter(
        (s) => parseFloat(s.overallPersistenceScore || 0) < 2.5
      );
      if (lowPersistence.length < 3) {
        const needed = 3 - lowPersistence.length;
        const filler = validStudents
          .filter((s) => !lowPersistence.includes(s))
          .slice(0, needed);
        lowPersistence = lowPersistence.concat(filler);
      }

      // Max out at 10
      setStudents(lowPersistence.slice(0, 10));
    } catch (err) {
      console.error('Error fetching suggested engagement students:', err);
      toast.error('Failed to fetch suggested students.');
    } finally {
      setIsLoading(false);
    }
  }, [currentUser, db, myStudentsOnly]);

  useEffect(() => {
    fetchSuggestedStudents();
  }, [fetchSuggestedStudents]);

  const total = students.length;

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev < total - 1 ? prev + 1 : total - 1));
  };

  const handleContactStudent = (student) => {
    navigate(`/Communications?studentId=${student.id}`);
  };

  return (
    <DashboardCard
      title={<span className="font-medium">Suggested Engagement</span>}
      extraButtons={
        <button
          onClick={handleToggleStudents}
          className="text-sm font-medium text-blue-600 hover:text-blue-800"
        >
          {myStudentsOnly ? 'Show All Students' : 'Show My Students'}
        </button>
      }
      onHide={onHide} // if you need "hide" functionality
      isLoading={isLoading}
    >
      {isLoading ? (
        <div className="p-4 text-gray-500 text-sm">Loading...</div>
      ) : total === 0 ? (
        <div className="p-4 text-gray-500 text-sm">
          No suggested students to display.
        </div>
      ) : (
        <div className="flex flex-col items-center">
          {/* Navigation (if you want left/right single-student display) */}
          <div className="text-sm font-medium text-gray-600 mb-2">
            Showing student {currentIndex + 1} of {total}
          </div>

          <div className="flex items-center justify-center space-x-3">
            {/* Left arrow */}
            <button
              onClick={handlePrev}
              disabled={currentIndex === 0}
              className="p-2 rounded-full bg-gray-100 text-gray-500 hover:bg-gray-200 disabled:opacity-50 transition"
              aria-label="Previous"
            >
              <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M12.293 15.293a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L8.414 10l3.879 3.879a1 1 0 010 1.414z" />
              </svg>
            </button>

            {/* Render the single student card */}
            <div className="max-w-sm w-full">
              <SuggestedStudentCard
                key={students[currentIndex].id}
                predictor={students[currentIndex]}
              />
            </div>

            {/* Right arrow */}
            <button
              onClick={handleNext}
              disabled={currentIndex === total - 1}
              className="p-2 rounded-full bg-gray-100 text-gray-500 hover:bg-gray-200 disabled:opacity-50 transition"
              aria-label="Next"
            >
              <svg
                className="w-5 h-5 rotate-180"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M12.293 15.293a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L8.414 10l3.879 3.879a1 1 0 010 1.414z" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </DashboardCard>
  );
};

export default SuggestedEngagementCard;
