import React, { useState, useMemo } from "react";

// PowerfulPredictors Component
const PowerfulPredictors = () => {
  // 1. Single Array of Fields with Category
  const fieldsData = [
    // Engagement (LMS)
    { fieldName: "Discussion Participation", MOM: "0.712", dataPercentage: "85.33%", category: "Engagement (LMS)" },
    { fieldName: "Assignment Submissions", MOM: "0.643", dataPercentage: "78.44%", category: "Engagement (LMS)" },
    { fieldName: "Quiz Attempts", MOM: "0.584", dataPercentage: "72.89%", category: "Engagement (LMS)" },
    { fieldName: "Lecture Access Frequency", MOM: "0.712", dataPercentage: "88.11%", category: "Engagement (LMS)" },

    // Academic Performance (GPA)
    { fieldName: "Cumulative GPA", MOM: "0.713", dataPercentage: "75.64%", category: "Academic Performance (GPA)" },
    { fieldName: "Term GPA (Prior Term)", MOM: "0.714", dataPercentage: "72.29%", category: "Academic Performance (GPA)" },
    { fieldName: "Academic Standing (Current Term)", MOM: "0.716", dataPercentage: "70.84%", category: "Academic Performance (GPA)" },
    { fieldName: "Change in Term GPA", MOM: "0.740", dataPercentage: "59.82%", category: "Academic Performance (GPA)" },
    { fieldName: "Sections Failed (Cumulative)", MOM: "0.833", dataPercentage: "81.38%", category: "Academic Performance (GPA)" },

    // Academic Progress
    { fieldName: "Credits Earned", MOM: "0.612", dataPercentage: "67.14%", category: "Academic Progress" },
    { fieldName: "Credits Attempted", MOM: "0.584", dataPercentage: "62.89%", category: "Academic Progress" },
    { fieldName: "On-Track to Graduate", MOM: "0.642", dataPercentage: "74.12%", category: "Academic Progress" },
    { fieldName: "Dropout Risk", MOM: "0.598", dataPercentage: "68.11%", category: "Academic Progress" },
    { fieldName: "Time-to-Degree", MOM: "0.573", dataPercentage: "61.78%", category: "Academic Progress" },
    { fieldName: "Repeat Courses", MOM: "0.550", dataPercentage: "58.29%", category: "Academic Progress" },
    { fieldName: "Withdrawal Rates", MOM: "0.532", dataPercentage: "55.34%", category: "Academic Progress" },
    { fieldName: "Early Alert Referrals", MOM: "0.521", dataPercentage: "54.22%", category: "Academic Progress" },
    { fieldName: "Number of Semesters", MOM: "0.512", dataPercentage: "53.78%", category: "Academic Progress" },
    { fieldName: "Average Credits Per Term", MOM: "0.500", dataPercentage: "51.89%", category: "Academic Progress" },

    // Area of Study
    { fieldName: "STEM Enrollment", MOM: "0.558", dataPercentage: "68.33%", category: "Area of Study" },
    { fieldName: "Humanities Enrollment", MOM: "0.501", dataPercentage: "60.12%", category: "Area of Study" },
    { fieldName: "Business Enrollment", MOM: "0.540", dataPercentage: "63.45%", category: "Area of Study" },
    { fieldName: "Arts Enrollment", MOM: "0.520", dataPercentage: "58.78%", category: "Area of Study" },
    { fieldName: "Health Sciences Enrollment", MOM: "0.532", dataPercentage: "61.22%", category: "Area of Study" },
    { fieldName: "Engineering Enrollment", MOM: "0.490", dataPercentage: "57.33%", category: "Area of Study" },
    { fieldName: "Education Enrollment", MOM: "0.465", dataPercentage: "54.89%", category: "Area of Study" },

    // Financial Aid
    { fieldName: "Scholarship Percentage", MOM: "0.603", dataPercentage: "65.44%", category: "Financial Aid" },
    { fieldName: "Loan Dependency", MOM: "0.589", dataPercentage: "63.78%", category: "Financial Aid" },
    { fieldName: "Work-Study Participation", MOM: "0.571", dataPercentage: "61.33%", category: "Financial Aid" },
    { fieldName: "Pell Grant Eligibility", MOM: "0.555", dataPercentage: "59.29%", category: "Financial Aid" },
    { fieldName: "Merit Aid Eligibility", MOM: "0.542", dataPercentage: "57.44%", category: "Financial Aid" },
    { fieldName: "Parent Contribution", MOM: "0.532", dataPercentage: "55.88%", category: "Financial Aid" },
    { fieldName: "Unmet Financial Need", MOM: "0.520", dataPercentage: "54.12%", category: "Financial Aid" },
    { fieldName: "Financial Aid Appeals", MOM: "0.510", dataPercentage: "53.45%", category: "Financial Aid" },
    { fieldName: "Grant-to-Loan Ratio", MOM: "0.502", dataPercentage: "52.34%", category: "Financial Aid" },
    { fieldName: "Loan Repayment Risk", MOM: "0.496", dataPercentage: "51.22%", category: "Financial Aid" },
    { fieldName: "Aid Processing Timeliness", MOM: "0.490", dataPercentage: "50.78%", category: "Financial Aid" },

    // Enrollment
    { fieldName: "Full-Time Status", MOM: "0.719", dataPercentage: "88.22%", category: "Enrollment" },
    { fieldName: "Part-Time Status", MOM: "0.552", dataPercentage: "59.67%", category: "Enrollment" },
    { fieldName: "Enrollment Date", MOM: "0.600", dataPercentage: "63.44%", category: "Enrollment" },
    { fieldName: "Re-Enrollments", MOM: "0.540", dataPercentage: "58.22%", category: "Enrollment" },
    { fieldName: "Drop Status", MOM: "0.520", dataPercentage: "55.67%", category: "Enrollment" },

    // Background/Demographics
    { fieldName: "First-Generation Status", MOM: "0.438", dataPercentage: "53.67%", category: "Background/Demographics" },
    { fieldName: "Ethnicity", MOM: "0.471", dataPercentage: "58.89%", category: "Background/Demographics" },

    // Custom
    { fieldName: "Custom Metric 1", MOM: "0.890", dataPercentage: "92.11%", category: "Custom" },
  ];

  // 2. Calculate overallRank based on dataPercentage
  const rankedFieldsData = useMemo(() => {
    const clonedData = [...fieldsData];

    const parsePercentage = (percentageStr) => parseFloat(percentageStr.replace("%", ""));

    clonedData.sort((a, b) => parsePercentage(b.dataPercentage) - parsePercentage(a.dataPercentage));

    return clonedData.map((field, index) => ({
      ...field,
      overallRank: index + 1,
    }));
  }, [fieldsData]);

  // 3. Group fields by category
  const categories = useMemo(() => {
    const grouped = rankedFieldsData.reduce((acc, field) => {
      const { category } = field;
      if (!acc[category]) {
        acc[category] = {
          name: category,
          fields: [],
        };
      }
      acc[category].fields.push(field);
      return acc;
    }, {});

    // Convert grouped object to an array and sort by number of fields descending
    return Object.values(grouped).sort((a, b) => b.fields.length - a.fields.length);
  }, [rankedFieldsData]);

  // 4. State for active category
  const [activeCategory, setActiveCategory] = useState(null);

  // 5. Handlers for card clicks
  const handleCardClick = (category) => {
    setActiveCategory(category);
  };

  const handleBackClick = () => {
    setActiveCategory(null);
  };

  // 6. Render Dominoes (Rank Signal Bar)
  const renderDominoes = () => {
    // Total number of dominos based on maximum rank
    const totalDominos = 45; // Assuming ranks go up to 45

    // Determine which dominos should be active
    let activeRanks = [];

    if (activeCategory) {
      activeRanks = activeCategory.fields.map((field) => field.overallRank);
    }

    return Array.from({ length: totalDominos }, (_, i) => {
      const rank = i + 1;
      const isActive = activeCategory ? activeRanks.includes(rank) : false;

      // Define color for active dominos based on rank
      const activeColor = `hsl(220, 90%, ${40 + (rank % 50) * 1.2}%)`; // Adjust hue/saturation/lightness as needed

      return (
        <div
          key={i}
          className={`h-10 mx-0.5 rounded`}
          style={{
            flex: "1 1 auto",
            maxWidth: "2%",
            backgroundColor: isActive ? activeColor : "#d1d5db", // Tailwind's bg-gray-300
            transition: "background-color 0.3s",
          }}
        />
      );
    });
  };

  return (
    <div className="px-5">
      {/* Header */}
      <div className="text-center mb-4">
        <h1 className="text-3xl font-bold mb-2 mt-3">Powerful Predictors</h1>
        <p className="text-base text-gray-600 mb-2">
          Powerful Predictors use historical data to show what variables are important to persistence for this group of
          students.
        </p>
      </div>

      {/* Rank Signal Bar */}
      <div className="mb-4 px-1 flex items-start">
        <div className="flex-1">
          <div className="text-xs text-gray-500 mb-1 flex justify-between">
            <span>Highest Signal</span>
            <span>Lowest Signal</span>
          </div>
          <div className="flex">{renderDominoes()}</div>
          <div className="flex justify-between text-xs text-gray-500 mt-1">
            <div className="text-left">Rank 1</div>
            <div className="text-right">Rank 45</div>
          </div>
        </div>
      </div>

      {/* Predictor Cards */}
      {!activeCategory && (
        <div className="p-4 rounded-md border border-gray-500 bg-gray-50 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {categories.map((category, index) => (
            <div
              key={index}
              onClick={() => handleCardClick(category)}
              className="cursor-pointer border border-gray-200 bg-gray-50 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow relative"
            >
              <h3 className="text-xl font-semibold">{category.name}</h3>
              <p className="text-sm text-gray-500">Fields: {category.fields.length}</p>
            </div>
          ))}
        </div>
      )}

      {/* Predictor Details */}
      {/* Predictor Details */}
      {activeCategory && (
        <div className="p-4 rounded-md border border-gray-500 bg-gray-50">
          {/* Back Button and Category Title */}
          <div className="flex items-center mb-1">
            <span
              onClick={handleBackClick}
              className="cursor-pointer text-gray-600 hover:text-gray-800 mr-2"
            >
              ←
            </span>
            <h3 className="text-lg font-medium">{activeCategory.name}</h3>
          </div>

          {/* Fields List */}
          <div className="p-4 rounded-md overflow-auto">
            {/* Header Row */}
            <div className="grid grid-cols-12 gap-4 py-2 border-b text-sm font-semibold text-gray-700">
              <div className="col-span-1">Rank</div>
              <div className="col-span-6">Field Name</div>
              <div className="text-center mr-10 col-span-3">Category</div>
              <div className="text-left col-span-1">MOM (All)</div>
              <div className="col-span-1">Data %</div>
            </div>
            {/* Fields */}
            {activeCategory.fields
              .sort((a, b) => a.overallRank - b.overallRank)
              .map((field, index) => (
                <div
                  key={index}
                  className={`grid grid-cols-12 gap-4 py-2 border-b last:border-b-0 text-sm text-gray-700 ${
                    field.overallRank === 1 ? "bg-green-100" : ""
                  }`}
                >
                  <div className="col-span-1">#{field.overallRank}</div>
                  <div className="col-span-6">{field.fieldName}</div>
                  <div className="col-span-3 ml-3">{field.category}</div>
                  <div className="col-span-1 ml-2">{field.MOM}</div>
                  <div className="col-span-1">{field.dataPercentage}</div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PowerfulPredictors;
