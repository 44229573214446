import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebase'; // Adjust the import path as necessary
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  getDoc,
  serverTimestamp,
  where,
  getDocs,
  writeBatch,
  setDoc,
} from 'firebase/firestore';
import { FaTimes, FaPaperPlane, FaUserFriends } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';

const StudentChat = ({ currentUser, activeTab, onClose, onChatOpen }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const messagesEndRef = useRef(null);
  const chatBoxRef = useRef(null);

  // Advisors
  const [advisorIds, setAdvisorIds] = useState([]);
  const [advisorsData, setAdvisorsData] = useState({});
  const [selectedAdvisorId, setSelectedAdvisorId] = useState(null);
  const [selectedAdvisorData, setSelectedAdvisorData] = useState(null);

  const [loadingAdvisors, setLoadingAdvisors] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [showAdvisorSelect, setShowAdvisorSelect] = useState(false);

  // Rate limiting
  const messageTimestampsRef = useRef([]);
  const RATE_LIMIT_COUNT = 5;        // Max number of messages
  const RATE_LIMIT_WINDOW = 60_000;  // 60 seconds

  // Alert for rate limit
  const [rateLimitAlert, setRateLimitAlert] = useState(null);

  // ─────────────────────────────────────────────────────────────
  // 1) Fetch the list of assigned advisors on mount
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    const fetchAdvisors = async () => {
      if (!currentUser?.uid) return;
      try {
        setLoadingAdvisors(true);
        const studentAdvisorsRef = doc(db, 'studentAdvisors', currentUser.uid);
        const studentAdvisorsDoc = await getDoc(studentAdvisorsRef);

        if (studentAdvisorsDoc.exists()) {
          const { advisorIds } = studentAdvisorsDoc.data();
          setAdvisorIds(advisorIds);

          // Fetch advisor details
          const advisorsPromises = advisorIds.map(async (advisorId) => {
            const advisorDocRef = doc(db, 'advisors', advisorId);
            const advisorDoc = await getDoc(advisorDocRef);
            if (advisorDoc.exists()) {
              const aData = advisorDoc.data();
              return {
                id: advisorId,
                name: `${aData.firstName} ${aData.lastName}`,
                photoURL: aData.profilePicture || '/default.jpg',
              };
            } else {
              console.error(`Advisor with ID ${advisorId} does not exist.`);
              return null;
            }
          });

          const advisors = await Promise.all(advisorsPromises);
          const validAdvisors = advisors.filter((advisor) => advisor !== null);

          // Create a mapping of advisorId -> advisorData
          const advisorsMap = {};
          validAdvisors.forEach((advisor) => {
            advisorsMap[advisor.id] = advisor;
          });

          setAdvisorsData(advisorsMap);

          // Select the first advisor by default
          if (validAdvisors.length > 0) {
            const firstAdvisor = validAdvisors[0];
            setSelectedAdvisorId(firstAdvisor.id);
            setSelectedAdvisorData(firstAdvisor);
          }
        } else {
          console.error('No advisors assigned to this student (studentAdvisors doc missing).');
        }
      } catch (error) {
        console.error('Error fetching advisors:', error);
      }
      setLoadingAdvisors(false);
    };

    fetchAdvisors();
  }, [currentUser?.uid]);

  // Construct chatId based on selected advisor and activeTab
  const chatId = selectedAdvisorId && activeTab
    ? `${activeTab}_${currentUser.uid}_${selectedAdvisorId}`
    : null;

  // ─────────────────────────────────────────────────────────────
  // 2) Real-time listener for messages in this chat
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    if (!currentUser || !chatId) {
      setMessages([]);
      return;
    }

    setLoadingMessages(true);

    const messagesRef = collection(db, 'chats', chatId, 'messages');
    const q = query(messagesRef, orderBy('timestamp'));

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const msgs = [];
        querySnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          // Mark name/photo depending on sender
          if (data.senderId === currentUser.uid) {
            data.senderName = `${currentUser.firstName} ${currentUser.lastName}`;
            data.senderPhotoURL = currentUser.photoUrl || '/default.jpg';
            data.senderType = 'student';
          } else if (data.senderId === selectedAdvisorId && selectedAdvisorData) {
            data.senderName = selectedAdvisorData.name;
            data.senderPhotoURL = selectedAdvisorData.photoURL;
            data.senderType = 'advisor';
          } else {
            data.senderName = 'Unknown';
            data.senderPhotoURL = '/default.jpg';
            data.senderType = 'unknown';
          }
          msgs.push(data);
        });
        setMessages(msgs);
        setLoadingMessages(false);

        // Scroll to bottom
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      },
      (error) => {
        console.error('Error fetching messages:', error);
        setLoadingMessages(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser, chatId, selectedAdvisorId, selectedAdvisorData, activeTab]);

  // ─────────────────────────────────────────────────────────────
  // 3) Mark messages as read once chat is opened or re-opened
  // ─────────────────────────────────────────────────────────────
  const handleMarkMessagesAsRead = async () => {
    if (!selectedAdvisorId || !activeTab || !chatId) return;

    try {
      const messagesRef = collection(db, 'chats', chatId, 'messages');
      const unreadQuery = query(
        messagesRef,
        where('senderId', '!=', currentUser.uid),
        where('read', '==', false)
      );
      const unreadSnapshot = await getDocs(unreadQuery);
      const batch = writeBatch(db);

      unreadSnapshot.forEach((docSnap) => {
        batch.update(docSnap.ref, { read: true });
      });
      await batch.commit();
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  useEffect(() => {
    if (onChatOpen && chatId) {
      onChatOpen();
      handleMarkMessagesAsRead();
    }
  }, [onChatOpen, chatId]);

  // ─────────────────────────────────────────────────────────────
  // 4) Send a message + update last contact
  // ─────────────────────────────────────────────────────────────
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !chatId) return;

    const now = Date.now();

    // Rate limit check
    messageTimestampsRef.current = messageTimestampsRef.current.filter(
      (timestamp) => now - timestamp < RATE_LIMIT_WINDOW
    );
    if (messageTimestampsRef.current.length >= RATE_LIMIT_COUNT) {
      setRateLimitAlert(
        'You are sending messages too quickly. Please wait before sending more.'
      );
      setTimeout(() => setRateLimitAlert(null), 5000);
      return;
    }

    try {
      // 4a) Add the new message to Firestore
      const messagesRef = collection(db, 'chats', chatId, 'messages');
      await addDoc(messagesRef, {
        content: newMessage,
        senderId: currentUser.uid,
        senderType: 'student',
        timestamp: serverTimestamp(),
        read: false,
      });
      setNewMessage('');
      messageTimestampsRef.current.push(now);

      // 4b) Update "last contact" for the selected advisor in studentAdvisors doc
      // Create a nested map so Firestore stores it properly:
      const studentAdvDocRef = doc(db, 'studentAdvisors', currentUser.uid);
      const lastContactMap = {};
      lastContactMap[selectedAdvisorId] = serverTimestamp();

      await setDoc(
        studentAdvDocRef,
        {
          lastContactTimestamps: lastContactMap,
        },
        { merge: true }
      );

      // Optionally scroll to bottom
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // ─────────────────────────────────────────────────────────────
  // 5) Close chat logic (ESC key & outside click)
  // ─────────────────────────────────────────────────────────────
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
        onClose();
      }
    };
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  // ─────────────────────────────────────────────────────────────
  // 6) Advisor selection
  // ─────────────────────────────────────────────────────────────
  const handleAdvisorChange = (e) => {
    const advisorId = e.target.value;
    if (!advisorId) {
      setSelectedAdvisorId(null);
      setSelectedAdvisorData(null);
    } else {
      const advisor = advisorsData[advisorId];
      setSelectedAdvisorId(advisorId);
      setSelectedAdvisorData(advisor);
      // Close the dropdown after short delay
      setTimeout(() => setShowAdvisorSelect(false), 500);
    }
  };

  // ─────────────────────────────────────────────────────────────
  // 7) Group messages for UI
  // ─────────────────────────────────────────────────────────────
  const groupedMessages = [];
  let lastGroup = null;
  const MAX_TIME_DIFF = 2 * 60 * 1000; // 2 minutes

  messages.forEach((msg) => {
    const msgTime = msg.timestamp?.toDate
      ? msg.timestamp.toDate().getTime()
      : Date.now();

    if (!lastGroup) {
      lastGroup = {
        senderId: msg.senderId,
        senderName: msg.senderName,
        senderPhotoURL: msg.senderPhotoURL,
        messages: [msg],
        lastTimestamp: msgTime,
      };
    } else {
      const timeDiff = msgTime - lastGroup.lastTimestamp;
      const sameSender = msg.senderId === lastGroup.senderId;
      if (sameSender && timeDiff < MAX_TIME_DIFF) {
        lastGroup.messages.push(msg);
        lastGroup.lastTimestamp = msgTime;
      } else {
        groupedMessages.push(lastGroup);
        lastGroup = {
          senderId: msg.senderId,
          senderName: msg.senderName,
          senderPhotoURL: msg.senderPhotoURL,
          messages: [msg],
          lastTimestamp: msgTime,
        };
      }
    }
  });
  if (lastGroup) groupedMessages.push(lastGroup);

  return (
    <div
      className="fixed bottom-4 right-4 w-80 md:w-96 min-h-[400px] max-h-[500px] bg-white rounded-lg shadow-lg flex flex-col z-50"
      ref={chatBoxRef}
    >
      {/* Header */}
      <div className="relative flex justify-between items-center p-3 bg-blue-600 rounded-t-lg">
        <div>
          <h2 className="text-sm font-semibold text-white">Plan: {activeTab}</h2>
          <h3 className="text-xs text-white">
            {selectedAdvisorData
              ? `Chat with ${selectedAdvisorData.name}`
              : 'Chat with Advisor'}
          </h3>
        </div>
        <div className="flex items-center space-x-2">
          {advisorIds.length > 0 && (
            <button
              onClick={() => setShowAdvisorSelect((prev) => !prev)}
              className="text-white hover:text-gray-200"
              aria-label="Toggle advisor selection"
            >
              <FaUserFriends size={16} />
            </button>
          )}
          <button
            onClick={onClose}
            className="text-white hover:text-gray-200"
            aria-label="Close chat"
          >
            <FaTimes size={16} />
          </button>
        </div>

        {/* Advisor Selection Dropdown */}
        <div
          className={`absolute left-0 right-0 top-full bg-white border border-gray-200 rounded-b-lg transform transition-all duration-200 origin-top ${
            showAdvisorSelect
              ? 'opacity-100 pointer-events-auto translate-y-0'
              : 'opacity-0 pointer-events-none -translate-y-2'
          } shadow-lg p-3 z-50`}
        >
          <label
            htmlFor="advisor-select"
            className="block text-xs font-medium text-gray-700 mb-1"
          >
            Select Advisor:
          </label>
          {loadingAdvisors ? (
            <div className="text-xs text-gray-500 flex items-center space-x-2">
              <div className="animate-spin h-4 w-4 border-2 border-blue-500 border-t-transparent rounded-full"></div>
              <p>Loading advisors...</p>
            </div>
          ) : advisorIds.length === 0 ? (
            <p className="text-xs text-gray-500">No advisors assigned.</p>
          ) : (
            <select
              id="advisor-select"
              value={selectedAdvisorId || ''}
              onChange={handleAdvisorChange}
              className="mt-1 block w-full pl-2 pr-8 py-1.5 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
            >
              <option value="">-- Select an Advisor --</option>
              {advisorIds.map((advisorId) => (
                <option key={advisorId} value={advisorId}>
                  {advisorsData[advisorId]?.name || 'Advisor'}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      {/* Alert Message for Rate Limit */}
      {rateLimitAlert && (
        <div className="p-2 bg-red-100 text-red-700 text-xs text-center">
          {rateLimitAlert}
        </div>
      )}

      {/* Messages List */}
      <div className="flex-1 overflow-y-auto overflow-x-hidden p-3 bg-gray-100 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        {loadingMessages && (
          <div className="flex justify-center items-center h-full">
            <div className="flex space-x-2 items-center text-gray-500 text-sm">
              <div className="animate-spin h-5 w-5 border-2 border-blue-500 border-t-transparent rounded-full"></div>
              <span>Loading messages...</span>
            </div>
          </div>
        )}
        {!loadingMessages && groupedMessages.length === 0 && chatId && (
          <p className="text-center text-gray-500 text-sm">
            No messages yet. Start the conversation!
          </p>
        )}

        {!loadingMessages &&
          groupedMessages.map((group, gIndex) => {
            const isCurrentUser = group.senderId === currentUser.uid;
            const lastMsg = group.messages[group.messages.length - 1];
            const timestamp = lastMsg.timestamp?.toDate
              ? lastMsg.timestamp.toDate()
              : null;
            const timeAgo = timestamp
              ? formatDistanceToNow(timestamp, { addSuffix: true })
              : '';

            return (
              <div
                key={gIndex}
                className={`mb-4 flex ${
                  isCurrentUser ? 'justify-end' : 'justify-start'
                }`}
              >
                {!isCurrentUser && (
                  <img
                    src={group.senderPhotoURL || '/default.jpg'}
                    alt={group.senderName}
                    className="w-8 h-8 rounded-full mr-2 self-end"
                  />
                )}

                <div className="max-w-full flex flex-col space-y-1">
                  {group.messages.map((msg, mIndex) => (
                    <div
                      key={mIndex}
                      className={`px-3 py-2 rounded-lg text-sm break-words ${
                        isCurrentUser
                          ? 'bg-blue-500 text-white rounded-br-none'
                          : 'bg-white text-gray-800 rounded-bl-none shadow'
                      }`}
                      style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
                    >
                      {msg.content}
                    </div>
                  ))}
                  <p
                    className={`text-xs mt-1 ${
                      isCurrentUser ? 'text-right text-gray-400' : 'text-gray-400'
                    }`}
                  >
                    {timeAgo}
                  </p>
                </div>
              </div>
            );
          })}
        <div ref={messagesEndRef} />
      </div>

      {/* Message Input */}
      <form onSubmit={handleSendMessage} className="p-2 bg-white border-t flex items-center">
        <input
          type="text"
          className="flex-1 border border-gray-300 rounded-full px-3 py-1.5 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm break-words"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder={chatId ? 'Type your message...' : 'Select an advisor to start'}
          disabled={!chatId}
          style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        />
        <button
          type="submit"
          className={`ml-2 bg-blue-600 text-white p-2 rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition ${
            !chatId ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          aria-label="Send message"
          disabled={!chatId}
        >
          <FaPaperPlane size={14} />
        </button>
      </form>
    </div>
  );
};

export default StudentChat;
