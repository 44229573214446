// src/components/ProfilePageComponents/CurrentSchedule.js
import React from 'react';
// 1. Import the useTranslation hook
import { useTranslation } from 'react-i18next';

const CurrentSchedule = ({ authUser, schedule, fetchedCourseData }) => {
  // 2. Initialize the translation function
  const { t } = useTranslation();

  const calculateTotalCredits = (schedule) => {
    const allClasses = Object.values(schedule).flat();

    const filteredCourses = fetchedCourseData.filter((course) =>
      allClasses.includes(course.courseInfo_courseNumber)
    );

    const totalCredits = filteredCourses.reduce((sum, course) => {
      return sum + parseInt(course.Credits, 10);
    }, 0);

    return totalCredits;
  };

  const totalCredits = calculateTotalCredits(schedule);

  // We'll use a fallback of 'N/A' if `authUser?.surveyAnswers?.Year` is missing
  const currentYear = authUser?.surveyAnswers?.Year || t('currentSchedule.na', 'N/A');

  return (
    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
      <h2 className="text-gray-700 font-semibold">
        {/* Use dynamic text for the year and total credits */}
        {t('currentSchedule.title', {
          year: currentYear,
          credits: totalCredits,
          defaultValue: 'Current Schedule ({{year}}) - Total Credits: {{credits}}',
        })}
      </h2>

      {Object.keys(schedule).length > 0 ? (
        <div className="flex justify-between">
          {/* Fall Semester Section */}
          <div className="w-1/2 pr-1" style={{ maxWidth: 'calc(50%)' }}>
            <h3 className="text-lg text-gray-500 font-semibold mt-2">
              {t('currentSchedule.fallSemester', 'Fall Semester')}
            </h3>
            <ul>
              {schedule.Fall.length > 0 ? (
                schedule.Fall.map((course, index) => (
                  <li
                    key={index}
                    className="bg-blue-200 text-blue-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-blue-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                  >
                    {course}
                  </li>
                ))
              ) : (
                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                  {t('currentSchedule.noFallCourses', 'No Planned Courses for Fall Semester')}
                </span>
              )}
            </ul>
          </div>

          {/* Spring Semester Section */}
          <div className="w-1/2 pl-1" style={{ maxWidth: 'calc(50%)' }}>
            <h3 className="text-lg text-gray-500 font-semibold mt-2">
              {t('currentSchedule.springSemester', 'Spring Semester')}
            </h3>
            <ul>
              {schedule.Spring.length > 0 ? (
                schedule.Spring.map((course, index) => (
                  <li
                    key={index}
                    className="bg-blue-200 text-blue-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-blue-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                  >
                    {course}
                  </li>
                ))
              ) : (
                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                  {t('currentSchedule.noSpringCourses', 'No Planned Courses for Spring Semester')}
                </span>
              )}
            </ul>
          </div>
        </div>
      ) : (
        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
          {t('currentSchedule.noCurrentYearCourses', 'No Planned Courses for Current Year')}
        </span>
      )}
    </div>
  );
};

export default CurrentSchedule;
