// scheduleAlgorithm.js
// This module computes valid schedule combinations given courseSchedules and break blocks.
// It uses a backtracking approach with early constraint pruning and scores each valid combination
// based on user-defined preferences (desired start/end times, lunch break, and after-class break).
// It supports an option to ignore recitations (using only lecture options).
// Each returned schedule includes a normalized score from 0 to 100 (100 being optimal).

function parseTimeGeneric(timeStr) {
  if (!timeStr) return 0;
  if (timeStr.toLowerCase().includes("am") || timeStr.toLowerCase().includes("pm")) {
    let str = timeStr.toLowerCase().replace(/\s/g, "").replace(/\./g, "");
    let isPM = str.includes("pm");
    let isAM = str.includes("am");
    str = str.replace("am", "").replace("pm", "");
    const parts = str.split(":");
    let hours = parseInt(parts[0], 10);
    const minutes = parts[1] ? parseInt(parts[1], 10) : 0;
    if (isPM && hours < 12) {
      hours += 12;
    }
    if (isAM && hours === 12) {
      hours = 0;
    }
    return hours * 60 + minutes;
  } else {
    const parts = timeStr.split(":");
    const hours = parseInt(parts[0], 10);
    const minutes = parts[1] ? parseInt(parts[1], 10) : 0;
    return hours * 60 + minutes;
  }
}

// Helper: Pair lecture and recitations with deduplication.
function pairLectureAndRecitations(course) {
  const lectures = course.availableSections.filter(opt => {
    return opt.sfuData &&
      opt.sfuData.courseSchedule &&
      (opt.sfuData.courseSchedule.some(
         sched => sched.classType === "e" || sched.sectionCode === "LEC"
      ) ||
       (typeof opt.section === 'string' && opt.section.toLowerCase().endsWith("00")));
  });
  // Deduplicate lectures by section (ignoring case)
  const uniqueLectures = [];
  const seen = new Set();
  lectures.forEach(lecture => {
    const sec = lecture.section.toLowerCase();
    if (!seen.has(sec)) {
      seen.add(sec);
      uniqueLectures.push(lecture);
    }
  });
  const recitations = course.availableSections.filter(opt => {
    return opt.sfuData &&
      opt.sfuData.courseSchedule &&
      (opt.sfuData.courseSchedule.some(
         sched => sched.classType === "n" && sched.sectionCode !== "LEC"
      ) ||
       !(typeof opt.section === 'string' && opt.section.toLowerCase().endsWith("00")));
  });
  
  const compositeOptions = [];
  
  uniqueLectures.forEach(lecture => {
    const lectureMeeting = lecture.sfuData.courseSchedule.find(
      sched => sched.classType === "e" || sched.sectionCode === "LEC"
    );
    const assoc = lectureMeeting ? lectureMeeting.associatedClass : null;
    let matchedRecitations = [];
    if (assoc) {
      matchedRecitations = recitations.filter(rec =>
        rec.sfuData.courseSchedule.some(sched => sched.associatedClass === assoc)
      );
    }
    if (matchedRecitations.length === 0) {
      const lecNum = parseInt(lecture.section.replace(/^\D+/, ''), 10);
      if (!isNaN(lecNum)) {
        matchedRecitations = recitations.filter(rec => {
          const recNum = parseInt(rec.section.replace(/^\D+/, ''), 10);
          return (!isNaN(recNum)) && (Math.floor(recNum / 100) === Math.floor(lecNum / 100)) && (recNum !== lecNum);
        });
      }
    }
    if (matchedRecitations.length > 0) {
      matchedRecitations.forEach(recitation => {
        // Merge lecture and recitation schedules and deduplicate meeting entries.
        const merged = [...lecture.sfuData.courseSchedule, ...recitation.sfuData.courseSchedule];
        const mergedUnique = [];
        const seenMeetings = new Set();
        merged.forEach(meeting => {
          const key = `${meeting.startTime}-${meeting.endTime}-${meeting.days}-${meeting.classType}`;
          if (!seenMeetings.has(key)) {
            seenMeetings.add(key);
            mergedUnique.push(meeting);
          }
        });
        compositeOptions.push({
          dept: course.dept,
          number: course.number,
          section: `${lecture.section}+${recitation.section}`,
          sfuData: { courseSchedule: mergedUnique },
          composite: { lecture, recitation }
        });
      });
    }
  });
  return compositeOptions;
}

function computeScheduleCombinations(courseSchedules, breaks, options) {
  const { ignoreRecitations, preferences } = options;

  // Build options array for each course.
  const optionsArray = courseSchedules.map(course => {
    if (course.sfuData && course.sfuData.courseSchedule && course.sfuData.courseSchedule.length > 0) {
      const hasLecture = course.sfuData.courseSchedule.some(
        sched => sched.classType === "e" || sched.sectionCode === "LEC"
      );
      return hasLecture ? [course] : [];
    } else if (course.availableSections && course.availableSections.length > 0) {
      if (ignoreRecitations) {
        const lectures = course.availableSections.filter(opt => {
          return opt.sfuData &&
            opt.sfuData.courseSchedule &&
            opt.sfuData.courseSchedule.some(
              sched => sched.classType === "e" || sched.sectionCode === "LEC"
            );
        });
        // Deduplicate lectures by section.
        const uniqueLectures = [];
        const seen = new Set();
        lectures.forEach(lecture => {
          const sec = lecture.section.toLowerCase();
          if (!seen.has(sec)) {
            seen.add(sec);
            uniqueLectures.push(lecture);
          }
        });
        return uniqueLectures.map(lecture => ({
          dept: course.dept,
          number: course.number,
          section: lecture.section,
          sfuData: lecture.sfuData
        }));
      } else {
        const composites = pairLectureAndRecitations(course);
        return composites;
      }
    }
    return [];
  });

  if (optionsArray.some(arr => arr.length === 0)) {
    return [];
  }

  const results = [];

  // Helper function to check conflict between two schedules.
  function isConflict(schedule1, schedule2) {
    for (const m1 of schedule1) {
      if (m1.isExam) continue;
      const days1 = m1.days ? m1.days.split(',').map(d => d.trim()) : [];
      const start1 = parseTimeGeneric(m1.startTime || '0:00');
      const end1 = parseTimeGeneric(m1.endTime || '0:00');
      for (const m2 of schedule2) {
        if (m2.isExam) continue;
        const days2 = m2.days ? m2.days.split(',').map(d => d.trim()) : [];
        const start2 = parseTimeGeneric(m2.startTime || '0:00');
        const end2 = parseTimeGeneric(m2.endTime || '0:00');
        if (days1.some(day => days2.includes(day))) {
          // Standard overlap check.
          if (start1 < end2 && end1 > start2) {
            return true;
          }
          // If after class break preference is enabled, ensure a minimum gap.
          if (preferences.afterClassBreakEnabled) {
            // If m1 finishes before m2 starts.
            if (end1 <= start2 && (start2 - end1) < preferences.afterClassBreakDuration) {
              return true;
            }
            // If m2 finishes before m1 starts.
            if (end2 <= start1 && (start1 - end2) < preferences.afterClassBreakDuration) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  // Backtracking to build valid combinations.
  function backtrack(index, currentCombination) {
    if (index === optionsArray.length) {
      results.push([...currentCombination]);
      return;
    }
    for (const option of optionsArray[index]) {
      let conflict = false;
      for (const selected of currentCombination) {
        if (isConflict(selected.sfuData.courseSchedule, option.sfuData.courseSchedule)) {
          conflict = true;
          break;
        }
      }
      if (!conflict) {
        for (const sched of option.sfuData.courseSchedule) {
          if (sched.isExam) continue;
          const days = sched.days ? sched.days.split(',').map(d => d.trim()) : [];
          const start = parseTimeGeneric(sched.startTime || '0:00');
          const end = parseTimeGeneric(sched.endTime || '0:00');
          for (const bk of breaks) {
            if (bk.days && bk.days.some(day => days.includes(day))) {
              const breakStart = parseTimeGeneric(bk.start);
              const breakEnd = parseTimeGeneric(bk.end);
              if (start < breakEnd && end > breakStart) {
                conflict = true;
                break;
              }
            }
          }
          if (conflict) break;
        }
      }
      if (!conflict) {
        currentCombination.push(option);
        backtrack(index + 1, currentCombination);
        currentCombination.pop();
      }
    }
  }

  backtrack(0, []);

  // Scoring function for a combination.
  function scoreCombination(combination) {
    let score = 0;
    let allMeetings = [];
    combination.forEach(option => {
      if (option.sfuData && option.sfuData.courseSchedule) {
        option.sfuData.courseSchedule.forEach(m => {
          if (!m.isExam) {
            allMeetings.push(m);
          }
        });
      }
    });
    if (allMeetings.length === 0) return score;
    let globalEarliest = Math.min(...allMeetings.map(m => parseTimeGeneric(m.startTime || '0:00')));
    let globalLatest = Math.max(...allMeetings.map(m => parseTimeGeneric(m.endTime || '0:00')));
    const desiredStart = parseTimeGeneric(preferences.desiredStart || '08:00');
    const desiredEnd = parseTimeGeneric(preferences.desiredEnd || '17:00');
    const lunchStart = parseTimeGeneric(preferences.lunchStart || '12:00');
    const lunchEnd = parseTimeGeneric(preferences.lunchEnd || '13:00');

    if (globalEarliest < desiredStart) {
      score += (desiredStart - globalEarliest);
    }
    if (globalLatest > desiredEnd) {
      score += (globalLatest - desiredEnd);
    }
    for (const m of allMeetings) {
      const mStart = parseTimeGeneric(m.startTime || '0:00');
      const mEnd = parseTimeGeneric(m.endTime || '0:00');
      if (mStart < lunchEnd && mEnd > lunchStart) {
        score += 1000;
      }
    }
    return score;
  }

  const scoredResults = results.map(comb => ({
    combination: comb,
    rawScore: scoreCombination(comb)
  }));

  // Deduplicate combinations.
  const uniqueCombMap = new Map();
  scoredResults.forEach(item => {
    const key = item.combination
      .map(opt => `${opt.dept}-${opt.number}-${opt.section.toLowerCase()}`)
      .join('|');
    if (!uniqueCombMap.has(key)) {
      uniqueCombMap.set(key, item);
    }
  });
  const uniqueScored = Array.from(uniqueCombMap.values());
  const rawScores = uniqueScored.map(item => item.rawScore);
  const minScore = Math.min(...rawScores);
  const maxScore = Math.max(...rawScores);
  uniqueScored.forEach(item => {
    if (maxScore > minScore) {
      item.normalizedScore = Math.round(((maxScore - item.rawScore) / (maxScore - minScore)) * 100);
    } else {
      item.normalizedScore = 100;
    }
  });

  uniqueScored.sort((a, b) => a.rawScore - b.rawScore);

  return uniqueScored.map(item => ({
    combination: item.combination,
    score: item.normalizedScore
  }));
}

export { computeScheduleCombinations, parseTimeGeneric };
