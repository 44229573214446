import React, { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import NavBar from "../NavBar";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { fetchSFUCourseDetails } from "../utils/fetchSFUCourse";
import BreaksSection from "./BreaksSection";
import CourseSection from "./CourseSection";
import { computeScheduleCombinations } from "./scheduleAlgorithm";
import { Link } from "react-router-dom";

// Fetch sections from SFU API.
async function fetchSFUSections(year, term, dept, courseNumber) {
  const baseURL = "https://www.sfu.ca/bin/wcm/course-outlines";
  const queryString = `?${year}/${term}/${dept}/${courseNumber}`;
  console.log("Fetching sections URL:", baseURL + queryString);
  const response = await fetch(baseURL + queryString, { headers: { Accept: "application/json" } });
  if (!response.ok) {
    throw new Error(`SFU API error for sections: status ${response.status}`);
  }
  return await response.json();
}

// Fallback: Try alternate terms and then previous years.
// Always fetch available sections, and if possible select a primary lecture section.
// If no valid meeting data is found, return availableSections (with sfuData set to null)
async function fetchFallbackSchedulesForCourse(course, latestYear, currentTerm) {
  const termOrder = ["spring", "summer", "fall"];
  const maxPastYears = 3;
  for (let year = latestYear; year >= latestYear - maxPastYears; year--) {
    for (let term of termOrder) {
      try {
        const sections = await fetchSFUSections(year, term, course.dept, course.number);
        if (sections && sections.length > 0) {
          const availableSections = await Promise.all(
            sections.map(async sectionItem => {
              const sectionCode = sectionItem.value;
              try {
                const data = await fetchSFUCourseDetails(year, term, course.dept, course.number, sectionCode);
                return { 
                  ...sectionItem, 
                  section: sectionCode, 
                  sfuData: data, 
                  fetchedTerm: term, 
                  sfuYear: year 
                };
              } catch (error) {
                console.error("Fallback error for", course.dept.toUpperCase(), course.number, sectionCode, error);
                return { 
                  ...sectionItem, 
                  section: sectionCode, 
                  sfuData: null, 
                  fetchedTerm: term, 
                  sfuYear: year 
                };
              }
            })
          );
          let primarySection;
          if (course.section) {
            primarySection = availableSections.find(sec =>
              sec.section.toLowerCase() === course.section.toLowerCase() &&
              sec.sfuData &&
              sec.sfuData.courseSchedule &&
              sec.sfuData.courseSchedule.length > 0
            );
          }
          if (!primarySection) {
            primarySection = availableSections.find(sec =>
              sec.sfuData &&
              sec.sfuData.courseSchedule &&
              (sec.classType === "e" ||
               (sec.sectionCode && sec.sectionCode.toUpperCase() === "LEC") ||
               (typeof sec.section === "string" && sec.section.toLowerCase().endsWith("00")))
            );
          }
          if (primarySection && primarySection.sfuData && primarySection.sfuData.courseSchedule && primarySection.sfuData.courseSchedule.length > 0) {
            let dataOriginAlert = null;
            if (primarySection.fetchedTerm !== currentTerm || primarySection.sfuYear !== latestYear) {
              dataOriginAlert = `Additional course details are from ${primarySection.fetchedTerm.toUpperCase()} ${primarySection.sfuYear}, while section data is from ${currentTerm.toUpperCase()} ${latestYear}. Data may not be finalized.`;
            }
            return { 
              sfuData: primarySection.sfuData, 
              fetchedTerm: term, 
              sfuYear: year, 
              availableSections, 
              dataOriginAlert 
            };
          } else {
            return { 
              sfuData: null, 
              fetchedTerm: term, 
              sfuYear: year, 
              availableSections 
            };
          }
        }
      } catch (error) {
        console.error(`Fallback error for ${course.dept.toUpperCase()} ${course.number} in ${year} ${term}:`, error);
      }
    }
  }
  return {};
}

function SchedulePage() {
  const { currentUser } = useAuth();

  const DAYS_MAP = {
    Mo: "Monday",
    Tu: "Tuesday",
    We: "Wednesday",
    Th: "Thursday",
    Fr: "Friday",
  };

  const parseTimeGeneric = (timeStr) => {
    if (!timeStr) return 0;
    if (timeStr.toLowerCase().includes("am") || timeStr.toLowerCase().includes("pm")) {
      let str = timeStr.toLowerCase().replace(/\s/g, "").replace(/\./g, "");
      let isPM = str.includes("pm");
      let isAM = str.includes("am");
      str = str.replace("am", "").replace("pm", "");
      const parts = str.split(":");
      let hours = parseInt(parts[0], 10);
      const minutes = parts[1] ? parseInt(parts[1], 10) : 0;
      if (isPM && hours < 12) { hours += 12; }
      if (isAM && hours === 12) { hours = 0; }
      return hours * 60 + minutes;
    } else {
      const parts = timeStr.split(":");
      const hours = parseInt(parts[0], 10);
      const minutes = parts[1] ? parseInt(parts[1], 10) : 0;
      return hours * 60 + minutes;
    }
  };

  const formatHour = (hour) => {
    const period = hour < 12 ? "AM" : "PM";
    const adjusted = hour % 12 === 0 ? 12 : hour % 12;
    return `${adjusted}:00 ${period}`;
  };

  const formatTime = (timeStr) => {
    const minutes = parseTimeGeneric(timeStr);
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    const period = h < 12 ? "AM" : "PM";
    const adjusted = h % 12 === 0 ? 12 : h % 12;
    const mm = m.toString().padStart(2, "0");
    return `${adjusted}:${mm} ${period}`;
  };

  function getScoreColor(score) {
    if (score >= 80) return "text-green-600";
    if (score >= 50) return "text-yellow-600";
    return "text-red-600";
  }

  const [ignoreDuplicateSections, setIgnoreDuplicateSections] = useState(false);
  const [activeTab, setActiveTab] = useState("buildSchedule");
  const [courseStatus, setCourseStatus] = useState("all");
  const [campus, setCampus] = useState("all");
  const [instructionMode, setInstructionMode] = useState("all");
  const [availableCampuses, setAvailableCampuses] = useState([]);
  const [availableInstructionModes, setAvailableInstructionModes] = useState([]);
  const [userData, setUserData] = useState(null);
  const [allSemesters, setAllSemesters] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [plannedCourses, setPlannedCourses] = useState([]);
  const [activeCourses, setActiveCourses] = useState([]);
  const [allCourseSchedules, setAllCourseSchedules] = useState([]);
  const [courseSchedules, setCourseSchedules] = useState([]);
  const [combinations, setCombinations] = useState([]);
  const [combinationIndex, setCombinationIndex] = useState(0);
  const [isComputing, setIsComputing] = useState(false);
  const [hasComputed, setHasComputed] = useState(false);
  const [breaks, setBreaks] = useState([]);
  const [preferences, setPreferences] = useState({
    desiredStart: "09:00",
    desiredEnd: "17:00",
    lunchStart: "12:00",
    lunchEnd: "13:00",
    afterClassBreakEnabled: false,
    afterClassBreakDuration: 10
  });
  const [ignoreRecitations, setIgnoreRecitations] = useState(false);

  // Fetch the user document
  useEffect(() => {
    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.uid);
      getDoc(userDocRef)
        .then(docSnap => {
          if (docSnap.exists()) {
            console.log("User document fetched:", docSnap.data());
            setUserData(docSnap.data());
          } else {
            console.error("User document does not exist for uid:", currentUser.uid);
          }
        })
        .catch(error => {
          console.error("Error fetching user document:", error);
        });
    }
  }, [currentUser]);

  // Build semesters from the user's schedule document
  useEffect(() => {
    if (!userData || !userData.schedules) return;
    const semesters = [];
    Object.entries(userData.schedules).forEach(([planName, planData]) => {
      const numYears = planData.numYears || 4;
      for (let y = 1; y <= numYears; y++) {
        const yearKey = `year${y}`;
        const yearData = planData[yearKey];
        if (!yearData) return;
        const fallCourses = yearData.S1 && Array.isArray(yearData.S1.courses) ? yearData.S1.courses : [];
        if (fallCourses.length >= 0) {
          semesters.push({
            planName,
            yearIndex: y,
            sfuYear: 2023 + (y - 1),
            sfuTerm: "fall",
            label: `${planName} - Year ${y} (Fall)`
          });
        }
        const springCourses = yearData.S2 && Array.isArray(yearData.S2.courses) ? yearData.S2.courses : [];
        if (springCourses.length >= 0) {
          semesters.push({
            planName,
            yearIndex: y,
            sfuYear: 2023 + (y - 1),
            sfuTerm: "spring",
            label: `${planName} - Year ${y} (Spring)`
          });
        }
      }
    });
    setAllSemesters(semesters);
    if (semesters.length > 0) {
      setSelectedSemester(semesters[0]);
    }
  }, [userData]);

  // Build the planned courses from the user's schedule document
  useEffect(() => {
    if (!selectedSemester || !userData || !userData.schedules) {
      setPlannedCourses([]);
      setActiveCourses([]);
      return;
    }
    const { planName, yearIndex, sfuTerm } = selectedSemester;
    const planData = userData.schedules[planName];
    if (!planData || !planData[`year${yearIndex}`]) return;
    const s1orS2 = sfuTerm === "fall" ? "S1" : "S2";
    const semesterData = planData[`year${yearIndex}`][s1orS2];
    if (!semesterData || !semesterData.courses) {
      setPlannedCourses([]);
      setActiveCourses([]);
      return;
    }
    const courses = semesterData.courses.map(courseId => {
      let courseInfoStr = courseId;
      if (courseId.includes(" - ")) {
        const parts = courseId.split(" - ");
        courseInfoStr = parts[parts.length - 1].trim();
      }
      const tokens = courseInfoStr.split(" ");
      return {
        dept: (tokens[0] || "").toLowerCase(),
        number: tokens[1] || "000",
        section: tokens[2] ? tokens[2].toLowerCase() : ""
      };
    });
    setPlannedCourses(courses);
    setActiveCourses(courses);
  }, [selectedSemester, userData]);

  // Fetch schedules for an individual course.
  async function fetchSchedulesForCourse(course) {
    const latestYear = new Date().getFullYear();
    const currentTerm = selectedSemester ? selectedSemester.sfuTerm.toLowerCase() : "fall";
    const courseKeyStr = `${course.dept}-${course.number}-${course.section || "nosection"}_${JSON.stringify(course)}`;
    const cacheKey = `sfu_course_${latestYear}_${currentTerm}_${courseKeyStr}`;

    const storage = JSON.parse(localStorage.getItem("sfu_courses") || "{}");
    if (storage[cacheKey]) {
      try {
        return storage[cacheKey];
      } catch (error) {
        console.error("Error parsing cached data for", cacheKey, error);
      }
    }

    let result;
    try {
      // Always fetch available sections from the current term.
      const sections = await fetchSFUSections(latestYear, currentTerm, course.dept, course.number);
      if (!sections || sections.length === 0) {
        throw new Error("No sections returned from API");
      }
      const availableSections = await Promise.all(
        sections.map(async sectionItem => {
          const sectionCode = sectionItem.value;
          try {
            const data = await fetchSFUCourseDetails(latestYear, currentTerm, course.dept, course.number, sectionCode);
            return { 
              ...sectionItem, 
              section: sectionCode, 
              sfuData: data, 
              fetchedTerm: currentTerm, 
              sfuYear: latestYear 
            };
          } catch (error) {
            console.error("Error fetching SFU data for", course.dept.toUpperCase(), course.number, sectionCode, error);
            return { 
              ...sectionItem, 
              section: sectionCode, 
              sfuData: null, 
              fetchedTerm: currentTerm, 
              sfuYear: latestYear 
            };
          }
        })
      );

      let primarySection;
      if (course.section) {
        primarySection = availableSections.find(sec =>
          sec.section.toLowerCase() === course.section.toLowerCase() &&
          sec.sfuData &&
          sec.sfuData.courseSchedule &&
          sec.sfuData.courseSchedule.length > 0
        );
      }
      if (!primarySection) {
        primarySection = availableSections.find(sec =>
          sec.sfuData &&
          sec.sfuData.courseSchedule &&
          (sec.classType === "e" ||
           (sec.sectionCode && sec.sectionCode.toUpperCase() === "LEC") ||
           (typeof sec.section === "string" && sec.section.toLowerCase().endsWith("00")))
        );
      }
      if (
        primarySection &&
        primarySection.sfuData &&
        primarySection.sfuData.courseSchedule &&
        primarySection.sfuData.courseSchedule.length > 0
      ) {
        let dataOriginAlert = null;
        if (primarySection.fetchedTerm !== currentTerm || primarySection.sfuYear !== latestYear) {
          dataOriginAlert = `Additional course details are from ${primarySection.fetchedTerm.toUpperCase()} ${primarySection.sfuYear}, while section data is from ${currentTerm.toUpperCase()} ${latestYear}. Data may not be finalized.`;
        }
        result = {
          ...course,
          sfuData: primarySection.sfuData,
          fetchedTerm: primarySection.fetchedTerm,
          sfuYear: primarySection.sfuYear,
          availableSections,
          dataOriginAlert,
        };
      } else {
        result = {
          ...course,
          sfuData: null,
          fetchedTerm: currentTerm,
          sfuYear: latestYear,
          availableSections,
        };
      }
    } catch (error) {
      console.error("Error fetching sections for", course, error);
      const fallback = await fetchFallbackSchedulesForCourse(course, latestYear, currentTerm);
      result = { ...course, ...fallback };
    }
    storage[cacheKey] = result;
    localStorage.setItem("sfu_courses", JSON.stringify(storage));
    return result;
  }

  // Fetch schedules for all planned courses.
  useEffect(() => {
    async function fetchAllSchedules() {
      if (plannedCourses.length === 0 || !selectedSemester) {
        setAllCourseSchedules([]);
        return;
      }
      const results = await Promise.all(plannedCourses.map(course => fetchSchedulesForCourse(course)));
      console.log("Fetched SFU course schedules:", results);
      // Filter out courses that do not have any valid meeting times either in sfuData or in at least one available section.
      const filteredResults = results.filter(schedule => {
        if (schedule.sfuData && schedule.sfuData.courseSchedule && schedule.sfuData.courseSchedule.length > 0) {
          return true;
        }
        if (schedule.availableSections && schedule.availableSections.some(sec => sec.sfuData && sec.sfuData.courseSchedule && sec.sfuData.courseSchedule.length > 0)) {
          return true;
        }
        console.warn(`Course ${schedule.dept.toUpperCase()} ${schedule.number} has no valid meeting times and will be skipped.`);
        return false;
      });
      setAllCourseSchedules(filteredResults);
      setCombinations([]);
      setHasComputed(false);
    }
    fetchAllSchedules();
  }, [plannedCourses, selectedSemester]);

  // Build filter arrays for campuses and delivery modes.
  useEffect(() => {
    const campusesSet = new Set();
    const modesSet = new Set();
    allCourseSchedules.forEach(schedule => {
      if (schedule.sfuData && schedule.sfuData.courseSchedule) {
        schedule.sfuData.courseSchedule.forEach(meeting => {
          if (meeting.campus) {
            campusesSet.add(meeting.campus);
          }
        });
        if (schedule.sfuData.deliveryMethod) {
          modesSet.add(schedule.sfuData.deliveryMethod);
        }
      }
    });
    setAvailableCampuses(Array.from(campusesSet).sort());
    setAvailableInstructionModes(Array.from(modesSet).sort());
  }, [allCourseSchedules]);

  // Filter courses based on active selection and other filters.
  useEffect(() => {
    const filtered = allCourseSchedules.filter(schedule => {
      const isSelectedCourse = activeCourses.some(ac =>
        ac.dept === schedule.dept &&
        ac.number === schedule.number &&
        ac.section === schedule.section
      );
      if (!isSelectedCourse) return false;
      
      let campusMatch = true;
      if (campus !== "all") {
        campusMatch =
          schedule.sfuData &&
          schedule.sfuData.courseSchedule &&
          schedule.sfuData.courseSchedule.some(
            meeting =>
              meeting.campus &&
              meeting.campus.toLowerCase() === campus.toLowerCase()
          );
      }
      
      let instructionMatch = true;
      if (instructionMode !== "all") {
        instructionMatch =
          schedule.sfuData &&
          schedule.sfuData.deliveryMethod &&
          schedule.sfuData.deliveryMethod.toLowerCase() === instructionMode.toLowerCase();
      }
      
      let statusMatch = true;
      if (courseStatus === "open") {
        statusMatch = (schedule.sfuData &&
                       !schedule.sfuData.errorMessage &&
                       schedule.sfuData.courseSchedule &&
                       schedule.sfuData.courseSchedule.length > 0) ||
                      (schedule.availableSections && schedule.availableSections.length > 0);
      }
      
      return campusMatch && instructionMatch && statusMatch;
    });
    setCourseSchedules(filtered);
  }, [activeCourses, allCourseSchedules, campus, instructionMode, courseStatus]);

  const colorOptions = [
    "bg-red-300",
    "bg-green-300",
    "bg-blue-300",
    "bg-yellow-300",
    "bg-purple-300",
    "bg-pink-300",
    "bg-indigo-300",
    "bg-teal-300",
    "bg-orange-300"
  ];
  const courseColors = {};
  plannedCourses.forEach((course, index) => {
    const key = `${course.dept}-${course.number}-${course.section}`.toLowerCase();
    courseColors[key] = colorOptions[index % colorOptions.length];
  });

  function getNormalizedCombinationKey(combination) {
    const optionKeys = combination.map(opt => {
      if (opt.sfuData && opt.sfuData.courseSchedule) {
        const meetings = opt.sfuData.courseSchedule
          .map(m => `${m.startTime}-${m.endTime}-${m.days}`)
          .sort()
          .join(',');
        return `${opt.dept}-${opt.number}-${meetings}`;
      }
      return `${opt.dept}-${opt.number}`;
    });
    return optionKeys.join('|');
  }

  async function handleComputeCombinations() {
    setHasComputed(true);
    setIsComputing(true);
    let computed = await computeScheduleCombinations(
      courseSchedules,
      breaks.filter(bk => bk.selected),
      { ignoreRecitations, preferences }
    );
    if (ignoreDuplicateSections) {
      const deduped = [];
      const seenKeys = new Set();
      computed.forEach(combo => {
        const key = getNormalizedCombinationKey(combo.combination);
        if (!seenKeys.has(key)) {
          seenKeys.add(key);
          deduped.push(combo);
        }
      });
      computed = deduped;
    }
    setCombinations(computed);
    setCombinationIndex(0);
    setIsComputing(false);
  }

  const toggleCourse = (course) => {
    const exists = activeCourses.some(ac =>
      ac.dept === course.dept &&
      ac.number === course.number &&
      ac.section === course.section
    );
    if (exists) {
      setActiveCourses(activeCourses.filter(
        ac => !(ac.dept === course.dept && ac.number === course.number && ac.section === course.section)
      ));
    } else {
      setActiveCourses([...activeCourses, course]);
    }
  };

  const selectAllCourses = (checked) => {
    setActiveCourses(checked ? plannedCourses : []);
  };

  const currentCombination = combinations[combinationIndex]?.combination || [];
  const currentScore = combinations[combinationIndex]?.score;
  let minHour = 8;
  let maxHour = 17;
  const allTimes = [];
  currentCombination.forEach(courseOption => {
    if (courseOption.sfuData && courseOption.sfuData.courseSchedule) {
      courseOption.sfuData.courseSchedule.forEach(sched => {
        if (!sched.isExam) {
          allTimes.push(parseTimeGeneric(sched.startTime || "0:00"));
          allTimes.push(parseTimeGeneric(sched.endTime || "0:00"));
        }
      });
    }
  });
  breaks.filter(bk => bk.selected).forEach(bk => {
    allTimes.push(parseTimeGeneric(bk.start));
    allTimes.push(parseTimeGeneric(bk.end));
  });
  if (allTimes.length > 0) {
    minHour = Math.floor(Math.min(...allTimes) / 60);
    maxHour = Math.ceil(Math.max(...allTimes) / 60);
  }
  const hours = Array.from({ length: maxHour - minHour }, (_, i) => minHour + i);

  return (
    <div className="w-full min-h-screen bg-white">
      <NavBar />
      <div className="container mx-auto mt-10 mb-6">
        {/* Tabs Navigation */}
        <div className="pt-10 mb-4 border-b border-gray-200">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" role="tablist">
            <li className="mr-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === "buildSchedule" ? "text-blue-600 border-blue-600" : "hover:text-gray-600 hover:border-gray-300"}`}
                type="button"
                role="tab"
                aria-selected={activeTab === "buildSchedule"}
                onClick={() => setActiveTab("buildSchedule")}
              >
                Build Schedule
              </button>
            </li>
            <li className="mr-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === "shoppingCart" ? "text-blue-600 border-blue-600" : "hover:text-gray-600 hover:border-gray-300"}`}
                type="button"
                role="tab"
                aria-selected={activeTab === "shoppingCart"}
                onClick={() => setActiveTab("shoppingCart")}
              >
                Shopping Cart
              </button>
            </li>
            <li role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === "currentSchedule" ? "text-blue-600 border-blue-600" : "hover:text-gray-600 hover:border-gray-300"}`}
                type="button"
                role="tab"
                aria-selected={activeTab === "currentSchedule"}
                onClick={() => setActiveTab("currentSchedule")}
              >
                Current Schedule
              </button>
            </li>
          </ul>
        </div>

        {/* Filters Card */}
        <Card className="mb-4">
          <div className="flex items-center">
            <div className="flex flex-row items-center space-x-4">
              <div className="flex items-center">
                <label className="mr-1 text-sm font-medium text-gray-700">Term:</label>
                <select
                  value={selectedSemester ? selectedSemester.label : ""}
                  onChange={(e) => {
                    const chosenLabel = e.target.value;
                    const newSem = allSemesters.find(s => s.label === chosenLabel);
                    setSelectedSemester(newSem || null);
                  }}
                  className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                >
                  {allSemesters.map(sem => (
                    <option key={sem.label} value={sem.label}>
                      {sem.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center">
                <label className="mr-1 text-sm font-medium text-gray-700">Course Status:</label>
                <select
                  value={courseStatus}
                  onChange={(e) => setCourseStatus(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                >
                  <option value="all">All Classes</option>
                  <option value="open">Open Classes Only</option>
                </select>
              </div>
              <div className="flex items-center">
                <label className="mr-1 text-sm font-medium text-gray-700">Campus:</label>
                <select
                  value={campus}
                  onChange={(e) => setCampus(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                >
                  <option value="all">All Campuses</option>
                  {availableCampuses.map(c => (
                    <option key={c} value={c}>{c}</option>
                  ))}
                </select>
              </div>
              <div className="flex items-center">
                <label className="mr-1 text-sm font-medium text-gray-700">Instruction Mode:</label>
                <select
                  value={instructionMode}
                  onChange={(e) => setInstructionMode(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                >
                  <option value="all">All Modes</option>
                  {availableInstructionModes.map(mode => (
                    <option key={mode} value={mode}>{mode}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </Card>

        <div className="flex flex-col md:flex-row gap-4">
          {/* Left: Courses Section */}
          <Card className="w-full md:w-1/2 shadow">
            <CourseSection
              plannedCourses={plannedCourses}
              activeCourses={activeCourses}
              allCourseSchedules={allCourseSchedules}
              isComputing={isComputing}
              computeCombinations={handleComputeCombinations}
              combinations={combinations}
              combinationIndex={combinationIndex}
              setCombinationIndex={setCombinationIndex}
              onToggleCourse={toggleCourse}
              onSelectAllCourses={selectAllCourses}
              hasComputed={hasComputed}
            />
          </Card>
          {/* Right: Breaks & Schedule Preferences */}
          <Card className="w-full md:w-1/2 shadow">
            <div>
              <BreaksSection breaks={breaks} setBreaks={setBreaks} formatTime={formatTime} />
            </div>
            <hr/>
            <div>
              <h2 className="text-lg font-bold mb-2">Schedule Preferences</h2>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <label className="text-sm font-medium text-gray-700">Desired Earliest Start:</label>
                  <input
                    type="time"
                    value={preferences.desiredStart}
                    onChange={(e) =>
                      setPreferences({ ...preferences, desiredStart: e.target.value })
                    }
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                  />
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-700">Desired Latest End:</label>
                  <input
                    type="time"
                    value={preferences.desiredEnd}
                    onChange={(e) =>
                      setPreferences({ ...preferences, desiredEnd: e.target.value })
                    }
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                  />
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-700">Lunch Start:</label>
                  <input
                    type="time"
                    value={preferences.lunchStart}
                    onChange={(e) =>
                      setPreferences({ ...preferences, lunchStart: e.target.value })
                    }
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                  />
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-700">Lunch End:</label>
                  <input
                    type="time"
                    value={preferences.lunchEnd}
                    onChange={(e) =>
                      setPreferences({ ...preferences, lunchEnd: e.target.value })
                    }
                    className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                  />
                </div>
              </div>
              <div className="mt-4 flex flex-col space-y-2">
                <div className="flex items-center">
                  <input
                    id="ignore-recitations"
                    type="checkbox"
                    checked={ignoreRecitations}
                    onChange={(e) => setIgnoreRecitations(e.target.checked)}
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor="ignore-recitations" className="ml-2 text-sm font-medium text-gray-700">
                    Ignore Recitations
                  </label>
                  <span title="When enabled, only lecture times are used, ignoring recitation meetings." className="ml-1 text-xs text-gray-500 cursor-help">ℹ️</span>
                </div>
                <div className="flex items-center">
                  <input
                    id="ignore-duplicate-sections"
                    type="checkbox"
                    checked={ignoreDuplicateSections}
                    onChange={(e) => setIgnoreDuplicateSections(e.target.checked)}
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor="ignore-duplicate-sections" className="ml-2 text-sm font-medium text-gray-700">
                    Ignore Duplicate Section Differences
                  </label>
                  <span title="When enabled, schedule combinations that differ only by section (but have identical meeting times) are deduplicated." className="ml-1 text-xs text-gray-500 cursor-help">ℹ️</span>
                </div>
                <div className="flex items-center">
                  <input
                    id="after-class-break"
                    type="checkbox"
                    checked={preferences.afterClassBreakEnabled || false}
                    onChange={(e) => setPreferences({ ...preferences, afterClassBreakEnabled: e.target.checked })}
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor="after-class-break" className="ml-2 text-sm font-medium text-gray-700">
                    After Class Break
                  </label>
                  <span title="When enabled, ensures a minimum break time after each class." className="ml-1 text-xs text-gray-500 cursor-help">ℹ️</span>
                </div>
                {preferences.afterClassBreakEnabled && (
                  <div className="flex items-center">
                    <label className="mr-2 text-sm font-medium text-gray-700">Minimum Break Time (min):</label>
                    <input
                      type="number"
                      min="0"
                      value={preferences.afterClassBreakDuration || 10}
                      onChange={(e) =>
                        setPreferences({ ...preferences, afterClassBreakDuration: parseInt(e.target.value, 10) })
                      }
                      className="mt-1 block w-20 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                    />
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>

        {plannedCourses.length > 0 ? (
          <div className="overflow-x-auto mt-6">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-center text-sm font-medium text-gray-700">Time</th>
                  {Object.values(DAYS_MAP).map(day => (
                    <th key={day} className="px-4 py-2 text-center text-sm font-medium text-gray-700">
                      {day}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {hours.map(hour => (
                  <tr key={hour}>
                    <td className="px-4 py-2 text-center text-sm text-gray-700">
                      {formatHour(hour)}
                    </td>
                    {Object.entries(DAYS_MAP).map(([abbr, day]) => {
                      const cellEntries = [];
                      currentCombination.forEach(courseOption => {
                        if (courseOption.sfuData && courseOption.sfuData.courseSchedule) {
                          courseOption.sfuData.courseSchedule.forEach(sched => {
                            if (sched.isExam) return;
                            const dayArray = sched.days ? sched.days.split(",").map(d => d.trim()) : [];
                            if (dayArray.includes(abbr)) {
                              let key = `${courseOption.dept}-${courseOption.number}-${courseOption.section}`.toLowerCase();
                              if (!courseColors[key] && courseOption.section.includes('+')) {
                                key = `${courseOption.dept}-${courseOption.number}-${courseOption.section.split('+')[0]}`.toLowerCase();
                              }
                              const courseColorClass = courseColors[key] || "bg-blue-100";
                              cellEntries.push({
                                dept: courseOption.dept,
                                number: courseOption.number,
                                section: courseOption.section,
                                startTime: sched.startTime,
                                endTime: sched.endTime,
                                colorClass: courseColorClass
                              });
                            }
                          });
                        }
                      });
                      const hourStart = hour * 60;
                      const hourEnd = (hour + 1) * 60;
                      const breakEntries = [];
                      breaks.filter(bk => bk.selected).forEach(bk => {
                        if (bk.days && bk.days.includes(abbr)) {
                          const bkStart = parseTimeGeneric(bk.start);
                          const bkEnd = parseTimeGeneric(bk.end);
                          if (bkStart < hourEnd && bkEnd > hourStart) {
                            breakEntries.push({
                              start: bk.start,
                              end: bk.end,
                              name: bk.name,
                            });
                          }
                        }
                      });
                      return (
                        <td key={day} className="px-4 py-2 align-top">
                          {cellEntries.map((entry, idx) => (
                            <div key={idx} className={`${entry.colorClass} p-1 rounded mb-1 text-xs text-center`}>
                              {entry.dept.toUpperCase()} {entry.number} ({entry.section.toUpperCase()})
                              <br />
                              {entry.startTime} - {entry.endTime}
                            </div>
                          ))}
                          {breakEntries.map((bk, idx) => (
                            <div key={idx} className="bg-red-200 p-1 rounded mb-1 text-xs text-center">
                              {bk.name} Break: {formatTime(bk.start)} - {formatTime(bk.end)}
                            </div>
                          ))}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            {combinations.length > 0 && (
              <div className="mt-4">
                <p className="mb-2 text-sm text-gray-700">
                  Showing combination {combinationIndex + 1} of {combinations.length}
                </p>
                {currentScore !== null && (
                  <p className={`font-bold text-lg ${getScoreColor(currentScore)} bg-gray-100 px-2 py-1 rounded`}>
                    Schedule Score: {currentScore}/100
                  </p>
                )}
                <div className="flex space-x-2 mt-2">
                  <button className="btn btn-outline" onClick={() => setCombinationIndex(prev => (prev > 0 ? prev - 1 : combinations.length - 1))}>
                    Prev Combination
                  </button>
                  <button className="btn btn-primary" onClick={() => setCombinationIndex(prev => (prev < combinations.length - 1 ? prev + 1 : 0))}>
                    Next Combination
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-4">
            <span className="block bg-red-100 text-red-800 text-sm font-medium px-2.5 py-1 rounded">
              No courses added.
            </span>
            <div className="mt-2">
              <Link to="/plan" className="btn btn-primary">
                Add Courses
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SchedulePage;
