import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  arrayUnion,
  getDoc,
} from 'firebase/firestore';

import debounce from 'lodash.debounce';
import { TextInput, Spinner } from 'flowbite-react';
import {
  FaSearch,
  FaPlus,
  FaEdit,
  FaTrash,
  FaInfoCircle,
  FaUserPlus,
  FaArrowLeft,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import StudentRecommendModal from './ResourcesPageComponents/StudentRecommendModal';
import CustomSidebar from '../Sidebar/Sidebar';

/** ------------------------------------------------------------------
 *  1. Config: Top-Level Categories
 *  ------------------------------------------------------------------ */
const TOP_LEVEL_CATEGORIES = [
  'Academic Support',
  'Community & Groups',
  'Health & Wellness',
  'Jobs & Internships',
  'Life at CU',
  'Safety & Crisis Support',
  'Technology Services',
  'Tuition & Enrollment',
];

/** ------------------------------------------------------------------
 *  2. ResourceDetailsModal
 *  ------------------------------------------------------------------ */
const ResourceDetailsModal = ({
  resource,
  onClose,
  onEdit,
  onDelete,
  onRecommend,
  isLoading,
  hasSelectedStudentIds = false,
}) => {
  if (!resource) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="relative bg-white dark:bg-gray-700 rounded-lg shadow-lg p-6 w-full max-w-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 
                     hover:text-gray-900 rounded-lg text-sm w-8 h-8 dark:hover:bg-gray-600 
                     dark:hover:text-white"
          onClick={onClose}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>

        <div className="mb-4">
          <h2 className="text-2xl font-bold mb-2">{resource.name}</h2>
          <p className="text-gray-700 dark:text-gray-300">
            {resource.description}
          </p>
          {resource.image && (
            <img
              src={resource.image}
              alt={resource.name}
              className="mt-4 w-full h-48 object-cover rounded"
            />
          )}

          <p className="mt-4">
            <strong>Category:</strong> {resource.category || 'N/A'}
          </p>
          <p>
            <strong>Tags:</strong>{' '}
            {Array.isArray(resource.tags) && resource.tags.length > 0
              ? resource.tags.join(', ')
              : 'None'}
          </p>
          <p className="mt-2">
            <strong>Department/School:</strong> {resource.department || 'N/A'}
          </p>
          {resource.link && (
            <p className="mt-2">
              <strong>Link:</strong>{' '}
              <a
                href={resource.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600"
              >
                {resource.link}
              </a>
            </p>
          )}
        </div>

        <div className="flex justify-end gap-2">
          <button
            onClick={onEdit}
            className="flex items-center px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
          >
            <FaEdit className="mr-2" /> Edit
          </button>
          <button
            onClick={onDelete}
            className="flex items-center px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            <FaTrash className="mr-2" /> Delete
          </button>
          <button
            onClick={onRecommend}
            className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            <FaUserPlus className="mr-2" />
            {hasSelectedStudentIds
              ? 'Recommend to Selected Students'
              : 'Recommend'}
          </button>
        </div>

        {/* Local spinner if isLoading */}
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-25 rounded-lg">
            <Spinner aria-label="Processing" />
          </div>
        )}
      </div>
    </div>
  );
};

/** ------------------------------------------------------------------
 *  3. ResourcesCard
 *  ------------------------------------------------------------------ */
const ResourcesCard = ({
  resource,
  handleShowDetails,
  handleRecommend,
  isRecommendMode,
}) => {
  if (isRecommendMode) {
    // RECOMMEND MODE - entire card is clickable, no "Details" button
    return (
      <div
        onClick={() => handleRecommend(resource)}
        className="
          bg-white dark:bg-gray-800
          border border-gray-400
          rounded-lg p-4
          flex flex-col
          cursor-pointer
          transition-colors
          hover:!bg-gray-100
        "
      >
        {resource.image && (
          <img
            src={resource.image}
            alt={resource.name}
            className="w-full h-32 object-cover rounded mb-2"
          />
        )}
        <h3 className="text-lg font-semibold mb-1">{resource.name}</h3>
        <p className="text-gray-600 dark:text-gray-300 flex-1">
          {resource.description}
        </p>
      </div>
    );
  }

  // NORMAL MODE
  return (
    <div className="bg-white dark:bg-gray-800 border border-gray-400 rounded-lg p-4 flex flex-col">
      {resource.image && (
        <img
          src={resource.image}
          alt={resource.name}
          className="w-full h-32 object-cover rounded mb-2"
        />
      )}
      <h3 className="text-lg font-semibold mb-1">{resource.name}</h3>
      <p className="text-gray-600 dark:text-gray-300 flex-1">
        {resource.description}
      </p>
      <div className="mt-2 flex justify-between items-center">
        <button
          onClick={() => handleShowDetails(resource)}
          className="flex items-center text-blue-500 hover:text-blue-700"
        >
          <FaInfoCircle className="mr-1" /> Details
        </button>
      </div>
    </div>
  );
};

/** ------------------------------------------------------------------
 *  4. The Main ResourcePage
 *  ------------------------------------------------------------------ */
const ResourcePage = () => {
  const { studentId } = useParams();
  const location = useLocation();
  const passedStudentIds = location.state?.selectedStudentIds || [];
  const navigate = useNavigate();

  // "Recommend mode" checks
  const isInSingleRecommendMode = Boolean(studentId);
  const isInMultipleRecommendMode = passedStudentIds.length > 0;
  const isRecommendMode = isInSingleRecommendMode || isInMultipleRecommendMode;

  // ----------------------------------------------------------------
  // States
  // ----------------------------------------------------------------
  const [allResources, setAllResources] = useState([]);
  const [allStudents, setAllStudents] = useState([]); // For the Recommend Modal

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  // For create/edit resource
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editResourceId, setEditResourceId] = useState(null);
  const [newResource, setNewResource] = useState({
    name: '',
    description: '',
    image: '',
    department: '',
    link: '',
    category: '',
    tags: [],
  });

  // For details modal
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsResource, setDetailsResource] = useState(null);

  // For recommending
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [assigning, setAssigning] = useState(false); // local spinner in details modal

  // For page load / create/edit/delete loading
  const [loading, setLoading] = useState(false);

  // ----------------------------------------------------------------
  // 1) Fetch all resources & students on mount
  // ----------------------------------------------------------------
  useEffect(() => {
    const fetchAllResources = async () => {
      setLoading(true);
      try {
        const snapshot = await getDocs(collection(db, 'resources'));
        const data = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setAllResources(data);
      } catch (error) {
        console.error('Error fetching resources:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAllStudents = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'users'));
        const data = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setAllStudents(data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchAllResources();
    fetchAllStudents();
  }, []);

  // ----------------------------------------------------------------
  // 2) In-memory filtering
  // ----------------------------------------------------------------
  const debouncedSearch = useCallback(
    debounce((term) => setSearchTerm(term), 300),
    []
  );

  const filteredResources = allResources.filter((res) => {
    // Category
    if (selectedCategory && res.category !== selectedCategory) return false;
    // Tag
    if (selectedTag && (!res.tags || !res.tags.includes(selectedTag))) {
      return false;
    }
    // Search
    if (searchTerm) {
      const lower = searchTerm.toLowerCase();
      const matches =
        res.name.toLowerCase().includes(lower) ||
        res.description.toLowerCase().includes(lower) ||
        (res.department || '').toLowerCase().includes(lower) ||
        (res.link || '').toLowerCase().includes(lower);
      if (!matches) return false;
    }
    return true;
  });

  // Get tags available in the selected category
  const getAvailableTags = () => {
    if (!selectedCategory) return [];
    const inCat = allResources.filter((r) => r.category === selectedCategory);
    const allTags = new Set();
    inCat.forEach((r) => {
      if (Array.isArray(r.tags)) {
        r.tags.forEach((t) => allTags.add(t));
      }
    });
    return Array.from(allTags);
  };

  // ----------------------------------------------------------------
  // Category & Tag
  // ----------------------------------------------------------------
  const handleSelectCategory = (cat) => {
    if (cat === selectedCategory) {
      setSelectedCategory('');
      setSelectedTag('');
    } else {
      setSelectedCategory(cat);
      setSelectedTag('');
    }
  };

  const handleTagSelect = (tag) => {
    if (tag === selectedTag) {
      setSelectedTag('');
    } else {
      setSelectedTag(tag);
    }
  };

  // ----------------------------------------------------------------
  // CREATE / EDIT Resource
  // ----------------------------------------------------------------
  const handleToggleCreateForm = () => {
    setShowCreateForm(!showCreateForm);
    if (!showCreateForm) {
      // Reset if we are opening
      setIsEditing(false);
      setEditResourceId(null);
      setNewResource({
        name: '',
        description: '',
        image: '',
        department: '',
        link: '',
        category: '',
        tags: [],
      });
    }
  };

  const handleInputChange = (e) => {
    setNewResource({
      ...newResource,
      [e.target.name]: e.target.value,
    });
  };

  const handleTagsChange = (value) => {
    const arr = value
      .split(',')
      .map((tag) => tag.trim())
      .filter(Boolean);
    setNewResource({ ...newResource, tags: arr });
  };

  const handleCreateResource = async () => {
    if (!newResource.name || !newResource.description || !newResource.category) {
      toast.info('Please fill out name, description, and category.');
      return;
    }
    setLoading(true);
    try {
      const resourcesRef = collection(db, 'resources');
      const docRef = await addDoc(resourcesRef, {
        ...newResource,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      const created = { id: docRef.id, ...newResource };

      // Optimistic update
      setAllResources((prev) => [...prev, created]);

      // Close form
      setShowCreateForm(false);
      setNewResource({
        name: '',
        description: '',
        image: '',
        department: '',
        link: '',
        category: '',
        tags: [],
      });
    } catch (error) {
      console.error('Error creating resource:', error);
      toast.error('Failed to create resource. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditResource = (resource) => {
    setIsEditing(true);
    setEditResourceId(resource.id);
    setNewResource({
      name: resource.name || '',
      description: resource.description || '',
      image: resource.image || '',
      department: resource.department || '',
      link: resource.link || '',
      category: resource.category || '',
      tags: Array.isArray(resource.tags) ? resource.tags : [],
    });
    setShowCreateForm(true);
    setShowDetailsModal(false);
  };

  const handleSaveEditedResource = async () => {
    if (!editResourceId) return;
    if (!newResource.name || !newResource.description || !newResource.category) {
      toast.info('Please fill out name, description, and category.');
      return;
    }
    setLoading(true);
    try {
      const resourceRef = doc(db, 'resources', editResourceId);
      await updateDoc(resourceRef, {
        ...newResource,
        updatedAt: new Date(),
      });

      // Optimistic update
      setAllResources((prev) =>
        prev.map((r) =>
          r.id === editResourceId ? { id: r.id, ...newResource } : r
        )
      );

      setShowCreateForm(false);
      setIsEditing(false);
      setEditResourceId(null);
      setNewResource({
        name: '',
        description: '',
        image: '',
        department: '',
        link: '',
        category: '',
        tags: [],
      });
    } catch (error) {
      console.error('Error updating resource:', error);
      toast.error('Failed to update resource. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // ----------------------------------------------------------------
  // DELETE Resource
  // ----------------------------------------------------------------
  const handleDeleteResource = async (resourceId) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this resource?'
    );
    if (!confirmDelete) return;
    setLoading(true);
    try {
      await deleteDoc(doc(db, 'resources', resourceId));

      // Optimistic update
      setAllResources((prev) => prev.filter((r) => r.id !== resourceId));
      setShowDetailsModal(false);
    } catch (error) {
      console.error('Error deleting resource:', error);
      toast.error('Failed to delete resource. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // ----------------------------------------------------------------
  // DETAILS & RECOMMEND
  // ----------------------------------------------------------------
  const handleShowDetails = (resource) => {
    if (isRecommendMode) {
      // In recommend mode, do nothing or call handleRecommend:
      return;
    }
    setDetailsResource(resource);
    setShowDetailsModal(true);
  };

  const recommendToSingleStudent = async (resource, singleId) => {
    if (!resource || !singleId) return;
    setAssigning(true);
    try {
      const studentRef = doc(db, 'users', singleId);
      const studentDocSnap = await getDoc(studentRef);

      if (studentDocSnap.exists()) {
        const studentData = studentDocSnap.data();
        if (studentData?.Resources?.includes(resource.id)) {
          toast.info(
            `Resource "${resource.name}" is already assigned to this student.`
          );
        } else {
          await updateDoc(studentRef, {
            Resources: arrayUnion(resource.id),
          });
          toast.success(
            `Resource "${resource.name}" recommended successfully to the student.`
          );
        }
      }
      setShowDetailsModal(false);
    } catch (error) {
      console.error('Error recommending resource:', error);
      toast.error('Failed to recommend resource. Please try again.');
    } finally {
      setAssigning(false);
    }
  };

  const handleBulkRecommend = async (resource) => {
    if (!resource || passedStudentIds.length === 0) return;
    setAssigning(true);
    let assignedCount = 0;
    let alreadyCount = 0;

    try {
      // Check each student individually
      for (const sId of passedStudentIds) {
        const sRef = doc(db, 'users', sId);
        const sSnap = await getDoc(sRef);

        if (sSnap.exists()) {
          const sData = sSnap.data();
          if (sData?.Resources?.includes(resource.id)) {
            alreadyCount++;
          } else {
            await updateDoc(sRef, {
              Resources: arrayUnion(resource.id),
            });
            assignedCount++;
          }
        }
      }

      toast.success(
        `Resource "${resource.name}" recommended to ${assignedCount} student(s). Already assigned to ${alreadyCount}.`
      );
      setShowDetailsModal(false);
    } catch (error) {
      console.error('Error recommending resource:', error);
      toast.error('Failed to recommend resource. Please try again.');
    } finally {
      setAssigning(false);
    }
  };

  const handleRecommend = (resource) => {
    // If we have multiple student IDs in location.state, do a bulk recommend
    if (isInMultipleRecommendMode) {
      handleBulkRecommend(resource);
      return;
    }
    // If we have a single student param
    if (isInSingleRecommendMode) {
      recommendToSingleStudent(resource, studentId);
      return;
    }
    // Otherwise open the StudentRecommendModal
    setSelectedResource(resource);
    setShowAssignModal(true);
  };

  // For the StudentRecommendModal "onRecommendSuccess" callback
  const handleRecommendToSelectedStudents = async (selectedStudentIds) => {
    if (!selectedResource) return;
    setAssigning(true);

    let assignedCount = 0;
    let alreadyCount = 0;
    try {
      for (const sId of selectedStudentIds) {
        const sRef = doc(db, 'users', sId);
        const sSnap = await getDoc(sRef);
        if (sSnap.exists()) {
          const sData = sSnap.data();
          if (sData?.Resources?.includes(selectedResource.id)) {
            alreadyCount++;
          } else {
            await updateDoc(sRef, {
              Resources: arrayUnion(selectedResource.id),
            });
            assignedCount++;
          }
        }
      }
      toast.success(
        `Resource "${selectedResource.name}" recommended to ${assignedCount} student(s). Already assigned to ${alreadyCount}.`
      );
      setShowAssignModal(false);
      setSelectedResource(null);
    } catch (error) {
      console.error('Error recommending resource:', error);
      toast.error('Failed to recommend resource. Please try again.');
    } finally {
      setAssigning(false);
    }
  };

  // ----------------------------------------------------------------
  // CLOSE MODALS
  // ----------------------------------------------------------------
  const closeAllModals = () => {
    // Closes EVERYTHING (used by the create/edit & details modals)
    setShowCreateForm(false);
    setIsEditing(false);
    setEditResourceId(null);
    setNewResource({
      name: '',
      description: '',
      image: '',
      department: '',
      link: '',
      category: '',
      tags: [],
    });
    setShowDetailsModal(false);
    setDetailsResource(null);
    setShowAssignModal(false);
    setSelectedResource(null);
  };

  // Only close the recommendation modal
  const handleCloseRecommendModal = () => {
    setShowAssignModal(false);
    setSelectedResource(null);
  };

  return (
    <div className="flex min-h-screen">
      {/* Left Sidebar */}
      <CustomSidebar adjustment="Resources" />

      {/* Main Content */}
      <div className="flex-1 p-4 ml-20 flex flex-col">
        {/* BANNER if in "Recommend Mode" */}
        {(isInSingleRecommendMode || isInMultipleRecommendMode) && (
          <div className="mt-3 bg-gray-100 border border-gray-200 text-gray-700 px-3 py-2 rounded flex items-center gap-2">
            {/* Back Arrow Button */}
            <button
              onClick={() => navigate(-1)}
              className="text-blue-500 hover:text-blue-700"
              aria-label="Go back"
            >
              <FaArrowLeft size={16} />
            </button>
            {isInSingleRecommendMode ? (
              <p>
                <strong>Recommend Mode:</strong> You are recommending resources to a single student (ID: {studentId}).
              </p>
            ) : (
              <p>
                <strong>Recommend Mode:</strong> You are recommending resources to {passedStudentIds.length} student(s).
              </p>
            )}
          </div>
        )}

        {/* Title / Subtitle */}
        <div className="text-center mb-4">
          <h1 className="text-3xl font-bold mb-2 mt-3">Campus Resources</h1>
          <p className="text-base text-gray-600 dark:text-gray-400 mb-2">
            Organized into top-level categories &amp; optional tag filtering.
          </p>
        </div>

        {/* Category Buttons */}
        <div className="flex flex-wrap gap-2 justify-center mb-4">
          {TOP_LEVEL_CATEGORIES.map((cat) => (
            <button
              key={cat}
              onClick={() => handleSelectCategory(cat)}
              className={`px-3 py-2 text-sm font-medium rounded 
                border border-blue-500 
                ${
                  selectedCategory === cat
                    ? 'bg-blue-500 text-white'
                    : 'text-blue-500 hover:bg-blue-500 hover:text-white'
                }`}
            >
              {cat}
            </button>
          ))}
        </div>

        {/* Tag Filter */}
        {selectedCategory && (
          <div className="flex flex-wrap items-center justify-center mb-4 gap-2">
            <span className="text-sm font-medium">
              Filter resources by tag:
            </span>
            {getAvailableTags().length === 0 ? (
              <span className="text-gray-500">No tags found</span>
            ) : (
              getAvailableTags().map((tag) => (
                <button
                  key={tag}
                  onClick={() => handleTagSelect(tag)}
                  className={`px-3 py-1 text-sm border rounded 
                    ${
                      selectedTag === tag
                        ? 'bg-green-500 text-white border-green-500'
                        : 'border-green-500 text-green-500 hover:bg-green-500 hover:text-white'
                    }`}
                >
                  {tag}
                </button>
              ))
            )}
            {selectedTag && (
              <button
                onClick={() => handleTagSelect(selectedTag)}
                className="px-2 py-1 text-xs text-gray-500 underline"
              >
                Clear Tag
              </button>
            )}
          </div>
        )}

        {/* Search & New Resource */}
        <div className="flex flex-col sm:flex-row items-center gap-4 mb-4">
          <div className="flex-1 w-full">
            <TextInput
              type="text"
              placeholder="Search resources..."
              icon={FaSearch}
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </div>
          <button
            type="button"
            onClick={handleToggleCreateForm}
            className="flex items-center justify-center text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 
                       focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg 
                       text-sm px-3 py-2 text-center dark:border-blue-500 dark:text-blue-500 
                       dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
          >
            <FaPlus className="mr-2" />
            {showCreateForm && !isEditing ? 'Cancel' : 'New Resource'}
          </button>
        </div>

        {/* Resource Grid or Spinner */}
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="flex justify-center">
              <Spinner aria-label="Loading resources" />
            </div>
          ) : filteredResources.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              {filteredResources.map((resource) => (
                <ResourcesCard
                  key={resource.id}
                  resource={resource}
                  handleShowDetails={handleShowDetails}
                  handleRecommend={handleRecommend}
                  isRecommendMode={isRecommendMode}
                />
              ))}
            </div>
          ) : (
            <div className="col-span-full text-center text-gray-500">
              No resources found.
            </div>
          )}
        </div>
      </div>

      {/* Create/Edit Resource Modal */}
      {showCreateForm && (
        <div
          id="crud-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 justify-center 
            items-center w-full md:inset-0 h-full max-h-full overflow-y-auto 
            overflow-x-hidden flex bg-black bg-opacity-50 backdrop-blur-sm"
          onClick={closeAllModals}
        >
          <div className="relative p-4 w-full max-w-md max-h-[85vh] overflow-y-auto">
            <div
              className="relative bg-white dark:bg-gray-700 rounded-lg shadow"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {isEditing ? 'Edit Resource' : 'Create New Resource'}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                    rounded-lg text-sm w-8 h-8 dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={closeAllModals}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <form className="p-4 md:p-5">
                <div className="grid gap-4 mb-4">
                  {/* Name */}
                  <div className="col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Resource Name
                    </label>
                    <TextInput
                      name="name"
                      placeholder="Enter resource name"
                      value={newResource.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  {/* Description */}
                  <div className="col-span-2">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Description
                    </label>
                    <TextInput
                      name="description"
                      placeholder="Enter description"
                      value={newResource.description}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  {/* Category */}
                  <div className="col-span-2">
                    <label
                      htmlFor="category"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Category
                    </label>
                    <select
                      name="category"
                      className="w-full border rounded px-3 py-2"
                      value={newResource.category}
                      onChange={(e) =>
                        setNewResource({ ...newResource, category: e.target.value })
                      }
                      required
                    >
                      <option value="">-- Select Category --</option>
                      {TOP_LEVEL_CATEGORIES.map((cat) => (
                        <option key={cat} value={cat}>
                          {cat}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Tags (comma separated) */}
                  <div className="col-span-2">
                    <label
                      htmlFor="tags"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Tags (comma separated)
                    </label>
                    <TextInput
                      name="tags"
                      placeholder="e.g. Advising, Tutoring"
                      value={newResource.tags.join(',')}
                      onChange={(e) => handleTagsChange(e.target.value)}
                    />
                  </div>
                  {/* Image URL */}
                  <div className="col-span-2">
                    <label
                      htmlFor="image"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Image URL
                    </label>
                    <TextInput
                      name="image"
                      placeholder="Enter image URL"
                      value={newResource.image}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Department */}
                  <div className="col-span-2">
                    <label
                      htmlFor="department"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Department/School
                    </label>
                    <TextInput
                      name="department"
                      placeholder="Enter department or school"
                      value={newResource.department}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Link */}
                  <div className="col-span-2">
                    <label
                      htmlFor="link"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Resource Link
                    </label>
                    <TextInput
                      name="link"
                      placeholder="Enter resource link"
                      value={newResource.link}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                {/* Action Button with local spinner if loading */}
                <div className="flex justify-center relative">
                  <button
                    type="button"
                    className="flex items-center text-white bg-blue-700 
                      hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 
                      font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 
                      dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={
                      isEditing ? handleSaveEditedResource : handleCreateResource
                    }
                    disabled={loading}
                  >
                    {isEditing ? (
                      <>
                        <FaEdit className="mr-2" /> Save Changes
                      </>
                    ) : (
                      <>
                        <FaPlus className="mr-2" /> Create Resource
                      </>
                    )}
                  </button>
                  {loading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-25 rounded-lg">
                      <Spinner aria-label="Saving..." />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Details Modal (not in recommend mode) */}
      {!isRecommendMode && showDetailsModal && detailsResource && (
        <ResourceDetailsModal
          resource={detailsResource}
          onClose={() => setShowDetailsModal(false)}
          onEdit={() => handleEditResource(detailsResource)}
          onDelete={() => handleDeleteResource(detailsResource.id)}
          onRecommend={() => handleRecommend(detailsResource)}
          isLoading={assigning} // local spinner for recommending
          hasSelectedStudentIds={passedStudentIds.length > 0}
        />
      )}

      {/* StudentRecommendModal */}
      {showAssignModal && selectedResource && (
        <StudentRecommendModal
          isVisible={showAssignModal}
          onClose={handleCloseRecommendModal}
          selectedResource={selectedResource}
          singleStudentId={studentId}
          onRecommendSuccess={handleRecommendToSelectedStudents}
          students={allStudents}
          isSubmitting={assigning}
        />
      )}
    </div>
  );
};

export default ResourcePage;