import React from 'react';
import { BsPlus } from 'react-icons/bs';

const NotesTab = ({
  notes,
  newNote,
  onAddNote,
  onDeleteNote,
  onNewNoteChange,
}) => {
  return (
    <div className="flex flex-col h-screen p-4">
      <h2 className="text-xl font-semibold mb-4">Notes</h2>

      {/* Textarea with plus icon */}
      <div className="w-full relative mb-4">
        <textarea
          className="w-full h-20 p-2 pr-10 border border-gray-300 rounded resize-none"
          placeholder="Add a new note..."
          value={newNote}
          onChange={(e) => onNewNoteChange(e.target.value)}
        />
        <button
          className="absolute top-2 right-2 bg-blue-500 text-white p-2 rounded-full 
                     hover:bg-blue-600 transition-colors"
          onClick={onAddNote}
          title="Add Note"
        >
          <BsPlus className="w-5 h-5" />
        </button>
      </div>

      {/* Existing notes list */}
      <div className="flex-1 overflow-auto">
        <ul className="space-y-2 mt-2">
          {notes.length > 0 ? (
            notes.map((note, index) => (
              <li
                key={index}
                className="relative p-2 bg-yellow-100 text-yellow-800 
                           rounded shadow-md"
              >
                <div>{note.text}</div>
                {note.timestamp && note.timestamp.toDate && (
                  <div className="text-xs text-gray-600 mt-1">
                    {note.timestamp.toDate().toLocaleString()}
                  </div>
                )}
                <button
                  className="absolute top-1 right-1 text-red-500 hover:text-red-700"
                  onClick={() => onDeleteNote(index)}
                  title="Delete Note"
                >
                  &times;
                </button>
              </li>
            ))
          ) : (
            <p className="text-gray-500 text-sm">No notes yet.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NotesTab;
