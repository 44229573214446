import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Detect user's language
import en from './locales/en.json'; // Import English translations
import fr from './locales/fr.json'; // Import French translations

console.log('Initializing i18next...');
console.log('Loaded languages:', Object.keys({ en, fr }));

i18n
  .use(LanguageDetector) // Automatically detect user's language preference
  .use(initReactI18next) // Bind i18n instance to React
  .init({
    resources: {
      en: { translation: en }, // English translations
      fr: { translation: fr }, // French translations
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if no match is found
    interpolation: {
      escapeValue: false, // React already handles escaping for security
    },
    detection: {
      // Configure how language is detected
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'], // Priority of language detection methods
      caches: ['cookie', 'localStorage'], // Where to store the detected language
    },
    react: {
      useSuspense: false, // Disable suspense for now (if using it elsewhere, you can enable this)
    },
  });

export default i18n;
