import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar';
import '../../index.css';
import { doc, getDocs, getDoc, collection, query, where, orderBy, limit, onSnapshot } from "firebase/firestore";
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../firebase';
import { Card } from 'flowbite-react';
import { toast } from 'react-toastify';
import ProfileJoyrideTour from './ProfileJoyrideTour';
import ScheduleMeetingModal from './MeetingsComponents/ScheduleMeetingModal';
import Heading from './ProfilePageComponents/Heading';
import ProgressBars from './ProfilePageComponents/CourseProgressBar';
import SchoolPortals from './ProfilePageComponents/SchoolPortals';
import InterestsList from './ProfilePageComponents/InterestsList';
import AdvisingDisplay from './ProfilePageComponents/AdvisingDisplay';
import CurrentSchedule from './ProfilePageComponents/CurrentSchedule';
import RecommendedResources from './ProfilePageComponents/RecommendedResources';

// 1. Import useTranslation
import { useTranslation } from 'react-i18next';

const StudentProfile = () => {
    // 2. Use the t function for translations
    const { t } = useTranslation();

    const [currentUser, setCurrentUser] = useState(null);
    const [schedule, setSchedule] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentUser: authUser, fetchedCourseData, fetchedMajors, fetchedSurveyData, fetchedMinors } = useAuth();
    const [finalizedSchedule, setFinalizedSchedule] = useState({});
    const [completedSemesters, setCompletedSemesters] = useState({});
    const [milestones, setMilestones] = useState([]);
    const [completedMilestones, setCompletedMilestones] = useState(0);
    const [advisors, setAdvisors] = useState([]); 
    const [isAdvisingModalOpen, setIsAdvisingModalOpen] = useState(false);
    const [scheduledMeetings, setScheduledMeetings] = useState([]);
    const [recommendedResources, setRecommendedResources] = useState([]);
    const [resourcesLoading, setResourcesLoading] = useState(true);
    const [resourcesError, setResourcesError] = useState(null);

    const yearMapping = {
        "First Year": "year1",
        "Second Year": "year2",
        "Third Year": "year3",
        "Fourth Year": "year4",
        "Fifth Year": "year5"
    };

    const fetchScheduleData = (year, finalizedSchedule) => {
        if (year && finalizedSchedule) {
            const mappedYear = yearMapping[year];
            if (mappedYear) {
                const fallCourses = [];
                const springCourses = [];
                // Check if Fall semester exists and push courses to fallCourses array
                if (finalizedSchedule[mappedYear]?.S1) {
                    fallCourses.push(...finalizedSchedule[mappedYear].S1.courses);
                }
                // Check if Spring semester exists and push courses to springCourses array
                if (finalizedSchedule[mappedYear]?.S2) {
                    springCourses.push(...finalizedSchedule[mappedYear].S2.courses);
                }
                setSchedule({
                    Fall: fallCourses,
                    Spring: springCourses,
                });
            }
        }
    };

    const getStudentInfo = async () => {
        try {
            if (authUser) {
                setCurrentUser(authUser);
                const userDocRef = doc(db, 'users', authUser.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();

                    // Validate and retrieve primaryTab
                    const primaryTab = userData?.primaryTab;
                    if (!primaryTab) {
                        console.error('primaryTab is undefined');
                        return;
                    }

                    // Validate and retrieve schedules
                    const schedules = userData?.schedules;
                    if (!schedules || !schedules[primaryTab]) {
                        console.error(`No schedules found for primaryTab: ${primaryTab}`);
                        return;
                    }

                    const retrievedFinalizedSchedule = schedules[primaryTab];
                    setFinalizedSchedule(retrievedFinalizedSchedule);

                    const retrievedCompletedSemesters = userData.completedSemesters || {};
                    setCompletedSemesters(retrievedCompletedSemesters);

                    let courseArray = [];
                    for (let i = 1; i <= 5; i++) {
                        let year = `year${i}`;
                        for (let j = 1; j <= 2; j++) {
                            let semester = `S${j}`;
                            if (retrievedFinalizedSchedule[year] && retrievedFinalizedSchedule[year][semester]) {
                                courseArray.push(...retrievedFinalizedSchedule[year][semester]['courses']);
                            }
                        }
                    }

                    const seeYr = userData.surveyAnswers?.Year || 'defaultYear';
                    const mappedSeeYr = yearMapping[seeYr];
                    if (!mappedSeeYr) {
                        console.warn(`Year mapping for '${seeYr}' not found.`);
                    }

                    let courseArray1 = [];
                    for (let j = 1; j <= 2; j++) {
                        let semester = `S${j}`;
                        if (retrievedFinalizedSchedule[mappedSeeYr] && retrievedFinalizedSchedule[mappedSeeYr][semester]) {
                            courseArray1.push(...retrievedFinalizedSchedule[mappedSeeYr][semester]['courses']);
                        }
                    }

                    const year = userData.surveyAnswers?.Year || 'N/A';
                    await fetchScheduleData(year, schedules[primaryTab]);
                } else {
                    console.error('User document does not exist');
                }
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (authUser && fetchedCourseData) {
            getStudentInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser, fetchedCourseData, fetchedSurveyData]);

    useEffect(() => {
        const fetchMilestoneData = async () => {
            if (authUser) {
                const userDocRef = doc(db, 'users', authUser.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    if (data.finalizedMilestones) {
                        const primaryTab = data.primaryTab || 'defaultTab'; 
                        const milestonesInprimaryTab = data.finalizedMilestones[primaryTab] || {};

                        const milestoneList = Object.values(milestonesInprimaryTab).reduce((acc, yearData) => {
                            Object.values(yearData).forEach(semData => {
                                if (semData.milestones) {
                                    acc = acc.concat(semData.milestones);
                                }
                            });
                            return acc;
                        }, []);

                        setMilestones(milestoneList);
                        const completed = milestoneList.filter(milestone => milestone.completed).length;
                        setCompletedMilestones(completed);
                    }
                }
            }
        };
        fetchMilestoneData();
    }, [authUser]);

    useEffect(() => {
        const fetchAdvisors = async () => {
            try {
                if (!currentUser) {
                    setLoading(true);
                    return;
                }
                const studentId = currentUser.uid;

                // Fetch the advisor assignments from 'studentAdvisors' collection
                const mappingDoc = await db.collection('studentAdvisors').doc(studentId).get();

                if (!mappingDoc.exists) {
                    // If you want a toast for no advisors:
                    // toast.info(t('studentProfile.noAdvisorAssigned', 'You have not been assigned any advisors yet.'));
                    return;
                }

                const { advisorIds } = mappingDoc.data();
                if (!advisorIds || advisorIds.length === 0) {
                    // toast.info(t('studentProfile.noAdvisorAssigned', 'You have not been assigned any advisors yet.'));
                    return;
                }

                const advisorPromises = advisorIds.map(id => db.collection('advisors').doc(id).get());
                const advisorDocs = await Promise.all(advisorPromises);

                const existingAdvisors = advisorDocs
                    .filter(doc => doc.exists)
                    .map(doc => ({
                        id: doc.id,
                        advisorId: doc.id,
                        firstName: doc.data().firstName,
                        lastName: doc.data().lastName,
                        meetingLink: doc.data().meetingLink || '',
                        profilePicture: doc.data().profilePicture || '/default.jpg'
                    }));

                const missingAdvisors = advisorDocs.filter(doc => !doc.exists).map(doc => doc.id);
                if (missingAdvisors.length > 0) {
                    toast.error(`Assigned advisor data not found for IDs: ${missingAdvisors.join(', ')}`);
                }

                setAdvisors(existingAdvisors);
            } catch (error) {
                console.error('Error fetching advisors:', error);
                toast.error(t('studentProfile.failedFetchAdvisors', 'Failed to fetch advisors.'));
            }
        };

        fetchAdvisors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    useEffect(() => {
        if (authUser) {
            const meetingsQuery = query(
                collection(db, 'meetings'),
                where('studentId', '==', authUser.uid),
                where('status', '==', 'Scheduled'),
                orderBy('datetime', 'asc'),
                limit(5)
            );

            const unsubscribe = onSnapshot(meetingsQuery, (snapshot) => {
                const meetingsData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setScheduledMeetings(meetingsData);
            }, (error) => {
                console.error('Error fetching scheduled meetings:', error);
                toast.error(t('studentProfile.scheduledMeetingFetchFailure', 'Failed to fetch scheduled meetings.'));
            });

            return () => unsubscribe();
        }
    }, [authUser, t]);

    const fetchScheduledMeetings = async () => {
        try {
            const meetingsSnapshot = await db
                .collection('meetings')
                .where('studentId', '==', authUser.uid)
                .where('status', '==', 'Scheduled')
                .orderBy('datetime', 'asc')
                .limit(5)
                .get();

            const meetingsData = meetingsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            setScheduledMeetings(meetingsData);
        } catch (error) {
            console.error('Error fetching scheduled meetings:', error);
            toast.error(t('studentProfile.scheduledMeetingFetchFailure', 'Failed to fetch scheduled meetings.'));
        }
    };

    useEffect(() => {
        if (authUser) {
            fetchScheduledMeetings();
        }
    }, [authUser]);

    // Fetch recommended resources
    const fetchRecommendedResources = async () => {
        try {
            if (currentUser) {
                const userDocRef = doc(db, 'users', currentUser.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    const resourceIds = userData.Resources || [];

                    if (resourceIds.length > 0) {
                        const resourcesData = [];
                        const batchSize = 10;
                        const batches = [];

                        // Split resourceIds into batches of 10
                        for (let i = 0; i < resourceIds.length; i += batchSize) {
                            const batchIds = resourceIds.slice(i, i + batchSize);
                            const resourcesCollection = collection(db, 'resources');
                            const resourcesQuery = query(resourcesCollection, where('__name__', 'in', batchIds));
                            batches.push(getDocs(resourcesQuery));
                        }

                        const snapshots = await Promise.all(batches);
                        snapshots.forEach(snapshot => {
                            snapshot.docs.forEach(docSnap => {
                                resourcesData.push({
                                    id: docSnap.id,
                                    ...docSnap.data(),
                                });
                            });
                        });

                        setRecommendedResources(resourcesData);
                    } else {
                        setRecommendedResources([]);
                    }
                } else {
                    setRecommendedResources([]);
                }
            }
        } catch (error) {
            console.error('Error fetching recommended resources:', error);
            setResourcesError(t('studentProfile.failedLoadResources', 'Failed to load recommended resources.'));
        } finally {
            setResourcesLoading(false);
        }
    };

    useEffect(() => {
        if (currentUser) {
            fetchRecommendedResources();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    const openAdvisingModal = () => {
        setIsAdvisingModalOpen(true);
    };

    const closeAdvisingModal = () => {
        setIsAdvisingModalOpen(false);
    };

    if (loading) {
        return (
            <div role="status" className="flex items-center justify-center h-screen">
                <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 
                           50 100.591C22.3858 100.591 0 78.2051 0 
                           50.5908C0 22.9766 22.3858 0.59082 50 
                           0.59082C77.6142 0.59082 100 22.9766 100 
                           50.5908ZM9.08144 50.5908C9.08144 73.1895 
                           27.4013 91.5094 50 91.5094C72.5987 91.5094 
                           90.9186 73.1895 90.9186 50.5908C90.9186 
                           27.9921 72.5987 9.67226 50 9.67226C27.4013 
                           9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 
                           97.8624 35.9116 97.0079 33.5539C95.2932 
                           28.8227 92.871 24.3692 89.8167 20.348C85.8452 
                           15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 
                           4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 
                           0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 
                           1.69328 37.813 4.19778 38.4501 6.62326C39.0873 
                           9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 
                           9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 
                           10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 
                           17.9648 79.3347 21.5619 82.5849 25.841C84.9175 
                           28.9121 86.7997 32.2913 88.1811 35.8758C89.083 
                           38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    />
                </svg>
                {/* Use translation for the loading text */}
                <span className="sr-only">
                    {t('studentProfile.loading', 'Loading...')}
                </span>
            </div>
        );
    }

    return (
        <div>
            <NavBar />
            {/* If you use a guided tour, ensure that any textual content is also localized 
               <ProfileJoyrideTour/> 
            */}
            <Card className="w-full max-w-6xl px-4 bg-white-100 shadow-md relative mt-20">
                <Heading authUser={authUser} />
                <ProgressBars
                    authUser={authUser}
                    fetchedMajors={fetchedMajors}
                    fetchedMinors={fetchedMinors}
                    fetchedCourseData={fetchedCourseData}
                    finalizedSchedule={finalizedSchedule}
                    completedSemesters={completedSemesters}
                    milestones={milestones}
                    completedMilestones={completedMilestones}
                />

                <div className="grid grid-cols-3 gap-2 mt-1">
                    <SchoolPortals />
                    <InterestsList authUser={authUser} />
                    <AdvisingDisplay
                        advisors={advisors}
                        scheduledMeetings={scheduledMeetings}
                        openAdvisingModal={openAdvisingModal}
                    />
                </div>

                <CurrentSchedule
                    authUser={authUser}
                    schedule={schedule}
                    fetchedCourseData={fetchedCourseData}
                />

                <RecommendedResources
                    recommendedResources={recommendedResources}
                    resourcesLoading={resourcesLoading}
                    resourcesError={resourcesError}
                />
            </Card>

            <ScheduleMeetingModal
                isAdvisingModalOpen={isAdvisingModalOpen}
                onAdvisingModalClose={closeAdvisingModal}
                // Localize the title
                title={t('studentProfile.scheduledMeetingModalTitle', 'Schedule a Meeting')}
                advisors={advisors}
                onMeetingScheduled={fetchScheduledMeetings}
            />
        </div>
    );
};

export default StudentProfile;
