// Dashboard.jsx
import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';

// Firestore
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

// Icon (react-icons)
import { FaSyncAlt } from 'react-icons/fa';

// Components
import CustomSidebar from '../Sidebar/Sidebar';
import EngagementOpportunitiesCard from './Cards/EngagementOpportunitiesCard';
import ChartsCard from './Cards/ChartsCard';
import ScheduledReportsCard from './Cards/ScheduledReportsCard';
import QuickActionsCard from './Cards/QuickActionsCard';
import NoContactCard from './Cards/NoContactCard';
import SuggestedEngagementCard from './Cards/SuggestedEngagementCard';
import PersistencePredictionCard from './Cards/PersistencePredictionCard';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const db = getFirestore();

  // Basic user info
  const [firstName, setFirstName] = useState('Advisor');
  const [lastUpdated, setLastUpdated] = useState(null);

  // Track hidden state for each card
  const [hiddenCards, setHiddenCards] = useState({
    engagementOpportunities: false,
    charts: false,
    scheduledReports: false,
    quickActions: false,
    noContactCard: false,
    suggestedEngagementCard: false,
    persistencePrediction: false
  });

  // 1. Load preferences from cache or Firestore
  const loadPreferencesFromCacheOrDB = useCallback(async () => {
    if (!currentUser?.uid) return;

    // 1. Check localStorage first
    const cachedData = localStorage.getItem('dashboardPreferences');
    if (cachedData) {
      try {
        const parsed = JSON.parse(cachedData);
        if (parsed?.hiddenCards) {
          setHiddenCards(parsed.hiddenCards);
          return; // Do not fetch from Firestore if cache is present
        }
      } catch (err) {
        console.error('Failed to parse local storage:', err);
      }
    }

    // 2. Otherwise, fetch from Firestore
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const data = userDocSnap.data();
        if (data.dashboardPreferences?.hiddenCards) {
          setHiddenCards(data.dashboardPreferences.hiddenCards);
          localStorage.setItem(
            'dashboardPreferences',
            JSON.stringify(data.dashboardPreferences)
          );
        }
      }
    } catch (error) {
      console.error('Error fetching dashboard preferences:', error);
    }
  }, [currentUser, db]);

  useEffect(() => {
    loadPreferencesFromCacheOrDB();
  }, [loadPreferencesFromCacheOrDB]);

  // 2. Reload button to explicitly fetch from Firestore again
  const handleReloadPreferences = async () => {
    if (!currentUser?.uid) return;
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const data = userDocSnap.data();
        if (data.dashboardPreferences?.hiddenCards) {
          setHiddenCards(data.dashboardPreferences.hiddenCards);
          localStorage.setItem(
            'dashboardPreferences',
            JSON.stringify(data.dashboardPreferences)
          );
          toast.success('Reloaded preferences from Firestore!');
        }
      }
      setLastUpdated(new Date()); // update timestamp
    } catch (error) {
      console.error('Error reloading preferences:', error);
      toast.error('Failed to reload preferences.');
    }
  };

  // 3. Hide a single card => Update local state, localStorage, and Firestore
  const hideCard = async (cardKey) => {
    if (!currentUser?.uid) return;
    try {
      setHiddenCards((prev) => {
        const newHidden = { ...prev, [cardKey]: true };

        // Firestore
        const userDocRef = doc(db, 'users', currentUser.uid);
        setDoc(
          userDocRef,
          { dashboardPreferences: { hiddenCards: newHidden } },
          { merge: true }
        );

        // Local storage
        const cachedData = localStorage.getItem('dashboardPreferences');
        let stored = {};
        if (cachedData) {
          stored = JSON.parse(cachedData);
        }
        stored.hiddenCards = newHidden;
        localStorage.setItem('dashboardPreferences', JSON.stringify(stored));

        return newHidden;
      });
    } catch (error) {
      console.error('Error hiding card in Firestore:', error);
      toast.error('Failed to hide card.');
    }
  };

  // 4. Reset to default => unhide all cards, update DB, and local storage
  const resetToDefault = async () => {
    if (!currentUser?.uid) return;
    const defaultHidden = {
      engagementOpportunities: false,
      charts: false,
      scheduledReports: false,
      quickActions: false,
      noContactCard: false,
      suggestedEngagementCard: false,
      persistencePrediction: false,
    };
    try {
      setHiddenCards(defaultHidden);

      // Firestore
      const userDocRef = doc(db, 'users', currentUser.uid);
      await setDoc(
        userDocRef,
        { dashboardPreferences: { hiddenCards: defaultHidden } },
        { merge: true }
      );

      // Local storage
      localStorage.setItem(
        'dashboardPreferences',
        JSON.stringify({ hiddenCards: defaultHidden })
      );

      toast.success('Dashboard reset to default!');
    } catch (error) {
      console.error('Error resetting to default layout:', error);
      toast.error('Failed to reset dashboard.');
    }
  };

  // 5. Greeting / Basic Info
  useEffect(() => {
    if (currentUser && currentUser.firstName) {
      setFirstName(currentUser.firstName);
    } else {
      setFirstName('Advisor');
    }
    setLastUpdated(new Date());
  }, [currentUser]);

  // 6. Render UI
  const handleCaseloadClick = () => {
    toast.info('Open a modal or navigate to change caseload...');
  };

  return (
    <div className="bg-gray-50 flex" style={{ display: 'flex', width: '100vw', flexGrow: '1' }}>
      <CustomSidebar adjustment="Dashboard" />

      {/* Main Content */}
      <div className="ml-[6vw] min-w-[92vw] max-w-[92vw] pt-8 mb-2">
        {/* Top row */}
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="text-xl font-semibold">
            Welcome, {firstName}!
          </div>

          {/* Last Updated + Reload Icon */}
          <div className="text-md flex items-center gap-2">
            <span>
              {lastUpdated
                ? `Last Updated: ${lastUpdated.toLocaleString()}`
                : 'No updates yet'}
            </span>
            <button
              onClick={handleReloadPreferences}
              className="text-blue-600 hover:text-blue-800"
              aria-label="Reload Preferences"
            >
              <FaSyncAlt size={16} />
            </button>
          </div>

          {/* Caseload */}
          <div className="text-md">
            Viewing data for your{' '}
            <span
              className="underline cursor-pointer text-blue-600 hover:text-blue-800"
              onClick={handleCaseloadClick}
            >
              caseload
            </span>
          </div>
        </div>

        {/* 3-column grid layout */}
        <div className="grid grid-cols-3 gap-3" style={{ minHeight: 'calc(100vh - 120px)' }}>
          {/* Left Column (col-span-2) */}
          <div className="col-span-2 space-y-2">
            {!hiddenCards.engagementOpportunities && (
              <EngagementOpportunitiesCard onHide={() => hideCard('engagementOpportunities')} />
            )}
            {!hiddenCards.persistencePrediction && (
              <PersistencePredictionCard onHide={() => hideCard('persistencePrediction')} />
            )}
            {!hiddenCards.charts && (
              <ChartsCard onHide={() => hideCard('charts')} />
            )}
            {!hiddenCards.scheduledReports && (
              <ScheduledReportsCard onHide={() => hideCard('scheduledReports')} />
            )}
          </div>

          {/* Right Column (col-span-1) */}
          <div className="col-span-1 flex flex-col space-y-2">
            {!hiddenCards.quickActions && (
              <QuickActionsCard onHide={() => hideCard('quickActions')} />
            )}
            {!hiddenCards.suggestedEngagementCard && (
              <SuggestedEngagementCard onHide={() => hideCard('suggestedEngagementCard')} />
            )}
            {!hiddenCards.noContactCard && (
              <NoContactCard onHide={() => hideCard('noContactCard')} />
            )}
          </div>
        </div>

        {/* Reset to Default Button */}
        <button
          onClick={resetToDefault}
          className="bg-blue-500 hover:bg-blue-600 text-white rounded px-3 py-1 text-sm mt-4"
        >
          Reset to Default
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
