// src/components/Dashboard/Cards/SkeletonCard.jsx
import React from 'react';

const SkeletonCard = ({ className = '' }) => {
  return (
    <div
      className={`bg-white dark:bg-gray-800 border border-gray-300 
                 dark:border-gray-700 rounded-lg shadow-md p-2 w-full 
                 animate-pulse ${className}`}
    >
      {/* Title Skeleton */}
      <div className="flex justify-between items-center">
        <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded w-1/3"></div>
        <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded w-6"></div>
      </div>

      {/* Spacer */}
      <div className="mt-4"></div>

      {/* Content Skeleton */}
      <div className="space-y-4">
        <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full"></div>
        <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6"></div>
        <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-2/3"></div>
      </div>
    </div>
  );
};

export default SkeletonCard;
