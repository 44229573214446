import React, { useState } from 'react';
import { toast } from 'react-toastify'; // Make sure you have this import if you're using react-toastify
import { useTranslation } from 'react-i18next';

const ClassCart = ({
  classesInCart,
  onRemoveClass,
  onAddCourses,
  yearNumber,
  semester,
  surveyAnswers,
  finalizedCourseIds
}) => {
  // Initialize the translation hook with the 'classCart' namespace
  const { t } = useTranslation();

  // Safely parse the start year
  const startYear = parseInt(surveyAnswers?.SchoolStart?.year, 10) || 0;

  // Calculate the specific year for display when semester is provided
  const specificYear = semester === 'Fall'
    ? startYear + yearNumber - 1
    : startYear + yearNumber;

  // Safely compute numYears from finalizedCourseIds
  const safeNumYears = typeof finalizedCourseIds?.numYears === 'number'
    ? finalizedCourseIds.numYears
    : 0;

  // Adding 1, but only if it's a valid number
  const numYearsSelection = safeNumYears + 1;

  // Local state for manually selected year/semester (if none is provided as prop)
  const [selectedYear, setSelectedYear] = useState(specificYear || '');
  const [selectedSemester, setSelectedSemester] = useState(semester || '');

  /**
   * Handles adding courses when the semester/year is specifically passed down
   * (i.e., the user has a 'Fall' or 'Spring' button).
   */
  const handleAddCoursesRegular = () => {
    const targetSemester = semester === 'Fall' ? 'S1' : 'S2';
    const targetYear = yearNumber;
    onAddCourses(targetYear, targetSemester);
  };

  /**
   * Handles adding courses when the user manually selects the semester and year
   * from the dropdowns.
   */
  const handleAddCoursesSelected = () => {
    if (!selectedSemester || !selectedYear) {
      toast.warning(
        t(
          'classCart.selectYearSemesterWarning',
          "Please select the year and semester to which you'd like to add the courses."
        )
      );
      return; // Prevent further execution if the condition is not met
    }

    // Convert the user-facing year to the "targetYear" index used in onAddCourses.
    // If 'Fall', then it's effectively yearNumber = selectedYear - startYear + 1
    // If 'Spring', then it's yearNumber = selectedYear - startYear
    const targetYear =
      selectedSemester === 'Fall'
        ? selectedYear - startYear + 1
        : selectedYear - startYear;

    const targetSemester = selectedSemester === 'Fall' ? 'S1' : 'S2';

    onAddCourses(targetYear, targetSemester);
  };

  return (
    <div
      className={`py-3 ${
        !semester || semester.trim() === "" ? "px-2" : "px-3"
      } bg-gray-50 shadow-md border rounded-lg w-full min-h-200 mb-20`}
    >
      <h5 className="text-lg text-center font-bold mb-4">
        {t('classCart.title', 'Class Cart')}
      </h5>

      {classesInCart.length === 0 ? (
        <p className="text-md text-center">
          {t('classCart.addClassesPrompt', 'Add classes to the cart')}
        </p>
      ) : (
        <div className="max-h-96 overflow-y-auto">
          {classesInCart.map((classData, index) => (
            <ClassCartItem
              key={index}
              classData={classData}
              onRemoveClass={onRemoveClass}
            />
          ))}
        </div>
      )}

      <div className="flex justify-center mt-2">
        {/* If semester is NOT provided, display the dropdowns */}
        {!semester || semester.trim() === "" ? (
          <button
            className="w-full bg-blue-500 hover:bg-blue-700 text-white text-xs py-2 rounded mt-1 flex items-center justify-center space-x-2"
            onClick={handleAddCoursesSelected}
          >
            <span>{t('classCart.addTo', 'Add to')}</span>

            {/* Semester Dropdown */}
            <select
              value={selectedSemester}
              onChange={(e) => setSelectedSemester(e.target.value)}
              className="bg-gray-100 text-gray-800 text-xs border-none outline-none appearance-none w-24 rounded px-2 py-1 text-left"
              onClick={(e) => e.stopPropagation()}
            >
              <option value="" disabled hidden>
                {t('classCart.semesterPlaceholder', 'Semester')}
              </option>
              <option value="Fall">{t('classCart.fall', 'Fall')}</option>
              <option value="Spring">{t('classCart.spring', 'Spring')}</option>
            </select>

            {/* Year Dropdown */}
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
              className="bg-gray-100 text-gray-800 text-xs border-none outline-none appearance-none w-16 rounded px-2 py-1 text-left"
              onClick={(e) => e.stopPropagation()}
            >
              <option value="" disabled hidden>
                {t('classCart.yearPlaceholder', 'Year')}
              </option>
              {/* Only generate years if numYearsSelection is valid */}
              {Number.isInteger(numYearsSelection) && numYearsSelection > 0 ? (
                [...Array(numYearsSelection)].map((_, idx) => (
                  <option key={idx} value={startYear + idx}>
                    {startYear + idx}
                  </option>
                ))
              ) : (
                <option disabled>
                  {t('classCart.invalidYears', 'Invalid Years')}
                </option>
              )}
            </select>
          </button>
        ) : (
          /* If semester is provided, show a simpler button. */
          <button
            className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-1 rounded mt-1 flex items-center justify-center space-x-2"
            onClick={handleAddCoursesRegular}
          >
            <span>
              {t('classCart.addToSpecific', 'Add to')} {semester} {specificYear}
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

const ClassCartItem = ({ classData, onRemoveClass }) => {
  // Initialize the translation hook with the 'classCart' namespace
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  const handleHover = (hoverState) => {
    setIsHovered(hoverState);
  };

  return (
    <div
      className="class-cart-item bg-white mb-2 rounded p-2 relative shadow-none"
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <p className="font-bold">{classData.courseInfo_courseNumber}</p>
      {/* Add more details if needed */}
      {isHovered && (
        <div className="absolute bottom-2 right-3">
          <button
            className="text-xs text-red-500 hover:text-black"
            onClick={() => onRemoveClass(classData)}
          >
            {t('classCart.remove', 'Remove')}
          </button>
        </div>
      )}
    </div>
  );
};

export default ClassCart;
