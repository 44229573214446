import React, { useState, useEffect, useRef } from 'react';
import calculateProgressForMajor from '../CourseProgressFunctions/CalculateProgressForMajor';
import calculateProgressForMinor from '../CourseProgressFunctions/CalculateProgressForMinor';
import { useTranslation } from 'react-i18next';

const ProgressBars = ({
    authUser,
    fetchedMajors,
    fetchedMinors,
    fetchedCourseData,
    finalizedSchedule,
    completedSemesters,
    milestones,
    completedMilestones
}) => {
    // 1. Initialize i18n
    const { t } = useTranslation();

    const [selectedProgram, setSelectedProgram] = useState({});
    const [dropdownVisible, setDropdownVisible] = useState(false); 
    const dropdownRef = useRef(null);

    useEffect(() => {
        const majors = authUser?.surveyAnswers?.Majors || [];
        const minors = authUser?.surveyAnswers?.Minors || [];

        // If there's at least one major or minor, set an initial selection
        if (majors.length > 0 || minors.length > 0) {
            setSelectedProgram({
                type: majors.length > 0 ? 'Major' : 'Minor',
                // If major exists, use the first major; else use first minor + ' Minor'
                name: majors.length > 0
                    ? majors[0]
                    : (minors[0] + ` ${t('progressBars.minorSuffix', 'Minor')}`)
            });
        }
    }, [authUser, t]);

    const handleProgramChange = (program) => {
        setSelectedProgram(program);
        setDropdownVisible(false); 
    };

    const handleSelectProgramButtonClick = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const isMajor = selectedProgram?.type === 'Major';

    // Decide whether to call the major or minor progress calculation
    const { takenCoursesCount, planCoursesCount, totCoursesCount } = isMajor
        ? calculateProgressForMajor(
            selectedProgram.name,
            fetchedMajors,
            fetchedCourseData,
            finalizedSchedule,
            completedSemesters
        )
        : calculateProgressForMinor(
            // Remove the localized " Minor" suffix if present
            selectedProgram?.name?.replace(` ${t('progressBars.minorSuffix', 'Minor')}`, ''),
            fetchedMinors,
            fetchedCourseData,
            finalizedSchedule,
            completedSemesters
        );

    const progressTaken = totCoursesCount > 0 ? Math.round((planCoursesCount / totCoursesCount) * 100) : 0;
    const progressDone = totCoursesCount > 0 ? Math.round((takenCoursesCount / totCoursesCount) * 100) : 0;
    const remainingCourses = totCoursesCount - takenCoursesCount - planCoursesCount;

    const totalMilestones = milestones?.length || 0;
    const milestoneProgress = totalMilestones > 0 
        ? Math.round((completedMilestones / totalMilestones) * 100) 
        : 0;

    // Close the dropdown if user clicks outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const NA = t('progressBars.na', 'N/A');
    const multipleProgramsCount =
        (authUser?.surveyAnswers?.Majors?.length || 0) +
        (authUser?.surveyAnswers?.Minors?.length || 0);

    return (
        <div className="flex justify-between">
            {/* Program Pathway Progress Section */}
            <div className="mb-0">
                <div className="flex items-center mb-2">
                    {/* If there are multiple majors or minors, show a dropdown. */}
                    {multipleProgramsCount > 1 ? (
                        <>
                            <h4>{t('progressBars.progressTowards', 'Progress Towards')}</h4>
                            <div className="relative" ref={dropdownRef}>
                                <button
                                    className="ml-1 px-2 py-1 bg-gray-200 rounded transition-colors hover:bg-gray-300"
                                    onClick={handleSelectProgramButtonClick}
                                >
                                    <span>{selectedProgram?.name || NA}</span>
                                </button>
                                <span className="ml-1">
                                    {t('progressBars.pathway', 'Pathway')}
                                </span>

                                {dropdownVisible && (
                                    <div className="absolute left-0 mt-1 bg-white border border-gray-200 rounded shadow-lg z-10">
                                        <ul className="py-1">
                                            {/* Majors */}
                                            {authUser?.surveyAnswers?.Majors?.map((major, index) => (
                                                <li key={`major-${index}`}>
                                                    <button
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                        onClick={() =>
                                                            handleProgramChange({
                                                                type: 'Major',
                                                                name: major
                                                            })
                                                        }
                                                    >
                                                        {major}
                                                    </button>
                                                </li>
                                            ))}
                                            {/* Minors */}
                                            {authUser?.surveyAnswers?.Minors?.map((minor, index) => (
                                                <li key={`minor-${index}`}>
                                                    <button
                                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                        onClick={() =>
                                                            handleProgramChange({
                                                                type: 'Minor',
                                                                name: `${minor} ${t('progressBars.minorSuffix', 'Minor')}`
                                                            })
                                                        }
                                                    >
                                                        {`${minor} ${t('progressBars.minorSuffix', 'Minor')}`}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        // If there's only one major/minor, just show a header
                        <h4>
                            {t('progressBars.progressTowardsPathway', {
                                programName: selectedProgram?.name || NA
                            })}
                        </h4>
                    )}
                </div>

                {/* Progress Bar */}
                <div className="relative w-full max-w-[22vw] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                        className="absolute bg-green-500 h-2.5"
                        style={{
                            width: `${progressDone}%`,
                            left: '0',
                            top: 0,
                            borderTopLeftRadius: '9999px',
                            borderBottomLeftRadius: '9999px',
                            borderTopRightRadius: progressTaken > 0 ? '0' : '9999px',
                            borderBottomRightRadius: progressTaken > 0 ? '0' : '9999px'
                        }}
                    ></div>
                    <div
                        className="absolute bg-yellow-300 h-2.5"
                        style={{
                            width: `${progressTaken}%`,
                            left: `${progressDone}%`,
                            top: 0,
                            borderTopRightRadius: '9999px',
                            borderBottomRightRadius: '9999px',
                            borderTopLeftRadius: progressDone > 0 ? '0' : '9999px',
                            borderBottomLeftRadius: progressDone > 0 ? '0' : '9999px'
                        }}
                    ></div>
                </div>

                {/* Labels: Taken, Planned, Unplanned */}
                <div className="flex justify-between mt-2 space-x-10 w-full max-w-[22vw] items-center">
                    <div className="rounded bg-green-100 px-2 py-0.3">
                        <span className="text-green-600 font-semibold">
                            {/* Example of using interpolation for counts */}
                            {t('progressBars.taken', { count: takenCoursesCount })}
                        </span>
                    </div>
                    <div className="rounded bg-yellow-100 px-2 py-0.3 ml-3">
                        <span className="text-yellow-500 font-semibold">
                            {t('progressBars.planned', { count: planCoursesCount || 0 })}
                        </span>
                    </div>
                    <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                        <span className="text-gray-600 font-semibold">
                            {t('progressBars.unplanned', { count: remainingCourses || 0 })}
                        </span>
                    </div>
                </div>
            </div>

            {/* Milestone Progress Section */}
            <div className="milestone-progress">
                <h4 className="mb-2">{t('progressBars.milestoneProgress', 'Milestone Progress')}</h4>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div
                        className="bg-blue-500 h-2.5 rounded-full"
                        style={{ width: `${milestoneProgress || 0}%` }}
                    ></div>
                </div>
                <div className="flex justify-between mt-2">
                    <div className="rounded bg-blue-100 px-2 py-0.3">
                        <span className="text-blue-600 font-semibold">
                            {t('progressBars.completed', { count: completedMilestones || 0 })}
                        </span>
                    </div>
                    <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                        <span className="text-gray-500 font-semibold">
                            {t('progressBars.remaining', {
                                count: (totalMilestones - (completedMilestones || 0)) || 0
                            })}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressBars;
