import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const EnrollmentLineGraph = ({ data }) => {
  if (!data) {
    return null;
  }

  // Group data by school year and sum the enrollment across terms in that year
  const yearlyData = {};
  data.forEach((item) => {
    const term = item.term;
    const year = parseInt(term.slice(-2), 10); // Last two digits as the year, e.g., '22' in 'FA22'
    const semester = term.slice(0, 2).toUpperCase(); // Extract semester, e.g., 'FA', 'SP', 'SU'

    // Determine the academic year (e.g., FA22, SP23, SU23 -> 2023-2024)
    let schoolYear = "";
    if (semester === "FA") {
      schoolYear = `20${year}-20${year + 1}`;
    } else {
      schoolYear = `20${year - 1}-20${year}`;
    }

    if (!yearlyData[schoolYear]) {
      yearlyData[schoolYear] = 0;
    }
    yearlyData[schoolYear] += item.Total;
  });

  // Convert yearlyData object into arrays for labels and values
  const sortedYears = Object.keys(yearlyData).sort();
  const labels = sortedYears;
  const values = sortedYears.map((year) => yearlyData[year]);

  // Define border styles for each segment
  const borderDashStyles = labels.map((_, index) => (index < 2 ? [] : [5, 5])); // Solid for first two, dotted for others

  // Create the chart data structure
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Total Enrollment per Year",
        data: values,
        fill: false,
        borderColor: "#8884d8",
        backgroundColor: "#8884d8",
        pointBackgroundColor: "#8884d8",
        pointBorderColor: "#8884d8",
        tension: 0.4, // Curve the line slightly
        // Use segment styling to apply border styles to each section
        segment: {
          borderDash: (ctx) => borderDashStyles[ctx.p0DataIndex],
        },
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          boxWidth: 20,
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Academic Year",
        },
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Enrollment",
        },
      },
    },
  };

  return (
    <div
      className="w-full flex items-center justify-center"
      style={{ width: "900px", height: "350px" }}
    >
      <Line data={chartData} options={options} />
    </div>
  );
};

export default EnrollmentLineGraph;
