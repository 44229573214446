import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/Routing/App';
import "bootstrap/dist/css/bootstrap.min.css"; // Importing Bootstrap
import 'flowbite/dist/flowbite.css'; // Importing Flowbite
import './index.css'; // Importing custom styles

// Define the current application version
const APP_VERSION = '1.0.0';

// Function to check and update app version in localStorage
function handleVersionMismatch() {
  const storedVersion = localStorage.getItem('appVersion');
  
  console.log(`Stored version: ${storedVersion}`);
  console.log(`Current app version: ${APP_VERSION}`);

  if (storedVersion !== APP_VERSION) {
    console.log('Version mismatch detected. Clearing local storage.');
    localStorage.clear(); // Clear localStorage if version mismatch
    localStorage.setItem('appVersion', APP_VERSION);
    console.log(`Updated app version in local storage to: ${APP_VERSION}`);
  } else {
    console.log('On latest version');
  }
}

// Call the function to handle version mismatch
handleVersionMismatch();

// Find the root element and render the app
const rootElement = document.getElementById('root');

if (!rootElement) {
  console.error("Root element not found. Please check your HTML file.");
} else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
