import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import CustomSidebar from '../Sidebar/Sidebar';
import MultiSelect from '../../ClassSearch/MultiSelect';
import AnalyticsClassSearch from './Course/AnalyticsCourseSearch';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import { useAuth } from '../../../contexts/AuthContext';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';


const CoursesAnalytics = () => {
  const { fetchedCourseData, fetchedMajorRecs } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const semesterFromLink = searchParams.get('semester');

  const { currentUser } = useAuth();
  const sentinelRef = useRef(null);

  const [displayedData, setDisplayedData] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(1);
  const [displayedCourseCount, setDisplayedCourseCount] = useState(0);

  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Multi-select filter states
  const [levelFilter, setLevelFilter] = useState([]);
  const [majorFilter, setMajorFilter] = useState([]);
  const [creditsFilter, setCreditsFilter] = useState([]);

  const [userRecsData, setUserRecsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [finalizedCourseIds, setFinalizedCourseIds] = useState([]);
  const [showRecommendedCourses, setShowRecommendedCourses] = useState(false);

  const [transformedMajorRecData, setTransformedMajorRecData] = useState([]);

  // New flagSort state
  const [flagSort, setFlagSort] = useState(false);

  // Debounce delay in milliseconds
  const DEBOUNCE_DELAY = 1;

  // Transform fetchedMajorRecs
  useEffect(() => {
    if (fetchedMajorRecs) {
      const transformedData = fetchedMajorRecs.reduce((acc, curr) => {
        const { Major, ...categories } = curr;
        acc[Major] = categories;
        return acc;
      }, {});
      setTransformedMajorRecData(transformedData);
    }
  }, [fetchedMajorRecs]);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = currentUser;

        if (user) {
          const userDocRef = db.collection('users').doc(user.uid);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || '';
            const courseIds = userData.schedules[activeTab] || [];
            const backendSurveyAnswers = userData.surveyAnswers || [];
            const backendUserRecData = userData.recommendationRatings || {};
            setFinalizedCourseIds(courseIds);
            setSurveyAnswers(backendSurveyAnswers);
            setUserRecsData(backendUserRecData);

            const allCourseIds = [];

            for (const yearKey in courseIds) {
              const yearObj = courseIds[yearKey];

              // Iterate over each semester in the year
              for (const semesterKey in yearObj) {
                const semesterIds = yearObj[semesterKey].courses;

                // Append semesterIds to allCourseIds
                allCourseIds.push(...semesterIds);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
      setLoading(false);
    };

    fetchUserData();
  }, [currentUser]);

  // Extract unique filter options using useMemo for performance
  const uniqueLevels = useMemo(() => {
    if (!fetchedCourseData) return [];
    const levels = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.courseYear) levels.add(item.courseYear);
    });
    return Array.from(levels).sort((a, b) => parseInt(a) - parseInt(b));
  }, [fetchedCourseData]);

  const uniqueMajors = useMemo(() => {
    if (!fetchedCourseData) return [];
    const majors = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.Major) {
        item.Major.split(',').forEach((major) => {
          majors.add(major.trim());
        });
      }
    });
    return Array.from(majors).sort();
  }, [fetchedCourseData]);

  const uniqueCredits = useMemo(() => {
    if (!fetchedCourseData) return [];
    const credits = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.Credits) credits.add(item.Credits);
    });
    return Array.from(credits).sort((a, b) => parseFloat(a) - parseFloat(b));
  }, [fetchedCourseData]);

  // Define suggestions for MultiSelect components
  const levelSuggestions = useMemo(
    () => uniqueLevels.map((level) => `${level}`),
    [uniqueLevels]
  );

  const majorSuggestions = useMemo(() => uniqueMajors, [uniqueMajors]);

  const creditsSuggestions = useMemo(
    () => uniqueCredits.map((credit) => `${credit} Credit(s)`),
    [uniqueCredits]
  );

  // Debounced filter function
  const debouncedFilter = useCallback(
    debounce(() => {
      filterDataAndSetDisplay();
    }, DEBOUNCE_DELAY),
    [
      fetchedCourseData,
      searchQuery,
      levelFilter,
      majorFilter,
      creditsFilter,
      showRecommendedCourses,
      surveyAnswers,
      userRecsData,
      transformedMajorRecData,
      // Add flagSort to dependencies
      flagSort,
    ]
  );

  // Filter data whenever dependencies change, using debounce
  useEffect(() => {
    debouncedFilter();
    return debouncedFilter.cancel;
  }, [
    fetchedCourseData,
    searchQuery,
    levelFilter,
    majorFilter,
    creditsFilter,
    showRecommendedCourses,
    surveyAnswers,
    userRecsData,
    transformedMajorRecData,
    flagSort,
    debouncedFilter,
  ]);

  // Filtering logic
  const filterDataAndSetDisplay = () => {
    console.log('hiasdf')
    if (fetchedCourseData) {
      const filtered = Object.values(fetchedCourseData).filter((item) => {
        const majors = item.Major.split(',').map((major) => major.trim());

        // Level Filter
        if (
          levelFilter.length > 0 &&
          !levelFilter.some(
            (filter) => parseInt(filter) === parseInt(item.courseYear)
          )
        ) {
          return false;
        }

        // Major Filter
        if (
          majorFilter.length > 0 &&
          !majorFilter.some((filter) => majors.includes(filter))
        ) {
          return false;
        }

        // Credits Filter
        if (
          creditsFilter.length > 0 &&
          !creditsFilter.some(
            (filter) =>
              parseFloat(filter.replace(' Credit(s)', '')) === parseFloat(item.Credits)
          )
        ) {
          return false;
        }

        if (flagSort) {
          if (item.OfferedSections === item.ProjectedSections){
            return false;
          }
        }

        // Search Query
        const nameMatch = item.courseInfo_courseName
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const numberMatch = item.courseInfo_courseNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        return nameMatch || numberMatch;
      });

      // Sort: If flagSort is true, put flagged courses (OfferedSections !== ProjectedSections) first
      const sortedData = filtered.sort((a, b) => {
        return a.courseInfo_courseNumber.localeCompare(b.courseInfo_courseNumber);
      });

      console.log(sortedData.length, 'hiasdf')

      setFilteredData(sortedData);
      setRecommendedCourses(sortedData);
      setDisplayedData(sortedData.slice(0, 100));
      setCurrentBatch(1);
      setDisplayedCourseCount(sortedData.length);
    }
  };

  // Infinite scroll: Load more data when sentinel is intersecting
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          displayedData.length < filteredData.length
        ) {
          loadMoreData();
        }
      },
      {
        threshold: 0,
        root: sentinelRef.current ? sentinelRef.current.parentElement : null,
        rootMargin: '100px',
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [displayedData, filteredData]);

  const loadMoreData = () => {
    const nextBatch = currentBatch + 1;
    const newData = filteredData.slice(0, nextBatch * 100);
    setDisplayedData(newData);
    setCurrentBatch(nextBatch);
  };

  // Handle resetting all filters
  const handleResetFilters = () => {
    setLevelFilter([]);
    setMajorFilter([]);
    setCreditsFilter([]);
    setShowRecommendedCourses(false);
    setSearchQuery('');
    // Also reset flagSort
    setFlagSort(false);
  };

  // Display loading spinner while fetching data
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 
                 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 
                 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 
                 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 
                 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 
                 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 
                 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 
                 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 
                 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 
                 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 
                 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex">
        {/* Filters Sidebar */}
        <div className="w-1/5 mt-2">
          <div className="p-4 bg-gray-50 shadow-md border rounded-lg">
            <h5 className="text-lg text-center font-bold mb-4">Filters</h5>

            {/* Search Filter */}
            <div className="mb-2">
              <label
                htmlFor="searchQuery"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Search:
              </label>
              <input
                type="text"
                id="searchQuery"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                           focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>

            {/* Level Filter */}
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Level:
              </label>
              <MultiSelect
                suggestions={levelSuggestions}
                value={levelFilter}
                onChange={(e) => setLevelFilter(e.target.value)}
                placeholder="Select Level(s)"
                required={false}
                unselectable={[]} 
                tagColor="blue"
              />
            </div>

            {/* Major Filter */}
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Major:
              </label>
              <MultiSelect
                suggestions={majorSuggestions}
                value={majorFilter}
                onChange={(e) => setMajorFilter(e.target.value)}
                placeholder="Select Major(s)"
                required={false}
                unselectable={[]} 
                tagColor="green"
              />
            </div>

            {/* Credits Filter */}
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Credits:
              </label>
              <MultiSelect
                suggestions={creditsSuggestions}
                value={creditsFilter}
                onChange={(e) => setCreditsFilter(e.target.value)}
                placeholder="Select Credit(s)"
                required={false}
                unselectable={[]} 
                tagColor="purple"
              />
            </div>

            {/* Flag Sort Checkbox */}
            <div className="mb-3 flex items-center">
              <input
                type="checkbox"
                id="flagSort"
                checked={flagSort}
                onChange={(e) => setFlagSort(e.target.checked)}
                className="mr-1.5 text-blue-600 bg-gray-100 border-gray-300 rounded-sm"
              />
              <label
                htmlFor="flagSort"
                className="block text-sm font-medium text-gray-700"
              >
                Show Flagged Courses
              </label>
            </div>

            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 
                         rounded mt-1"
              onClick={handleResetFilters}
            >
              Reset Filters
            </button>

            {/* Courses Display Count */}
            <div className="flex justify-center mt-3">
              <span className="bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 
                             rounded-full"
              >
                {displayedCourseCount} Courses Displayed
              </span>
            </div>
          </div>
        </div>

        {/* Courses Display */}
        <div
          className="ml-4 mt-2 w-4/5"
          style={{ minHeight: 'calc(100vh - 8rem)', maxHeight: 'calc(100vh - 8rem)' }}
        >
          <div className="p-4 bg-gray-50 shadow-md border rounded-lg flex flex-col h-full">
            {/* Header with Centered Title and Info Icon */}
            <div className="flex items-center justify-center mb-4 relative">
              <h5 className="text-lg font-bold text-center">Courses</h5>
            </div>

            {/* Courses Header */}
            <div className="grid grid-cols-8 font-semibold text-sm text-gray-500 mb-2">
              <div className="text-center col-span-1 ml-1 mt-3">
                Course ID
                <FontAwesomeIcon
                  icon={faSort}
                  className="mb-0.5 ml-0.5"
                  style={{ fontSize: '10px' }}
                />
              </div>
              <div className="text-center col-span-3 mt-3">
                Course Name
                <FontAwesomeIcon
                  icon={faSort}
                  className="mb-0.5 ml-0.5"
                  style={{ fontSize: '10px' }}
                />
              </div>
              <div className="ml-3 col-span-1 flex items-center">
                <div className="flex flex-col">
                  <span className="text-center">Offered</span>
                  <span className="ml-1 text-center">(Planned)</span>
                </div>
                <FontAwesomeIcon
                  icon={faSort}
                  className="ml-1"
                  style={{ fontSize: '10px' }}
                />
              </div>
              <div className="col-span-1 flex items-center">
                <div className="flex flex-col">
                  <span className="text-center">Needed</span>
                  <span className="ml-1 text-center">(Projected)</span>
                </div>
                <FontAwesomeIcon
                  icon={faSort}
                  className="ml-1"
                  style={{ fontSize: '10px' }}
                />
              </div>
              <div className="text-center mr-3 col-span-1 mt-3">
                Fill Rate
                <FontAwesomeIcon
                  icon={faSort}
                  className="mb-0.5 ml-0.5"
                  style={{ fontSize: '10px' }}
                />
              </div>
            </div>

            {/* Courses List with Infinite Scroll */}
            <div
              className="flex-1 overflow-y-auto"
              style={{
                minHeight: 'calc(100vh - 17rem)',
                maxHeight: 'calc(100vh - 17rem)',
              }}
            >
              <div>
                {displayedData.map((classData, index) => {
                  const fillRate = Math.min(
                    Math.round(
                      (classData.ProjectedSeats / classData.OfferedSeats) * 100
                    ),
                    100
                  ); // Ensure it's capped at 100%

                  return (
                    <AnalyticsClassSearch
                      key={index}
                      index={index}
                      classData={classData}
                      fillRate={fillRate} // Pass the fill rate
                    />
                  );
                })}
              </div>
              <div ref={sentinelRef}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesAnalytics;
