import React from "react";
import { useNavigate } from "react-router-dom";
import GPAGraph from "./GPAGraph";

const getBorderColorClass = (prediction) => {
  switch (prediction) {
    case "Very Low":
      return "text-red-700";
    case "Low":
      return "text-orange-600";
    case "Moderate":
      return "text-yellow-600";
    case "High":
      return "text-green-600";
    case "Very High":
      return "text-blue-600";
    default:
      return "text-gray-600";
  }
};

const StudentAnalyticsGrid = ({ persistenceCalculations }) => {
  const navigate = useNavigate();

  const {
    prediction = "N/A",
    overallPersistenceScore,
    persistenceFactors,
    GPAHistory,
    applicableEngagements,
  } = persistenceCalculations || {};

  const engagementCount = Array.isArray(applicableEngagements)
    ? applicableEngagements.length
    : 0;
  const persistenceScore =
    overallPersistenceScore !== undefined && overallPersistenceScore !== null
      ? overallPersistenceScore.toFixed(2)
      : "N/A";
  const predictionColorClass = getBorderColorClass(prediction);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full">
      {/* LEFT COLUMN */}
      <div className="flex flex-col gap-2">
        {/* ENGAGEMENT OPPORTUNITIES CARD */}
        <div className="bg-white p-3 rounded shadow border border-gray-200">
          <h3 className="text-base font-semibold text-gray-800 mb-2">
            Engagement Opportunities
          </h3>
          <div className="flex items-center gap-2">
            <p className="text-2xl font-bold text-gray-800">{engagementCount}</p>
            {engagementCount > 0 ? (
              <div className="flex flex-wrap gap-2 items-center">
                {applicableEngagements.map((engagement, idx) => (
                  <span
                    key={idx}
                    className="mt-1 inline-flex items-center bg-red-100 text-red-800 px-2 py-1 rounded-full text-xs font-medium"
                  >
                    {engagement?.name || "Unnamed Engagement"}
                  </span>
                ))}
              </div>
            ) : (
              <p className="text-xs text-gray-600 ml-2">
                No engagement opportunities available.
              </p>
            )}
          </div>
        </div>

        {/* GPA PROGRESSION CARD */}
        <div className="bg-white p-3 rounded shadow border border-gray-200">
          <h3 className="text-base font-semibold text-gray-800 mb-2">
            GPA Progression
          </h3>
          <div className="w-full flex justify-center overflow-auto">
            <GPAGraph gpaData={GPAHistory?.history ?? []} />
          </div>
        </div>
      </div>

      {/* RIGHT COLUMN */}
      <div className="bg-white p-3 rounded shadow border border-gray-200 flex flex-col gap-2">
        <h2 className="text-lg font-semibold text-gray-800">
          Persistence Analytics
        </h2>

        {/* PREDICTION */}
        <div className="p-2 rounded bg-gray-50 border border-gray-100">
          <p className="text-base text-gray-600 leading-relaxed">
            Prediction:{" "}
            <span className={`font-bold ${predictionColorClass}`}>
              {prediction} ({persistenceScore}/100)
            </span>
          </p>
        </div>

        {/* KEY DATA POINTS */}
        <div className="p-2 rounded bg-gray-50 border border-gray-100">
          <h3 className="text-base font-semibold text-gray-700 mb-1">
            Key Data Points
          </h3>
          <ul className="p-2 list-disc text-sm text-gray-600 ml-3 space-y-1">
            {persistenceFactors?.GPA && (
              <li>
                <span className="font-semibold">GPA:</span>{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: persistenceFactors.GPA }}
                />
              </li>
            )}
            {persistenceFactors?.GPA_Trends && (
              <li>
                <span className="font-semibold">GPA Trend:</span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: persistenceFactors.GPA_Trends,
                  }}
                />
              </li>
            )}
            {persistenceFactors?.Engagement && (
              <li>
                <span className="font-semibold">Engagement:</span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: persistenceFactors.Engagement,
                  }}
                />
              </li>
            )}
            {persistenceFactors?.Course_Progress && (
              <li>
                <span className="font-semibold">Course Progress:</span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: persistenceFactors.Course_Progress,
                  }}
                />
              </li>
            )}
          </ul>
        </div>

        {/* OTHER DATA POINTS (TAGS) */}
        {persistenceFactors?.Tags && (
          <div className="p-2 rounded bg-gray-50 border border-gray-100">
            <h3 className="text-base font-semibold text-gray-700 mb-1">
              Miscellaneous Data Points
            </h3>
            <ul className="p-2 list-disc text-sm ml-3 space-y-1 text-gray-600">
              {Object.entries(persistenceFactors.Tags).map(
                ([tagKey, tagValue], idx) => (
                  <li key={idx}>
                    <span className="font-semibold">
                      {tagKey.replace(/_/g, " ")}:
                    </span>{" "}
                    <span dangerouslySetInnerHTML={{ __html: tagValue }} />
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentAnalyticsGrid;
