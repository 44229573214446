import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title);

const FillRateBarChart = ({ data }) => {
  // Provide fallback "fake data" if no data is passed
  const fallbackData = [
    { term: "FA20", capacity: 250, enrollment: 200 },
    { term: "SP21", capacity: 250, enrollment: 180 },
    { term: "SU21", capacity: 250, enrollment: 50 },
    { term: "FA21", capacity: 250, enrollment: 220 },
    { term: "SP22", capacity: 250, enrollment: 200 },
    { term: "SU22", capacity: 250, enrollment: 70 },
    { term: "FA22", capacity: 250, enrollment: 210 },
    { term: "SP23", capacity: 250, enrollment: 240 },
    { term: "SU23", capacity: 250, enrollment: 80 },
  ];

  // Use provided data or fallback data
  const chartData = {
    labels: (data || fallbackData).map((item) => item.term), // X-axis labels
    datasets: [
      {
        label: "Capacity",
        data: (data || fallbackData).map((item) => item.capacity), // Capacity values
        backgroundColor: "rgba(220, 220, 220, 1)", // Light gray
        borderWidth: 0,
        barThickness: 40, // Bar thickness
        borderRadius: 5, // Rounded corners
      },
      {
        label: "Enrollment",
        data: (data || fallbackData).map((item) => item.enrollment), // Enrollment values
        backgroundColor: (data || fallbackData).map((item) => {
          // Conditional coloring based on term
          if (item.term.startsWith("SP")) return "rgba(136, 132, 216, 1)"; // Spring
          if (item.term.startsWith("SU")) return "rgba(130, 202, 157, 1)"; // Summer
          if (item.term.startsWith("FA")) return "rgba(255, 115, 0, 1)"; // Fall
          return "rgba(136, 132, 216, 1)"; // Default
        }),
        barThickness: 30, // Slightly smaller thickness for enrollment bars
        borderRadius: 5, // Rounded corners
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 15,
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) =>
            `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      x: {
        stacked: true, // Stack X-axis
        grid: {
          display: false, // Hide gridlines
        },
        title: {
          display: true,
          text: "Previous Terms", // Title for X-axis
        },
      },
      y: {
        stacked: true, // Stack Y-axis
        beginAtZero: true,
        title: {
          display: true,
          text: "Enrollment", // Title for Y-axis
        },
      },
    },
  };

  return (
    <div style={{ width: "900px", height: "400px" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default FillRateBarChart;
