import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { db } from '../../firebase'; // Adjust this import based on your project structure
import { runTransaction } from 'firebase/firestore';

/**
 * Custom hook to handle "autofill" functionality for a single semester.
 * 
 * @param {Object} params
 * @param {Object} params.currentUser - The currently logged-in user
 * @param {string} params.effectiveUserId - The userId of the student whose plan is being edited
 * @param {string} params.activeTab - The name of the currently active plan/tab
 * @param {function} params.recheckPrerequisites - Function to re-check prerequisites after updates
 * @param {function} params.setFinalizedOrganizedCourses - State setter to update the local finalizedOrganizedCourses
 * @param {Object[]} params.allCourses - The full array of course objects from Firestore
 * @param {Object} params.majorCourses - Object mapping major -> data needed for major courses
 * @param {function} params.organizeMajorCoursesByYearAndSemester - Helper function organizing major courses
 * @param {Object} params.majorElectives - Data structure for major electives
 * @param {Object} params.majorRequirementCourses - Data structure for major requirements
 * @param {Object} params.minorCourses - Data structure for minor courses
 * @param {Object} params.minorRequirementCourses - Data structure for minor requirements
 * @param {Object} params.finalizedOrganizedCourses - The local state of the plan's organized courses
 * @param {number} params.maxCreditsPerSemester - The maximum allowable credits for one semester
 */
export default function useAutofillCourse({
  currentUser,
  effectiveUserId,
  activeTab,
  recheckPrerequisites,
  setFinalizedOrganizedCourses,
  allCourses,
  majorCourses,
  organizeMajorCoursesByYearAndSemester,
  majorElectives,
  majorRequirementCourses,
  minorCourses,
  minorRequirementCourses,
  finalizedOrganizedCourses,
  maxCreditsPerSemester,
}) {

  /**
   * Autofill all possible courses for a specific semester (yearNumber, semester).
   */
  const handleAutofillCourse = useCallback(
    async (yearNumber, semester) => {
      // Optional toast ID for controlling Toastify
      const loadingToastId = 'autofill-course-toast';

      // Optionally show a loading toast
      // toast.loading('Autofilling courses...', {
      //   toastId: loadingToastId,
      //   autoClose: false,
      //   position: 'top-center',
      //   style: { width: '12rem' },
      // });

      try {
        if (!currentUser || !effectiveUserId) {
          console.error('Error: currentUser or effectiveUserId is not defined');
          return;
        }

        console.log('HERE2', yearNumber, semester)

        const userRef = db.collection('users').doc(effectiveUserId);
        await runTransaction(db, async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }

          const userData = userDoc.data();
          const semesterCode = semester === 'Fall' ? 'S1' : 'S2';
          const planData = userData.schedules?.[activeTab] || {};

          // Ensure structures exist
          if (!planData[`year${yearNumber}`]) {
            planData[`year${yearNumber}`] = {};
          }
          if (!planData[`year${yearNumber}`][semesterCode]) {
            planData[`year${yearNumber}`][semesterCode] = { courses: [] };
          }

          const existingFinalizedSchedule =
            planData[`year${yearNumber}`][semesterCode].courses || [];

          // Collect all courses in the *entire* plan for deduping
          const allCourseNumbersInPlan = [];
          for (const yearKey in planData) {
            for (const semKey in planData[yearKey]) {
              if (planData[yearKey][semKey]?.courses) {
                allCourseNumbersInPlan.push(...planData[yearKey][semKey].courses);
              }
            }
          }

          // Collect all courses in the local finalizedOrganizedCourses state, for reference
          const allFinalizedCourses = new Set();
          for (const yKey in finalizedOrganizedCourses) {
            for (const sKey in finalizedOrganizedCourses[yKey]) {
              finalizedOrganizedCourses[yKey][sKey].forEach((course) => {
                allFinalizedCourses.add(course.courseInfo_courseNumber);
              });
            }
          }

          // Filter out courses that are "autofillable": 
          //  - belongs to a 4-year plan
          //  - is offered in the target semester
          //  - not already in the plan
          //  - prereqs are met
          //  - has normal credits
          const allAutofillableCourses = allCourses.filter((course) =>
            course.courseYear <= 4 &&
            course.Offering.includes(semester) &&
            !allFinalizedCourses.has(course.courseInfo_courseNumber) &&
            course.isPrereqMet &&
            course.Credits < 6
          );

          // ----------------------------------------------------------------------------
          // 1) MAJOR COURSES
          // ----------------------------------------------------------------------------
          const combinedSemesterMajorCourses = [];
          Object.entries(majorCourses).forEach(([, courseInfo]) => {
            const byYearAndSem = organizeMajorCoursesByYearAndSemester(courseInfo);
            if (byYearAndSem && byYearAndSem[yearNumber]) {
              combinedSemesterMajorCourses.push(...(byYearAndSem[yearNumber][semester] || []));
            }
          });
          const semesterMajorCoursesWithAutofill = combinedSemesterMajorCourses.map((c) => ({
            ...c,
            autofillType: 'Major Course',
          }));

          // ----------------------------------------------------------------------------
          // 2) MAJOR ELECTIVES
          // ----------------------------------------------------------------------------
          const combinedElectiveCourses = [];
          // Aggregate the major electives for the given year+semester
          Object.entries(majorElectives).forEach(([, electivesByMajor]) => {
            if (electivesByMajor?.[`year${yearNumber}`]) {
              combinedElectiveCourses.push(
                ...electivesByMajor[`year${yearNumber}`][semester],
              );
            }
          });

          // Count how many times each "Possibility" is required
          const electiveNameStrings = combinedElectiveCourses.map((course) => course.Possibilities);
          const semesterElectiveRequirements = electiveNameStrings.reduce((acc, name) => {
            if (!acc[name]) {
              const course = combinedElectiveCourses.find((c) => c.Possibilities === name);
              const electiveFullName = course ? course.Requirements : '';
              acc[name] = { quantity: 0, electiveFullName };
            }
            acc[name].quantity += 1;
            return acc;
          }, {});

          let autofillElectiveCourses = [];
          Object.entries(semesterElectiveRequirements).forEach(
            ([requirementName, { quantity, electiveFullName }]) => {
              // Find how many relevant courses already exist
              let existingCoursesInSemester = [];
              if (requirementName === 'Free-NonMajor-Elective') {
                existingCoursesInSemester = existingFinalizedSchedule
                  .map((num) => allCourses.find((c) => c.courseInfo_courseNumber === num))
                  .filter((course) => !!course);
              } else {
                existingCoursesInSemester = existingFinalizedSchedule
                  .map((num) => allCourses.find((c) => c.courseInfo_courseNumber === num))
                  .filter((course) => course?.Elective_fulfillment.includes(requirementName));
              }

              const remainingQuantity = quantity - existingCoursesInSemester.length;
              if (remainingQuantity > 0) {
                let filteredCourses = [];
                if (requirementName === 'Free-NonMajor-Elective') {
                  filteredCourses = allAutofillableCourses.filter((course) => {
                    const isDuplicate = allCourseNumbersInPlan.includes(
                      course.courseInfo_courseNumber,
                    );
                    // Example of a filter: only pick courses with Credits >= 3 or 4
                    // (You may adjust as needed.)
                    return !isDuplicate && course.Credits >= 4;
                  });
                } else {
                  filteredCourses = allAutofillableCourses.filter((course) => {
                    const isDuplicate = allCourseNumbersInPlan.includes(
                      course.courseInfo_courseNumber,
                    );
                    return (
                      course.Elective_fulfillment.includes(requirementName) && !isDuplicate
                    );
                  });
                }

                // Sort by some "recScore" descending (or any other metric)
                const sortedCourses = filteredCourses.sort((a, b) => b.recScore - a.recScore);

                // Slice top N
                const topCourses = sortedCourses.slice(0, remainingQuantity).map((c) => ({
                  ...c,
                  electiveRequirement: requirementName,
                  electiveFullName,
                }));
                autofillElectiveCourses.push(...topCourses);
              }
            },
          );

          const autofillElectiveCoursesWithAutofill = autofillElectiveCourses.map((course) => ({
            ...course,
            autofillType: 'Elective',
          }));

          // ----------------------------------------------------------------------------
          // 3) MAJOR REQUIREMENTS
          // ----------------------------------------------------------------------------
          const combinedRequirementCourses = [];
          Object.entries(majorRequirementCourses).forEach(([, requirementsByMajor]) => {
            if (requirementsByMajor?.[`year${yearNumber}`]) {
              combinedRequirementCourses.push(
                ...requirementsByMajor[`year${yearNumber}`][semester],
              );
            }
          });

          const requirementNameStrings = combinedRequirementCourses.map((c) => c.Requirements);
          const semesterRequirementDetails = requirementNameStrings.reduce((acc, name) => {
            if (!acc[name]) {
              const course = combinedRequirementCourses.find((req) => req.Requirements === name);
              const possibleCourses = course
                ? course.Possibilities.split(',').map((x) => x.trim())
                : [];
              acc[name] = { quantity: 0, possibleCourses };
            }
            acc[name].quantity += 1;
            return acc;
          }, {});

          let autofillRequirementCourses = [];
          Object.entries(semesterRequirementDetails).forEach(
            ([requirementName, { quantity, possibleCourses }]) => {
              // Are any of these possibleCourses already in the plan?
              const alreadyUsedCount = possibleCourses.filter((num) =>
                existingFinalizedSchedule.includes(num),
              ).length;
              const remainingQuantity = quantity - alreadyUsedCount;
              if (remainingQuantity > 0) {
                const filtered = possibleCourses
                  .map((courseNumber) =>
                    allAutofillableCourses.find(
                      (c) =>
                        c.courseInfo_courseNumber === courseNumber &&
                        !allCourseNumbersInPlan.includes(courseNumber),
                    ),
                  )
                  .filter((c) => c !== undefined);

                const sortedCourses = filtered.sort((a, b) => b.recScore - a.recScore);

                const top = sortedCourses.slice(0, remainingQuantity).map((c) => ({
                  ...c,
                  requirementName,
                }));
                autofillRequirementCourses.push(...top);
              }
            },
          );
          const autofillRequirementCoursesWithAutofill = autofillRequirementCourses.map((c) => ({
            ...c,
            autofillType: 'Requirement',
          }));

          // ----------------------------------------------------------------------------
          // 4) MINOR COURSES
          // ----------------------------------------------------------------------------
          const combinedMinorCourses = [];
          Object.entries(minorCourses).forEach(([, requirementsByMinor]) => {
            if (
              requirementsByMinor?.[`year${yearNumber}`] &&
              requirementsByMinor[`year${yearNumber}`][semester]
            ) {
              combinedMinorCourses.push(...requirementsByMinor[`year${yearNumber}`][semester]);
            }
          });

          let autofillMinorCoursesWithAutofill = [];
          if (combinedMinorCourses.length) {
            const minorNums = combinedMinorCourses.map((c) => c.courseInfo_courseNumber);
            const autofillMinorCoursesUnique = minorNums
              .map((num) =>
                allAutofillableCourses.find(
                  (c) => c.courseInfo_courseNumber === num && !allCourseNumbersInPlan.includes(num),
                ),
              )
              .filter(Boolean);

            autofillMinorCoursesWithAutofill = autofillMinorCoursesUnique.map((c) => ({
              ...c,
              autofillType: 'Minor Course',
            }));
          }

          // ----------------------------------------------------------------------------
          // 5) MINOR REQUIREMENTS
          // ----------------------------------------------------------------------------
          const combinedMinorReqCourses = [];
          Object.entries(minorRequirementCourses).forEach(([, reqsByMinor]) => {
            if (
              reqsByMinor?.[`year${yearNumber}`] &&
              reqsByMinor[`year${yearNumber}`][semester]
            ) {
              combinedMinorReqCourses.push(...reqsByMinor[`year${yearNumber}`][semester]);
            }
          });

          let autofillMinorRequirementCourses = [];
          if (combinedMinorReqCourses.length) {
            const requirementMinorNameStrings = combinedMinorReqCourses.map((c) => c.Requirements);
            const semesterMinorReqDetails = requirementMinorNameStrings.reduce((acc, name) => {
              if (!acc[name]) {
                const c = combinedMinorReqCourses.find((x) => x.Requirements === name);
                const possible = c ? c.Possibilities.split(',').map((x) => x.trim()) : [];
                acc[name] = { quantity: 0, possibleCourses: possible };
              }
              acc[name].quantity += 1;
              return acc;
            }, {});

            Object.entries(semesterMinorReqDetails).forEach(
              ([reqName, { quantity, possibleCourses }]) => {
                const hasCount = possibleCourses.filter((num) =>
                  existingFinalizedSchedule.includes(num),
                ).length;
                const needed = quantity - hasCount;
                if (needed > 0) {
                  const filtered = possibleCourses
                    .map((num) =>
                      allAutofillableCourses.find(
                        (c) => c.courseInfo_courseNumber === num && !allCourseNumbersInPlan.includes(num),
                      ),
                    )
                    .filter(Boolean);

                  const sorted = filtered.sort((a, b) => b.recScore - a.recScore);
                  const top = sorted.slice(0, needed).map((x) => ({ ...x, requirementName: reqName }));
                  autofillMinorRequirementCourses.push(...top);
                }
              },
            );
          }
          const autofillMinorRequirementCoursesWithAutofill =
            autofillMinorRequirementCourses.map((c) => ({
              ...c,
              autofillType: 'Requirement',
            }));

          // ----------------------------------------------------------------------------
          // Combine them all
          // ----------------------------------------------------------------------------
          const allSemesterAutofillCourses = [
            ...semesterMajorCoursesWithAutofill,
            ...autofillElectiveCoursesWithAutofill,
            ...autofillRequirementCoursesWithAutofill,
            ...autofillMinorCoursesWithAutofill,
            ...autofillMinorRequirementCoursesWithAutofill,
          ];

          // Keep only unique by courseInfo_courseNumber
          const uniqueAutofillCourses = allSemesterAutofillCourses.reduce((acc, curr) => {
            const exists = acc.find(
              (item) => item.courseInfo_courseNumber === curr.courseInfo_courseNumber,
            );
            return exists ? acc : [...acc, curr];
          }, []);

          // Filter out any duplicates with the entire plan
          const trulyUnique = uniqueAutofillCourses.filter(
            (c) => !allCourseNumbersInPlan.includes(c.courseInfo_courseNumber),
          );

          // Check existing credit count in this semester
          const existingFinalizedScheduleObjects = allCourses.filter((c) =>
            existingFinalizedSchedule.includes(c.courseInfo_courseNumber),
          );
          const existingCreditCount = existingFinalizedScheduleObjects.reduce(
            (total, c) => total + c.Credits,
            0,
          );

          const maxAdditionalCredits = maxCreditsPerSemester - existingCreditCount;
          let accumulated = 0;
          const truncatedAutofillCourses = [];

          // Only add courses until we reach maxAdditionalCredits
          for (const course of trulyUnique) {
            if (accumulated + course.Credits > maxAdditionalCredits) break;
            truncatedAutofillCourses.push(course);
            accumulated += course.Credits;
          }

          // Mark them as isAutofill for the UI
          const newAutofillCourses = truncatedAutofillCourses.map((course) => ({
            ...course,
            isAutofill: true,
          }));

          // Generate final updated list
          const newCourseIds = [
            ...new Set([...existingFinalizedSchedule, ...newAutofillCourses.map((c) => c.courseInfo_courseNumber)]),
          ];

          console.log(newCourseIds, 'here')

          // Write to Firestore
          planData[`year${yearNumber}`][semesterCode].courses = newCourseIds;
          transaction.update(userRef, {
            [`schedules.${activeTab}.year${yearNumber}.${semesterCode}.courses`]: newCourseIds,
          });

          // Update local state in memory
          setFinalizedOrganizedCourses((prev) => {
            const updated = { ...prev };
            if (!updated[`year${yearNumber}`]) {
              updated[`year${yearNumber}`] = {};
            }
            if (!updated[`year${yearNumber}`][semester]) {
              updated[`year${yearNumber}`][semester] = [];
            }

            const existingNums = updated[`year${yearNumber}`][semester].map(
              (c) => c.courseInfo_courseNumber,
            );
            const filtered = newAutofillCourses.filter(
              (c) => !existingNums.includes(c.courseInfo_courseNumber),
            );
            updated[`year${yearNumber}`][semester] = [
              ...updated[`year${yearNumber}`][semester],
              ...filtered,
            ];

            // Finally, re-check prerequisites
            recheckPrerequisites(updated);

            return updated;
          });
        });

        // Optionally update the toast to success
        setTimeout(() => {
          toast.update(loadingToastId, {
            render: 'Autofilled courses',
            type: 'success',
            autoClose: 1000,
            isLoading: false,
            hideProgressBar: true,
            style: { width: '12rem' },
          });
        }, 500);
      } catch (err) {
        console.error('Error autofilling courses:', err);
        // Optionally show an error toast
        // setTimeout(() => {
        //   toast.update(loadingToastId, {
        //     render: 'Failed to autofill courses',
        //     type: 'error',
        //     autoClose: 3000,
        //     isLoading: false,
        //   });
        // }, 500);
      }
    },
    [
      currentUser,
      effectiveUserId,
      activeTab,
      recheckPrerequisites,
      setFinalizedOrganizedCourses,
      allCourses,
      majorCourses,
      organizeMajorCoursesByYearAndSemester,
      majorElectives,
      majorRequirementCourses,
      minorCourses,
      minorRequirementCourses,
      finalizedOrganizedCourses,
      maxCreditsPerSemester,
    ],
  );

  return { handleAutofillCourse };
}