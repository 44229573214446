import React, { useState } from 'react';

const CourseSection = ({
  plannedCourses,
  activeCourses,
  allCourseSchedules,
  isComputing,
  computeCombinations,
  combinations,
  combinationIndex,
  setCombinationIndex,
  onToggleCourse,
  onSelectAllCourses,
  hasComputed
}) => {
  const [expandedCourses, setExpandedCourses] = useState({});

  const toggleExpand = (course) => {
    const key = `${course.dept}-${course.number}-${course.section}`.toLowerCase();
    setExpandedCourses(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const getScoreColor = (score) => {
    if (score >= 80) return "text-green-600";
    if (score >= 50) return "text-yellow-600";
    return "text-red-600";
  };

  const truncate = (str, maxLength) => {
    if (!str) return "";
    return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
  };

  const currentScore = (combinations && combinations.length > 0)
    ? combinations[combinationIndex]?.score
    : null;

  const allSelected =
    plannedCourses.length > 0 &&
    plannedCourses.every(course =>
      activeCourses.some(ac =>
        ac.dept === course.dept &&
        ac.number === course.number &&
        ac.section === course.section
      )
    );

  const colorOptions = [
    "bg-red-300",
    "bg-green-300",
    "bg-blue-300",
    "bg-yellow-300",
    "bg-purple-300",
    "bg-pink-300",
    "bg-indigo-300",
    "bg-teal-300",
    "bg-orange-300"
  ];
  const courseColors = {};
  plannedCourses.forEach((course, index) => {
    const key = `${course.dept}-${course.number}-${course.section}`.toLowerCase();
    courseColors[key] = colorOptions[index % colorOptions.length];
  });

  return (
    <>
      <div>
        <h2 className="text-xl font-bold text-gray-800 mb-4">Courses</h2>
        <div className="max-h-[500px] overflow-y-auto">
          {plannedCourses.length > 0 ? (
            <div>
              <div className="flex items-center mb-2">
                <input
                  id="select-all-courses"
                  type="checkbox"
                  checked={allSelected}
                  onChange={(e) => onSelectAllCourses(e.target.checked)}
                  className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
                <label htmlFor="select-all-courses" className="ml-2 text-sm font-medium text-gray-700">
                  Select All Courses
                </label>
              </div>
              <div className="space-y-3 border-t border-gray-200 pt-2">
                {plannedCourses.map((course, idx) => {
                  const key = `${course.dept}-${course.number}-${course.section}`.toLowerCase();
                  const isActive = activeCourses.some(ac =>
                    ac.dept === course.dept &&
                    ac.number === course.number &&
                    ac.section === course.section
                  );
                  const fetchedCourse = allCourseSchedules.find(cs =>
                    cs.dept === course.dept &&
                    cs.number === course.number &&
                    cs.section === course.section
                  );
                  const leftColumn = (
                    <div className="flex items-center space-x-3">
                      <span className={`inline-block w-4 h-4 rounded-full ${courseColors[key]}`}></span>
                      <div className="flex flex-col">
                        <span className="text-sm font-medium text-gray-800">
                          {course.dept.toUpperCase()} {course.number} {course.section && `(${course.section.toUpperCase()})`}
                        </span>
                        {fetchedCourse && fetchedCourse.sfuData && fetchedCourse.sfuData.info && (
                          <span className="text-xs text-gray-500">
                            {fetchedCourse.sfuData.info.term}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                  const rightColumn = (
                    <div className="flex items-center space-x-2">
                      {fetchedCourse && fetchedCourse.sfuData && fetchedCourse.sfuData.info ? (
                        <>
                          {fetchedCourse.sfuData.info.title && (
                            <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                              {truncate(fetchedCourse.sfuData.info.title, 25)}
                            </span>
                          )}
                          {fetchedCourse.sfuData.info.deliveryMethod && (
                            <span className="bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                              {fetchedCourse.sfuData.info.deliveryMethod}
                            </span>
                          )}
                          {fetchedCourse.sfuData.info.units && (
                            <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                              {fetchedCourse.sfuData.info.units} Units
                            </span>
                          )}
                        </>
                      ) : (
                        <span className="text-xs text-gray-500">No additional course information available.</span>
                      )}
                    </div>
                  );
                  return (
                    <div key={idx} className="border rounded shadow-sm p-3">
                      <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-4">
                          <input
                            id={`course-${idx}`}
                            type="checkbox"
                            checked={isActive}
                            onChange={() => onToggleCourse(course)}
                            className="h-4 w-4 text-blue-600 border-gray-300 rounded mt-1"
                          />
                          {leftColumn}
                        </div>
                        {rightColumn}
                        <button onClick={() => toggleExpand(course)} className="text-blue-600 ml-2">
                          {expandedCourses[key] ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.5 15.75l7.5-7.5 7.5 7.5"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          )}
                        </button>
                      </div>
                      {expandedCourses[key] && (
                        <div className="mt-2 border-t pt-2">
                          {fetchedCourse && fetchedCourse.dataOriginAlert && (
                            <div className="bg-yellow-200 text-yellow-800 text-xs p-1 rounded mb-2">
                              {fetchedCourse.dataOriginAlert}
                            </div>
                          )}
                          {fetchedCourse && fetchedCourse.sfuData && fetchedCourse.sfuData.info ? (
                            <dl className="text-sm text-gray-600 space-y-1">
                              {fetchedCourse.sfuData.info.title && (
                                <>
                                  <dt className="font-semibold">Title:</dt>
                                  <dd>{fetchedCourse.sfuData.info.title}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.name && (
                                <>
                                  <dt className="font-semibold">Course Name:</dt>
                                  <dd>{fetchedCourse.sfuData.info.name}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.term && (
                                <>
                                  <dt className="font-semibold">Term:</dt>
                                  <dd>{fetchedCourse.sfuData.info.term}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.units && (
                                <>
                                  <dt className="font-semibold">Units:</dt>
                                  <dd>{fetchedCourse.sfuData.info.units}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.deliveryMethod && (
                                <>
                                  <dt className="font-semibold">Delivery Method:</dt>
                                  <dd>{fetchedCourse.sfuData.info.deliveryMethod}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.degreeLevel && (
                                <>
                                  <dt className="font-semibold">Degree Level:</dt>
                                  <dd>{fetchedCourse.sfuData.info.degreeLevel}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.classNumber && (
                                <>
                                  <dt className="font-semibold">Class Number:</dt>
                                  <dd>{fetchedCourse.sfuData.info.classNumber}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.dept &&
                                fetchedCourse.sfuData.info.number &&
                                fetchedCourse.sfuData.info.section && (
                                  <>
                                    <dt className="font-semibold">Course Identifier:</dt>
                                    <dd>
                                      {`${fetchedCourse.sfuData.info.dept.toUpperCase()} ${fetchedCourse.sfuData.info.number} ${fetchedCourse.sfuData.info.section.toUpperCase()}`}
                                    </dd>
                                  </>
                                )}
                              {fetchedCourse.sfuData.info.outlinePath && (
                                <>
                                  <dt className="font-semibold">Outline Path:</dt>
                                  <dd>{fetchedCourse.sfuData.info.outlinePath}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.type && (
                                <>
                                  <dt className="font-semibold">Type:</dt>
                                  <dd>{fetchedCourse.sfuData.info.type}</dd>
                                </>
                              )}
                              <dt className="font-semibold">Prerequisites:</dt>
                              <dd>{fetchedCourse.sfuData.info.prerequisites || "None"}</dd>
                              <dt className="font-semibold">Corequisites:</dt>
                              <dd>{fetchedCourse.sfuData.info.corequisites || "None"}</dd>
                              {fetchedCourse.sfuData.info.description && (
                                <>
                                  <dt className="font-semibold">Description:</dt>
                                  <dd>{fetchedCourse.sfuData.info.description}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.designation && (
                                <>
                                  <dt className="font-semibold">Designation:</dt>
                                  <dd>{fetchedCourse.sfuData.info.designation}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.notes && (
                                <>
                                  <dt className="font-semibold">Notes:</dt>
                                  <dd>{fetchedCourse.sfuData.info.notes}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.registrarNotes && (
                                <>
                                  <dt className="font-semibold">Registrar Notes:</dt>
                                  <dd>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: fetchedCourse.sfuData.info.registrarNotes
                                      }}
                                    />
                                  </dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.requiredReadingNotes && (
                                <>
                                  <dt className="font-semibold">Required Reading:</dt>
                                  <dd>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: fetchedCourse.sfuData.info.requiredReadingNotes
                                      }}
                                    />
                                  </dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.departmentalUgradNotes && (
                                <>
                                  <dt className="font-semibold">Departmental Ugrad Notes:</dt>
                                  <dd>{fetchedCourse.sfuData.info.departmentalUgradNotes}</dd>
                                </>
                              )}
                              {fetchedCourse.sfuData.info.specialTopic && (
                                <>
                                  <dt className="font-semibold">Special Topic:</dt>
                                  <dd>{fetchedCourse.sfuData.info.specialTopic}</dd>
                                </>
                              )}
                            </dl>
                          ) : (
                            <div className="text-sm text-gray-500">
                              No additional course information available.
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <p className="text-sm text-gray-700">Loading courses...</p>
          )}
        </div>
        <div className="mt-4">
          <button
            onClick={computeCombinations}
            className="inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5"
            disabled={isComputing || plannedCourses.length === 0}
          >
            {isComputing ? (
              <>
                <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908Z" fill="#E5E7EB"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                </svg>
                Generating Schedules...
              </>
            ) : (
              "Generate Schedules"
            )}
          </button>
        </div>
        {combinations.length > 0 && (
          <div className="mt-4">
            <p className="mb-2 text-sm text-gray-700">
              Showing combination {combinationIndex + 1} of {combinations.length}
            </p>
            {currentScore !== null && (
              <p className={`font-bold text-lg ${getScoreColor(currentScore)} bg-gray-100 px-2 py-1 rounded`}>
                Schedule Score: {currentScore}/100
              </p>
            )}
            <div className="flex space-x-2 mt-2">
              <button className="btn btn-outline" onClick={() => setCombinationIndex(prev => (prev > 0 ? prev - 1 : combinations.length - 1))}>
                Prev Combination
              </button>
              <button className="btn btn-primary" onClick={() => setCombinationIndex(prev => (prev < combinations.length - 1 ? prev + 1 : 0))}>
                Next Combination
              </button>
            </div>
          </div>
        )}
        {hasComputed && combinations.length === 0 && plannedCourses.length > 0 && !isComputing && (
          <div className="mt-4 p-4 border border-red-300 bg-red-50">
            <p className="text-sm text-red-800">
              No valid schedule combination was found with the selected courses.
              Please adjust your course selection and try again.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default CourseSection;
