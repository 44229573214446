import React, { useState, useEffect, useCallback } from 'react';
import MultiSelect from '../../../ClassSearch/MultiSelect';
import { toast } from 'react-toastify';
import { FaPlus } from 'react-icons/fa';
import { useAuth } from '../../../../contexts/AuthContext';

// If you previously used the "filters.json" approach, you can load them. 
// For simplicity, we’ll just define them inline:
const PERSISTENCE_CATEGORIES = ['Very High', 'High', 'Moderate', 'Low', 'Very Low'];

const SearchComponent = ({
  onSearch,
  students,
  fieldTypes,
  onFilter,
  onSelectAll,
  selectedStudents,
  filteredStudentsCount,
  everythingSelected,
  advisors,

  dashboardFilterType,
  dashboardFilterValue,
}) => {
  const { fetchedMajors } = useAuth();

  const [searchTerm, setSearchTerm] = useState('');
  const [availableFilters, setAvailableFilters] = useState({
    gradYear: [],
    program: [],
    advisors: [],
    // We remove 'performance' and add 'persistence'
    persistence: PERSISTENCE_CATEGORIES,
  });
  const [selectedFilters, setSelectedFilters] = useState({
    gradYear: [],
    program: [],
    advisors: [],
    persistence: [], // <-- new
  });

  // Custom field-based filter
  const [selectedField, setSelectedField] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [operatorError, setOperatorError] = useState('');

  // We'll store separate state for each multi-select
  const [selectedGradYears, setSelectedGradYears] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  // Replace "selectedPerformance" with "selectedPersistence"
  const [selectedPersistence, setSelectedPersistence] = useState([]);

  // Utility to remove or rename fields for the custom filter
  const transformFieldName = (field) => {
    // Example renames
    const renameMap = {
      planned: 'Classes Planned',
      taken: 'Classes Taken',
      total: 'Total Classes',
      GPA: 'GPA',
      // add if needed
    };
    if (renameMap[field]) return renameMap[field];
    // Fallback: nice spacing
    const spaced = field.replace(/([A-Z])/g, ' $1');
    const capitalized = spaced
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    return capitalized;
  };

  const fieldNameMap = Object.keys(fieldTypes).reduce((map, field) => {
    const transformed = transformFieldName(field);
    // skip excluded fields if desired
    map[transformed] = field;
    return map;
  }, {});
  const studentFields = Object.keys(fieldNameMap);

  // On mount, fetch some "gradYear" or "program" data
  useEffect(() => {
    const fetchFilters = async () => {
      // If you used filters.json, or want to do it inline:
      // We'll just do it inline for demonstration
      const newAvailable = {
        gradYear: [], // we’ll fill from the "students" directly
        program: Object.keys(fetchedMajors || {}),
        advisors: advisors,
        persistence: PERSISTENCE_CATEGORIES,
      };

      // Example: find unique grad years from your students
      const gradYearSet = new Set();
      students.forEach((s) => {
        const gy = s.surveyAnswers?.SchoolEnd?.year;
        if (gy) gradYearSet.add(gy);
      });
      newAvailable.gradYear = Array.from(gradYearSet);

      setAvailableFilters(newAvailable);
    };
    fetchFilters();
  }, [students, fetchedMajors, advisors]);

  // Whenever user changes the search box
  useEffect(() => {
    onSearch(searchTerm);
  }, [searchTerm, onSearch]);

  // Rebuild "selectedFilters" whenever the user picks from multi-select
  useEffect(() => {
    setSelectedFilters({
      gradYear: selectedGradYears,
      program: selectedPrograms,
      advisors: selectedAdvisors,
      persistence: selectedPersistence, // new
    });
  }, [selectedGradYears, selectedPrograms, selectedAdvisors, selectedPersistence]);

  // Combine customFilter with selectedFilters
  useEffect(() => {
    onFilter({
      ...selectedFilters,
      customFilter: {
        field: selectedField,
        operator: selectedOperator,
        value: filterValue,
        error: operatorError,
      },
    });
  }, [
    selectedFilters,
    selectedField,
    selectedOperator,
    filterValue,
    operatorError,
    onFilter,
  ]);

  // If you want to handle a "dashboardFilterType/dashboardFilterValue", 
  // you can do it similarly to how you handle above.

  // Function to get possible values for a custom field (optional)
  const getPossibleValuesForField = (field) => {
    const uniqueValues = new Set();
    students.forEach((student) => {
      if (student[field] !== undefined && student[field] !== null) {
        if (Array.isArray(student[field])) {
          student[field].forEach((v) => uniqueValues.add(v));
        } else {
          uniqueValues.add(student[field]);
        }
      }
    });
    return Array.from(uniqueValues);
  };

  // "Select All" or "Unselect All"
  const handleSelectAllClick = () => {
    onSelectAll();
  };

  // Reset all filters
  const handleResetFilters = () => {
    setSearchTerm('');
    setSelectedGradYears([]);
    setSelectedPrograms([]);
    setSelectedAdvisors([]);
    setSelectedPersistence([]); // new
    setSelectedField('');
    setSelectedOperator('');
    setFilterValue('');
    setOperatorError('');
  };

  return (
    <div className="pr-4 pl-4 pb-2 pt-2 bg-gray-50 shadow-md border rounded-lg">
      <h5 className="text-lg text-center font-bold mb-2">Filters</h5>

      {/* Search Filter */}
      <div className="mb-2">
        <label
          htmlFor="searchQuery"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Search:
        </label>
        <input
          type="text"
          id="searchQuery"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                     focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
        />
      </div>

        <label htmlFor="customField" className="block text-sm font-medium mb-1">
          Filter by Field:
        </label>
        <MultiSelect
          suggestions={studentFields}
          value={selectedField ? [transformFieldName(selectedField)] : []}
          onChange={(e) => {
            const transformedName = e.target.value[0] || '';
            const originalName = fieldNameMap[transformedName];
            setSelectedField(originalName);
            setSelectedOperator('');
            setFilterValue('');
            setOperatorError('');
          }}
          placeholder="Select a field"
          required={false}
          tagColor="gray"
          singleSelect
        />

      {/* Operator */}
      {selectedField && (
        <div className="mt-2">
          <label htmlFor="operator" className="block text-sm font-medium mb-1">
            Operator:
          </label>
          <MultiSelect
            suggestions={['equals', 'greater than', 'less than']}
            value={selectedOperator ? [selectedOperator] : []}
            onChange={(e) => {
              const op = e.target.value[0] || '';
              setSelectedOperator(op);
              // If "greater than"/"less than" but field not numeric => error
              // etc. 
              // For brevity, omit that logic or replicate your prior approach
            }}
            placeholder="Select an operator"
            required={false}
            tagColor="gray"
            singleSelect
          />
        </div>
      )}

      {/* Value Input */}
      {selectedOperator && !operatorError && (
        <div className="mt-2">
          <label htmlFor="filterValue" className="block text-sm font-medium mb-1">
            Value:
          </label>
          {/* If the field is numeric, show <input type="number">. 
              Otherwise show a MultiSelect of possible values, etc. */}
          <MultiSelect
            suggestions={getPossibleValuesForField(selectedField)}
            value={filterValue ? [filterValue] : []}
            onChange={(e) => setFilterValue(e.target.value[0] || '')}
            placeholder="Select a value"
            required={false}
            tagColor="gray"
            singleSelect
          />
        </div>
      )}

      {/* Graduation Year Filter */}
      <div className="mb-2">
        <label htmlFor="gradYear" className="block text-sm font-medium mb-1">
          Graduation Year:
        </label>
        <MultiSelect
          suggestions={availableFilters.gradYear}
          value={selectedGradYears}
          onChange={(e) => setSelectedGradYears(e.target.value)}
          placeholder="Select graduation years"
          required={false}
          tagColor="red"
        />
      </div>

      {/* Program Filter */}
      <div className="mb-2">
        <label htmlFor="program" className="block text-sm font-medium mb-1">
          Program:
        </label>
        <MultiSelect
          suggestions={availableFilters.program}
          value={selectedPrograms}
          onChange={(e) => setSelectedPrograms(e.target.value)}
          placeholder="Select programs"
          required={false}
          tagColor="green"
        />
      </div>

      {/* Advisor Filter */}
      <div className="mb-2">
        <label htmlFor="advisors" className="block text-sm font-medium mb-1">
          Advisor:
        </label>
        <MultiSelect
          suggestions={availableFilters.advisors}
          value={selectedAdvisors}
          onChange={(e) => setSelectedAdvisors(e.target.value)}
          placeholder="Select advisors"
          required={false}
          tagColor="purple"
        />
      </div>

      {/* NEW: Persistence Filter (replaces Performance) */}
      <div className="mb-2">
        <label htmlFor="persistence" className="block text-sm font-medium mb-1">
          Persistence:
        </label>
        <MultiSelect
          suggestions={availableFilters.persistence} 
          value={selectedPersistence}
          onChange={(e) => setSelectedPersistence(e.target.value)}
          placeholder="Select persistence level(s)"
          required={false}
          tagColor="orange"
        />
      </div>

      <button
        className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm 
                   py-2 px-4 rounded mt-2"
        onClick={handleSelectAllClick}
      >
        {everythingSelected ? 'Unselect All Students' : 'Select All Students'}
      </button>

      <button
        className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm 
                   py-2 px-4 rounded mt-2"
        onClick={handleResetFilters}
      >
        Reset Filters
      </button>

      <div className="flex justify-center mt-3">
        <span className="bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          {filteredStudentsCount} Students Displayed
        </span>
      </div>
    </div>
  );
};

export default SearchComponent;
