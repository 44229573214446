import React, { useState, useEffect } from 'react';
import { IoCheckmarkCircleOutline, IoRepeatOutline } from "react-icons/io5"; // Importing icons
import { useTranslation } from 'react-i18next';

const CoursesListing = ({ classData, onAddClass, inPlan, isRec }) => {
  // Initialize the translation hook without specifying a namespace
  const { t } = useTranslation();

  const [collapsed, setCollapsed] = useState(true);
  const [isInPlan, setIsInPlan] = useState(inPlan);
  const [isHovered, setIsHovered] = useState(false); // New state for hover effect

  useEffect(() => {
    setIsInPlan(inPlan);
  }, [inPlan]);

  const getBackgroundGradient = (score) => {
    const green = [144, 238, 144]; // RGB for light green
    const yellow = [255, 255, 224]; // RGB for light yellow
    const red = [255, 182, 193]; // RGB for light red

    let startColor, endColor;
    let percentage;

    if (score >= 50) {
      startColor = yellow;
      endColor = green;
      percentage = (score - 50) / 50;
    } else {
      startColor = red;
      endColor = yellow;
      percentage = score / 50;
    }

    const interpolateColor = (start, end, factor) =>
      start.map((startComponent, index) =>
        Math.round(startComponent + factor * (end[index] - startComponent))
      );

    const [r, gVal, b] = interpolateColor(startColor, endColor, percentage);

    return `rgb(${r}, ${gVal}, ${b})`;
  };

  const toggleCollapse = () => setCollapsed(!collapsed);

  const handleButtonClick = (event) => {
    event.stopPropagation(); // Prevent the click from toggling collapse
    onAddClass(classData);
  };

  // Determine the button content based on state
  const buttonContent = isInPlan ? (
    isHovered ? (
      <>
        <IoRepeatOutline className="w-5 h-5 mr-1" />
        {t('coursesListing.addAgain', 'Add Again')}
      </>
    ) : (
      <>
        <IoCheckmarkCircleOutline className="w-5 h-5 mr-1" />
        {t('coursesListing.inPlan', 'In Plan')}
      </>
    )
  ) : (
    t('coursesListing.addToCart', 'Add to Cart')
  );

  return (
    <div className="border bg-white rounded-lg p-2 mb-2">
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleCollapse}>
        <div>
          <p className="font-bold">{t('coursesListing.courseId', 'Course ID')}: {classData.courseInfo_courseNumber}</p>
          <p>
            {t('coursesListing.credits', 'Credits')}: {classData.Credits}{' '}
            {isRec ? (
              <span
                style={{ background: getBackgroundGradient(classData.recScore) }}
                className="p-1 mb-2 rounded-lg"
              >
                {t('coursesListing.recScore', 'Rec Score')}: {classData.recScore}
              </span>
            ) : (
              ''
            )}
          </p>
        </div>
        <p className="text-center flex-1">{classData.courseInfo_courseName}</p>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleButtonClick}
            className={`flex items-center justify-center ${
              isInPlan
                ? 'text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-500 dark:focus:ring-green-800'
                : 'text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800'
            } font-medium rounded-lg text-sm px-2 py-2 text-center transition-all duration-300`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {buttonContent}
          </button>
          {collapsed ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          )}
        </div>
      </div>
      {!collapsed && (
        <div className="mt-3">
          <div className="flex justify-between">
            <p className="mb-2">
              <strong>{t('coursesListing.instructor', 'Instructor')}:</strong> {classData.Instructor}
            </p>
            <p className="mb-2 text-right">
              <strong>{t('coursesListing.typicallyOffered', 'Typically Offered')}:</strong> {classData.Offering}
            </p>
            <p className="mb-2 text-center">
              <strong>{t('coursesListing.mode', 'Mode')}:</strong> {classData.Mode}
            </p>
          </div>
          <p className="mb-2">
            <strong>{t('coursesListing.description', 'Description')}:</strong> {classData.Description}
          </p>
        </div>
      )}
    </div>
  );
};

export default CoursesListing;
