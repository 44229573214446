import { findEnrollmentTotal } from './CalculateEnrollmentTrends'; // Helper for finding term enrollment total

// List of majors with default weights
const majorsList = [
  { major: "Business and Management", weight: 0.12 },
  { major: "Computer Science", weight: 0.1 },
  { major: "Nursing", weight: 0.08 },
  { major: "Psychology", weight: 0.08 },
  { major: "Biology", weight: 0.08 },
  { major: "Engineering", weight: 0.08 },
  { major: "Education", weight: 0.06 },
  { major: "Communications", weight: 0.06 },
  { major: "Finance and Accounting", weight: 0.06 },
  { major: "Criminal Justice", weight: 0.05 },
  { major: "Anthropology and Sociology", weight: 0.04 },
  { major: "English", weight: 0.04 },
  { major: "Economics", weight: 0.04 },
  { major: "Political Science", weight: 0.04 },
  { major: "History", weight: 0.04 },
  { major: "Kinesiology and Physical Therapy", weight: 0.04 },
  { major: "Health Professions", weight: 0.03 },
  { major: "Art", weight: 0.03 },
  { major: "Math", weight: 0.03 },
  { major: "Environmental Science", weight: 0.03 },
  { major: "Foreign Languages", weight: 0.02 },
  { major: "Design", weight: 0.02 },
  { major: "Trades and Personal Services", weight: 0.02 },
  { major: "International Relations", weight: 0.02 },
  { major: "Chemistry", weight: 0.02 },
  { major: "Agricultural Sciences", weight: 0.02 },
  { major: "Information Technology", weight: 0.02 },
  { major: "Performing Arts", weight: 0.02 },
  { major: "Religious Studies", weight: 0.01 },
  { major: "Film and Photography", weight: 0.01 },
  { major: "Music", weight: 0.01 },
  { major: "Physics", weight: 0.01 },
  { major: "Philosophy", weight: 0.01 },
  { major: "Architecture", weight: 0.01 },
  { major: "Protective Services", weight: 0.01 },
  { major: "Legal Studies", weight: 0.01 },
  { major: "Pharmacy", weight: 0.01 },
  { major: "Dental Studies", weight: 0.01 },
  { major: "Veterinary Studies", weight: 0.01 },
  { major: "Actuarial Sciences", weight: 0.01 },
];

// Department-major priority mapping
const departmentMajorPriority = {
    ACMA: ["Actuarial Sciences", "Math", "Computer Science", "Information Technology", "Physics", "Chemistry"],
    ALS: ["Health Professions", "Kinesiology and Physical Therapy", "Psychology"],
    APMA: ["Math", "Engineering", "Physics"],
    ARAB: ["Foreign Languages", "International Relations", "Political Science"],
    ARCH: ["Architecture", "Design", "Environmental Science"],
    BISC: ["Biology", "Environmental Science", "Health Professions"],
    BPK: ["Kinesiology and Physical Therapy", "Health Professions", "Psychology"],
    BUS: ["Business and Management", "Finance and Accounting", "Economics"],
    CA: ["Art", "Design", "Film and Photography"],
    CHEM: ["Chemistry", "Biology", "Physics"],
    CHIN: ["Foreign Languages", "Cultural Studies", "International Relations"],
    CMNS: ["Communications", "English", "Psychology"],
    CMPT: ["Computer Science", "Math", "Information Technology", "Engineering"],
    COGS: ["Psychology", "Computer Science", "Philosophy"],
    CRIM: ["Criminal Justice", "Psychology", "Legal Studies"],
    DATA: ["Data Science", "Computer Science", "Math"],
    DIAL: ["Foreign Languages", "International Relations", "Cultural Studies"],
    DMED: ["Design", "Communications", "Film and Photography"],
    EASC: ["Environmental Science", "Geology", "Geography"],
    ECO: ["Economics", "Finance and Accounting", "Political Science"],
    ECON: ["Economics", "Finance and Accounting", "Business and Management"],
    EDPR: ["Education", "Psychology", "Sociology"],
    EDUC: ["Education", "Psychology", "Health Professions"],
    ENGL: ["English", "Communications", "Art"],
    ENSC: ["Engineering", "Environmental Science", "Math"],
    ENV: ["Environmental Science", "Geography", "Biology"],
    ETEC: ["Engineering Technicians", "Engineering", "Physics"],
    EVSC: ["Environmental Science", "Biology", "Chemistry"],
    FAL: ["Foreign Languages", "Cultural Studies", "History"],
    FASS: ["Anthropology and Sociology", "Political Science", "Psychology"],
    FREN: ["Foreign Languages", "International Relations", "History"],
    GA: ["Art", "Design", "Film and Photography"],
    GEOG: ["Geography", "Environmental Science", "Geology"],
    GERM: ["Foreign Languages", "International Relations", "History"],
    GERO: ["Psychology", "Health Professions", "Sociology"],
    GRK: ["Foreign Languages", "History", "Philosophy"],
    GRL: ["International Relations", "History", "Political Science"],
    GS: ["Anthropology and Sociology", "Political Science", "International Relations"],
    GSWS: ["Sociology", "Psychology", "Political Science"],
    HIST: ["History", "Political Science", "Anthropology and Sociology"],
    HSCI: ["Health Professions", "Biology", "Kinesiology and Physical Therapy"],
    HUM: ["Philosophy", "History", "English"],
    IAT: ["Design", "Computer Science", "Film and Photography"],
    INDG: ["Cultural Studies", "Anthropology and Sociology", "History"],
    INLG: ["Linguistics", "Cultural Studies", "Foreign Languages"],
    INS: ["International Relations", "Political Science", "History"],
    IS: ["International Relations", "Cultural Studies", "Foreign Languages"],
    ITAL: ["Foreign Languages", "History", "Cultural Studies"],
    JAPN: ["Foreign Languages", "Cultural Studies", "History"],
    LANG: ["Foreign Languages", "Cultural Studies", "International Relations"],
    LAS: ["Cultural Studies", "Anthropology and Sociology", "History"],
    LBRL: ["Philosophy", "History", "English"],
    LBST: ["Anthropology and Sociology", "History", "Political Science"],
    LING: ["Linguistics", "Foreign Languages", "Psychology"],
    LS: ["Legal Studies", "Political Science", "Sociology"],
    MACM: ["Math", "Computer Science", "Engineering"],
    MASC: ["Engineering", "Engineering Technicians", "Physics"],
    MATH: ["Math", "Computer Science", "Physics"],
    MBB: ["Biology", "Health Professions", "Chemistry"],
    MSE: ["Engineering", "Materials Science", "Physics"],
    MTEC: ["Engineering Technicians", "Engineering", "Information Technology"],
    NEUR: ["Neuroscience", "Psychology", "Biology"],
    NUSC: ["Health Professions", "Kinesiology and Physical Therapy", "Biology"],
    ONC: ["Health Professions", "Biology", "Psychology"],
    PERS: ["Foreign Languages", "History", "Cultural Studies"],
    PHIL: ["Philosophy", "History", "Psychology"],
    PHYS: ["Physics", "Engineering", "Math"],
    PLAN: ["Urban Planning", "Environmental Science", "Geography"],
    PLCY: ["Political Science", "Public Administration", "Economics"],
    POL: ["Political Science", "History", "International Relations"],
    PSYC: ["Psychology", "Health Professions", "Sociology"],
    PUB: ["Public Administration", "Political Science", "Economics"],
    PUNJ: ["Foreign Languages", "Cultural Studies", "Anthropology and Sociology"],
    REM: ["Environmental Science", "Urban Planning", "Geography"],
    SA: ["Art", "Design", "Cultural Studies"],
    SCI: ["General Sciences", "Biology", "Chemistry"],
    SD: ["Design", "Film and Photography", "Communications"],
    SDA: ["Design", "Art", "Film and Photography"],
    SEE: ["Engineering", "Environmental Science", "Urban Planning"],
    SPAN: ["Foreign Languages", "Cultural Studies", "History"],
    STAT: ["Statistics", "Math", "Data Science"],
    TEKX: ["Engineering", "Information Technology", "Computer Science"],
    TRSS: ["Trades and Personal Services", "Engineering Technicians", "Design"],
    URB: ["Urban Planning", "Geography", "Environmental Science"],
    WL: ["Cultural Studies", "Foreign Languages", "History"],
  };  

/**
 * Calculates a major distribution for a course based on enrollment data.
 * Ensures the counts sum up to the FA24 total enrollment.
 *
 * @param {string} courseNumber - The course number.
 * @param {Array} enrollmentData - The enrollment data for the course.
 * @returns {Array} Major distribution as an array of objects.
 */
export const calculateMajorDistribution = (courseNumber, enrollmentData) => {
    // Extract department from course number
    const department = courseNumber.match(/[A-Z]+/)[0];
  
    // Get total enrollment for FA24
    const fa24Total = findEnrollmentTotal(enrollmentData, "FA24");
  
    // Get prioritized majors for the department
    const prioritizedMajors = departmentMajorPriority[department] || [];
    
    // Step 1: Compute power law weights for prioritized majors
    const powerLawWeights = prioritizedMajors.map((_, index) => 1 / Math.pow(index + 1, 2));
    const totalPowerLawWeight = powerLawWeights.reduce((sum, weight) => sum + weight, 0);
    const normalizedPowerLawWeights = powerLawWeights.map((weight) => weight / totalPowerLawWeight);
  
    // Step 2: Get weights for prioritized majors from majorsList
    const majorWeights = majorsList.reduce((acc, { major, weight }) => {
      acc[major] = weight;
      return acc;
    }, {});
  
    const prioritizedWeights = prioritizedMajors.map((major) => majorWeights[major] || 0);
    const totalPrioritizedWeight = prioritizedWeights.reduce((sum, weight) => sum + weight, 0);
    const normalizedPrioritizedWeights = prioritizedWeights.map((weight) => weight / totalPrioritizedWeight);
  
    // Step 3: Combine power law and weights (70% power law, 30% weights)
    const combinedWeights = prioritizedMajors.map((major, index) => {
      const powerLawWeight = normalizedPowerLawWeights[index];
      const weightComponent = normalizedPrioritizedWeights[index];
      return 0.7 * powerLawWeight + 0.3 * weightComponent;
    });
  
    const totalCombinedWeight = combinedWeights.reduce((sum, weight) => sum + weight, 0);
    const normalizedCombinedWeights = combinedWeights.map((weight) => weight / totalCombinedWeight);
  
    // Step 4: Calculate counts for prioritized majors (85% of total enrollment)
    const prioritizedTotal = Math.round(fa24Total * 0.85);
    const prioritizedDistribution = prioritizedMajors.map((major, index) => ({
      major,
      count: Math.round(prioritizedTotal * normalizedCombinedWeights[index]),
    }));
  
    // Step 5: Distribute remaining 15% among non-prioritized majors
    const nonPrioritizedMajors = majorsList
      .filter(({ major }) => !prioritizedMajors.includes(major))
      .map((majorObj) => ({
        ...majorObj,
        weight: majorObj.weight, // Use original weights for non-prioritized majors
      }));
  
    // Normalize weights for non-prioritized majors
    const nonPrioritizedWeightTotal = nonPrioritizedMajors.reduce(
      (sum, { weight }) => sum + weight,
      0
    );
    const normalizedNonPrioritizedMajors = nonPrioritizedMajors.map((majorObj) => ({
      ...majorObj,
      weight: majorObj.weight / nonPrioritizedWeightTotal,
    }));
  
    const nonPrioritizedTotal = fa24Total - prioritizedTotal;
    const nonPrioritizedDistribution = normalizedNonPrioritizedMajors.map(({ major, weight }) => ({
      major,
      count: Math.round(nonPrioritizedTotal * weight),
    }));
  
    // Combine prioritized and non-prioritized distributions
    const majorDistribution = [...prioritizedDistribution, ...nonPrioritizedDistribution];
  
    // Adjust distribution to match exact fa24Total
    const totalCalculated = majorDistribution.reduce((sum, { count }) => sum + count, 0);
    const adjustment = fa24Total - totalCalculated;
    if (adjustment !== 0) {
      // Apply adjustment to the first prioritized major
      majorDistribution.find(({ major }) => major === prioritizedMajors[0]).count += adjustment;
    }
  
    // Filter out majors with 0 count
    return majorDistribution.filter(({ count }) => count > 0);
  };
    