import React, { useState, useEffect, useCallback, useMemo, useRef, forwardRef} from 'react';
import NavBar from '../NavBar';
import CourseRendering from './CourseRendering';
import './PlanPage.css';
import { db } from '../firebase';
import { collection, getDoc, collectionGroup, query, where, onSnapshot, updateDoc, doc, getDocs, writeBatch } from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MilestoneModal from '../Milestones/MilestoneModal';
import { createPDFDocument } from './ExportData/exportDegreePlan';
import PDFPreview from './ExportData/PDFPreview';
import { useAuth } from '../../contexts/AuthContext';
import { FaPlus, FaMagic, FaTrash, FaMinus, FaComments } from 'react-icons/fa';
import MilestoneDrawer from '../Milestones/MilestoneDrawer';
import PlanJoyrideTour from './Archive/PlanJoyrideTour';
import ElectiveSidebar from '../Degree Plans/ElectiveSidebar';
import EvaluationModal from './EvaluationModal/EvaluationModal';
import { BsThreeDots } from "react-icons/bs";
import { FaStar, FaRegStar, FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { GrFormAdd, GrFormClose } from "react-icons/gr";
import PlanPageHeader from './PlanPageHeader';
import { CiGrid2V, CiGrid41 } from "react-icons/ci";
import { LuGoal } from 'react-icons/lu';
import { toast } from 'react-toastify';
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import TransferDataModal from './Transfer/TransferDataModal';
import TransferIntoModal from './Transfer/TransferIntoModal';
import IncompletePrereqsWarningModal from './IncompletePrereqsWarningModal';
import DroppableSemester from './DroppableSemester'
import DegreeAuditModal from './DegreeAudit/DegreeAuditModal';
import StudentChat from './StudentChat';
import CustomSidebar from '../Admin/Sidebar/Sidebar';
import useAutofillCourse from './hooks/useAutofillCourse';
import useMoveCourse from './hooks/useMoveCourse';

import { useTranslation } from 'react-i18next';

const PlanPage = () => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState({});
  const dropdownRefs = useRef({});
  const buttonRefs = useRef([]);
  const [numYears, setNumYears] = useState(null);
  const [finalizedCourses, setFinalizedCourses] = useState([]);
  const [finalizedCourseIds, setFinalizedCourseIds] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
  const [newScheduleName, setNewScheduleName] = useState('');
  const [activeTab, setActiveTab] = useState('defaultTab'); // Set a default value for activeTab
  const [primaryTab, setPrimaryTab] = useState('defaultTab');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(''); // State for managing errors
  const [dataFetchComplete, setDataFetchComplete] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [hideCompletedYears, setHideCompletedYears] = useState(false);
  const yearRefs = useRef({});  // Create a ref to hold references for each year container
  const [isProcessing, setIsProcessing] = useState(false);
  const [showCommentsChat, setShowCommentsChat] = useState(false);

  const [showModal, setShowModal] = useState(false);  // Add this line
  const [editingMilestone, setEditingMilestone] = useState(null); // New state for editing
  const [showDrawer, setShowDrawer] = useState(false);
  const [currentSemester, setCurrentSemester] = useState({ year: null, semester: null });
  const [showAllMilestonesDrawer, setShowAllMilestonesDrawer] = useState(false);
  const [editingTab, setEditingTab] = useState(null);
  const [newTabName, setNewTabName] = useState('');
  const [transferCreditsData, setTransferCreditsData] = useState([]);
  const [showIncompletePrereqsWarningModal, setShowIncompletePrereqsWarningModal] = useState(false);

  const [selectedPlaceholderCategory, setSelectedPlaceholderCategory] = useState([]);
  const [selectedPlaceholderYear, setSelectedPlaceholderYear] = useState([]);
  const [selectedPlaceholderSemester, setSelectedPlaceholderSemester] = useState([]);
  const [selectedPlaceholderRequirement, setSelectedPlaceholderRequirement] = useState([]);
  const [showPlaceholderSidebar, setShowPlaceholderSidebar] = useState(false);

  const maxCreditsPerSemester = 50;

  const { currentUser, isUserDataFetched, fetchUserData, fetchedCourseData, fetchedPrereqData, majorData, fetchedMajorRecs, fetchedMinors, fetchedMajors, pathwayGroupingsData } = useAuth();

  const generateInitialState = (numYears) => {
    const yearObject = {};

    for (let i = 1; i <= numYears; i++) {
      yearObject[`year${i}`] = {
        Fall: [],
        Spring: []
      };
    }

    return yearObject;
  };

  const lastCalledUpdateCourseProgress = useRef(0);

    const [finalizedOrganizedCourses, setFinalizedOrganizedCourses] = useState(generateInitialState(numYears));

  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [cumulativeCourseIds, setCumulativeCourseIds] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [majorCourses, setMajorCourses] = useState([]);
  const [minorCourses, setMinorCourses] = useState([]);

  const [allCoursesByYearAndSemester, setAllCoursesByYearAndSemester] = useState({});
  const [middleschoolClasses, setMiddleschoolClasses] = useState([]);
  const [prereqs, setPrereqs] = useState([]);
  const [majorPathwayData, setMajorPathwayData] = useState({});
  const [majorElectives, setMajorElectives] = useState(generateInitialState(numYears));
  const [majorRequirementCourses, setMajorRequirementCourses] = useState(generateInitialState(numYears));
  const [minorRequirementCourses, setMinorRequirementCourses] = useState(generateInitialState(numYears));
  const [finalizedMilestones, setFinalizedMilestones] = useState([]);
  const [milestonesByYearAndSemester, setMilestonesByYearAndSemester] = useState(generateInitialState(numYears));
  const [pdfBytes, setPdfBytes] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [highlightOption, setHighlightOption] = useState(null);
  const [completedSemesters, setCompletedSemesters] = useState({});
  const [userRecsData, setUserRecsData] = useState({});
  const [transformedMajorRecData, setTransformedMajorRecData] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedElectiveCategory, setSelectedElectiveCategory] = useState([]);
  const [electiveYear, setElectiveYear] = useState([]);
  const [electiveSemester, setElectiveSemester] = useState([]);
  const [isEvaluationModalOpen, setEvaluationModalOpen] = useState(false);
  const [isTransferDataModalOpen, setTransferDataModalOpen] = useState(false);
  const [isDegreeAuditModalOpen, setIsDegreeAuditModalOpen] = useState(false);
  const [allUserMajorPathways, setAllUserMajorPathways] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [viewMode, setViewMode] = useState('vertical');
  const [isAutofilling, setIsAutofilling] = useState(false);
  const [hasAutofilledAll, setHasAutofilledAll] = useState(false);
  const [areAnyPrereqsNotMet, setAreAnyPrereqsNotMet] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [selectedAdvisorId, setSelectedAdvisorId] = useState(null);
  const [unreadCounts, setUnreadCounts] = useState({});
  const { userId } = useParams();
  const effectiveUserId = useParams().studentId || currentUser?.uid;

  // Function to mark messages as read for the active chat
  const handleChatOpen = async () => {
    if (!selectedAdvisorId || !activeTab) return;

    const chatId = `${activeTab}_${effectiveUserId}_${selectedAdvisorId}`;
    const messagesRef = collection(db, 'chats', chatId, 'messages');
    const q = query(
      messagesRef,
      where('senderId', '!=', effectiveUserId),
      where('read', '==', false)
    );

    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);

    querySnapshot.forEach((docSnap) => {
      batch.update(doc(db, 'chats', chatId, 'messages', docSnap.id), { read: true });
    });

    await batch.commit();

    // The unreadCount will automatically update via the onSnapshot listener
  };

  // Listen for unread messages across all advisor chats
  useEffect(() => {
    if (!currentUser) return;

    const q = query(
      collectionGroup(db, 'messages'),
      where('senderId', '!=', effectiveUserId),
      where('read', '==', false)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newUnreadCounts = {};
      querySnapshot.forEach((docSnap) => {
        // Each message doc path looks like: chats/{chatId}/messages/{messageId}
        // Extract the chatId from the parent of the parent:
        const chatId = docSnap.ref.parent.parent.id;
        // chatId format: "activeTab_studentId_advisorId"
        const [planId] = chatId.split('_'); // Extract the plan (activeTab)

        if (!newUnreadCounts[planId]) {
          newUnreadCounts[planId] = 0;
        }
        newUnreadCounts[planId] += 1;
      });
      setUnreadCounts(newUnreadCounts);
    }, (error) => {
      console.error('Error fetching unread messages:', error);
    });

    return () => unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    if (fetchedMajorRecs) {
      const transformedMajorRecData = fetchedMajorRecs.reduce((acc, curr) => {
        const { Major, ...categories } = curr;
        acc[Major] = categories;
        return acc;
      }, {});
      setTransformedMajorRecData(transformedMajorRecData);
    }
  }, [fetchedMajorRecs]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownKeys = Object.keys(dropdownRefs.current);
      let isClickInside = false;
  
      // Check if the click is inside any of the dropdowns
      for (let key of dropdownKeys) {
        if (dropdownRefs.current[key]?.contains(event.target)) {
          isClickInside = true;
          break;
        }
      }
  
      // Check if the click is inside any of the buttons
      for (let button of buttonRefs.current) {
        if (button?.contains(event.target)) {
          isClickInside = true;
          break;
        }
      }
  
      // Check if the event target is the "Remove" button or its parent container
      if (event.target.closest('.remove-button')) {
        isClickInside = true;
      }
  
      // Check if the event target is the "Requisite" button or its parent container
      if (event.target.closest('.requisite-button')) {
        isClickInside = true;
      }
  
      // If the click was outside and the dropdown is not already closed
      if (!isClickInside) {
        if (Object.keys(dropdownOpen).length > 0) {
          setDropdownOpen({});
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);  

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowAddScheduleModal(false); // Close the modal
    }
  };

  useEffect(() => {
    if (showAddScheduleModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showAddScheduleModal]);

  useEffect(() => {
      const hasAnyUnmetPrereqs = Object.entries(finalizedOrganizedCourses).some(([year, semesters]) =>
        Object.entries(semesters).some(([semester, index1]) => {
          // Map semester names to keys
          const semesterHasAnyUnmetPrereqs = finalizedOrganizedCourses[year]?.[semester].some((course, index2) => {
            if (!course.isPrereqMet) {
            }
            return !course.isPrereqMet
          })
          return semesterHasAnyUnmetPrereqs;
        })
      );

      setAreAnyPrereqsNotMet(hasAnyUnmetPrereqs);

  }, [finalizedOrganizedCourses]);
  

  const organizeAllCoursesByYearAndSemester = useCallback((allCourses) => {
    const coursesByYearAndSemester = {};
    allCourses.forEach((course) => {
      const year = course.courseYear || 'Uncategorized';
      const semester = course.CourseSem || 'Uncategorized';
      if (!coursesByYearAndSemester[year]) {
        coursesByYearAndSemester[year] = { Fall: [], Spring: [] };
      }
      const semesterName = semester === 'S1' ? 'Fall' : (semester === 'S2' ? 'Spring' : 'Uncategorized');
      if (semesterName !== 'Uncategorized') {
        coursesByYearAndSemester[year][semesterName].push(course);
      }
    });
    return coursesByYearAndSemester;
  }, []);

  const organizeMajorCoursesByYearAndSemester = useCallback((majorCourses) => {
    const coursesByYearAndSemester = {};
    majorCourses.forEach((course) => {
      const year = course.courseYear || 'Uncategorized';
      const semester = course.CourseSem || 'Uncategorized';
      if (!coursesByYearAndSemester[year]) {
        coursesByYearAndSemester[year] = { Fall: [], Spring: [] };
      }
      const semesterName = semester === 'S1' ? 'Fall' : (semester === 'S2' ? 'Spring' : 'Uncategorized');
      if (semesterName !== 'Uncategorized') {
        coursesByYearAndSemester[year][semesterName].push(course);
      }
    });
    return coursesByYearAndSemester;
  }, []);

  const hasUncompletedCourses = useMemo(() => {
    return Object.entries(finalizedOrganizedCourses).some(([year, semesters]) =>
      Object.entries(semesters).some(([semester, courses]) => {
        // Map semester names to keys
        const semesterKey = semester === 'Fall' ? 'S1' : 'S2';
        return !completedSemesters[year]?.[semesterKey] && courses.length > 0;
      })
    );
  }, [finalizedOrganizedCourses, completedSemesters]);

  const organizeCoursesByYearAndSemester = useCallback(
    (courseIds, allCourses, prereqs, cumulativeIds) => {
  
      const coursesByYearAndSemester = {};
  
      for (const yearKey in courseIds) {
        const yearObj = courseIds[yearKey];
  
        for (const semesterKey in yearObj) {
          const semesterCourseIds = yearObj[semesterKey].courses || [];
          const semesterPlaceholders = yearObj[semesterKey].Placeholders || [];
          let semesterClasses = [];
  
          // Process Courses
          semesterCourseIds.forEach((idOrPlaceholder) => {
            if (typeof idOrPlaceholder === 'string') {
              // If it's a string (course ID), find the corresponding course in `allCourses`
              const semesterCourse = allCourses.find(
                (course) => course.courseInfo_courseNumber === idOrPlaceholder
              );
              if (semesterCourse) {
                // If the course is found, process the course data
                const courseInfo = prereqs.find(
                  (prereq) => prereq.Course === semesterCourse.courseInfo_courseNumber
                );
                if (courseInfo) {
                  semesterCourse.Prerequisites = parsePrerequisites(courseInfo.Prerequisites);
                  semesterCourse.Corequisites = parseCorequisites(courseInfo.Corequisites);
                }
                semesterCourse.isPrereqMet = checkPrerequisites(
                  semesterCourse,
                  yearKey,
                  semesterKey,
                  cumulativeIds
                );
                semesterClasses.push(semesterCourse);
              }
            }
            // If it's not a string, it's handled as a placeholder elsewhere
          });
  
          // Process Placeholders
          if (Array.isArray(semesterPlaceholders)) {
            semesterClasses.push(...semesterPlaceholders);
          }
  
          const semesterName = semesterKey === 'S1' ? 'Fall' : 'Spring';
          if (!coursesByYearAndSemester[yearKey]) {
            coursesByYearAndSemester[yearKey] = { Fall: [], Spring: [] };
          }
  
          coursesByYearAndSemester[yearKey][semesterName].push(...semesterClasses);
        }
      }
  
      return coursesByYearAndSemester;
    },
    [/* Add dependencies here if necessary */]
  );
  

  const organizeMilestonesByYearAndSemester = useCallback((milestones = []) => {
    // Ensure milestones is an array
    if (!Array.isArray(milestones)) {
      console.error('Milestones is not an array:', milestones);
      return {};
    }
  
    const milestonesByYearAndSemester = {};
    
    // Initialize milestonesByYearAndSemester with empty arrays for each year and semester
    for (let i = 1; i <= numYears; i++) {
      milestonesByYearAndSemester[`year${i}`] = {
        Fall: [],
        Spring: []
      };
    }
  
    // Iterate through milestones and organize them by year and semester
    milestones.forEach((milestone) => {
      if (milestone) {
        const year = `year${milestone.Milestone_Year}`;
        const semester = milestone.Milestone_Sem === 'S1' ? 'Fall' : 'Spring';
        if (milestonesByYearAndSemester[year] && milestonesByYearAndSemester[year][semester]) {
          milestonesByYearAndSemester[year][semester].push(milestone);
        }
      }
    });
  
    return milestonesByYearAndSemester;
  }, [numYears]);  

  const [numYears2, setNumYears2] = useState([]);
  const [fullName, setFullName] = useState([]);

  const fetchUserDataLocal = useCallback(async () => {
    console.log('Fetching user data...');
    try {
      if (currentUser) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        const userDoc = await userDocRef.get();
  
        if (userDoc.exists) {
          const userData = userDoc.data();
          console.log('User Data: ', userData);

          const fullName = userData?.firstName && userData?.lastName
          ? `${userData.firstName} ${userData.lastName}`
          : 'Student';
          setFullName(fullName);


          // Step 1: Filter pathway groupings to only the ones that are "Major"
          const allMajorPathways = pathwayGroupingsData.filter(
            major => major.pathwayType === 'Major'
          );

          // Step 2: Match user's first major from surveyAnswers
          const userMajorName = userData.surveyAnswers?.Majors?.[0]; 
          // or if you store it differently, e.g. userData.surveyAnswers.Major

          const userMajorPathwayData = allMajorPathways.find(
            major => major.pathwayName === userMajorName
          ) || {};

          // Step 3: Set state
          setMajorPathwayData(userMajorPathwayData);


          const courseIds = userData.schedules?.[activeTab] || []; // Ensure activeTab is used safely
          setFinalizedCourseIds(courseIds);
  
          const middleschoolcourseIds = userData.middleschoolClasses || [];
          setMiddleschoolClasses(middleschoolcourseIds);
  
          const backendSurveyAnswers = userData.surveyAnswers || [];
          setSurveyAnswers(backendSurveyAnswers);
  
          const backendUserRecData = userData.recommendationRatings || {};
          setUserRecsData(backendUserRecData);

          const backendTransferCredits = userData.transferData || {};
          setTransferCreditsData(backendTransferCredits);
  
          const calculateRecScore = require('../CourseRecommendation');
  
          const allCourses = fetchedCourseData;
          const prereqs = fetchedPrereqData;
  
  
          let transformedMajorRecs = {};
  
          for (let i = 0; i < fetchedMajorRecs.length; i++) {
            const curr = fetchedMajorRecs[i];
            const { Major, ...categories } = curr;
            transformedMajorRecs[Major] = categories;
          }
  
          const allCoursesWithRecScore = allCourses.map(course => {
            const recScore = calculateRecScore(transformedMajorRecs[course.Major], userData.recommendationRatings, course, userData.surveyAnswers);
      
            return {
              ...course,
              recScore
            };
          });


          // Assume surveyData['Majors'] and surveyData['Minors'] are arrays of major and minor names
          const majorNames = backendSurveyAnswers['Majors'];
          const minorNames = backendSurveyAnswers['Minors'] || []; // Handling if minors are not present

          // Objects to store data keyed by major/minor name
          const majorCoursesData = {};
          const majorElectivesData = {};
          const majorRequirementsData = {};

          const minorCoursesData = {};
          const minorRequirementsData = {};

          // Function to calculate max years for given courses
          const calculateMaxYears = (courses) => Math.max(...courses.map(course => course.courseYear));

          // Semester mapping
          const semesterMap = {
              'S1': 'Fall',
              'S2': 'Spring'
          };

          let combinedMajorPathways = {}

          // Process each major
          majorNames.forEach((majorName) => {

              const majorCoursesFromBackend = Object.values(fetchedMajors[majorName]);
              combinedMajorPathways[majorName] = majorCoursesFromBackend

              const majorCoursesMap = new Map();
              const majorRequirementsMap = {};
              const electiveCoursesMap = {};

              const maxMajorYears = calculateMaxYears(majorCoursesFromBackend);

              for (let i = 1; i <= maxMajorYears; i++) {
                  majorRequirementsMap[`year${i}`] = {
                      Fall: [],
                      Spring: []
                  };
                  electiveCoursesMap[`year${i}`] = {
                      Fall: [],
                      Spring: []
                  };
              }

              majorCoursesFromBackend.forEach(course => {
                  if (course.courseType === 'Major Course') {
                      if (course.Requirements === '') {
                          majorCoursesMap.set(course.courseInfo_courseNumber, {
                              courseYear: course.courseYear,
                              courseSemester: course.CourseSem
                          });
                      } else {
                          if (course.courseYear <= maxMajorYears) {
                              const semester = semesterMap[course.CourseSem] || course.CourseSem;
                              majorRequirementsMap[`year${course.courseYear}`][semester].push(course);
                          }
                      }
                  } else if (course.courseType === 'Elective') {
                      if (course.courseYear <= maxMajorYears) {
                          const semester = semesterMap[course.CourseSem] || course.CourseSem;
                          electiveCoursesMap[`year${course.courseYear}`][semester].push(course);
                      }
                  } else if (course.courseType === 'Requirement') {
                      if (course.courseYear <= maxMajorYears) {
                          const semester = semesterMap[course.CourseSem] || course.CourseSem;
                          majorRequirementsMap[`year${course.courseYear}`][semester].push(course);
                      }
                  }
              });

              const allCoursesWithRecScore = allCourses.map(course => {
                  const recScore = calculateRecScore(transformedMajorRecs[course.Major], userData.recommendationRatings, course, userData.surveyAnswers);
                  const majorCourseNumbers = majorCoursesFromBackend.map(majorCourse => majorCourse.courseInfo_courseNumber);
                  const courseType = majorCourseNumbers.includes(course.courseInfo_courseNumber) ? "Major Course" : "Elective";

                  return {
                      ...course,
                      recScore,
                      courseType
                  };
              });

              const majorCoursesInfo = allCoursesWithRecScore
                  .filter(course => majorCoursesMap.has(course.courseInfo_courseNumber))
                  .map(course => {
                      const { courseYear, courseSemester } = majorCoursesMap.get(course.courseInfo_courseNumber);
                      return {
                          ...course,
                          courseYear,
                          CourseSem: courseSemester
                      };
                  });

              majorCoursesData[majorName] = majorCoursesInfo;
              majorElectivesData[majorName] = electiveCoursesMap;
              majorRequirementsData[majorName] = majorRequirementsMap;
          });

          setAllUserMajorPathways(combinedMajorPathways)

          // Process each minor
          minorNames.forEach((minorName) => {
              const minorCoursesFromBackend = Object.values(fetchedMinors[minorName]);

              const minorCoursesMap = {};
              const minorRequirementsMap = {};

              const maxMinorYears = calculateMaxYears(minorCoursesFromBackend);

              for (let i = 1; i <= maxMinorYears; i++) {
                  minorCoursesMap[`year${i}`] = {
                      Fall: [],
                      Spring: []
                  };
                  minorRequirementsMap[`year${i}`] = {
                      Fall: [],
                      Spring: []
                  };
              }

              minorCoursesFromBackend.forEach(course => {
                  if (course.Requirements === '') {
                      if (course.courseYear <= maxMinorYears) {
                          const semester = semesterMap[course.CourseSem] || course.CourseSem;
                          minorCoursesMap[`year${course.courseYear}`][semester].push(course);
                      }
                  } else {
                      if (course.courseYear <= maxMinorYears) {
                          const semester = semesterMap[course.CourseSem] || course.CourseSem;
                          minorRequirementsMap[`year${course.courseYear}`][semester].push(course);
                      }
                  }
              });

              minorCoursesData[minorName] = minorCoursesMap;
              minorRequirementsData[minorName] = minorRequirementsMap;
          });

          // Set the state with the new structured data
          setMajorCourses(majorCoursesData);
          setMajorElectives(majorElectivesData);
          setMajorRequirementCourses(majorRequirementsData);

          setMinorCourses(minorCoursesData);
          setMinorRequirementCourses(minorRequirementsData);

          setAllCourses(allCoursesWithRecScore);

          setNumYears2(userData?.surveyAnswers?.SchoolEnd?.year - userData?.surveyAnswers?.SchoolStart?.year);
          setNumYears(userData?.schedules?.[activeTab]?.numYears);
  
          const cumulativeIds = calculateCumulativeIds(courseIds);
          setCumulativeCourseIds(cumulativeIds);
  
          const organizedCourses = organizeCoursesByYearAndSemester(courseIds, allCoursesWithRecScore, prereqs, cumulativeIds);
          setFinalizedOrganizedCourses(organizedCourses);
  
          const allOrganizedCourses = organizeAllCoursesByYearAndSemester(allCoursesWithRecScore);
          setAllCoursesByYearAndSemester(allOrganizedCourses);
  
          const milestonesData = userData.finalizedMilestones?.[activeTab] || {};
          const finalizedMilestonesArray = Object.values(milestonesData).reduce((acc, yearData) => {
            Object.values(yearData).forEach(semData => {
              acc = acc.concat(semData.milestones);
            });
            return acc;
          }, []);
  
          setFinalizedMilestones(finalizedMilestonesArray);
  
          const organizedMilestones = organizeMilestonesByYearAndSemester(finalizedMilestonesArray);
          setMilestonesByYearAndSemester(organizedMilestones);
  
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [fetchedCourseData, fetchedPrereqData, fetchedMajors, majorData, fetchedMajorRecs, fetchedMinors, currentUser, activeTab, tabs]);
  
  useEffect(() => {
    if (activeTab) {
      fetchUserDataLocal();
    }
  }, [fetchUserDataLocal, activeTab]);
  
  useEffect(() => {
    fetchUserDataLocal();
  }, [fetchUserDataLocal]);

  useEffect(() => {
    const fetchScheduleData = async () => {
      try {
        if (currentUser) {
          const userDocRef = db.collection('users').doc(effectiveUserId);
          const userDoc = await userDocRef.get();
  
          if (userDoc.exists) {
            const userData = userDoc.data();
            const scheduleArray = Object.keys(userData.schedules || {}).map((tabName) => ({
              name: tabName,
              data: userData.schedules[tabName],
            })).sort((a, b) => a.name.localeCompare(b.name)); // Optional: Sort alphabetically or by some other criteria
  
            setTabs(scheduleArray);
            const initialTab = userData.activeTab || scheduleArray[0].name;
            setActiveTab(initialTab);

            const primaryTabFromBackend = userData?.primaryTab || scheduleArray[0].name;
            setPrimaryTab(primaryTabFromBackend)

            if (!userData?.primaryTab){
              await userDocRef.update({
                primaryTab: primaryTabFromBackend
              });
            }

            // Ensure the courses for the initial active tab are loaded
            const courseIds = userData.schedules[initialTab] || [];
            setFinalizedCourseIds(courseIds);
  
            const cumulativeIds = calculateCumulativeIds(courseIds);
            setCumulativeCourseIds(cumulativeIds);
  
            const organizedCourses = organizeCoursesByYearAndSemester(courseIds, allCourses, prereqs, cumulativeIds);
            setFinalizedOrganizedCourses(organizedCourses);
  
          } else {
            setShowAddScheduleModal(true);
          }
        }
      } catch (error) {
        console.error('Error fetching schedule data', error);
      }
    };
  
    fetchScheduleData();
  }, [currentUser, effectiveUserId]); // Ensure this runs when the user, allCourses, or prereqs changes
  

  useEffect(() => {
    if (currentUser) {
      const fetchCompletedSemesters = async () => {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        const userDoc = await userDocRef.get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          setCompletedSemesters(userData.completedSemesters || {});
        }
      };
      fetchCompletedSemesters();
    }
  }, [currentUser]);

  const handleToggleSemesterComplete = async (year, semester) => {
    const semesterKey = semester === 'Fall' ? 'S1' : 'S2';
    const newStatus = !completedSemesters[`year${year}`]?.[semesterKey];
    const updatedCompletedSemesters = {
        ...completedSemesters,
        [`year${year}`]: {
            ...completedSemesters[`year${year}`],
            [semesterKey]: newStatus,
        },
    };

    // If the semester is marked incomplete, mark all subsequent semesters as incomplete
    if (!newStatus) {
      for (let i = year; i <= numYears; i++) {
          const yearKey = `year${i}`;
          updatedCompletedSemesters[yearKey] = {
              S1: i === year && semesterKey === 'S2' ? true : false,
              S2: false,
          };
      }
    }


    setCompletedSemesters(updatedCompletedSemesters);

    if (currentUser) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        try {
            const userDoc = await userDocRef.get();

            if (userDoc.exists) {
                const userData = userDoc.data();
                const allPlans = Object.keys(userData.schedules || {});
                const activeTab = userData.activeTab || allPlans[0];

                // Fetch courses from the active tab for the completed semester
                const coursesToCopy = userData.schedules[activeTab][`year${year}`]?.[semesterKey]?.courses || [];

                // Prepare batch updates
                const batch = db.batch();

                // Include the update to completedSemesters in the batch
                batch.update(userDocRef, { completedSemesters: updatedCompletedSemesters });

                // Overwrite the courses in the completed semester in each plan
                allPlans.forEach((plan) => {
                    const updatedSchedule = {
                        ...userData.schedules[plan],
                        [`year${year}`]: {
                            ...userData.schedules[plan][`year${year}`],
                            [semesterKey]: {
                                ...userData.schedules[plan][`year${year}`]?.[semesterKey],
                                courses: coursesToCopy,
                            },
                        },
                    };

                    batch.update(userDocRef, {
                        [`schedules.${plan}`]: updatedSchedule,
                    });
                });

                // Commit all updates
                await batch.commit();

                // Optionally, show a success message or update local state
                console.log('Semester status updated and courses synchronized across all plans.');
            }
        } catch (error) {
            console.error('Error updating completed semesters:', error);
        }
    }
};

const shouldShowMarkCompleteButton = (year, semester) => {
  if (semester === 'Fall') {
      const prevYear = `year${year - 1}`;
      const prevSemester = 'Spring';
      return year === 1 || completedSemesters[prevYear]?.['S2']; // Check Spring of the previous year
  } else if (semester === 'Spring') {
      const prevYear = `year${year}`;
      const prevSemester = 'Fall';
      return completedSemesters[prevYear]?.['S1']; // Check Fall of the same year
  }
  return true;
};

const handlePlaceholderSelection = (category, yr, sem, req) => {
  setSelectedPlaceholderCategory(category);
  setSelectedPlaceholderYear(yr);
  setSelectedPlaceholderSemester(sem);
  setSelectedPlaceholderRequirement(req);
  setShowPlaceholderSidebar(true);
}

const shouldShowRemoveYearButton = (year) => {
  const yearKey = `year${year}`;
  const completed = completedSemesters[yearKey];
  // Check if either Fall (S1) or Spring (S2) semesters are completed
  return !(completed?.S1 || completed?.S2);
};

const calculateCumulativeIds = (courseIds) => {
  // let cumulativeIds = { year0: Array.isArray(transferCreditsData) ? transferCreditsData : [] };
  let cumulativeIds = {};
  let prevYearsCourses = [];

  for (let i = 1; i <= courseIds.numYears; i++) {
      let year = `year${i}`;
      let yearCourses = {};
      let cumulativeCourses = prevYearsCourses;

      for (let j = 1; j <= 2; j++) {
          let semester = `S${j}`;
          if (courseIds && courseIds[year] && courseIds[year][semester]) {
              cumulativeCourses = cumulativeCourses.concat(courseIds[year][semester].courses);
          }
          cumulativeCourses = Array.from(new Set(cumulativeCourses));
          yearCourses[semester] = [...cumulativeCourses];
      }

      prevYearsCourses = prevYearsCourses.concat(cumulativeCourses);
      prevYearsCourses = Array.from(new Set(prevYearsCourses));
      cumulativeIds[year] = yearCourses;
  }

  return cumulativeIds;
};

  const parsePrerequisites = (prerequisites) => {
    let arrayOfPrerequisites = prerequisites.split("&");
    arrayOfPrerequisites = arrayOfPrerequisites.map(element => element.replace(/\(|\)/g, ''));
    return arrayOfPrerequisites.map(element => element.split("||"));
  };

  const parseCorequisites = (corequisites) => {
    let arrayOfCorequisites = corequisites.split("&");
    arrayOfCorequisites = arrayOfCorequisites.map(element => element.replace(/\(|\)/g, ''));
    return arrayOfCorequisites.map(element => element.split("||"));
  };

  const checkPrerequisites = (course, yearKey, semesterKey, cumulativeIds) => {
    let isPrereqMet = true;
    let isCoreqMet = true;

    if (course.Prerequisites && course.Prerequisites[0] && course.Prerequisites[0][0]) {
      isPrereqMet = course.Prerequisites.every(prereqGroup =>
        prereqGroup.some(prereq => {
          if (yearKey === 'year1' && (semesterKey === 'S1' || semesterKey === 'Fall')) {
            if (cumulativeIds['year0']) {
              return cumulativeIds['year0'].includes(prereq);
            }
            else {
              return false
            }
          } else if ((semesterKey === 'S1' || semesterKey === 'Fall')) {
            const lastYear = parseInt(yearKey.charAt(4)) - 1;
            return cumulativeIds[`year${lastYear}`]['S2'].includes(prereq);
          } else {
            return cumulativeIds[yearKey]['S1'].includes(prereq);
          }
        })
      );
    }

    if (course.Corequisites && course.Corequisites[0] && course.Corequisites[0][0]) {
      let semesterCode = semesterKey === "Fall" ? "S1" : "S2";
      isCoreqMet = course.Corequisites.some(coreqGroup => {
        return coreqGroup.some(coreq => {
          return cumulativeIds[yearKey][semesterCode].includes(coreq);
        });
      });
    }

    const isTotalMet = isPrereqMet && isCoreqMet;

    return isTotalMet;
  };

  const recheckPrerequisites = useCallback((updatedOrganizedCourses) => {
    let cumulativeIds = { year0: [] };
    let prevYearsCourses = [];

    for (let i = 1; i <= numYears; i++) {
      let year = `year${i}`;
      let yearCourses = {};
      let cumulativeCourses = prevYearsCourses;

      for (let j = 1; j <= 2; j++) {
        let semester = `S${j}`;
        let semesterCode = semester === "S1" ? "Fall" : "Spring";

        if (updatedOrganizedCourses && updatedOrganizedCourses[year] && updatedOrganizedCourses[year][semesterCode]) {
          cumulativeCourses = cumulativeCourses.concat(updatedOrganizedCourses[year][semesterCode].map(course => course.courseInfo_courseNumber));
        }
        cumulativeCourses = Array.from(new Set(cumulativeCourses));
        yearCourses[semester] = [...cumulativeCourses];
      }

      prevYearsCourses = prevYearsCourses.concat(cumulativeCourses);
      prevYearsCourses = Array.from(new Set(prevYearsCourses));
      cumulativeIds[year] = yearCourses;
    }

    const updateObject = { ...updatedOrganizedCourses };

    for (const yearKey in updateObject) {
      ['Fall', 'Spring'].forEach(semesterKey => {
        let semesterCode = semesterKey === "Fall" ? "S1" : "S2";
        const semesterClassesObject = updateObject[yearKey][semesterKey] || [];

        updateObject[yearKey][semesterKey] = semesterClassesObject.map(course => {
          const courseInfo = fetchedPrereqData.find(prereq => prereq.Course === course.courseInfo_courseNumber);

          if (courseInfo) {
            course.Prerequisites = parsePrerequisites(courseInfo.Prerequisites);
            course.Corequisites = parseCorequisites(courseInfo.Corequisites);
          }

          course.isPrereqMet = checkPrerequisites(course, yearKey, semesterKey, cumulativeIds);

          return { ...course };
        });
      });
    }

    setFinalizedOrganizedCourses(updateObject);
  }, [allCourses, prereqs]);

  const handleUpdateCourseProgress= async () => {
    try {
        if (currentUser && effectiveUserId) {
          const userDocRef = db.collection('users').doc(effectiveUserId);
          const userDoc = await userDocRef.get();

            if (userDoc.exists) {
                const userData = userDoc.data();
                const primaryTab = userData?.primaryTab;
                const finalizedSchedule = userData?.schedules[primaryTab];
                const userCompletedSemesters = userData.completedSemesters || {};

                let courseArray = [];
                for (let i = 1; i <= 4; i++) {
                    let year = `year${i}`;
                    for (let j = 1; j <= 2; j++) {
                        let semester = `S${j}`;
                        if (finalizedSchedule && finalizedSchedule[year] && finalizedSchedule[year][semester]) {
                            courseArray.push(...finalizedSchedule[year][semester]['courses']);
                        }
                    }
                }

                const studentCourses = fetchedCourseData ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber)) : [];
                const major = userData.surveyAnswers?.Majors[0] || 'N/A';

                const majorCourses = Object.values(fetchedMajors[major]);

                const totalCourses = majorCourses ? majorCourses.filter(majorCourse => majorCourse.courseType !== 'Milestone').length : 0;

                const takenCoursesSet = new Set();
                Object.keys(userCompletedSemesters).forEach(year => {
                    Object.keys(userCompletedSemesters[year]).forEach(semesterKey => {
                        if (userCompletedSemesters[year][semesterKey]) {
                            const completedCourses = finalizedSchedule[year][semesterKey]?.courses || [];
                            completedCourses.forEach(course => {
                                takenCoursesSet.add(course);
                            });
                        }
                    });
                });
                const takenCoursesArray = Array.from(takenCoursesSet);

                const takenCourses = fetchedCourseData.filter(course => takenCoursesArray.includes(course.courseInfo_courseNumber));

                function findRequirementCourses(majorCourses, courses, nonUsableCourses) {
                  // Create a set for faster lookup of non-usable courses
                  const nonUsableSet = new Set(nonUsableCourses);
              
                  // Filter out the requirements from majorCourses
                  const requirements = majorCourses.filter(majorCourse =>
                      (majorCourse.courseType === 'Major Course' && majorCourse.Requirements) ||
                      majorCourse.courseType === 'Requirement'
                  );
              
                  // Map student's courses for quick access and exclude non-usable courses
                  const studentCoursesMap = new Map();
                  courses.forEach((course, index) => {
                      if (!nonUsableSet.has(course.courseInfo_courseNumber)) {
                          studentCoursesMap.set(course.courseInfo_courseNumber, { ...course, index });
                      }
                  });
              
                  // Build the possible matches between requirements and student's courses
                  const reqIndexToPossibleCourses = [];
                  requirements.forEach((requirement, reqIndex) => {
                      const possibleCourses = requirement.Possibilities.split(',').map(course => course.trim());
                      reqIndexToPossibleCourses[reqIndex] = [];
                      possibleCourses.forEach(courseNumber => {
                          if (studentCoursesMap.has(courseNumber)) {
                              reqIndexToPossibleCourses[reqIndex].push(studentCoursesMap.get(courseNumber).index);
                          }
                      });
                  });
              
                  // Initialize match arrays
                  const matchToCourse = new Array(requirements.length).fill(-1); // requirement index -> course index
                  const matchToRequirement = new Array(courses.length).fill(-1); // course index -> requirement index
              
                  // Helper function for DFS in bipartite matching
                  function bpm(u, seen) {
                      for (const v of reqIndexToPossibleCourses[u]) {
                          if (!seen[v]) {
                              seen[v] = true;
                              if (matchToRequirement[v] === -1 || bpm(matchToRequirement[v], seen)) {
                                  matchToRequirement[v] = u;
                                  matchToCourse[u] = v;
                                  return true;
                              }
                          }
                      }
                      return false;
                  }
              
                  // Perform the matching
                  for (let u = 0; u < requirements.length; u++) {
                      const seen = new Array(courses.length).fill(false);
                      bpm(u, seen);
                  }
              
                  // Extract the matched courses
                  const largestValidArray = [];
                  for (let u = 0; u < matchToCourse.length; u++) {
                      if (matchToCourse[u] !== -1) {
                          largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
                      }
                  }
              
                  return largestValidArray;
                }                           

                function findElectiveCourses(majorCourses, courses) {
                  // Filter out the elective requirements from majorCourses
                  const electiveRequirements = majorCourses.filter(
                      majorCourse => majorCourse.courseType === 'Elective'
                  );
              
                  // Build the possible matches between electives and student's courses
                  const electiveIndexToPossibleCourses = [];
                  electiveRequirements.forEach((electiveReq, electiveIndex) => {
                      const electiveName = electiveReq.Possibilities;
                      electiveIndexToPossibleCourses[electiveIndex] = [];
              
                      // If the elective name is "Free-NonMajor-Elective", allow any course to fulfill it
                      if (electiveName === "Free-NonMajor-Elective") {
                          for (let i = 0; i < courses.length; i++) {
                              electiveIndexToPossibleCourses[electiveIndex].push(i);
                          }
                      } else {
                          // Otherwise, only include courses that match specific elective fulfillment criteria
                          courses.forEach((studentCourse, courseIndex) => {
                              if (
                                  studentCourse.Elective_fulfillment &&
                                  studentCourse.Elective_fulfillment.includes(electiveName)
                              ) {
                                  electiveIndexToPossibleCourses[electiveIndex].push(courseIndex);
                              }
                          });
                      }
                  });
              
                  // Initialize match arrays
                  const matchToCourse = new Array(electiveRequirements.length).fill(-1); // elective index -> course index
                  const matchToElective = new Array(courses.length).fill(-1); // course index -> elective index
              
                  // Helper function for DFS in bipartite matching
                  function bpm(u, seen) {
                      for (const v of electiveIndexToPossibleCourses[u]) {
                          if (!seen[v]) {
                              seen[v] = true;
                              if (matchToElective[v] === -1 || bpm(matchToElective[v], seen)) {
                                  matchToElective[v] = u;
                                  matchToCourse[u] = v;
                                  return true;
                              }
                          }
                      }
                      return false;
                  }
              
                  // Perform the matching
                  for (let u = 0; u < electiveRequirements.length; u++) {
                      const seen = new Array(courses.length).fill(false);
                      bpm(u, seen);
                  }
              
                  // Extract the matched courses
                  const largestValidArray = [];
                  for (let u = 0; u < matchToCourse.length; u++) {
                      if (matchToCourse[u] !== -1) {
                          largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
                      }
                  }
              
                  return largestValidArray;
                }        
                
                // Calculate taken courses

                const takenMajorCoursesArray = takenCourses.filter(course => {
                    const isInMajorCourses = majorCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
                    return isInMajorCourses;
                });

                const takenElectiveCoursesArray = findElectiveCourses(
                    majorCourses.filter(course => course.courseType === 'Elective'),
                    takenCourses
                );
                
                const takenRequirementCoursesArray = findRequirementCourses(
                    majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || (course.courseType === 'Requirement')),
                    takenCourses,
                    takenElectiveCoursesArray
                );      


                // Calculate all course properties, then subtract fo planned

                const allMajorCoursesArray = studentCourses.filter(course => {
                    const isInMajorCourses = majorCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
                    return isInMajorCourses;
                });

                const allElectiveCoursesArray = findElectiveCourses(
                    majorCourses.filter(course => course.courseType === 'Elective'),
                    studentCourses
                );
                
                const allRequirementCoursesArray = findRequirementCourses(
                    majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || (course.courseType === 'Requirement')),
                    studentCourses,
                    allElectiveCoursesArray
                );      

                const takenCoursesLengths = takenMajorCoursesArray.length + takenElectiveCoursesArray.length + takenRequirementCoursesArray.length

                const allCoursesLengths = allMajorCoursesArray.length + allElectiveCoursesArray.length + allRequirementCoursesArray.length - takenCoursesLengths

                const courseProgressObj = {taken: takenCoursesLengths, planned: allCoursesLengths, total: totalCourses}
                  
                await userDocRef.update({
                  courseProgress: courseProgressObj
                });

                console.log("Course Progress Updated Successfully");
            }
        }
    } catch (error) {
        console.error('Error updating course progress:', error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (activeTab === primaryTab) {
      const now = Date.now();
      const FIVE_SECONDS = 5000;
      
      if (now - lastCalledUpdateCourseProgress.current >= FIVE_SECONDS) {
        handleUpdateCourseProgress();
        lastCalledUpdateCourseProgress.current = now; // Update the last called time
      }
    }
  }, [primaryTab, activeTab, finalizedOrganizedCourses, completedSemesters]);


  useEffect(() => {
    const checkShownPrereqsWarningStatus = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', effectiveUserId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          let hasSeenPrereqsWarning = userDoc.data().hasSeenPrereqsWarning || false;
          if (!hasSeenPrereqsWarning) {
            setShowIncompletePrereqsWarningModal(true);
          }
        }
      }
    };

    checkShownPrereqsWarningStatus();
  }, [currentUser]);

  const handleRemove = useCallback(async (classToRemove, year, sem) => {
    console.log('classToRemove: ', classToRemove);
    const removedCourseNumber = classToRemove.courseInfo_courseNumber;
  
    try {
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        const semesterCode = sem === "Fall" ? "S1" : "S2";
  
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const previousFinalizedSchedule = userData.schedules[activeTab] || {};
          const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};
  
          // Initialize year and semester if they don't exist
          if (!updateObj[`year${year}`]) {
            updateObj[`year${year}`] = { S1: { courses: [], Placeholders: [] }, S2: { courses: [], Placeholders: [] } };
          }
          if (!updateObj[`year${year}`][semesterCode]) {
            updateObj[`year${year}`][semesterCode] = { courses: [], Placeholders: [] };
          }
  
          if (removedCourseNumber === "Placeholder") {
            // Remove from placeholders
            const existingPlaceholders = updateObj[`year${year}`][semesterCode].Placeholders || [];
            const newPlaceholders = existingPlaceholders.filter(placeholder => placeholder.PlaceholderID !== classToRemove.PlaceholderID);
            
            updateObj[`year${year}`][semesterCode].Placeholders = newPlaceholders;
            console.log('Placeholders: ', existingPlaceholders, newPlaceholders, updateObj);
            console.log(`Removed placeholder with pid: ${classToRemove.PlaceholderID} from year${year} ${sem}`);
          } else {
            // Remove from courses
            const existingCourses = updateObj[`year${year}`][semesterCode].courses || [];
            const newCourses = existingCourses.filter(courseId => courseId !== removedCourseNumber);
            updateObj[`year${year}`][semesterCode].courses = newCourses;
            console.log(`Removed course: ${removedCourseNumber} from year${year} ${sem}`);
          }
  
          // Update the transaction
          transaction.update(userRef, {
            [`schedules.${activeTab}`]: updateObj
          }, { lastUpdateTime: userDoc.updateTime });

          setFinalizedOrganizedCourses(prev => {
            const newCourses = { ...prev };
            newCourses[`year${year}`][sem] = newCourses[`year${year}`][sem].filter(course => course.courseInfo_courseNumber !== removedCourseNumber);
  
            recheckPrerequisites(newCourses);
  
            return newCourses;
          });
  
        }).catch(error => {
          console.error('Transaction failed:', error);
          throw error;
        });
  
      } else {
        console.error('No authenticated user found.');
      }
    } catch (error) {
      console.error('Error removing class:', error);
      // Optionally, handle the error (e.g., show a notification to the user)
    }
  }, [currentUser, activeTab, recheckPrerequisites]);

  const updateCourseOrderInFirestore = async (year, semester, updatedCourseNumbers) => {
    try {
      const userId = currentUser?.uid;
      const userRef = db.collection('users').doc(userId);
  
      const semesterCode = semester === 'Fall' ? 'S1' : 'S2';
  
      await userRef.update({
        [`schedules.${activeTab}.year${year}.${semesterCode}.courses`]: updatedCourseNumbers,
      });
  
      console.log('Course order updated in Firestore');
    } catch (error) {
      console.error('Error updating course order in Firestore:', error);
    }
  };

  const { handleAutofillCourse } = useAutofillCourse({
    currentUser,
    effectiveUserId,
    activeTab,
    recheckPrerequisites,
    setFinalizedOrganizedCourses,
    allCourses,
    majorCourses,
    organizeMajorCoursesByYearAndSemester,
    majorElectives,
    majorRequirementCourses,
    minorCourses,
    minorRequirementCourses,
    finalizedOrganizedCourses,
    maxCreditsPerSemester,
  });

  const handleAutofillAllCourses = useCallback(async () => {
    // Show loading toast
    const toastId = toast.loading("Autofilling all courses...", {position: 'top-center'});
  
    setIsAutofilling(true);
    setHasAutofilledAll(true);
  
    try {
      const years = activePlanNumYears >= 4 ? [1, 2, 3, 4] : Array.from({ length: numYears }, (_, index) => index + 1);
      const semesters = ["Fall", "Spring"];
  
      function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      
      for (const yearNumber of years) {
        for (const semester of semesters) {
          let semesterCode = semester === "Fall" ? "S1" : "S2";
          const isCompleted = (completedSemesters) && 
                              (completedSemesters[`year${yearNumber}`]) && 
                              (completedSemesters[`year${yearNumber}`][semesterCode]) && 
                              (completedSemesters[`year${yearNumber}`][semesterCode] == true);
          
          if (!isCompleted) {
            await handleAutofillCourse(yearNumber, semester);
            await delay(150); // Add a 0.2-second delay
          }
        }
      }
  
      // Show success toast after autofill is completed
      toast.update(toastId, {
        render: "All courses autofilled successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        position: 'top-center',
      });
  
    } catch (error) {
      // Show error toast if there's a failure
      console.error('Error autofilling all courses:', error);
      toast.update(toastId, {
        render: "Error autofilling all courses!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
        position: 'top-center',
      });
    } finally {
      handleUpdateCourseProgress();
      setIsAutofilling(false);
    }
  }, [handleAutofillCourse, numYears, completedSemesters]);


  const organizePrereqCourses = useCallback(() => {
    const prereqCourses = {};
    for (let i = 1; i <= numYears; i++) {
      if (!prereqCourses[i]) {
        prereqCourses[i] = [];
      }
      prereqCourses[i].push(...middleschoolClasses);
      finalizedCourses.forEach((course) => {
        const year = course.courseYear || 'Uncategorized';
        if (year < i) {
          prereqCourses[i].push(course.courseInfo_courseNumber);
        }
      });
    }
    return prereqCourses;
  }, [middleschoolClasses, finalizedCourses, numYears]);

  const prereqClasses = organizePrereqCourses();

  const getTotalCreditsForSemester = useCallback((courses) => {
    if (!courses) {
      return 0;
    }
    return courses.reduce((total, course) => {
      const credits = course.Credits === "N/A" ? 0 : course.Credits;
      return total + credits;
    }, 0);
  }, []);



  const getTotalCreditsForSchedule = useCallback((allOrganizedCourses) => {

    let studentCourses = [];
    let totalCreditsCount = 0;
    let upperDivisionCreditsCount = 0;

    for (const year in allOrganizedCourses) {
      if (allOrganizedCourses.hasOwnProperty(year)) {
        const semesters = allOrganizedCourses[year];
        for (const semester in semesters) {
          if (semesters.hasOwnProperty(semester)) {
            const courses = semesters[semester];
            studentCourses = studentCourses.concat(courses);
            const semesterCreditsCount = courses.reduce((total, course) => {
              return total + (course.Credits === 'N/A' || course.Credits === undefined ? 0 : Number(course.Credits));
            }, 0);          
            totalCreditsCount += semesterCreditsCount;
            const upperDivisionSemesterCreditsCount = courses
              .filter(course => {
                // Match the first sequence of digits in the course number
                const match = course.courseInfo_courseNumber.match(/(\d+)/);
                const courseNumber = match ? parseInt(match[1], 10) : 100; 
                // Decide if it's upper division (e.g., 300+)
                return courseNumber >= 300;
              })
              .reduce((total, course) => {
                return total + (course.Credits === 'N/A' || course.Credits === undefined ? 0 : Number(course.Credits));
              }, 0);
            upperDivisionCreditsCount += upperDivisionSemesterCreditsCount;
          }
        }
      }
    }
  
    return {totalCredits: totalCreditsCount, majorRequiredCredits: majorPathwayData.OverallCR, upperDivisionCredits: upperDivisionCreditsCount, majorRequiredUpperDivisionCredits: majorPathwayData.UDCR};

  }, [currentUser, activeTab]);


  const CreditsOverview = ({ finalizedOrganizedCourses, onOpen }) => {
    const { t } = useTranslation();

    const [finalizedOrganizedCoursesCreditsOverview, setFinalizedOrganizedCoursesCreditsOverview] = useState(finalizedOrganizedCourses);

    useEffect(() => {
      setFinalizedOrganizedCoursesCreditsOverview(finalizedOrganizedCourses);
    }, [finalizedOrganizedCourses]);

    let {
      totalCredits,
      majorRequiredCredits,
      upperDivisionCredits,
      majorRequiredUpperDivisionCredits
    } = getTotalCreditsForSchedule(finalizedOrganizedCoursesCreditsOverview);
    
    return (
      <div className="p-2 max-w-full mx-auto bg-whites rounded-lg">
        {/*<h2 className="text-lg font-semibold mb-4">Credit Overview</h2>*/}
        <div className="flex flex-wrap justify-between gap-2">
          <p className="text-sm">
            <button
              onClick={() => onOpen(true)}
              className={`bg-opacity-50 text-sm font-semibold rounded-lg px-2 py-1 mr-2 flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                totalCredits >= majorRequiredCredits
                  ? 'bg-green-100 text-green-700'
                  : totalCredits / majorRequiredCredits > 0.4
                  ? 'bg-yellow-100 text-yellow-700'
                  : 'bg-red-100 text-red-700'
              }`}
            >
              {t('planPage.totalCredits', 'Total Credits')}: {totalCredits}/{majorRequiredCredits}
            </button>
          </p>

          {/*<p className="text-sm">
            <span className="font-medium">Counting Towards Major:</span> {creditsTowardsMajor}
          </p>*/}

          {majorRequiredUpperDivisionCredits !== 'N/A' && (
            <p className="text-sm">
              <span
                className={`bg-opacity-50 text-sm font-semibold rounded-lg px-2 py-1 mr-2 flex items-center ${
                  upperDivisionCredits >= majorRequiredUpperDivisionCredits
                    ? 'bg-green-100 text-green-700'
                    : upperDivisionCredits / majorRequiredUpperDivisionCredits > 0.4
                    ? 'bg-yellow-100 text-yellow-700'
                    : 'bg-red-100 text-red-700'
                }`}
              >
                {t('planPage.upperDivisionCredits', 'Upper Division Credits')}: {upperDivisionCredits}/{majorRequiredUpperDivisionCredits}
              </span>
            </p>
          )}

          {hasUncompletedCourses && (
            <p className="text-sm">
              <span
                className={`bg-opacity-50 text-sm font-semibold rounded-lg px-2 py-1 mr-2 flex items-center ${
                  areAnyPrereqsNotMet ? 'bg-orange-100 text-orange-700' : 'bg-green-100 text-green-700'
                }`}
              >
                {areAnyPrereqsNotMet
                  ? t('planPage.warningRequisitesUnmet', 'Warning: Requisites Unmet')
                  : t('planPage.allRequisitesMet', 'All Requisites Met')}
              </span>
            </p>
          )}
        </div>
      </div>
    );
  };

  const { moveCourse } = useMoveCourse({
    currentUser,
    effectiveUserId,
    finalizedOrganizedCourses,
    setFinalizedOrganizedCourses,
    recheckPrerequisites,
    activeTab,
  });

  const handleAddMilestone = (year, semester) => {
    console.log('Adding milestone for:', { year, semester });
    setCurrentSemester({ year, semester });
    setShowDrawer(true);
  };

  // New handler to edit a milestone
  const handleEditMilestone = (milestone) => {
    console.log('Editing milestone:', milestone);
    setEditingMilestone(milestone);
    setCurrentSemester({
      year: milestone.Milestone_Year,
      semester: milestone.Milestone_Sem === 'S1' ? 'Fall' : 'Spring',
    });
    setShowModal(true);
  };
  
  const handleOpenAllMilestonesDrawer = () => {
    setShowAllMilestonesDrawer(true);
  };  

  const handleSaveMilestone = async (title, description, dueDate, milestoneID = null) => {
    console.log('Saving milestone with currentSemester:', currentSemester);
    if (
      !title ||
      !description ||
      !dueDate ||
      !currentSemester ||
      !currentSemester.year ||
      !currentSemester.semester
    ) {
      console.error('Invalid currentSemester:', currentSemester);
      return;
    }

    // Construct newMilestone object
    const newMilestone = {
      Milestone_ID: milestoneID || `milestone_${Date.now()}`,
      Title: title,
      Description: description,
      DueDate: dueDate,
      Milestone_Year: currentSemester.year,
      Milestone_Sem: currentSemester.semester === 'Fall' ? 'S1' : 'S2',
      // Only set createdAt and completed if adding a new milestone
      ...(milestoneID
        ? {}
        : { createdAt: new Date().toISOString(), completed: false }),
    };    

    try {
      if (currentUser && effectiveUserId) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || 'default'; // Use activeTab to store under the correct plan/tab
            const year = `year${currentSemester.year}`;
            const semester = currentSemester.semester === 'Fall' ? 'S1' : 'S2';

            let updatedMilestones =
              userData.finalizedMilestones?.[activeTab]?.[year]?.[semester]?.milestones || [];

            if (milestoneID) {
              // Editing existing milestone
              const index = updatedMilestones.findIndex((m) => m.Milestone_ID === milestoneID);
              if (index !== -1) {
                updatedMilestones[index] = {
                  ...updatedMilestones[index],
                  ...newMilestone,
                };
              }
            } else {
              // Adding new milestone
              updatedMilestones = [...updatedMilestones, newMilestone];
            }

            transaction.set(
              userDocRef,
              {
                finalizedMilestones: {
                  ...userData.finalizedMilestones,
                  [activeTab]: {
                    ...userData.finalizedMilestones?.[activeTab],
                    [year]: {
                      ...userData.finalizedMilestones?.[activeTab]?.[year],
                      [semester]: { milestones: updatedMilestones },
                    },
                  },
                },
              },
              { merge: true }
            );
          }
        });

        if (milestoneID) {
          // Update the state with the edited milestone
          setFinalizedMilestones((prev) =>
            prev.map((m) => (m.Milestone_ID === milestoneID ? { ...m, ...newMilestone } : m))
          );
        } else {
          // Update the state with the new milestone
          setFinalizedMilestones((prev) => [...prev, newMilestone]);
        }

        setShowModal(false);
        setEditingMilestone(null); // Reset editing state
      }
    } catch (error) {
      console.error('Error saving milestone:', error);
    }
  };
  const handleRemoveMilestone = async (milestoneToRemove) => {
    try {
      if (currentUser && effectiveUserId) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || 'default';
            const year = `year${milestoneToRemove.Milestone_Year}`;
            const semester = milestoneToRemove.Milestone_Sem == 'S1' ? 'S1' : 'S2';
            const updatedMilestones = userData.finalizedMilestones[activeTab][year][semester].milestones.filter(
              (milestone) => milestone.Milestone_ID !== milestoneToRemove.Milestone_ID
            );

            if (updatedMilestones.length === 0) {
              // If there are no milestones left in this semester, remove the semester
              delete userData.finalizedMilestones[activeTab][year][semester];
              
              // Check if the year object is empty after removing the semester
              if (Object.keys(userData.finalizedMilestones[activeTab][year]).length === 0) {
                delete userData.finalizedMilestones[activeTab][year];
              }

              // Check if the activeTab object is empty after removing the year
              if (Object.keys(userData.finalizedMilestones[activeTab]).length === 0) {
                delete userData.finalizedMilestones[activeTab];
              }
            } else {
              // Otherwise, update the milestones for that semester
              userData.finalizedMilestones[activeTab][year][semester].milestones = updatedMilestones;
            }

            transaction.update(userDocRef, { finalizedMilestones: userData.finalizedMilestones });

            // Update local state
            setFinalizedMilestones((prev) => prev.filter((milestone) => milestone.Milestone_ID !== milestoneToRemove.Milestone_ID));
            setMilestonesByYearAndSemester((prev) => ({
              ...prev,
              [year]: {
                ...prev[year],
                [semester]: updatedMilestones,
              },
            }));
          }
        });
      }
    } catch (error) {
      console.error('Error removing milestone:', error);
    }
  };

  const handleToggleComplete = async (milestone, event) => {
    event.stopPropagation();
    try {
      const updatedMilestone = { ...milestone, completed: !milestone.completed };
  
      if (currentUser && effectiveUserId) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || 'default';
            const year = `year${milestone.Milestone_Year}`;
            const semester = milestone.Milestone_Sem === 'S1' ? 'S1' : 'S2';
            const milestoneIndex = userData.finalizedMilestones[activeTab][year][semester].milestones.findIndex(
              (m) => m.Milestone_ID === milestone.Milestone_ID
            );
  
            if (milestoneIndex > -1) {
              userData.finalizedMilestones[activeTab][year][semester].milestones[milestoneIndex] = updatedMilestone;
  
              transaction.update(userDocRef, { finalizedMilestones: userData.finalizedMilestones });
  
              setFinalizedMilestones((prev) =>
                prev.map((m) => (m.Milestone_ID === milestone.Milestone_ID ? updatedMilestone : m))
              );
              setMilestonesByYearAndSemester((prev) => ({
                ...prev,
                [year]: {
                  ...prev[year],
                  [semester]: userData.finalizedMilestones[activeTab][year][semester].milestones,
                },
              }));
            }
          }
        });
      }
    } catch (error) {
      console.error('Error toggling milestone completion:', error);
    }
  };

  useEffect(() => {
    const organizedMilestones = organizeMilestonesByYearAndSemester(finalizedMilestones);
    setMilestonesByYearAndSemester(organizedMilestones);
  }, [finalizedMilestones, organizeMilestonesByYearAndSemester]);

  const handleExportPlan = async () => {
    try {
      // Reset isUserDataFetched to allow fetchUserData to run
      if (isUserDataFetched && isUserDataFetched.current !== undefined) {
        isUserDataFetched.current = false;
      } else {
        console.error('isUserDataFetched is undefined or null');
      }
  
      // Call fetchUserData and wait for it to complete
      await fetchUserData(currentUser);
  
      // Set dataFetchComplete to true after data is fetched
      setDataFetchComplete(true);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    // Check if data fetching is complete before generating the PDF
    if (dataFetchComplete) {
      const generatePDF = async () => {
        try {
          const pdfBytes = await createPDFDocument(finalizedOrganizedCourses, currentUser);
          setPdfBytes(pdfBytes);
          setShowPdfModal(true);
        } catch (error) {
          console.error('Error generating PDF:', error);
        } finally {
          // Reset dataFetchComplete to prevent repeated exports
          setDataFetchComplete(false);
        }
      };
  
      generatePDF();
    }
  }, [dataFetchComplete, finalizedOrganizedCourses, currentUser]);  
  
  const closePdfModal = () => {
    setShowPdfModal(false);
    setPdfBytes(null);
  };

  const handleRemoveSemesterCourses = async (year, semester) => {
    if (currentUser && effectiveUserId) {
      const userRef = db.collection('users').doc(effectiveUserId);

      try {
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }

          const userData = userDoc.data();
          const updatedFinalizedSchedule = { ...userData.schedules[activeTab] };

          updatedFinalizedSchedule[`year${year}`][semester].courses = [];

          transaction.update(userRef, { [`schedules.${activeTab}`]: updatedFinalizedSchedule });

          const updatedCompletedSemesters = {
            ...completedSemesters,
            [`year${year}`]: {
              ...completedSemesters[`year${year}`],
              [semester]: false,
            },
          };
          transaction.update(userRef, { completedSemesters: updatedCompletedSemesters });
          setCompletedSemesters(updatedCompletedSemesters);
        });

        const semesterString = semester === 'S1' ? 'Fall' : 'Spring';
        setFinalizedOrganizedCourses((prev) => {
          const newCourses = {
            ...prev,
            [`year${year}`]: {
              ...prev[`year${year}`],
              [semesterString]: [],
            },
          };

          recheckPrerequisites(newCourses);

          return newCourses;
        });

        console.log(`Courses for Year ${year} ${semesterString} removed successfully`);
      } catch (error) {
        console.error('Failed to remove semester courses:', error);
      }
    } else {
      console.error('Error: currentUser or effectiveUserId is not defined');
    }
  };

  const handleRemoveAllCourses = async () => {
    if (currentUser && effectiveUserId) {
      const userRef = db.collection('users').doc(effectiveUserId);
  
      try {
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const updatedFinalizedSchedule = { ...userData.schedules[activeTab] };
          const numYearsValue = updatedFinalizedSchedule.numYears; // Save the numYears value
  
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            const fallCompleted = completedSemesters[yearKey]?.S1;
            const springCompleted = completedSemesters[yearKey]?.S2;
  
            updatedFinalizedSchedule[yearKey] = {
              S1: fallCompleted ? userDoc.data().schedules[activeTab][yearKey]?.S1 : { courses: [] },
              S2: springCompleted ? userDoc.data().schedules[activeTab][yearKey]?.S2 : { courses: [] },
            };
          }
  
          updatedFinalizedSchedule.numYears = numYearsValue; // Re-assign the numYears value to ensure it is not removed
  
          transaction.update(userRef, { [`schedules.${activeTab}`]: updatedFinalizedSchedule });
  
          const updatedFinalizedCourses = {};
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            const fallCompleted = completedSemesters[yearKey]?.S1;
            const springCompleted = completedSemesters[yearKey]?.S2;
  
            updatedFinalizedCourses[yearKey] = {
              Fall: fallCompleted ? finalizedOrganizedCourses[yearKey]?.Fall : [],
              Spring: springCompleted ? finalizedOrganizedCourses[yearKey]?.Spring : [],
            };
          }
  
          setFinalizedOrganizedCourses(updatedFinalizedCourses);
  
          console.log('Courses removed successfully from incomplete semesters.');
        });
      } catch (error) {
        console.error('Failed to remove courses from incomplete semesters:', error);
      }
    } else {
      console.error('Error: currentUser or effectiveUserId is not defined');
    }
  };  


  const handleReshuffleCourses= async () => {
    if (currentUser && effectiveUserId) {
      const userRef = db.collection('users').doc(effectiveUserId);
  
      try {
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          /*const userData = userDoc.data();
          const updatedFinalizedSchedule = { ...userData.schedules[activeTab] };
          const numYearsValue = updatedFinalizedSchedule.numYears; // Save the numYears value
  
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            const fallCompleted = completedSemesters[yearKey]?.S1;
            const springCompleted = completedSemesters[yearKey]?.S2;
  
            updatedFinalizedSchedule[yearKey] = {
              S1: fallCompleted ? userDoc.data().schedules[activeTab][yearKey]?.S1 : { courses: [] },
              S2: springCompleted ? userDoc.data().schedules[activeTab][yearKey]?.S2 : { courses: [] },
            };
          }
  
          updatedFinalizedSchedule.numYears = numYearsValue; // Re-assign the numYears value to ensure it is not removed
  
          transaction.update(userRef, { [`schedules.${activeTab}`]: updatedFinalizedSchedule });
  
          const updatedFinalizedCourses = {};
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            const fallCompleted = completedSemesters[yearKey]?.S1;
            const springCompleted = completedSemesters[yearKey]?.S2;
  
            updatedFinalizedCourses[yearKey] = {
              Fall: fallCompleted ? finalizedOrganizedCourses[yearKey]?.Fall : [],
              Spring: springCompleted ? finalizedOrganizedCourses[yearKey]?.Spring : [],
            };
          }
  
          setFinalizedOrganizedCourses(updatedFinalizedCourses);*/

          console.log('Clicked reshuffle')
  
          console.log('Courses removed successfully from incomplete semesters.');
        });
      } catch (error) {
        console.error('Failed to remove courses from incomplete semesters:', error);
      }
    } else {
      console.error('Error: currentUser or effectiveUserId is not defined');
    }
  };  

  const handleAddYear = async () => {
    if (isProcessing) return; // Prevent concurrent execution
    setIsProcessing(true);
    try {
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const activeTabIndex = tabs.findIndex(tab => tab.name === activeTab);
          if (activeTabIndex === -1) return; // No active tab found
  
          const activeTabData = tabs[activeTabIndex];
          const activePlanNumYears = numYears;
          const updatedNumYears = activePlanNumYears + 1;
          console.log('Updated num years: ', activeTabData, activeTabData.numYears, updatedNumYears);
  
          const newYearKey = `year${updatedNumYears}`;
          const updatedTab = {
            ...activeTabData,
            numYears: updatedNumYears,
            [newYearKey]: {
              S1: { courses: [] },
              S2: { courses: [] },
            },
          };
  
          // Update Firestore document
          transaction.update(userRef, {
            [`schedules.${activeTab}.numYears`]: updatedNumYears,
            [`schedules.${activeTab}.${newYearKey}`]: {
              S1: { courses: [] },
              S2: { courses: [] },
            },
          });
  
          // Update the tabs array in state
          const updatedTabs = [...tabs];
          updatedTabs[activeTabIndex] = updatedTab;
  
          setTabs(updatedTabs);
          setFinalizedOrganizedCourses(prev => ({
            ...prev,
            [newYearKey]: {
              Fall: [],
              Spring: []
            }
          }));
  
          // Scroll to the newly added year after it's rendered
          setNumYears(updatedNumYears);  // Ensure state update triggers re-render
          setTimeout(() => {
            if (yearRefs.current[`year${updatedNumYears}`]) {
              yearRefs.current[`year${updatedNumYears}`].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          }, 100);
  
          console.log('New year added to finalizedSchedule');
        });
      } else {
        console.error('Error: currentUser or effectiveUserId is not defined');
      }
    } catch (error) {
      console.error('Failed to add new year:', error);
    } finally {
      setIsProcessing(false);
    }
  };
  
  const handleRemoveYear = async () => {
    if (isProcessing) return; // Prevent concurrent execution
    setIsProcessing(true);
    try {
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const activeTabIndex = tabs.findIndex(tab => tab.name === activeTab);
          if (activeTabIndex === -1) return; // No active tab found
  
          const activeTabData = tabs[activeTabIndex];
          const activePlanNumYears = numYears;
  
          if (activePlanNumYears <= 1) {
            console.error('Cannot remove the last remaining year.');
            return; // Prevent removing the last year
          }
  
          const updatedNumYears = activePlanNumYears - 1;
          console.log('Updated Num Years: ', updatedNumYears);
  
          // Remove the last year but keep its courses
          const lastYearKey = `year${activePlanNumYears}`;
          const removedCourses = {
            Fall: finalizedOrganizedCourses[lastYearKey]?.Fall || [],
            Spring: finalizedOrganizedCourses[lastYearKey]?.Spring || [],
          };
  
          // Remove the milestones for the last year
          const updatedMilestones = { ...userData.finalizedMilestones };
          if (updatedMilestones[activeTab] && updatedMilestones[activeTab][lastYearKey]) {
            delete updatedMilestones[activeTab][lastYearKey]; // Remove milestones for the year being deleted
          }
  
          // Update Firestore document with both schedules and milestones
          const updatedSchedules = {
            ...userData.schedules,
            [activeTab]: {
              ...userData.schedules[activeTab],
              numYears: updatedNumYears,
            },
          };
          delete updatedSchedules[activeTab][lastYearKey];
  
          transaction.update(userRef, {
            schedules: updatedSchedules,
            finalizedMilestones: updatedMilestones, // Update milestones in Firestore
          });
  
          // Update the tabs array in state
          const updatedTabs = [...tabs];
          updatedTabs[activeTabIndex] = {
            ...activeTabData,
            numYears: updatedNumYears,
          };
          console.log('Tabs: ', updatedTabs);
          setTabs(updatedTabs);
  
          // Update finalizedOrganizedCourses but keep the courses
          setFinalizedOrganizedCourses(prev => {
            const newCourses = { ...prev };
            delete newCourses[lastYearKey];
            return newCourses;
          });
  
          // Update finalizedMilestones in state to remove milestones for the removed year
          setFinalizedMilestones(prev => {
            const newMilestones = { ...prev };
            if (newMilestones[activeTab]) {
              delete newMilestones[activeTab][lastYearKey]; // Remove milestones from local state
            }
            return newMilestones;
          });
  
          setNumYears(updatedNumYears);  // Ensure state update triggers re-render
          setTimeout(() => {
            if (yearRefs.current[`year${updatedNumYears}`]) {
              yearRefs.current[`year${updatedNumYears}`].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          }, 100);
  
          console.log('Last year removed from finalizedSchedule, milestones and courses preserved/handled');
        });
      } else {
        console.error('Error: currentUser or effectiveUserId is not defined');
      }
    } catch (error) {
      console.error('Failed to remove last year:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const getYearAndSemesterName = useMemo(() => {
    if (!surveyAnswers?.SchoolStart?.year) return () => '';
  
    const startYear = Number(surveyAnswers.SchoolStart.year);
  
    const getYearName = (yearNumber) => {
      if (yearNumber < 1) return '';
      return startYear + (yearNumber - 1);
    };
  
    return getYearName;
  }, [surveyAnswers?.SchoolStart?.year]);

  // Updating the rendering logic to use the numYears for the active plan
  const activePlanNumYears = tabs[activeTab]?.numYears;

  const hasCoursesInSemester = (yearNumber, semester) => {
    const semesterKey = semester === 'Fall' ? 'Fall' : 'Spring';
    return (finalizedOrganizedCourses[`year${yearNumber}`]?.[semesterKey]?.length || 0) > 0;
  };

  if (loading || numYears === null) {
    return (
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  const handleDropdownToggle = (year, semester) => {
    const key = `${year}-${semester}`;
    setDropdownOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleAlternativesClick = (category, year, semester) => {
    setSelectedElectiveCategory(category);
    setElectiveYear(year);
    if (semester === 'Fall') {
      semester = 1;
    } else {
      semester = 2;
    }
    setElectiveSemester(semester);
    setShowSidebar(true);
  };

  const handleKeepElectiveCourse = async (course, yr, sem) => {
    try {
      if (currentUser && effectiveUserId) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const previousFinalizedSchedule = userDoc.data().schedules[activeTab] || {};

            const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};
            const updatedFinalizedSchedule = {
              ...updateObj,
              [`year${yr}`]: {
                ...updateObj[`year${yr}`],
                [`S${sem}`]: {
                  ...(updateObj[`year${yr}`] && updateObj[`year${yr}`][`S${sem}`] ? updateObj[`year${yr}`][`S${sem}`] : {}),
                  courses: (updateObj[`year${yr}`] && updateObj[`year${yr}`][`S${sem}`] && updateObj[`year${yr}`][`S${sem}`].courses
                    ? updateObj[`year${yr}`][`S${sem}`].courses.concat([course.courseInfo_courseNumber])
                    : [course.courseInfo_courseNumber]),
                },
              },
            };

            transaction.update(userDocRef, {
              [`schedules.${activeTab}`]: updatedFinalizedSchedule,
            });
          } else {
            console.error('User data not found for the given userId:', effectiveUserId);
            throw new Error('User data not found');
          }
        });
      } else {
        console.error('Error: currentUser or effectiveUserId is not defined');
      }
    } catch (error) {
      console.error('Error keeping elective course:', error);
    }
  };

  const handleEvaluate = (course) => {
    setSelectedCourse(course);
    setEvaluationModalOpen(true);
  };

  const handleCloseEvaluationModal = () => {
    setEvaluationModalOpen(false);
    setSelectedCourse(null);
  };

  const handleCloseTransferDataModal = () => {
    setTransferDataModalOpen(false);
  };

  const handleCloseDegreeAuditModal = () => {
    setIsDegreeAuditModalOpen(false);
  };

  const handleOpenAddScheduleModal = () => {
    setShowAddScheduleModal(true);
  };

  const handleCloseAddScheduleModal = () => {
    setShowAddScheduleModal(false);
    setNewScheduleName('');
    setError(''); // Clear the error message when the modal is closed
  };  

  const handleDisplayTransferData = () => {
    setTransferDataModalOpen(true);
  }

  const handleCloseIncompletePrereqsWarningModal = async () => {
    setShowIncompletePrereqsWarningModal(false)
    const userDocRef = doc(db, 'users', effectiveUserId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      await updateDoc(userDocRef, {'hasSeenPrereqsWarning': true});
    }
  }

  const handleAddPlan = async (event) => {
    event.preventDefault(); // Prevent page reload
  
    if (!newScheduleName) {
      setError('Please enter a name for the new plan.');
      return;
    }
  
    if (tabs.some(tab => tab.name === newScheduleName)) {
      setError('A plan with this name already exists.');
      return;
    }
  
    try {
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const newSchedule = {
            numYears: numYears2,
          };
  
          for (let i = 1; i <= numYears2; i++) {
            newSchedule[`year${i}`] = {
              S1: { courses: [] },
              S2: { courses: [] },
            };
  
            // Copy completed semesters' courses to the new schedule
            if (completedSemesters[`year${i}`]?.S1) {
              newSchedule[`year${i}`].S1.courses = userData.schedules[activeTab][`year${i}`].S1.courses || [];
            }
            if (completedSemesters[`year${i}`]?.S2) {
              newSchedule[`year${i}`].S2.courses = userData.schedules[activeTab][`year${i}`].S2.courses || [];
            }
          }
  
          // Prepare the new tab data and add it to the existing schedules
          const updatedSchedules = {
            ...userData.schedules,
            [newScheduleName]: newSchedule,
          };
  
          // Ensure that no 'data' header is added anywhere
          transaction.update(userRef, {
            schedules: updatedSchedules,
            activeTab: newScheduleName,
          });
  
          // Update local state
          setTabs([...tabs, { name: newScheduleName, ...newSchedule }]);
          setActiveTab(newScheduleName);
          setError(''); // Clear the error message
          handleCloseAddScheduleModal();
        });
      } else {
        console.error('Error: currentUser or effectiveUserId is not defined');
      }
    } catch (error) {
      console.error('Error adding new schedule:', error);
      setError('An error occurred while adding the new plan.');
    }
  };
  
  const handleRemovePlan = async (planNameToRemove) => {
    if (!currentUser || !effectiveUserId) {
        console.error('Error: currentUser or effectiveUserId is not defined');
        return;
    }

    if (tabs.length <= 1) {
        setError('Cannot remove the last plan.');
        return;
    }

    try {
        const userRef = db.collection('users').doc(effectiveUserId);
        const batch = db.batch(); // Create a new batch instance

        const userDoc = await userRef.get();
        if (!userDoc.exists) {
            throw new Error('User data not found');
        }

        const userData = userDoc.data();

        // Remove the plan from schedules
        const updatedSchedules = tabs.filter(tab => tab.name !== planNameToRemove);

        if (updatedSchedules.length === tabs.length) {
            throw new Error('Plan not found');
        }

        const updatedSchedulesObject = updatedSchedules.reduce((acc, tab) => {
            acc[tab.name] = { ...userData.schedules[tab.name] }; // Correctly copy the existing tab data
            return acc;
        }, {});

        // Remove the associated milestones
        const updatedFinalizedMilestones = { ...userData.finalizedMilestones };
        if (updatedFinalizedMilestones && updatedFinalizedMilestones[planNameToRemove]) {
            delete updatedFinalizedMilestones[planNameToRemove];
        }

        let newActiveTab = activeTab;
        if (activeTab === planNameToRemove) {
            const removedTabIndex = tabs.findIndex(tab => tab.name === planNameToRemove);
            if (removedTabIndex > 0 && removedTabIndex === tabs.length - 1) {
                newActiveTab = tabs[removedTabIndex - 1].name;
            } else if (removedTabIndex > 0) {
                newActiveTab = tabs[removedTabIndex + 1].name;
            } else {
                newActiveTab = updatedSchedules[0].name;
            }
        }

        // Check if the removed plan is the primary tab
        if (primaryTab === planNameToRemove) {
            // Set the first available tab as the new primary if the primary one is removed
            const newPrimaryTab = updatedSchedules.length > 0 ? updatedSchedules[0].name : '';
            setPrimaryTab(newPrimaryTab);

            // Update Firestore with the new primary tab
            batch.update(userRef, {
                primaryTab: newPrimaryTab,
            });
        }

        // Prepare the batch update
        const batchUpdateData = {
            schedules: updatedSchedulesObject,
            activeTab: newActiveTab,
            finalizedMilestones: updatedFinalizedMilestones, // Include updated milestones
        };

        batch.update(userRef, batchUpdateData);

        // Commit the batch
        await batch.commit();

        // Update local state to reflect changes
        setTabs(updatedSchedules);
        setActiveTab(newActiveTab);

        // Update finalizedMilestones state
        setFinalizedMilestones(prev => {
            const { [planNameToRemove]: removed, ...rest } = prev;
            return rest;
        });

        setError('');

        console.log(`Plan "${planNameToRemove}" removed and active tab updated successfully`);
    } catch (error) {
        console.error('Error removing plan:', error);
        setError(`An error occurred while removing the plan: ${error.message}`);
    }
};
  
  const handleTabClick = async (tabName) => {
    try {
      setActiveTab(tabName);
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await userRef.update({
          activeTab: tabName
        });
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          const courseIds = userData.schedules[tabName] || [];
          setFinalizedCourseIds(courseIds);
  
          const cumulativeIds = calculateCumulativeIds(courseIds);
          setCumulativeCourseIds(cumulativeIds);
  
          const organizedCourses = organizeCoursesByYearAndSemester(courseIds, allCourses, prereqs, cumulativeIds);
          setFinalizedOrganizedCourses(organizedCourses);
        }
      }
    } catch (error) {
      console.error('Error setting active tab:', error);
    }
  };  

  const handleSwitchPrimaryTab = async (newPrimaryTab) => {
    try {
      setPrimaryTab(newPrimaryTab);
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await userRef.update({
          primaryTab: newPrimaryTab
        });
      }
    } catch (error) {
      console.error('Error setting primary tab:', error);
    }
  };
  
  const handleTabDoubleClick = (tab) => {
    setEditingTab(tab);
    setNewTabName(tab);
  };
  
  const handleRenameTab = async (oldTabName, newTabName) => {
    if (!newTabName || newTabName === oldTabName) {
      setEditingTab(null);
      return;
    }
  
    if (tabs.some(tab => tab.name === newTabName)) {
      alert('A plan with this name already exists.');
      return;
    }
  
    try {
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
  
          // Rename the schedule in Firestore
          const newSchedules = { ...userData.schedules };
          newSchedules[newTabName] = newSchedules[oldTabName];
          delete newSchedules[oldTabName];
  
          // Rename in finalizedMilestones if applicable
          const newFinalizedMilestones = { ...userData.finalizedMilestones };
          if (newFinalizedMilestones[oldTabName]) {
            newFinalizedMilestones[newTabName] = newFinalizedMilestones[oldTabName];
            delete newFinalizedMilestones[oldTabName];
          }
  
          // Prepare the data to update
          const updateData = {
            schedules: newSchedules,
            finalizedMilestones: newFinalizedMilestones,
          };
  
          // Update activeTab if necessary
          if (userData.activeTab === oldTabName) {
            updateData.activeTab = newTabName;
          }
  
          // Update primaryTab if necessary
          if (userData.primaryTab === oldTabName) {
            updateData.primaryTab = newTabName;
          }
  
          transaction.update(userRef, updateData);
  
          // Update local state after transaction
          const newTabs = tabs.map(tab =>
            tab.name === oldTabName ? { ...tab, name: newTabName } : tab
          );
          setTabs(newTabs);
  
          if (activeTab === oldTabName) {
            setActiveTab(newTabName);
          }
          if (primaryTab === oldTabName) {
            setPrimaryTab(newTabName);
          }
  
          setEditingTab(null); // Moved here
        });
      } else {
        console.error('Error: currentUser or effectiveUserId is not defined');
      }
    } catch (error) {
      console.error('Error renaming schedule:', error);
      alert(`An error occurred while renaming the plan: ${error.message}`);
    }
  };
  
  const handleRenameSubmit = (event) => {
    if (event.key === 'Enter' || event.type === 'blur') {
      if (newTabName.length <= 65) { // Ensure the name is within the allowed limit
        handleRenameTab(editingTab, newTabName);
      } else {
        alert('Tab name is too long. Please use 65 characters or fewer.');
      }
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      
      
      {/* <PlanJoyrideTour /> */}
      

      <div className={`w-11/12 max-w-8xl ${viewMode === 'horizontal' ? 'mb-20 mt-20' : 'mt-20 mb-0'}`}>
      {effectiveUserId !== currentUser.uid && (
        <PlanPageHeader
          studentName={fullName}
          studentId={effectiveUserId}
        />
      )}

      <div className="flex border-b border-gray-200 dark:border-gray-700 mb-2 items-center">

        {/* Tabs and Add Plan Button */}
        <div className="flex items-center flex-1 overflow-x-auto custom-scrollbar space-x-2">

          
          {/* Tabs Section */}
          <ul className="flex flex-nowrap">
            {tabs.map((tab, index) => (
              <li key={index} className="relative mr-2 flex items-center flex-shrink-0" role="presentation">
                {editingTab === tab.name ? (
                  <input
                    type="text"
                    value={newTabName}
                    onChange={(e) => setNewTabName(e.target.value)}
                    onBlur={handleRenameSubmit}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleRenameSubmit(e);
                    }}
                    className="inline-block py-2 px-3 border-b-2 rounded-t-lg focus:outline-none"
                    style={{ width: `${Math.max(newTabName.length, 1) * 7 + 35}px` }}
                    maxLength={65}
                    autoFocus
                  />
                ) : (
                  <button
                    className={`inline-block py-2 px-3 border-b-2 rounded-t-lg flex justify-between items-center ${
                      activeTab === tab.name
                        ? 'border-blue-500 text-blue-500'
                        : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                    }`}
                    onClick={() => handleTabClick(tab.name)}
                    onDoubleClick={() => handleTabDoubleClick(tab.name)}
                    type="button"
                    role="tab"
                    aria-controls={`${tab.name}-content`}
                    aria-selected={activeTab === tab.name ? 'true' : 'false'}
                  >
                    {/* Bookmark Icon */}
                    <button
                      className="mr-2 focus:outline-none"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering tab click
                        handleSwitchPrimaryTab(tab.name);
                      }}
                    >
                      {tab.name === primaryTab ? (
                        <FaBookmark className="text-red-700 text-sm" />
                      ) : (
                        <FaRegBookmark className="text-red-700 text-sm hover:text-black hover:text-md" />
                      )}
                    </button>
                    <span className="truncate">{tab.name}</span>
                    {tabs.length > 1 && (
                      <button
                        className="ml-2 text-black hover:text-red-700"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering tab click
                          handleRemovePlan(tab.name);
                        }}
                      >
                        &times;
                      </button>
                    )}
                  </button>
                )}
              </li>
            ))}
          </ul>

          {/* Add Plan Button */}
          <button
            onClick={handleOpenAddScheduleModal}
            className="text-black hover:text-gray-700 text-lg p-0 flex items-center mr-4" // Added mr-4 here
          >
            <GrFormAdd className="text-s" />
          </button>
        </div>
        
        {/* Action Buttons Section */}
        <div className="flex items-center space-x-2 ml-auto">

          <button
              onClick={() => setShowIncompletePrereqsWarningModal(true)}
              className="text-red-600 hover:text-red-800 text-lg font-semibold ml-auto p-0 flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10.29 3.86l-8.46 14.7c-.7 1.22.17 2.44 1.53 2.44h16.88c1.36 0 2.23-1.22 1.53-2.44l-8.46-14.7a1.6 1.6 0 00-2.76 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v4m0 4h.01"
              />
            </svg>
          </button>
          
          {/* View Toggle Button */}
          <button
            onClick={() => setViewMode(viewMode === 'horizontal' ? 'vertical' : 'horizontal')}
            className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700"
          >
            {viewMode === 'horizontal' ? (
              <CiGrid2V className="w-5 h-5" />
            ) : (
              <CiGrid41 className="w-5 h-5" />
            )}
          </button>

          {/* Export and View All Milestones Buttons */}
          <div className="inline-flex rounded-md shadow-sm" role="group">
            <button 
              type="button"
              onClick={handleOpenAllMilestonesDrawer}
              className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
            >
              <LuGoal className="w-3 h-3 me-2" />
              {t('planPage.viewAllMilestones', 'View All Milestones')}
            </button>
            <button
              type="button"
              onClick={handleExportPlan}
              className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-r-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
            >
              <svg
                className="w-3 h-3 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
              </svg>
              {t('planPage.exportPlan', 'Export Plan')}
            </button>
          </div>
        </div>
      </div>
      {effectiveUserId === currentUser.uid && (
        <NavBar />
      )}
      
        
        {effectiveUserId !== currentUser.uid && (
        <CustomSidebar />
      )

      }
        <div className="w-full">
          {showSidebar && (
            <ElectiveSidebar
              onClose={() => setShowSidebar(false)}
              elective={selectedElectiveCategory}
              electiveYear={electiveYear}
              electiveSemester={electiveSemester} 
              courses={fetchedCourseData}
              onKeepElectiveCourse={handleKeepElectiveCourse}
            />
          )}
          {showIncompletePrereqsWarningModal && (
            <IncompletePrereqsWarningModal
              onClose={handleCloseIncompletePrereqsWarningModal}
            />
          )}
          {viewMode === 'horizontal' ? (
            // Horizontal View
            <div className="grid grid-cols-1 gap-y-4 w-full">
              {/* Header Section: Total Credits and Control Buttons */}
              <div className="flex justify-between items-center mb-0">
                {/* Total Credits and Upper Division Credits */}
                <div className="flex items-center">
                  <CreditsOverview finalizedOrganizedCourses={finalizedOrganizedCourses} onOpen={setIsDegreeAuditModalOpen}/>
                </div>

                {/* Autofill, Remove All, and Hide/Show Completed Years Button */}
                <div className="ml-auto flex space-x-2 items-center">
                  {/* Your existing buttons */}
                  <button
                    onClick={handleDisplayTransferData}
                    disabled={isAutofilling}
                    className={`${
                      isAutofilling ? 'disabled' : ''
                    } text-green-700 hover:text-white border border-red-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                  >
                    {t('planPage.transferCredits', 'Transfer Credits')}
                  </button>
                  {hasUncompletedCourses && (
                    <button
                      onClick={handleRemoveAllCourses}
                      disabled={isAutofilling}
                      className={`${
                        isAutofilling ? 'disabled' : ''
                      } text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                    >
                      {t('planPage.removeAllCourses', 'Remove All Courses')}
                    </button>
                  )}
                  <button
                    onClick={handleAutofillAllCourses}
                    className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5"
                  >
                    {t('planPage.autofillPlan', 'Autofill Plan')}
                  </button>
                  {/* Hide/Show Completed Years Button */}
                  {Object.values(completedSemesters).some((year) =>
                    Object.values(year).every((semester) => semester)
                  ) && (
                    <button
                      type="button"
                      className="flex items-center py-1.5 px-3 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      onClick={() => setHideCompletedYears(!hideCompletedYears)}
                    >
                      {hideCompletedYears
                        ? t('planPage.showCompletedYears', 'Show Completed Years')
                        : t('planPage.hideCompletedYears', 'Hide Completed Years')}
                    </button>
                  )}
                </div>
              </div>

              {/* Render each year */}
              {Array.from({ length: numYears }, (_, i) => i + 1).map((yearNumber) => {
                const isYearCompleted =
                  completedSemesters[`year${yearNumber}`]?.S1 && completedSemesters[`year${yearNumber}`]?.S2;

                if (hideCompletedYears && isYearCompleted) {
                  return null;
                }

                return (
                  <div key={yearNumber} ref={(el) => (yearRefs.current[`year${yearNumber}`] = el)} className="w-full">
                    <div className="grid grid-cols-2 gap-3 w-full">
                      {/* Fall Semester */}
                      <div className="flex-1 min-w-[200px]">
                        <DroppableSemester
                          yearNumber={yearNumber}
                          semester="Fall"
                          courses={finalizedOrganizedCourses[`year${yearNumber}`]?.Fall || []}
                          isCompleted={completedSemesters[`year${yearNumber}`]?.S1}
                          onRemove={(classToRemove) => handleRemove(classToRemove, yearNumber, 'Fall')}
                          moveCourse={moveCourse}
                          onAlternativesClick={handleAlternativesClick}
                          onEvaluate={handleEvaluate}
                          setShowSidebar={handlePlaceholderSelection}
                          viewMode={viewMode}
                          prereqClasses={prereqClasses}
                          getTotalCreditsForSemester={getTotalCreditsForSemester}
                          handleDropdownToggle={handleDropdownToggle}
                          dropdownOpen={dropdownOpen}
                          completedSemesters={completedSemesters}
                          getYearAndSemesterName={getYearAndSemesterName}
                          hasCoursesInSemester={hasCoursesInSemester}
                          shouldShowMarkCompleteButton={shouldShowMarkCompleteButton}
                          handleToggleSemesterComplete={handleToggleSemesterComplete}
                          handleAddMilestone={handleAddMilestone}
                          handleRemoveSemesterCourses={handleRemoveSemesterCourses}
                          handleAutofillCourse={handleAutofillCourse}
                          handleRemove={handleRemove}
                          buttonRefs={buttonRefs}
                          activeTab={activeTab}
                          dropdownRefs={dropdownRefs}
                          isDragging={isDragging}
                          isAutofilling={isAutofilling}
                          setIsAutofilling={setIsAutofilling}
                          yearRefs={yearRefs}
                          // ... any other props you need
                        />
                      </div>

                      {/* Spring Semester */}
                      <div className="flex-1 min-w-[200px]">
                        <DroppableSemester
                          yearNumber={yearNumber}
                          semester="Spring"
                          courses={finalizedOrganizedCourses[`year${yearNumber}`]?.Spring || []}
                          isCompleted={completedSemesters[`year${yearNumber}`]?.S2}
                          onRemove={(classToRemove) => handleRemove(classToRemove, yearNumber, 'Spring')}
                          moveCourse={moveCourse}
                          onAlternativesClick={handleAlternativesClick}
                          onEvaluate={handleEvaluate}
                          viewMode={viewMode}
                          setShowSidebar={handlePlaceholderSelection}
                          prereqClasses={prereqClasses}
                          getTotalCreditsForSemester={getTotalCreditsForSemester}
                          handleDropdownToggle={handleDropdownToggle}
                          dropdownOpen={dropdownOpen}
                          completedSemesters={completedSemesters}
                          getYearAndSemesterName={getYearAndSemesterName}
                          hasCoursesInSemester={hasCoursesInSemester}
                          shouldShowMarkCompleteButton={shouldShowMarkCompleteButton}
                          handleToggleSemesterComplete={handleToggleSemesterComplete}
                          handleAddMilestone={handleAddMilestone}
                          handleRemoveSemesterCourses={handleRemoveSemesterCourses}
                          handleAutofillCourse={handleAutofillCourse}
                          handleRemove={handleRemove}
                          buttonRefs={buttonRefs}
                          activeTab={activeTab}
                          dropdownRefs={dropdownRefs}
                          isDragging={isDragging}
                          isAutofilling={isAutofilling}
                          setIsAutofilling={setIsAutofilling}
                          yearRefs={yearRefs}
                          // ... any other props you need
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* Add and Remove Year Buttons */}
              <div className="flex justify-center mt-4 mb-10 space-x-4">
                {/* Chat Icon with Notification Badge */}
                <div className="relative">
                  <button
                    onClick={() => setShowCommentsChat(true)}
                    className="text-gray-700 bg-gray-200 bg-opacity-60 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full w-10 h-10 flex items-center justify-center"
                    aria-label={t('planPage.openComments','Open Comments')}
                  >
                    <FaComments />
                  </button>
                  {unreadCounts[activeTab] && unreadCounts[activeTab] > 0 && (
                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2">
                      {unreadCounts[activeTab] > 99 ? '99+' : unreadCounts[activeTab]}
                    </div>
                  )}
                </div>

                {numYears > 1 && shouldShowRemoveYearButton(numYears) && (
                  <button
                    onClick={handleRemoveYear}
                    className="text-red-700 bg-red-200 bg-opacity-60 hover:bg-red-300 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-full w-10 h-10 flex items-center justify-center"
                    aria-label={t('planPage.removeYear','Remove Year')}
                  >
                    <FaMinus />
                  </button>
                )}
                <button
                  onClick={handleAddYear}
                  className="text-blue-700 bg-blue-200 bg-opacity-60 hover:bg-blue-300 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full w-10 h-10 flex items-center justify-center"
                  aria-label={t('planPage.addYear','Add Year')}
                >
                  <FaPlus />
                </button>
              </div>
            </div>
          ) : (
            // Vertical View
            <div className="w-full flex flex-col p-0 m-0">
              {/* Header Section: Total Credits and Control Buttons */}
              <div className="flex justify-between items-center mb-2">
                {/* Total Credits and Upper Division Credits */}
                <div className="flex items-center">
                  <CreditsOverview finalizedOrganizedCourses={finalizedOrganizedCourses} onOpen={setIsDegreeAuditModalOpen} />
                </div>

                {/* Autofill, Remove All, and Hide/Show Completed Buttons */}
                <div className="ml-auto flex space-x-2 items-center">
                  {/* Your existing buttons */}
                  <button
                    onClick={handleDisplayTransferData}
                    disabled={isAutofilling}
                    className={`${
                      isAutofilling ? 'disabled' : ''
                    } text-green-700 hover:text-white border border-red-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                  >
                    {t('planPage.transferCredits', 'Transfer Credits')}
                  </button>
                  {hasUncompletedCourses && (
                    <button
                      onClick={handleRemoveAllCourses}
                      disabled={isAutofilling}
                      className={`${isAutofilling ? 'disabled' : ''} text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                    >
                      {t('planPage.removeAllCourses', 'Remove All Courses')}
                    </button>
                  )}
                  <button
                    onClick={handleAutofillAllCourses}
                    className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5"
                  >
                    {t('planPage.autofillPlan', 'Autofill Plan')}
                  </button>
                  {/* Hide/Show Completed Terms Button */}
                  {Object.values(completedSemesters).some((year) =>
                    Object.values(year).some((semester) => semester)
                  ) && (
                    <button
                      type="button"
                      className="flex items-center py-1.5 px-3 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      onClick={() => setHideCompleted(!hideCompleted)}
                    >
                      {hideCompleted
                        ? t('planPage.showCompletedTerms', 'Show Completed Terms')
                        : t('planPage.hideCompletedTerms', 'Hide Completed Terms')}
                    </button>
                  )}
                </div>
              </div>

              {/* Semesters Container */}
              <div
                className="flex w-full h-full overflow-x-auto custom-scrollbar"
                style={{
                  padding: '0',
                  margin: '0',
                  alignItems: 'flex-start',
                  gap: '0.5rem', // gap-2 corresponds to 0.5rem
                  overflowX: 'auto', // Enables horizontal scrolling when content exceeds width
                }}
              >
                {Array.from({ length: numYears }, (_, i) => i + 1).flatMap((yearNumber) => {
                  const totalSemesters = 6; // Fixed total semesters for width calculation
                  const totalGaps = totalSemesters - 1; // Number of gaps between semesters
                  const semesterWidth = `calc((100% - (${totalGaps} * 0.5rem)) / ${totalSemesters})`;

                  return [
                    // **Fall Semester Column**
                    !completedSemesters[`year${yearNumber}`]?.S1 || !hideCompleted ? (
                      <div
                        key={`fall-${yearNumber}`}
                        ref={(el) => (yearRefs.current[`year${yearNumber}`] = el)}
                        className="flex flex-col justify-start"
                        style={{
                          width: semesterWidth,
                          flexShrink: 0, // Prevents shrinking of the cards
                          minWidth: '12.5rem', // Set a minimum width to maintain consistency
                          marginBottom: '0.5rem', // Adds vertical gap between semesters
                        }}
                      >
                        <DroppableSemester
                          yearNumber={yearNumber}
                          semester="Fall"
                          courses={finalizedOrganizedCourses[`year${yearNumber}`]?.Fall || []}
                          isCompleted={completedSemesters[`year${yearNumber}`]?.S1}
                          onRemove={(classToRemove) => handleRemove(classToRemove, yearNumber, 'Fall')}
                          moveCourse={moveCourse}
                          onAlternativesClick={handleAlternativesClick}
                          onEvaluate={handleEvaluate}
                          setShowSidebar={handlePlaceholderSelection}
                          viewMode={viewMode}
                          prereqClasses={prereqClasses}
                          getTotalCreditsForSemester={getTotalCreditsForSemester}
                          handleDropdownToggle={handleDropdownToggle}
                          dropdownOpen={dropdownOpen}
                          completedSemesters={completedSemesters}
                          getYearAndSemesterName={getYearAndSemesterName}
                          hasCoursesInSemester={hasCoursesInSemester}
                          shouldShowMarkCompleteButton={shouldShowMarkCompleteButton}
                          handleToggleSemesterComplete={handleToggleSemesterComplete}
                          handleAddMilestone={handleAddMilestone}
                          handleRemoveSemesterCourses={handleRemoveSemesterCourses}
                          handleAutofillCourse={handleAutofillCourse}
                          handleRemove={handleRemove}
                          buttonRefs={buttonRefs}
                          activeTab={activeTab}
                          dropdownRefs={dropdownRefs}
                          isDragging={isDragging}
                          isAutofilling={isAutofilling}
                          setIsAutofilling={setIsAutofilling}
                          yearRefs={yearRefs}
                          // ... any other props you need
                        />
                      </div>
                    ) : null,

                    // **Spring Semester Column**
                    !completedSemesters[`year${yearNumber}`]?.S2 || !hideCompleted ? (
                      <div
                        key={`spring-${yearNumber}`}
                        ref={(el) => (yearRefs.current[`year${yearNumber}`] = el)}
                        className="flex flex-col justify-start"
                        style={{
                          width: semesterWidth,
                          flexShrink: 0, // Prevents shrinking of the cards
                          minWidth: '12.5rem', // Set a minimum width to maintain consistency
                          marginBottom: '0.5rem', // Adds vertical gap between semesters
                        }}
                      >
                        <DroppableSemester
                          yearNumber={yearNumber}
                          semester="Spring"
                          courses={finalizedOrganizedCourses[`year${yearNumber}`]?.Spring || []}
                          isCompleted={completedSemesters[`year${yearNumber}`]?.S2}
                          onRemove={(classToRemove) => handleRemove(classToRemove, yearNumber, 'Spring')}
                          moveCourse={moveCourse}
                          onAlternativesClick={handleAlternativesClick}
                          onEvaluate={handleEvaluate}
                          viewMode={viewMode}
                          setShowSidebar={handlePlaceholderSelection}
                          prereqClasses={prereqClasses}
                          getTotalCreditsForSemester={getTotalCreditsForSemester}
                          handleDropdownToggle={handleDropdownToggle}
                          dropdownOpen={dropdownOpen}
                          completedSemesters={completedSemesters}
                          getYearAndSemesterName={getYearAndSemesterName}
                          hasCoursesInSemester={hasCoursesInSemester}
                          shouldShowMarkCompleteButton={shouldShowMarkCompleteButton}
                          handleToggleSemesterComplete={handleToggleSemesterComplete}
                          handleAddMilestone={handleAddMilestone}
                          handleRemoveSemesterCourses={handleRemoveSemesterCourses}
                          handleAutofillCourse={handleAutofillCourse}
                          handleRemove={handleRemove}
                          buttonRefs={buttonRefs}
                          activeTab={activeTab}
                          dropdownRefs={dropdownRefs}
                          isDragging={isDragging}
                          isAutofilling={isAutofilling}
                          setIsAutofilling={setIsAutofilling}
                          yearRefs={yearRefs}
                          // ... any other props you need
                        />
                      </div>
                    ) : null,
                  ];
                })}
              </div>

              {/* Add and Remove Year Buttons Aligned to the Right */}
              <div className="flex justify-between mt-1 pr-4 space-x-4 w-full">
                {/* Chat Icon with Notification Badge */}
                <div className="relative">
                  <button
                    onClick={() => setShowCommentsChat(true)}
                    className="text-gray-700 bg-gray-200 bg-opacity-60 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full w-10 h-10 flex items-center justify-center"
                    aria-label={t('planPage.openComments','Open Comments')}
                  >
                    <FaComments />
                  </button>
                  {unreadCounts[activeTab] && unreadCounts[activeTab] > 0 && (
                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2">
                      {unreadCounts[activeTab] > 99 ? '99+' : unreadCounts[activeTab]}
                    </div>
                  )}
                </div>
                
                {/* Container for plus and minus buttons */}
                <div className="flex ml-auto space-x-2">
                  {/* Single Remove Year Button */}
                  {numYears > 1 && shouldShowRemoveYearButton(numYears) && (
                    <button
                      onClick={() => handleRemoveYear(numYears)} // Remove the last year
                      className="text-red-700 bg-red-200 bg-opacity-60 hover:bg-red-300 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-full w-10 h-10 flex items-center justify-center"
                      aria-label={t('planPage.removeYear','Remove Year')}
                    >
                      <FaMinus />
                    </button>
                  )}
                  {/* Add Year Button */}
                  <button
                    onClick={handleAddYear}
                    className="text-blue-700 bg-blue-200 bg-opacity-60 hover:bg-blue-300 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full w-10 h-10 flex items-center justify-center"
                    aria-label={t('planPage.addYear','Add Year')}
                  >
                    <FaPlus />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {showPdfModal && (
          <PDFPreview
            pdfBytes={pdfBytes}
            onClose={closePdfModal}
            firstName={currentUser?.firstName || 'Unknown'}
            lastName={currentUser?.lastName || 'Unknown'}
            numYears={currentUser?.schedules?.[activeTab]?.numYears || 4}
          />
        )}
      </div>
      {showDrawer && currentSemester.year && currentSemester.semester && (
            <MilestoneDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            milestones={finalizedMilestones.filter(
              (milestone) =>
                milestone.Milestone_Year === currentSemester.year &&
                milestone.Milestone_Sem === (currentSemester.semester === 'Fall' ? 'S1' : 'S2')
            )}
            handleRemoveMilestone={handleRemoveMilestone}
            handleToggleComplete={handleToggleComplete}
            handleAddMilestone={handleAddMilestone}
            handleEditMilestone={handleEditMilestone} // Pass the edit handler
            showAllMilestones={false}
            setShowModal={setShowModal}
            currentSemester={currentSemester}
          />
        )}

        {/* Milestone Drawer for all milestones */}
        {showAllMilestonesDrawer && (
          <MilestoneDrawer
            showDrawer={showAllMilestonesDrawer}
            setShowDrawer={setShowAllMilestonesDrawer}
            milestones={finalizedMilestones}
            handleRemoveMilestone={handleRemoveMilestone}
            handleToggleComplete={handleToggleComplete}
            handleAddMilestone={() => setShowModal(true)}
            handleEditMilestone={handleEditMilestone} // Pass the edit handler
            showAllMilestones={true}
            setShowModal={setShowModal}
          />
        )}

        {showPlaceholderSidebar && (
          <ElectiveSidebar
          onClose={() => setShowPlaceholderSidebar(false)}
          showSidebar={showPlaceholderSidebar}
          currPath={"Finance"}
          elective={selectedPlaceholderCategory}
          electiveYear={selectedPlaceholderYear}
          electiveSemester={selectedPlaceholderSemester}
          courses={fetchedCourseData}
          onKeepElectiveCourse={handleKeepElectiveCourse}
          majorRecData={transformedMajorRecData}
          requirement={selectedPlaceholderRequirement}
        />
        )}

        {/* Milestone Modal for adding/editing */}
        <MilestoneModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleSaveMilestone={handleSaveMilestone}
          initialData={editingMilestone} // Pass initial data for editing
        />
        <EvaluationModal
          isOpen={isEvaluationModalOpen}
          onClose={handleCloseEvaluationModal}
          classData={selectedCourse}
        />
        <TransferIntoModal
          showModal={isTransferDataModalOpen}
          onClose={handleCloseTransferDataModal}
          plannedCourses={finalizedOrganizedCourses}
          // transferClassesData={transferCreditsData}
        />
        <DegreeAuditModal
          showModal={isDegreeAuditModalOpen}
          onClose={handleCloseDegreeAuditModal}
          plannedCourses={finalizedOrganizedCourses}
          pathwayCourses={allUserMajorPathways}
          fetchedCourseData={fetchedCourseData}
        />

      {showCommentsChat && (
        <StudentChat
          currentUser={currentUser}
          activeTab={activeTab}
          onClose={() => setShowCommentsChat(false)}
          onChatOpen={handleChatOpen}
        />
      )}

      {showAddScheduleModal && (
        <div id="authentication-modal" className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300 ease-in-out">
          <div className="relative p-4 w-full max-w-md max-h-2xl" ref={modalRef}>
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {t('planPage.addNewPlan', 'Add New Plan')}
                </h3>
                <button
                  type="button"
                  className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={handleCloseAddScheduleModal}
                  data-modal-hide="authentication-modal"
                  aria-label={t('planPage.closeModal','Close modal')}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">{t('planPage.closeModal','Close modal')}</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                <form className="space-y-4">
                  <div>
                    <label
                      htmlFor="schedule-name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {t('planPage.planName','Plan Name')}
                    </label>
                    <input
                      type="text"
                      id="schedule-name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder={t('planPage.enterScheduleName','Enter schedule name')}
                      value={newScheduleName}
                      onChange={(e) => setNewScheduleName(e.target.value)}
                      maxLength={65} // Limit to 50 characters
                      required
                    />
                  </div>
                  {error && (
                    <div className="flex justify-center mb-4">
                      <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
                        {error}
                      </span>
                    </div>
                  )}
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      onClick={handleAddPlan}
                    >
                      {t('planPage.addNewPlan','Add New Plan')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </DndProvider>
  );
};

export default PlanPage;