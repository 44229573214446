// StudentGroups.js
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import { db } from '../../../firebase';
import {
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
  doc,
  getDoc
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../../contexts/AuthContext';

import { useNavigate } from 'react-router-dom';

// IMPORTANT: import your *updated* SearchComponent that uses Persistence 
// instead of Performance, e.g. "persistenceCategory".
import SearchComponent from '../StudentListing/SearchComponent';

import { FaPlus, FaTimes } from 'react-icons/fa';
import GroupItem from './GroupItem';

// Import your existing modals
import CampaignModal from '../StudentListing/CampaignModal';
import TemplateModal from '../StudentListing/TemplateModal';

/**
 * Helper function to apply a single custom filter
 * for numeric or text comparisons (like "equals", "less than", etc.).
 */
function applyCustomFilter(list, { field, operator, value }) {
  let newList = [...list];

  newList = newList.filter((s) => {
    // Example: if field is "tags", we look in s.surveyAnswers?.Tags
    // Otherwise we look in s[field].
    const studentVal =
      field === 'tags' ? s.surveyAnswers?.Tags : s[field];
    if (studentVal == null) return false;

    if (operator === 'equals') {
      // If it's an array (like tags), check any match
      if (Array.isArray(studentVal)) {
        return studentVal.some(
          (elem) =>
            String(elem).trim().toLowerCase() ===
            String(value).trim().toLowerCase()
        );
      } else {
        // Normal string or number compare
        return (
          String(studentVal).trim().toLowerCase() ===
          String(value).trim().toLowerCase()
        );
      }
    } else if (operator === 'greater than') {
      return parseFloat(studentVal) > parseFloat(value);
    } else if (operator === 'less than') {
      return parseFloat(studentVal) < parseFloat(value);
    }

    return true; // if unknown operator, let it pass
  });

  return newList;
}

const StudentGroups = ({ students, advisors }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // ---------------------------
  // 1) Group Creation: State
  // ---------------------------
  const [showCreatePanel, setShowCreatePanel] = useState(false);
  const [groupType, setGroupType] = useState('filter'); // 'filter' = dynamic, 'manual' = static
  const [groupName, setGroupName] = useState('');
  const [isPublic, setIsPublic] = useState(false); // track group visibility

  // For dynamic group creation
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);

  // For manual group creation
  const [manuallySelectedIds, setManuallySelectedIds] = useState([]);

  // ---------------------------
  // 2) Group Listing
  // ---------------------------
  const [groups, setGroups] = useState([]);
  const [groupSearch, setGroupSearch] = useState('');
  const [groupTypeFilter, setGroupTypeFilter] = useState('All'); // All | Dynamic | Static

  // Filter by ownership/visibility
  const [groupVisibilityFilter, setGroupVisibilityFilter] = useState('All'); 
  // "All" | "My Groups" | "Shared with Me"

  const [expandedGroupId, setExpandedGroupId] = useState(null);

  // ---------------------------
  // 3) Selection for group actions
  // ---------------------------
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);

  // Action modals
  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);

  // Dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // ----------------------------------------------------
  // A) Compute "fieldTypes" for the dynamic filter logic
  // ----------------------------------------------------
  const excludeFields = ['id', 'fromFirestore', 'surveyAnswers', 'name', 'email'];
  const fieldTypes = useMemo(() => {
    const types = {};
    if (students.length > 0) {
      const s = students[0];
      Object.keys(s).forEach((field) => {
        if (!excludeFields.includes(field)) {
          const value = s[field];
          if (Array.isArray(value)) {
            if (value.length > 0) {
              types[field] = `array-${typeof value[0]}`;
            } else {
              types[field] = 'array';
            }
          } else {
            // If you want numeric checks for GPA, etc.
            // or you might want to skip certain fields
            types[field] = typeof value;
          }
        }
      });
      // For tags
      if (s.surveyAnswers?.Tags) {
        types['tags'] = 'array';
      }
    }
    return types;
  }, [students]);

  // --------------------------------
  // B) Fetch Groups from Firestore
  // --------------------------------
  const fetchGroups = useCallback(async () => {
    if (!currentUser) return;
    try {
      const ref = collection(db, 'studentGroups');
      const qRef = query(ref, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(qRef);
      const loaded = snapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setGroups(loaded);
    } catch (error) {
      console.error('Error fetching groups:', error);
      toast.error('Failed to fetch groups. Please try again.');
    }
  }, [currentUser]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  // --------------------------------
  // C) Dynamic Filter Preview (for groupType === 'filter')
  // --------------------------------
  useEffect(() => {
    if (groupType !== 'filter') {
      setFilteredStudents([]);
      return;
    }

    let results = [...students];

    // 1) Name search
    if (searchTerm) {
      results = results.filter((s) =>
        s.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // 2) Grad Year
    if (filters.gradYear?.length) {
      results = results.filter((s) =>
        filters.gradYear.includes(s.surveyAnswers?.SchoolEnd?.year)
      );
    }

    // 3) Program
    if (filters.program?.length) {
      results = results.filter((s) => {
        if (Array.isArray(s.major)) {
          return s.major.some((prog) => filters.program.includes(prog));
        } else {
          return filters.program.includes(s.major);
        }
      });
    }

    // 4) Advisors 
    // If your new approach uses s.assignedAdvisorNames
    // then we do something like:
    if (filters.advisors?.length) {
      results = results.filter((s) =>
        s.assignedAdvisorNames?.some((advName) =>
          filters.advisors.includes(advName)
        )
      );
    }

    // 5) Persistence 
    // (replaces old performance/GPA logic)
    if (filters.persistence?.length) {
      results = results.filter((s) =>
        filters.persistence.includes(s.persistenceCategory)
      );
    }

    // 6) Tags
    if (filters.tags?.length) {
      results = results.filter((s) => {
        const studentTags = s.surveyAnswers?.Tags || [];
        return studentTags.some((tag) => filters.tags.includes(tag));
      });
    }

    // 7) Custom Filter(s)
    if (filters.customFilter?.field) {
      const { field, operator, value } = filters.customFilter;
      results = applyCustomFilter(results, { field, operator, value });
    } else if (filters.customFilters?.length) {
      filters.customFilters.forEach((cf) => {
        results = applyCustomFilter(results, cf);
      });
    }

    setFilteredStudents(results);
  }, [groupType, filters, searchTerm, students]);

  // --------------------------------
  // D) Manual (static) selection
  // --------------------------------
  const handleManualCheckbox = (studentId, checked) => {
    setManuallySelectedIds((prev) => {
      if (checked) return [...prev, studentId];
      return prev.filter((id) => id !== studentId);
    });
  };
  const isManuallySelected = (id) => manuallySelectedIds.includes(id);

  // --------------------------------
  // E) Create new group
  // --------------------------------
  const handleCreateGroup = async () => {
    if (!groupName.trim()) {
      toast.warning('Please enter a group name.');
      return;
    }
    if (!currentUser) {
      toast.error('You must be logged in.');
      return;
    }

    try {
      const ref = collection(db, 'studentGroups');

      if (groupType === 'filter') {
        const payload = {
          name: groupName.trim(),
          isDynamic: true,
          filterDefinition: filters,
          owner: currentUser.uid,
          isPublic,
          createdAt: new Date(),
        };
        await addDoc(ref, payload);
        toast.success(`Dynamic group "${groupName.trim()}" created!`);
      } else {
        if (manuallySelectedIds.length === 0) {
          toast.warning('Please select at least one student (manual).');
          return;
        }
        const payload = {
          name: groupName.trim(),
          isDynamic: false,
          studentIds: manuallySelectedIds,
          owner: currentUser.uid,
          isPublic,
          createdAt: new Date(),
        };
        await addDoc(ref, payload);
        toast.success(`Static group "${groupName.trim()}" created!`);
      }

      // Reset
      setShowCreatePanel(false);
      setGroupName('');
      setFilters({});
      setSearchTerm('');
      setManuallySelectedIds([]);
      setIsPublic(false);

      // Reload groups
      fetchGroups();
    } catch (error) {
      console.error('Error creating group:', error);
      toast.error('Failed to create group.');
    }
  };

  // --------------------------------
  // F) Filter group list
  // --------------------------------
  const filteredGroups = useMemo(() => {
    let list = [...groups];

    // 1) By group name
    if (groupSearch.trim()) {
      list = list.filter((g) =>
        g.name.toLowerCase().includes(groupSearch.toLowerCase())
      );
    }

    // 2) By type
    if (groupTypeFilter !== 'All') {
      const wantDynamic = groupTypeFilter === 'Dynamic';
      list = list.filter((g) => g.isDynamic === wantDynamic);
    }

    // 3) By ownership/visibility
    if (groupVisibilityFilter === 'My Groups') {
      list = list.filter((g) => g.owner === currentUser?.uid);
    } else if (groupVisibilityFilter === 'Shared with Me') {
      // Groups that are public AND not owned by me
      list = list.filter(
        (g) => g.isPublic && g.owner !== currentUser?.uid
      );
    }

    return list;
  }, [
    groups,
    groupSearch,
    groupTypeFilter,
    groupVisibilityFilter,
    currentUser
  ]);

  // --------------------------------
  // G) Toggle expand group
  // --------------------------------
  const toggleExpandGroup = (groupId) => {
    setExpandedGroupId((prev) => (prev === groupId ? null : groupId));
  };

  // --------------------------------
  // H) Apply dynamic filter to get members
  // --------------------------------
  const applyDynamicFilterToGroup = (groupDef) => {
    let results = [...students];
    const fDef = groupDef.filterDefinition || {};

    // 1) Grad Year
    if (fDef.gradYear?.length) {
      results = results.filter((s) =>
        fDef.gradYear.includes(s.surveyAnswers?.SchoolEnd?.year)
      );
    }

    // 2) Program
    if (fDef.program?.length) {
      results = results.filter((s) => {
        if (Array.isArray(s.major)) {
          return s.major.some((prog) => fDef.program.includes(prog));
        }
        return fDef.program.includes(s.major);
      });
    }

    // 3) Advisor
    if (fDef.advisors?.length) {
      results = results.filter((s) =>
        s.assignedAdvisorNames?.some((advName) =>
          fDef.advisors.includes(advName)
        )
      );
    }

    // 4) Persistence 
    if (fDef.persistence?.length) {
      results = results.filter((s) =>
        fDef.persistence.includes(s.persistenceCategory)
      );
    }

    // 5) Tags
    if (fDef.tags?.length) {
      results = results.filter((s) => {
        const studentTags = s.surveyAnswers?.Tags || [];
        return studentTags.some((tag) => fDef.tags.includes(tag));
      });
    }

    // 6) Custom filter(s)
    if (fDef.customFilter?.field) {
      const { field, operator, value } = fDef.customFilter;
      results = applyCustomFilter(results, { field, operator, value });
    } else if (fDef.customFilters?.length) {
      fDef.customFilters.forEach((cf) => {
        results = applyCustomFilter(results, cf);
      });
    }

    return results;
  };

  // --------------------------------
  // I) Group selection logic
  // --------------------------------
  const handleGroupCheckboxChange = (groupId, checked) => {
    setSelectedGroupIds((prev) => {
      if (checked) return [...prev, groupId];
      return prev.filter((id) => id !== groupId);
    });
  };
  const isGroupSelected = (id) => selectedGroupIds.includes(id);

  // "Select All" (based on currently filtered list)
  const handleSelectAllGroups = () => {
    const allFilteredIds = filteredGroups.map((g) => g.id);

    // Check if all are already selected
    const allSelected = allFilteredIds.every((id) =>
      selectedGroupIds.includes(id)
    );

    if (allSelected) {
      // Unselect all filtered
      const newSelections = selectedGroupIds.filter(
        (id) => !allFilteredIds.includes(id)
      );
      setSelectedGroupIds(newSelections);
    } else {
      // Select all
      const unique = new Set([...selectedGroupIds, ...allFilteredIds]);
      setSelectedGroupIds(Array.from(unique));
    }
  };

  const everythingSelected = useMemo(() => {
    if (filteredGroups.length === 0) return false;
    return filteredGroups.every((g) => selectedGroupIds.includes(g.id));
  }, [filteredGroups, selectedGroupIds]);

  // --------------------------------
  // J) Group Actions
  // --------------------------------
  const toggleTemplateModalVisibility = () => {
    if (selectedGroupIds.length === 0) {
      toast.warning('Please select at least one group.');
      return;
    }
    setIsTemplateModalVisible(!isTemplateModalVisible);
  };

  const handleCampaignClick = () => {
    if (selectedGroupIds.length === 0) {
      toast.warning('Please select at least one group.');
      return;
    }
    setIsCampaignModalOpen(true);
  };

  // For resources, gather all members from selected groups
  const handleResourcesClick = () => {
    if (selectedGroupIds.length === 0) {
      toast.warning('Please select at least one group.');
      return;
    }

    // Gather unique set of student IDs from all selected groups
    const allMemberIds = new Set();
    selectedGroupIds.forEach((gId) => {
      const gObj = groups.find((gg) => gg.id === gId);
      if (gObj) {
        if (gObj.isDynamic) {
          const members = applyDynamicFilterToGroup(gObj);
          members.forEach((m) => allMemberIds.add(m.id));
        } else {
          (gObj.studentIds || []).forEach((sid) => allMemberIds.add(sid));
        }
      }
    });

    if (allMemberIds.size === 0) {
      toast.warning('None of these groups have members.');
      return;
    }

    navigate('/resources', {
      state: { selectedStudentIds: Array.from(allMemberIds) },
    });
  };

  // For campaign modal, we need the actual student objects
  const selectedGroupsMembersData = useMemo(() => {
    const allMemberIds = new Set();
    selectedGroupIds.forEach((gId) => {
      const gObj = groups.find((gg) => gg.id === gId);
      if (gObj) {
        if (gObj.isDynamic) {
          const members = applyDynamicFilterToGroup(gObj);
          members.forEach((m) => allMemberIds.add(m.id));
        } else {
          (gObj.studentIds || []).forEach((sid) => allMemberIds.add(sid));
        }
      }
    });
    // Return entire student objects
    return students.filter((stu) => allMemberIds.has(stu.id));
  }, [selectedGroupIds, groups, students]);

  // Close campaign modal
  const closeCampaignModal = () => {
    setIsCampaignModalOpen(false);
  };

  // --------------------------------
  // Handle outside click for dropdown
  // --------------------------------
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // --------------------------------
  // RENDER
  // --------------------------------
  return (
    <div className="w-full">
      <div className="mx-auto">
        {/* A) GROUP LISTING FILTERS */}
        <div className="flex flex-col md:flex-row items-center gap-2 mb-2">
          <input
            type="text"
            placeholder="Search Groups by Name"
            value={groupSearch}
            onChange={(e) => setGroupSearch(e.target.value)}
            className="form-input mt-1 block w-52 rounded-md border-gray-300 shadow-sm 
                       focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
          <select
            value={groupTypeFilter}
            onChange={(e) => setGroupTypeFilter(e.target.value)}
            className="form-select mt-1 block w-32 rounded-md border-gray-300 shadow-sm 
                       focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          >
            <option value="All">All Types</option>
            <option value="Dynamic">Dynamic</option>
            <option value="Static">Static</option>
          </select>

          <select
            value={groupVisibilityFilter}
            onChange={(e) => setGroupVisibilityFilter(e.target.value)}
            className="form-select mt-1 block mb-4 w-36 rounded-md border-gray-300 
                       shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          >
            <option value="All">All Visibility</option>
            <option value="My Groups">My Groups</option>
            <option value="Shared with Me">Shared with Me</option>
          </select>
        </div>

        {/* B) GROUPS LISTING CONTAINER */}
        <div className="border border-gray-300 rounded bg-gray-50 p-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold text-gray-700">
              Student Groups
            </h2>
            <button
              onClick={() => setShowCreatePanel((prev) => !prev)}
              className="inline-flex items-center text-blue-600 font-medium 
                         hover:text-blue-800"
            >
              <FaPlus className="mr-2" />
              Create Group
            </button>
          </div>

          {/* C) CREATE GROUP PANEL */}
          {showCreatePanel && (
            <div className="relative border border-gray-300 rounded p-4 mb-4 bg-white">
              <button
                onClick={() => setShowCreatePanel(false)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                <FaTimes />
              </button>

              <h3 className="text-xl font-bold mb-3 border-b pb-2">
                Create New Group
              </h3>

              {/* Group Name + Visibility */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-medium">
                    Group Name:
                  </label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full rounded-md border-gray-300
                               shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm
                               px-2 py-1"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    placeholder="e.g. Junior Honors"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Visibility:
                  </label>
                  <div className="flex items-center gap-3 mt-1">
                    <label className="text-sm flex items-center">
                      <input
                        type="radio"
                        name="groupVisibility"
                        value="public"
                        checked={isPublic === true}
                        onChange={() => setIsPublic(true)}
                        className="mr-1"
                      />
                      Public
                    </label>
                    <label className="text-sm flex items-center">
                      <input
                        type="radio"
                        name="groupVisibility"
                        value="private"
                        checked={isPublic === false}
                        onChange={() => setIsPublic(false)}
                        className="mr-1"
                      />
                      Private
                    </label>
                  </div>
                </div>
              </div>

              {/* Group Type */}
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  Group Type:
                </label>
                <div className="flex items-center gap-4">
                  <label className="text-sm flex items-center">
                    <input
                      type="radio"
                      name="groupType"
                      value="filter"
                      checked={groupType === 'filter'}
                      onChange={() => setGroupType('filter')}
                      className="mr-1"
                    />
                    Filter (Dynamic)
                  </label>
                  <label className="text-sm flex items-center">
                    <input
                      type="radio"
                      name="groupType"
                      value="manual"
                      checked={groupType === 'manual'}
                      onChange={() => setGroupType('manual')}
                      className="mr-1"
                    />
                    Manual (Static)
                  </label>
                </div>
              </div>

              {/* If groupType === 'filter', show the new SearchComponent */}
              {groupType === 'filter' && (
                <div className="mb-4">
                  {/* We wrap the SearchComponent in a full-width container */}
                  <div className="bg-gray-50 border border-gray-200 rounded p-3">
                    <SearchComponent
                      students={students}
                      advisors={advisors}
                      fieldTypes={fieldTypes}
                      onSearch={setSearchTerm}
                      onFilter={setFilters}
                      onSelectAll={() => {}}
                      everythingSelected={false}
                      filteredStudentsCount={filteredStudents.length}
                      // If you have no "dashboardFilterType", pass null
                      dashboardFilterType={null}
                      dashboardFilterValue={null}
                    />
                  </div>
                  <p className="text-sm text-gray-600 mt-2">
                    Currently matches{' '}
                    <strong>{filteredStudents.length}</strong> students
                  </p>
                </div>
              )}

              {/* If groupType === 'manual', show a checkbox list of all students */}
              {groupType === 'manual' && (
                <div className="border border-gray-200 bg-gray-50 
                                rounded p-3 mb-4 max-h-64 overflow-y-auto">
                  {students.map((stu) => (
                    <div key={stu.id} className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        checked={isManuallySelected(stu.id)}
                        onChange={(e) =>
                          handleManualCheckbox(stu.id, e.target.checked)
                        }
                        className="mr-2 rounded"
                      />
                      <span className="text-sm">{stu.name}</span>
                    </div>
                  ))}
                </div>
              )}

              {/* Action buttons */}
              <div className="flex justify-end gap-2 pt-2 border-t mt-3">
                <button
                  onClick={() => setShowCreatePanel(false)}
                  className="border border-gray-300 text-gray-700 
                             hover:bg-gray-200 px-4 py-2 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCreateGroup}
                  className="bg-green-600 hover:bg-green-700 text-white 
                             px-4 py-2 rounded"
                >
                  Save Group
                </button>
              </div>
            </div>
          )}

          {/* D) GROUP ACTIONS BUTTON + SELECT ALL */}
          {filteredGroups.length > 0 && (
            <div className="flex items-center justify-between mb-2">
              <div className="w-auto">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white text-sm 
                             py-2 px-4 rounded"
                  onClick={handleSelectAllGroups}
                >
                  {everythingSelected
                    ? 'Unselect All Groups'
                    : 'Select All Groups'}
                </button>
              </div>

              {/* Group Actions Dropdown */}
              <div className="relative inline-block text-left" ref={dropdownRef}>
                <button
                  type="button"
                  onClick={() => {
                    if (selectedGroupIds.length > 0) {
                      setIsDropdownOpen(!isDropdownOpen);
                    }
                  }}
                  className={`
                    ${selectedGroupIds.length === 0
                      ? 'bg-blue-300 cursor-not-allowed'
                      : 'bg-blue-700 hover:bg-blue-800'
                    }
                    text-white focus:ring-4 focus:outline-none 
                    focus:ring-blue-300 font-medium rounded-lg text-sm 
                    px-3 py-2.5 inline-flex items-center
                  `}
                >
                  Group Actions
                  <svg
                    className="w-2.5 h-2.5 ml-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {isDropdownOpen && selectedGroupIds.length > 0 && (
                  <div
                    className="z-10 mt-2 bg-white rounded-lg shadow w-60 
                               absolute right-0"
                  >
                    <ul className="p-3 space-y-1 text-sm text-gray-700">
                      <li>
                        <button
                          onClick={() => {
                            setIsDropdownOpen(false);
                            toggleTemplateModalVisibility();
                          }}
                          className="flex flex-col p-2 rounded 
                                     hover:bg-gray-100 w-full text-left"
                        >
                          <span className="font-medium text-gray-900">
                            Templates
                          </span>
                          <span className="text-xs font-normal text-gray-500">
                            Manage multi-term planning templates.
                          </span>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            setIsDropdownOpen(false);
                            handleResourcesClick();
                          }}
                          className="flex flex-col p-2 rounded hover:bg-gray-100 
                                     w-full text-left"
                        >
                          <span className="font-medium text-gray-900">
                            Recommend Resource
                          </span>
                          <span className="text-xs font-normal text-gray-500">
                            Suggest a campus tool or service.
                          </span>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            setIsDropdownOpen(false);
                            handleCampaignClick();
                          }}
                          className="flex flex-col p-2 rounded hover:bg-gray-100 
                                     w-full text-left"
                        >
                          <span className="font-medium text-gray-900">
                            Send Campaign
                          </span>
                          <span className="text-xs font-normal text-gray-500">
                            Broadcast an email to these group members.
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* E) HEADER ROW FOR GROUP LIST */}
          {filteredGroups.length > 0 && (
            <div className="grid grid-cols-12 items-center font-semibold 
                            text-sm text-gray-500 mb-2 border-b pb-2">
              <div className="col-span-1 ml-2 text-left">
                Select
                <FontAwesomeIcon
                  icon={faSort}
                  style={{ paddingLeft: '3', fontSize: '10px' }}
                />
              </div>
              <div className="col-span-3 text-left">
                Group Name{' '}
                <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
              </div>
              <div className="col-span-2 text-center">
                Type{' '}
                <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
              </div>
              <div className="col-span-2 text-center">
                Members{' '}
                <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
              </div>
              <div className="col-span-2 text-center">
                Visibility{' '}
                <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
              </div>
              <div className="col-span-2 text-right pr-2">
                Action{' '}
                <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
              </div>
            </div>
          )}

          {/* F) GROUP ROWS */}
          {filteredGroups.length === 0 ? (
            <p className="text-gray-500 text-sm">No groups found.</p>
          ) : (
            filteredGroups.map((group) => {
              let members = [];
              if (group.isDynamic) {
                members = applyDynamicFilterToGroup(group);
              } else {
                const setIds = new Set(group.studentIds || []);
                members = students.filter((s) => setIds.has(s.id));
              }

              const count = members.length;
              const expanded = expandedGroupId === group.id;

              return (
                <GroupItem
                  key={group.id}
                  group={group}
                  count={count}
                  members={members}
                  isExpanded={expanded}
                  onToggle={toggleExpandGroup}
                  isSelected={isGroupSelected(group.id)}
                  onCheckboxChange={handleGroupCheckboxChange}
                />
              );
            })
          )}
        </div>
      </div>

      {/* G) CAMPAIGN MODAL */}
      {isCampaignModalOpen && (
        <CampaignModal
          onClose={closeCampaignModal}
          selectedStudents={selectedGroupsMembersData}
        />
      )}

      {/* H) TEMPLATE MODAL */}
      <TemplateModal
        isTemplateModalVisible={isTemplateModalVisible}
        setIsTemplateModalVisible={setIsTemplateModalVisible}
        // If your TemplateModal expects an array of student IDs:
        selectedStudents={selectedGroupsMembersData.map((s) => s.id)}
      />
    </div>
  );
};

export default StudentGroups;
