// DroppableSemester.jsx
import React, { useState, useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { BsThreeDots } from 'react-icons/bs';
import { FaPlus, FaMagic, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DraggableCourse from '../DraggableCourse'; // Adjust path if needed
import { useTranslation } from 'react-i18next';

const UpdatedDroppableSemester = ({
  yearNumber,
  semester, // Now expected to be a friendly term (e.g. "Fall", "Spring", "Summer", etc.)
  courses,
  isCompleted,
  onRemove,
  moveCourse,
  onAlternativesClick,
  onEvaluate,
  viewMode,
  prereqClasses,
  getTotalCreditsForSemester,
  handleDropdownToggle,
  dropdownOpen,
  completedSemesters,
  getYearAndSemesterName,
  hasCoursesInSemester,
  shouldShowMarkCompleteButton,
  handleToggleSemesterComplete,
  handleAddMilestone,
  handleRemoveSemesterCourses,
  handleAutofillCourse,
  setShowSidebar,
  buttonRefs,
  activeTab,
  dropdownRefs,
  isDragging,
  isAutofilling,
  setIsAutofilling,
  yearRefs,
}) => {
  console.log('YSEM: ', yearNumber, semester);
  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [placeholderHeight, setPlaceholderHeight] = useState(0);
  const [draggedCourse, setDraggedCourse] = useState(null);

  const semesterRef = useRef(null);
  const courseRefs = useRef([]);
  const { t } = useTranslation();

  // Auto-scrolling variables
  const scrollRef = useRef(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);

  // Set up drop behavior
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'course',
    canDrop: (item) => !isCompleted,
    hover(item, monitor) {
      setDraggedCourse(item);
      if (!semesterRef.current) return;

      const hoverBoundingRect = semesterRef.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      let newPlaceholderIndex = courses.length;

      for (let i = 0; i < courses.length; i++) {
        const courseRef = courseRefs.current[i];
        if (courseRef?.current) {
          const courseRect = courseRef.current.getBoundingClientRect();
          const courseMiddleY =
            courseRect.top + courseRect.height / 2 - hoverBoundingRect.top;
          if (hoverClientY < courseMiddleY) {
            newPlaceholderIndex = i;
            break;
          }
        }
      }

      // Update placeholder index
      if (placeholderIndex !== newPlaceholderIndex) {
        setPlaceholderIndex(newPlaceholderIndex);
      }

      // Update placeholder height based on dragged course’s height
      if (item.height && placeholderHeight !== item.height) {
        setPlaceholderHeight(item.height);
      }

      // Auto-scrolling logic
      const scrollContainer = scrollRef.current;
      if (scrollContainer) {
        const scrollThreshold = 50; // Distance from top/bottom to start scrolling
        const scrollSpeed = 10;     // Adjust scroll speed here
        if (hoverClientY < scrollThreshold) {
          scrollContainer.scrollTop -= scrollSpeed;
          setIsAutoScrolling(true);
        } else if (hoverClientY > scrollContainer.clientHeight - scrollThreshold) {
          scrollContainer.scrollTop += scrollSpeed;
          setIsAutoScrolling(true);
        } else {
          setIsAutoScrolling(false);
        }
      }
    },
    drop(item, monitor) {
      if (!monitor.didDrop()) {
        moveCourse(
          item.course,
          item.fromYear,
          item.fromSemester,
          item.index,
          yearNumber,
          semester,
          placeholderIndex !== null ? placeholderIndex : courses.length
        );
      }
      setDraggedCourse(null);
      setPlaceholderIndex(null);
      setPlaceholderHeight(0);
    },
    collect: (monitor) => {
      if (!monitor.isOver()) {
        setPlaceholderIndex(null);
        setPlaceholderHeight(0);
        setIsAutoScrolling(false);
      }
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  // Immediately place a placeholder where the course originally was
  // if it’s dragged within the same semester.
  useEffect(() => {
    if (
      draggedCourse &&
      draggedCourse.fromYear === yearNumber &&
      draggedCourse.fromSemester === semester
    ) {
      setPlaceholderIndex(draggedCourse.index);
      setPlaceholderHeight(draggedCourse.height || 0);
    }
  }, [draggedCourse, yearNumber, semester]);

  drop(semesterRef);

  // Assign refs to each course
  const assignCourseRef = (idx) => {
    if (!courseRefs.current[idx]) {
      courseRefs.current[idx] = React.createRef();
    }
    return courseRefs.current[idx];
  };

  const handleDragEnd = () => {
    setDraggedCourse(null);
    setPlaceholderIndex(null);
    setPlaceholderHeight(0);
  };

  console.log('Semester: ', semester);

  // For displaying the year label.
  // Now we assume getYearAndSemesterName simply returns the calendar year for a given yearNumber.
  const finalLabel = t('planPage.termYearFormat', {
    term: semester,
    year: getYearAndSemesterName(yearNumber),
  });
  // Alternatively, if you prefer a simpler label:
  // const finalLabel = `${semester} ${getYearAndSemesterName(yearNumber)}`;

  // Determine alignment of the footer buttons
  const buttonCount =
    1 + (yearNumber <= 4 ? 1 : 0) + (hasCoursesInSemester(yearNumber, semester) ? 1 : 0);
  let alignmentClass = 'justify-end';
  if (viewMode === 'vertical') {
    if (buttonCount === 3) alignmentClass = 'justify-center';
    else if (buttonCount === 2) alignmentClass = 'justify-end';
  }

  const isActive = isOver && canDrop;
  const isInvalid = isOver && !canDrop;

  return (
    <div ref={semesterRef} className="relative semester-container group">
      <div className="relative overflow-visible">
        {/* Outline if valid drop */}
        {isActive && (
          <div className="absolute inset-0 border-[1.5px] border-blue-500 rounded-lg pointer-events-none z-10" />
        )}
        {/* Outline if invalid drop */}
        {isInvalid && (
          <div className="absolute inset-0 border-[1.5px] border-red-500 rounded-lg pointer-events-none z-10" />
        )}

        <div
          className={`border-solid border-1 ${viewMode === 'horizontal' ? 'p-2.5' : ''} rounded-lg border-gray-250 ${isCompleted ? 'bg-edvise' : ''}`}
          style={
            viewMode === 'vertical'
              ? {
                  height: '32rem',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }
              : {}
          }
        >
          {/* Header */}
          <div
            className={`flex-none flex items-center justify-between ${
              viewMode === 'vertical'
                ? 'mb-1 bg-gray-50 border-b border-gray-300 p-1.5 w-full'
                : 'mb-2'
            }`}
          >
            <h2 className={`year-heading ${viewMode === 'vertical' ? 'text-sm' : 'text-base'} font-medium`}>
              {finalLabel}
            </h2>
            <div className="flex items-center space-x-2">
              <span className="bg-purple-100 text-purple-800 text-xs font-medium px-1 py-0.25 rounded border border-purple-400">
                {getTotalCreditsForSemester(courses)} {t('planPage.credits', 'Credits')}
              </span>
              <div className="relative inline-block text-left" ref={(el) => (dropdownRefs.current[`${yearNumber}-${semester}`] = el)}>
                <button
                  id={`dropdownButton-${yearNumber}-${semester}`}
                  className="p-0 m-0 border-0 shadow-none text-gray-500 hover:text-gray-700"
                  onClick={() => handleDropdownToggle(yearNumber, semester)}
                >
                  <BsThreeDots className="w-5 h-5 mt-1.5" />
                </button>
                <div
                  id={`dropdown-${yearNumber}-${semester}`}
                  className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ${dropdownOpen[`${yearNumber}-${semester}`] ? '' : 'hidden'}`}
                  style={{ left: '-9.25rem' }}
                >
                  <ul className="py-2 text-sm text-gray-700" aria-labelledby={`dropdownButton-${yearNumber}-${semester}`}>
                    <li>
                      <button
                        onClick={() => handleAddMilestone(yearNumber, semester)}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      >
                        {t('planPage.milestones', 'Milestones')}
                      </button>
                    </li>
                    {hasCoursesInSemester(yearNumber, semester) &&
                      shouldShowMarkCompleteButton(yearNumber, semester) && (
                        <li>
                          <button
                            onClick={() => handleToggleSemesterComplete(yearNumber, semester)}
                            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                          >
                            {completedSemesters[`year${yearNumber}`]?.[semester]
                              ? t('planPage.markIncomplete', 'Mark Incomplete')
                              : t('planPage.markComplete', 'Mark Complete')}
                          </button>
                        </li>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Courses */}
          <div
            ref={scrollRef}
            className={`${viewMode === 'vertical' ? 'flex-1' : ''} overflow-y-auto custom-scrollbar relative p-1.5`}
            style={{
              maxHeight: viewMode === 'horizontal' ? '25rem' : undefined,
              overflowX: 'hidden',
              position: 'relative',
              zIndex: 0,
            }}
          >
            {courses.length === 0 && placeholderIndex === null ? (
              <span
                className={`bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded ${viewMode === 'vertical' ? 'flex items-center justify-center' : ''}`}
              >
                {t('planPage.noCoursesPlanned', 'No Courses Planned')}
              </span>
            ) : (
              <>
                {/* Courses before placeholder */}
                {courses
                  .filter((c) => {
                    // Don't render the “dragged” course itself in the list
                    return !(
                      draggedCourse &&
                      draggedCourse.fromYear === yearNumber &&
                      draggedCourse.fromSemester === semester &&
                      draggedCourse.course.courseInfo_courseNumber === c.courseInfo_courseNumber
                    );
                  })
                  .slice(0, placeholderIndex !== null ? placeholderIndex : courses.length)
                  .map((course, idx) => (
                    <DraggableCourse
                      key={course.courseInfo_courseNumber}
                      id={`course-${yearNumber}-${semester}-${idx}`}
                      course={course}
                      index={idx}
                      setShowSidebar={setShowSidebar}
                      yearNumber={yearNumber}
                      semester={semester}
                      onRemove={(cls) => onRemove(cls, yearNumber, semester)}
                      moveCourse={moveCourse}
                      onAlternativesClick={onAlternativesClick}
                      isCompleted={isCompleted}
                      onEvaluate={onEvaluate}
                      viewMode={viewMode}
                      prereqClasses={prereqClasses}
                      ref={assignCourseRef(idx)}
                      onDragEnd={handleDragEnd}
                      style={
                        viewMode === 'vertical'
                          ? {
                              overflow: 'visible',
                              margin: '0 auto',
                              maxWidth: 'calc(100% - 10px)',
                              position: 'relative',
                              zIndex: 1,
                            }
                          : {}
                      }
                    />
                  ))}
                {/* Placeholder */}
                {placeholderIndex !== null && (
                  <div
                    className="course-placeholder"
                    style={{
                      height: `${placeholderHeight}px`,
                      backgroundColor: '#e0e0e0',
                      margin: '5px 0',
                      borderRadius: '5px',
                    }}
                  />
                )}
                {/* Courses after placeholder */}
                {courses
                  .filter((c) => {
                    return !(
                      draggedCourse &&
                      draggedCourse.fromYear === yearNumber &&
                      draggedCourse.fromSemester === semester &&
                      draggedCourse.course.courseInfo_courseNumber === c.courseInfo_courseNumber
                    );
                  })
                  .slice(placeholderIndex !== null ? placeholderIndex : courses.length)
                  .map((course, idx) => {
                    const adjustedIndex = idx + (placeholderIndex ?? 0);
                    return (
                      <DraggableCourse
                        key={course.courseInfo_courseNumber}
                        id={`course-${yearNumber}-${semester}-${adjustedIndex}`}
                        course={course}
                        index={adjustedIndex}
                        setShowSidebar={setShowSidebar}
                        yearNumber={yearNumber}
                        semester={semester}
                        onRemove={(cls) => onRemove(cls, yearNumber, semester)}
                        moveCourse={moveCourse}
                        onAlternativesClick={onAlternativesClick}
                        isCompleted={isCompleted}
                        onEvaluate={onEvaluate}
                        viewMode={viewMode}
                        prereqClasses={prereqClasses}
                        ref={assignCourseRef(adjustedIndex)}
                        onDragEnd={handleDragEnd}
                        style={
                          viewMode === 'vertical'
                            ? {
                                overflow: 'visible',
                                margin: '0 auto',
                                maxWidth: 'calc(100% - 10px)',
                                position: 'relative',
                                zIndex: 1,
                              }
                            : {}
                        }
                      />
                    );
                  })}
              </>
            )}
          </div>

          {/* Footer Section */}
          {!isCompleted && (
            <div
              className={`${
                viewMode === 'vertical'
                  ? `flex-none hidden group-hover:flex transition-opacity duration-300 ${alignmentClass}`
                  : 'flex justify-end'
              } mt-2 bg-white py-2`}
            >
              <div className="flex space-x-2 add-autofill-container">
                {/* Add Button */}
                <button
                  ref={(el) => buttonRefs.current.push(el)}
                  className="text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center add-btn"
                >
                  <Link
                    to={{
                      pathname: '/Search',
                      search: `?yearNumber=${yearNumber}&semester=${semester}`,
                    }}
                  >
                    <FaPlus className="inline mb-0.5" /> {t('planPage.add', 'Add')}
                  </Link>
                </button>

                {/* Autofill Button (only up to year 4) */}
                {yearNumber <= 4 && (
                  <>
                    <div className="border-gray-300 border-l h-5 inline-block mt-[5px]" />
                    <button
                      ref={(el) => buttonRefs.current.push(el)}
                      onClick={() => handleAutofillCourse(yearNumber, semester)}
                      className={`text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn ${
                        !hasCoursesInSemester(yearNumber, semester) ? 'mr-2' : ''
                      }`}
                    >
                      <FaMagic className="inline mr-1 mb-0.5" /> {t('planPage.autofill', 'Autofill')}
                    </button>
                  </>
                )}
              </div>

              {/* Clear Button */}
              {hasCoursesInSemester(yearNumber, semester) && (
                <>
                  <div className="border-gray-300 ml-2 border-l h-5 inline-block mt-[5px] mr-[2px]" />
                  <button
                    ref={(el) => buttonRefs.current.push(el)}
                    onClick={() => handleRemoveSemesterCourses(yearNumber, semester)}
                    className="mr-1 text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn"
                  >
                    <FaTrash className="inline mr-0.5 mb-0.5 ml-0.5" /> {t('planPage.clear', 'Clear')}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdatedDroppableSemester;
