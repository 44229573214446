// Filename: AssignRole.jsx (or AssignRole.js)
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import firebase from 'firebase/compat/app';
import { db } from '../../firebase';
import {
  Button,
  TextInput,
  Spinner,
  Select,
  Label,
  Checkbox,
} from 'flowbite-react';
import { toast } from 'react-toastify';
import CustomSidebar from '../Sidebar/Sidebar';

const PAGE_SIZE = 10; // For pagination

const AssignRole = () => {
  const { currentUser } = useAuth();

  // ----------------------------------
  // TABS - Manage active tab via React state
  // ----------------------------------
  const [activeTab, setActiveTab] = useState('role');

  // ----------------------------------
  // States for Role Assignment (Tab 1)
  // ----------------------------------
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // ---------------------------------------------------
  // States for Assigning Students to Advisors (Tab 2)
  // + Basic Pagination & Search for the student list
  // ---------------------------------------------------
  const [students, setStudents] = useState([]);
  const [advisorsList, setAdvisorsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedStudent, setSelectedStudent] = useState('');
  const [selectedAdvisors, setSelectedAdvisors] = useState([]); // multiple advisors
  const [assignMessage, setAssignMessage] = useState('');
  const [assignLoading, setAssignLoading] = useState(false);

  // ---------------------------------------------
  // Optional: Meeting Notes (not specifically used, but left from original)
  // ---------------------------------------------
  const [meetingNotes, setMeetingNotes] = useState('');

  // ----------------------------------------------------------
  // State and logic for Assigned Advisors View (Tab 3)
  // + Pagination for assigned advisors
  // ----------------------------------------------------------
  const [studentAssignments, setStudentAssignments] = useState({});
  const [loadingAssignments, setLoadingAssignments] = useState(true);
  const [assignedCurrentPage, setAssignedCurrentPage] = useState(1);

  // ----------------------------------------------------------
  // State for Transfer Credits (Tab 4)
  // (Retained exactly from your original code)
  // ----------------------------------------------------------
  const [transferLoading, setTransferLoading] = useState(false);

  // Hardcoded transfer data (original code)
  const transferData = {
    transferClasses: [
      {
        term: 'Fall 2023',
        equivalentCourse: 'MATH 150',
        credits: 3,
        institution: 'SFU',
        grade: 'A',
        incomingCourse: 'MAT 216',
        sourceInstitution: 'Arapahoe Cmty College',
      },
      {
        term: 'Spring 2023',
        equivalentCourse: 'MATH 151',
        credits: 3,
        institution: 'SFU',
        grade: 'B',
        incomingCourse: 'MAT 217',
        sourceInstitution: 'Arapahoe Cmty College',
      },
    ],
    testClasses: [
      {
        term: 'Fall 2023',
        equivalentCourse: 'ENGL 101W',
        credits: 3,
        institution: 'SFU',
        testId: 'SAT',
        testComponent: 'ERWS',
        score: 770,
      },
      {
        term: 'Spring 2024',
        equivalentCourse: 'BISC 100',
        credits: 3,
        institution: 'SFU',
        testId: 'AP Biology',
        testComponent: 'N/A',
        score: 5,
      },
    ],
    otherClasses: [
      {
        term: 'Spring 2024',
        equivalentCourse: 'MATH 152',
        credits: 3,
        institution: 'SFU',
        grade: 'A-',
        description: 'Advanced mathematics course',
      },
    ],
  };

  // -------------------------------------------------------------------
  // Fetch Students and Advisors (Tabs 1 & 2) and assigned advisors (Tab 3)
  // -------------------------------------------------------------------
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Fetch Students
        const studentsSnapshot = await db
          .collection('users')
          .where('role', '==', 'student')
          .get();

        const studentsData = studentsSnapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        setStudents(studentsData);

        // Fetch Advisors
        const advisorsSnapshot = await db
          .collection('users')
          .where('role', '==', 'advisor')
          .get();

        const advisorsData = advisorsSnapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        setAdvisorsList(advisorsData);
      } catch (error) {
        console.error('Error fetching users:', error);
        toast.error('Failed to fetch users.');
      }
    };

    const fetchAssignedAdvisors = async () => {
      setLoadingAssignments(true);
      try {
        const promises = students.map((stu) =>
          db.collection('studentAdvisors').doc(stu.uid).get()
        );
        const docs = await Promise.all(promises);

        const map = {};
        for (let i = 0; i < docs.length; i++) {
          const studentId = students[i]?.uid;
          if (studentId && docs[i].exists) {
            map[studentId] = docs[i].data().advisorIds || [];
          } else {
            map[studentId] = [];
          }
        }
        setStudentAssignments(map);
      } catch (error) {
        console.error('Error fetching assigned advisors:', error);
        toast.error('Failed to fetch assigned advisors.');
      }
      setLoadingAssignments(false);
    };

    // Only Admins & Super Admins proceed
    if (currentUser && (currentUser.role === 'admin' || currentUser.role === 'super_admin')) {
      fetchUsers().then(() => {
        // Slight delay so 'students' is populated before we fetch assignments
        setTimeout(fetchAssignedAdvisors, 500);
      });
    }
  }, [currentUser, students.length]);

  // ---------------------------------------------------------
  // TAB 1: Assign Role to User
  // ---------------------------------------------------------
  const assignRole = async () => {
    if (!email || !role || !firstName || !lastName) {
      setMessage('Please fill in all fields.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      // Call the Firebase Cloud Function to set custom claims
      const setCustomClaims = firebase.functions().httpsCallable('setCustomClaims');
      const result = await setCustomClaims({ email, role, firstName, lastName });
      setMessage(result.data.message);

      // Force token refresh to apply new claims
      await firebase.auth().currentUser.getIdToken(true);

      // If role is 'advisor', create a document in 'advisors' collection
      if (role === 'advisor') {
        const usersSnapshot = await db.collection('users').where('email', '==', email).get();
        if (!usersSnapshot.empty) {
          const userDoc = usersSnapshot.docs[0];
          const advisorUID = userDoc.id;

          const advisorDocRef = db.collection('advisors').doc(advisorUID);
          const advisorDoc = await advisorDocRef.get();

          if (!advisorDoc.exists) {
            await advisorDocRef.set({
              advisorId: advisorUID,
              firstName,
              lastName,
              email,
              assignedStudents: [],
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });

            toast.success('Advisor added to advisors collection successfully.');
          } else {
            toast.info('Advisor already exists in advisors collection.');
          }
        } else {
          toast.error('User not found in users collection.');
        }
      }

      // Reset form fields
      setEmail('');
      setRole('');
      setFirstName('');
      setLastName('');
    } catch (error) {
      console.error('Error assigning role:', error);
      setMessage(`Error assigning role: ${error.message}`);
      toast.error(`Error assigning role: ${error.message}`);
    }

    setLoading(false);
  };

  // ------------------------------------------------------------------
  // TAB 2: Assign Student to Advisors (with pagination + search)
  // ------------------------------------------------------------------
  const filteredStudents = students.filter((student) => {
    const query = searchTerm.toLowerCase();
    const fullName = `${student.firstName} ${student.lastName}`.toLowerCase();
    return (
      fullName.includes(query) ||
      student.email?.toLowerCase().includes(query)
    );
  });
  const totalPages = Math.ceil(filteredStudents.length / PAGE_SIZE);

  const currentStudentsPage = filteredStudents.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  const assignStudentToAdvisors = async () => {
    if (!selectedStudent || selectedAdvisors.length === 0) {
      setAssignMessage('Please select a student and at least one advisor.');
      return;
    }

    setAssignLoading(true);
    setAssignMessage('');

    try {
      const mappingDocRef = db.collection('studentAdvisors').doc(selectedStudent);
      const mappingDoc = await mappingDocRef.get();

      if (mappingDoc.exists) {
        const existingAdvisors = mappingDoc.data().advisorIds || [];
        const advisorsToAdd = selectedAdvisors.filter(
          (advisorId) => !existingAdvisors.includes(advisorId)
        );

        if (advisorsToAdd.length === 0) {
          setAssignMessage('Selected advisors are already assigned to this student.');
          toast.info('No new advisors to assign.');
          setAssignLoading(false);
          return;
        }

        // Update studentAdvisors doc
        await mappingDocRef.update({
          advisorIds: firebase.firestore.FieldValue.arrayUnion(...advisorsToAdd),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        // Update advisors collection
        const updateAdvisorPromises = advisorsToAdd.map((advisorId) =>
          db.collection('advisors').doc(advisorId).update({
            assignedStudents: firebase.firestore.FieldValue.arrayUnion(selectedStudent),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
        );

        await Promise.all(updateAdvisorPromises);

        setAssignMessage('Advisors assigned to student successfully.');
        toast.success('Advisors assigned to student successfully.');
      } else {
        // Create new mapping doc
        await mappingDocRef.set({
          studentId: selectedStudent,
          advisorIds: selectedAdvisors,
          assignedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

        // Update advisors collection
        const updateAdvisorPromises = selectedAdvisors.map((advisorId) =>
          db.collection('advisors').doc(advisorId).update({
            assignedStudents: firebase.firestore.FieldValue.arrayUnion(selectedStudent),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
        );
        await Promise.all(updateAdvisorPromises);

        setAssignMessage('Advisors assigned to student successfully.');
        toast.success('Advisors assigned to student successfully.');
      }

      // Reset selection
      setSelectedStudent('');
      setSelectedAdvisors([]);
    } catch (error) {
      console.error('Error assigning advisors to student:', error);
      setAssignMessage(`Error assigning advisors to student: ${error.message}`);
      toast.error(`Error assigning advisors to student: ${error.message}`);
    }

    setAssignLoading(false);
  };

  // ----------------------------------------------------------------
  // TAB 3: Assigned Advisors (with pagination)
  // ----------------------------------------------------------------
  const removeAdvisorFromStudent = async (studentId, advisorId) => {
    try {
      await db
        .collection('studentAdvisors')
        .doc(studentId)
        .update({
          advisorIds: firebase.firestore.FieldValue.arrayRemove(advisorId),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

      await db
        .collection('advisors')
        .doc(advisorId)
        .update({
          assignedStudents: firebase.firestore.FieldValue.arrayRemove(studentId),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

      toast.success('Advisor removed from student successfully.');
      // Update local state
      setStudentAssignments((prev) => ({
        ...prev,
        [studentId]: prev[studentId]?.filter((id) => id !== advisorId),
      }));
    } catch (error) {
      console.error('Error removing advisor from student:', error);
      toast.error(`Error removing advisor: ${error.message}`);
    }
  };

  const assignedTotalPages = Math.ceil(students.length / PAGE_SIZE);
  const assignedStudentsPage = students.slice(
    (assignedCurrentPage - 1) * PAGE_SIZE,
    assignedCurrentPage * PAGE_SIZE
  );

  // ---------------------------------------------------
  // TAB 4: Assign Transfer Credits (original code)
  // ---------------------------------------------------
  const assignTransferCredits = async () => {
    if (!selectedStudent) {
      setAssignMessage('Please select a student.');
      return;
    }

    setTransferLoading(true);
    setAssignMessage('');

    try {
      const studentDocRef = db.collection('users').doc(selectedStudent);
      const studentDoc = await studentDocRef.get();

      if (!studentDoc.exists) {
        setAssignMessage('Student not found.');
        toast.error('Student not found.');
        setTransferLoading(false);
        return;
      }

      await studentDocRef.update({
        transferData: transferData,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      setAssignMessage('Transfer credits assigned successfully.');
      toast.success('Transfer credits assigned successfully.');
      setSelectedStudent('');
    } catch (error) {
      console.error('Error assigning transfer credits:', error);
      setAssignMessage(`Error assigning transfer credits: ${error.message}`);
      toast.error(`Error assigning transfer credits: ${error.message}`);
    }

    setTransferLoading(false);
  };

  // Restrict access if not admin
  if (!currentUser || (currentUser.role !== 'admin' && currentUser.role !== 'super_admin')) {
    return (
      <div className="p-6">
        <CustomSidebar />
        <p className="text-red-500 mt-4">You do not have permission to view this page.</p>
      </div>
    );
  }

  // -----------------------------------
  // MAIN RENDER
  // -----------------------------------
  return (
    <div className="assign-role p-6 bg-white rounded-lg shadow-md">
      <CustomSidebar />

      {/* Top Tab Bar (Similar to your Staff.js or StudentsPage.jsx) */}
      <div className="border-b border-gray-200 dark:border-gray-700 mb-4">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" role="tablist">
          {/* Tab 1: Assign Role */}
          <li className="mr-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors ${
                activeTab === 'role'
                  ? 'text-blue-700 border-blue-700'
                  : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300'
              }`}
              onClick={() => setActiveTab('role')}
              type="button"
              role="tab"
            >
              Assign Role
            </button>
          </li>

          {/* Tab 2: Assign Student to Advisors */}
          <li className="mr-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors ${
                activeTab === 'studentAdvisor'
                  ? 'text-blue-700 border-blue-700'
                  : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300'
              }`}
              onClick={() => setActiveTab('studentAdvisor')}
              type="button"
              role="tab"
            >
              Assign Student to Advisors
            </button>
          </li>

          {/* Tab 3: Assigned Advisors */}
          <li className="mr-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors ${
                activeTab === 'assignedAdvisors'
                  ? 'text-blue-700 border-blue-700'
                  : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300'
              }`}
              onClick={() => setActiveTab('assignedAdvisors')}
              type="button"
              role="tab"
            >
              Assigned Advisors
            </button>
          </li>

          {/* Tab 4: Transfer Credits */}
          <li className="mr-2" role="presentation">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors ${
                activeTab === 'transferCredits'
                  ? 'text-blue-700 border-blue-700'
                  : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300'
              }`}
              onClick={() => setActiveTab('transferCredits')}
              type="button"
              role="tab"
            >
              Transfer Credits
            </button>
          </li>
        </ul>
      </div>

      {/* TAB CONTENT */}
      <div className="mt-4">
        {/* TAB 1: Assign Role */}
        {activeTab === 'role' && (
          <div role="tabpanel">
            <h2 className="text-2xl font-semibold mb-4">Assign Role to User</h2>
            <div className="space-y-4">
              {/* Email Input */}
              <div>
                <Label htmlFor="email" value="User Email" />
                <TextInput
                  id="email"
                  type="email"
                  placeholder="user@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              {/* First Name */}
              <div>
                <Label htmlFor="firstName" value="First Name" />
                <TextInput
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>

              {/* Last Name */}
              <div>
                <Label htmlFor="lastName" value="Last Name" />
                <TextInput
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>

              {/* Role Selection */}
              <div>
                <Label htmlFor="role" value="Role" />
                <Select
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Role
                  </option>
                  <option value="student">Student</option>
                  <option value="advisor">Advisor</option>
                  <option value="admin">Admin</option>
                  <option value="super_admin">Super Admin</option>
                </Select>
              </div>

              {/* Button */}
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 
                           focus:ring-4 focus:ring-blue-300 font-medium 
                           rounded-lg text-sm px-5 py-2.5 me-2 mb-2
                           dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={assignRole}
                disabled={loading}
              >
                {loading ? <Spinner aria-label="Assigning role" size="sm" /> : 'Assign Role'}
              </button>

              {/* Feedback Message */}
              {message && <p className="mt-2 text-sm text-gray-600">{message}</p>}
            </div>
          </div>
        )}

        {/* TAB 2: Assign Student to Advisors */}
        {activeTab === 'studentAdvisor' && (
          <div role="tabpanel">
            <h2 className="text-2xl font-semibold mb-4">Assign Student to Advisors</h2>

            {/* Search Students */}
            <div className="mb-4">
              <Label htmlFor="searchTerm" value="Search Students" />
              <TextInput
                id="searchTerm"
                type="text"
                placeholder="Search by name or email..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </div>

            {/* Pagination Controls */}
            <div className="flex items-center justify-between mb-2">
              <p className="text-sm text-gray-500">
                Page {currentPage} of {totalPages || 1}
              </p>
              <div>
                <button
                  onClick={() => setCurrentPage((p) => Math.max(p - 1, 1))}
                  className="mr-2 text-sm text-blue-600 disabled:opacity-50"
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  onClick={() => setCurrentPage((p) => Math.min(p + 1, totalPages))}
                  className="text-sm text-blue-600 disabled:opacity-50"
                  disabled={currentPage === totalPages || totalPages === 0}
                >
                  Next
                </button>
              </div>
            </div>

            {/* Student Select */}
            <div>
              <Label htmlFor="studentSelect" value="Select Student" />
              <Select
                id="studentSelect"
                value={selectedStudent}
                onChange={(e) => setSelectedStudent(e.target.value)}
                required
              >
                <option value="" disabled>
                  Select Student
                </option>
                {currentStudentsPage.map((student) => (
                  <option key={student.uid} value={student.uid}>
                    {student.firstName} {student.lastName} ({student.email})
                  </option>
                ))}
              </Select>
            </div>

            {/* Advisor Checklist */}
            {selectedStudent && (
              <div className="mt-4">
                <Label value="Select Advisors" />
                <div className="flex flex-col space-y-2 max-h-48 overflow-y-auto border rounded p-2">
                  {advisorsList.map((advisor) => (
                    <div key={advisor.uid} className="flex items-center">
                      <Checkbox
                        id={`advisor-${advisor.uid}`}
                        checked={selectedAdvisors.includes(advisor.uid)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedAdvisors([...selectedAdvisors, advisor.uid]);
                          } else {
                            setSelectedAdvisors(
                              selectedAdvisors.filter((id) => id !== advisor.uid)
                            );
                          }
                        }}
                      />
                      <label
                        htmlFor={`advisor-${advisor.uid}`}
                        className="ml-2 text-sm font-medium text-gray-700"
                      >
                        {advisor.firstName} {advisor.lastName} ({advisor.email})
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Assign Advisors Button */}
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 
                         focus:ring-4 focus:ring-blue-300 font-medium 
                         rounded-lg text-sm px-5 py-2.5 me-2 mt-4
                         dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={assignStudentToAdvisors}
              disabled={assignLoading}
            >
              {assignLoading ? (
                <Spinner aria-label="Assigning advisors" size="sm" />
              ) : (
                'Assign Advisors'
              )}
            </button>

            {/* Feedback */}
            {assignMessage && <p className="mt-2 text-sm text-gray-600">{assignMessage}</p>}
          </div>
        )}

        {/* TAB 3: Assigned Advisors */}
        {activeTab === 'assignedAdvisors' && (
          <div role="tabpanel">
            <h2 className="text-2xl font-semibold mb-4">Assigned Advisors</h2>

            {/* Pagination Controls for the entire student list */}
            <div className="flex items-center justify-between mb-2">
              <p className="text-sm text-gray-500">
                Page {assignedCurrentPage} of {assignedTotalPages || 1}
              </p>
              <div>
                <button
                  onClick={() => setAssignedCurrentPage((p) => Math.max(p - 1, 1))}
                  className="mr-2 text-sm text-blue-600 disabled:opacity-50"
                  disabled={assignedCurrentPage === 1}
                >
                  Previous
                </button>
                <button
                  onClick={() => setAssignedCurrentPage((p) => Math.min(p + 1, assignedTotalPages))}
                  className="text-sm text-blue-600 disabled:opacity-50"
                  disabled={assignedCurrentPage === assignedTotalPages || assignedTotalPages === 0}
                >
                  Next
                </button>
              </div>
            </div>

            {loadingAssignments ? (
              <Spinner aria-label="Loading assigned advisors" />
            ) : (
              assignedStudentsPage.map((student) => {
                const assignedAdvisors = studentAssignments[student.uid] || [];
                return (
                  <div key={student.uid} className="p-4 border rounded-lg mb-4">
                    <h3 className="text-xl font-semibold mb-2">
                      {student.firstName} {student.lastName} ({student.email})
                    </h3>

                    {assignedAdvisors.length === 0 ? (
                      <p className="text-sm text-gray-500">No advisors assigned.</p>
                    ) : (
                      <div className="space-y-2">
                        {assignedAdvisors.map((advisorId) => (
                          <AssignedAdvisorItem
                            key={advisorId}
                            studentId={student.uid}
                            advisorId={advisorId}
                            removeAdvisorFromStudent={removeAdvisorFromStudent}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        )}

        {/* TAB 4: Assign Transfer Credits */}
        {activeTab === 'transferCredits' && (
          <div role="tabpanel">
            <h2 className="text-2xl font-semibold mb-4">Assign Transfer Credits</h2>
            <div className="space-y-4">
              {/* Select Student for Transfer Credit */}
              <div>
                <Label htmlFor="studentSelectTransfer" value="Select Student" />
                <Select
                  id="studentSelectTransfer"
                  value={selectedStudent}
                  onChange={(e) => setSelectedStudent(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Student
                  </option>
                  {students.map((student) => (
                    <option key={student.uid} value={student.uid}>
                      {student.firstName} {student.lastName} ({student.email})
                    </option>
                  ))}
                </Select>
              </div>

              {/* Assign Transfer Credits Button */}
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 
                           focus:ring-4 focus:ring-blue-300 font-medium
                           rounded-lg text-sm px-5 py-2.5 me-2 mb-2
                           dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={assignTransferCredits}
                disabled={transferLoading}
              >
                {transferLoading ? (
                  <Spinner aria-label="Assigning transfer credits" size="sm" />
                ) : (
                  'Assign Transfer Credits'
                )}
              </button>

              {/* Feedback (re-uses assignMessage) */}
              {assignMessage && <p className="mt-2 text-sm text-gray-600">{assignMessage}</p>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// ---------------------------------------------------------
// Child Component for Tab 3: Display each assigned advisor
// ---------------------------------------------------------
const AssignedAdvisorItem = ({ studentId, advisorId, removeAdvisorFromStudent }) => {
  const [advisorData, setAdvisorData] = useState(null);

  useEffect(() => {
    const fetchAdvisor = async () => {
      try {
        const doc = await db.collection('advisors').doc(advisorId).get();
        if (doc.exists) {
          setAdvisorData(doc.data());
        }
      } catch (error) {
        console.error('Error fetching advisor data:', error);
      }
    };
    fetchAdvisor();
  }, [advisorId]);

  if (!advisorData) {
    return <p className="text-sm">Loading advisor...</p>;
  }

  return (
    <div className="flex items-center justify-between p-2 bg-gray-100 rounded">
      <div>
        <p className="font-medium">
          {advisorData.firstName} {advisorData.lastName}
        </p>
        <p className="text-sm text-gray-600">{advisorData.email}</p>
      </div>
      <Button color="failure" size="xs" onClick={() => removeAdvisorFromStudent(studentId, advisorId)}>
        Remove
      </Button>
    </div>
  );
};

export default AssignRole;
