// GroupItem.js
import React from 'react';

const GroupItem = ({
  group,
  count,
  members,
  isExpanded,
  onToggle,
  isSelected,
  onCheckboxChange,
}) => {
  // Define styles for dynamic and static labels
  const typeStyles = group.isDynamic
    ? 'bg-green-100 text-green-800 rounded-full px-2 py-1 text-xs font-semibold'
    : 'bg-blue-100 text-blue-800 rounded-full px-2 py-1 text-xs font-semibold';

  // Visibility styles (Public vs Private)
  const visibilityStyles = group.isPublic
    ? 'bg-purple-100 text-purple-800 rounded-full px-2 py-1 text-xs font-semibold'
    : 'bg-gray-100 text-gray-800 rounded-full px-2 py-1 text-xs font-semibold';

  return (
    <div
      className="border bg-white rounded-lg p-1.5 mb-2 hover:bg-gray-50 
                 grid grid-cols-12 items-center"
    >
      {/* 1) Checkbox for selecting group */}
      <div className="col-span-1 flex justify-center">
        <input
          type="checkbox"
          className="w-4 h-4 mr-20 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500"
          checked={isSelected}
          onChange={(e) => onCheckboxChange(group.id, e.target.checked)}
        />
      </div>

      {/* 2) Group Name */}
      <div className="col-span-3 ml-2">
        <h4 className="font-bold text-black text-sm">{group.name}</h4>
      </div>

      {/* 3) Type */}
      <div className="col-span-2 text-center text-sm">
        <span className={typeStyles}>
          {group.isDynamic ? 'Dynamic' : 'Static'}
        </span>
      </div>

      {/* 4) Members count */}
      <div className="col-span-2 text-center text-sm">{count}</div>

      {/* 5) Visibility */}
      <div className="col-span-2 text-center text-sm">
        <span className={visibilityStyles}>
          {group.isPublic ? 'Public' : 'Private'}
        </span>
      </div>

      {/* 6) Action (View/Hide) */}
      <div className="col-span-2 text-right pr-2">
        <button
          onClick={() => onToggle(group.id)}
          className="text-xs text-blue-700 hover:text-white border border-blue-700 
                       hover:bg-blue-700 py-1 px-2 rounded transition-all duration-300"
        >
          {isExpanded ? 'Hide' : 'View'}
        </button>
      </div>

      {/* Expanded: list of members */}
      {isExpanded && (
        <div className="col-span-12 mt-2">
          {members.length > 0 && (
            <div className="grid grid-cols-12 items-center font-semibold text-sm text-gray-500 mb-1 border-b pb-1">
              <div className="col-span-3 ml-2">Name</div>
              <div className="col-span-3">Program</div>
              <div className="col-span-2 text-center">Grad Year</div>
              <div className="col-span-4 text-right pr-2">Last Login</div>
            </div>
          )}

          {members.length === 0 ? (
            <p className="text-sm text-gray-500 ml-2">No students in this group.</p>
          ) : (
            members.map((stu) => (
              <div
                key={stu.id}
                className="border bg-white rounded-lg p-1.5 mb-1 
                           hover:bg-gray-50 cursor-default grid grid-cols-12 items-center"
              >
                <div className="col-span-3 ml-2 text-sm font-semibold">
                  {stu.name}
                </div>
                <div className="col-span-3 text-sm">
                  {Array.isArray(stu.major) ? stu.major.join(', ') : stu.major}
                </div>
                <div className="col-span-2 text-sm text-center">
                  {stu.surveyAnswers?.SchoolEnd?.year || 'N/A'}
                </div>
                <div className="col-span-4 text-sm text-right pr-2">
                  <span className="text-gray-500 italic">
                    {stu.lastLoginDate || ''}
                  </span>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default GroupItem;
