import React, { useState, useRef, useEffect } from 'react';
import CourseAnalyticsModal from './DetailsModal/CourseAnalyticsModal';
import { FaFlag } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

const AnalyticsClassSearch = ({ index, classData, fillRate }) => {
  const [isHovered, setIsHovered] = useState(false); // State for hover effect
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); // State for details modal visibility
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false); // State for warning modal visibility
  const [warningModalPosition, setWarningModalPosition] = useState({ top: 0, left: 0 }); // State for warning modal position
  const warningModalRef = useRef(null); // Reference to the warning modal
  const flagRef = useRef(null); // Reference to the flag

  const handleDetailsButtonClick = (event) => {
    event.stopPropagation(); // Prevent the click from toggling collapse
    setIsDetailsModalOpen(true); // Open details modal on click
    setIsWarningModalVisible(false); // Close warning modal when details button is clicked
  };

  const handleCloseDetailsModal = () => {
    setIsDetailsModalOpen(false); // Close details modal
  };

  const handleFlagClick = (event) => {
    event.stopPropagation(); // Prevent event propagation

    if (index === 0 && flagRef.current) {
      // Get the flag's position in the viewport
      const rect = flagRef.current.getBoundingClientRect();
      // Slightly adjust the position to be higher and more to the left
      setWarningModalPosition({ top: rect.top - 70, left: rect.left - 180 });
    }

    setIsWarningModalVisible((prev) => !prev); // Toggle warning modal visibility
  };

  const handleOutsideClick = (event) => {
    if (warningModalRef.current && !warningModalRef.current.contains(event.target)) {
      setIsWarningModalVisible(false); // Close warning modal
    }
  };

  useEffect(() => {
    if (isWarningModalVisible) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isWarningModalVisible]);

  useEffect(() => {
    const handleScroll = () => {
      if (index === 0) {
        setIsWarningModalVisible(false); // Close warning modal on scroll if index is 0
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [index]);

  // Determine warning message and flag styling
  let flag = null;
  let warningMessage = '';
  const flagStyle = index === 0 ? 'fixed' : 'absolute';

  if (classData.ProjectedSections < classData.OfferedSections) {
    flag = (
      <div
        ref={flagRef}
        title="Too Many Resources Allocated"
        className="mr-3 text-blue-500 hover:text-blue-700 cursor-pointer relative transition-colors"
        onClick={handleFlagClick}
      >
        <FaFlag />
      </div>
    );
    warningMessage = `Warning: ${classData.courseInfo_courseNumber} is projected to to have more sections allocated than needed.`;
  } else if (classData.ProjectedSections > classData.OfferedSections) {
    flag = (
      <div
        ref={flagRef}
        title="Additional Resources Needed"
        className="mr-3 text-red-500 hover:text-red-700 cursor-pointer relative transition-colors"
        onClick={handleFlagClick}
      >
        <FaFlag />
      </div>
    );
    warningMessage = `Warning: ${classData.courseInfo_courseNumber} is projected to require more sections than currently allocated.`;
  }

  // Dynamically determine both background and text color based on fillRate
  const fillRateBarClass =
    fillRate < 50
      ? 'bg-red-600 text-red-100'
      : fillRate < 85
      ? 'bg-yellow-400 text-yellow-100'
      : fillRate < 95
      ? 'bg-green-600 text-green-100'
      : 'bg-blue-600 text-blue-100';

  return (
    <div className="border bg-white rounded-lg p-1.5 mb-2 relative">
      <div className="grid grid-cols-8 items-center">
        <div className="text-center font-bold col-span-1 mr-2.5">
          {classData.courseInfo_courseNumber}
        </div>
        <div className="text-center col-span-3">
          {classData.courseInfo_courseName}
        </div>
        <div className="text-center col-span-1 mr-4">
          <div className="text-center font-semibold text-sm">
            {classData.OfferedSections} sections
          </div>
          <div className="text-center text-xs">
            {classData.OfferedSeats} seats
          </div>
        </div>
        <div className="mr-2 text-center col-span-1 mr-5">
          <div className="text-center font-semibold text-sm">
            {classData.ProjectedSections} sections
          </div>
          <div className="text-center text-xs">
            {classData.ProjectedSeats} seats
          </div>
        </div>

        {/* Fill Rate Bar with dynamic text color */}
        <div className="col-span-1 flex items-center justify-center">
          <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
            <div
              className={`
                text-xs 
                font-medium
                text-center
                p-0.5
                leading-none
                rounded-full
                ${fillRateBarClass}
              `}
              style={{ width: `${fillRate}%` }}
            >
              {fillRate}%
            </div>
          </div>
        </div>

        <div className="text-right col-span-1 mr-2 flex items-center justify-end">
          {flag}
          <button
            onClick={handleDetailsButtonClick}
            className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded text-xs ml-2 px-2 py-1 transition-all duration-300"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Details
          </button>
        </div>
      </div>

      {/* Warning Modal */}
      {isWarningModalVisible && (
        <div
          ref={warningModalRef}
          className={`${flagStyle} bg-gray-100 shadow-lg border rounded p-2 z-[3] w-80`}
          style={
            index === 0
              ? { top: warningModalPosition.top, left: warningModalPosition.left }
              : { top: '-3rem', right: '0.2rem' }
          }
        >
          <div className="flex justify-between items-start">
            <div className="text-sm text-gray-700">{warningMessage}</div>
            <button
              onClick={() => setIsWarningModalVisible(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <IoClose size={18} />
            </button>
          </div>
        </div>
      )}

      {/* Course Details Modal */}
      {isDetailsModalOpen && (
        <CourseAnalyticsModal
          isOpen={isDetailsModalOpen}
          onClose={handleCloseDetailsModal}
          classData={classData}
        />
      )}
    </div>
  );
};

export default AnalyticsClassSearch;
