import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'flowbite-react';

const getBorderColorClass = (category) => {
  switch (category) {
    case 'Very Low':
      return 'border-t-[40px] border-l-[1.5px] border-r-[1.5px] border-b-[1.5px] border-red-900';
    case 'Low':
      return 'border-t-[40px] border-l-[1.5px] border-r-[1.5px] border-b-[1.5px] border-orange-700';
    case 'Moderate':
      return 'border-t-[40px] border-l-[1.5px] border-r-[1.5px] border-b-[1.5px] border-yellow-500';
    case 'High':
      return 'border-t-[40px] border-l-[1.5px] border-r-[1.5px] border-b-[1.5px] border-green-700';
    case 'Very High':
      return 'border-t-[40px] border-l-[1.5px] border-r-[1.5px] border-b-[1.5px] border-blue-700';
    default:
      return 'border-t-[40px] border-l-[2px] border-r-[2px] border-b-[2px] border-gray-700';
  }
};

const getInitials = (firstName = '', lastName = '') => {
  const fInitial = firstName.charAt(0).toUpperCase() || '';
  const lInitial = lastName.charAt(0).toUpperCase() || '';
  return `${fInitial}${lInitial}`;
};

const getTextColorClass = (prediction) => {
  switch (prediction) {
    case 'Very Low':
      return 'text-red-800 font-semibold whitespace-nowrap';
    case 'Low':
      return 'text-orange-700 font-semibold whitespace-nowrap';
    case 'Moderate':
      return 'text-yellow-500 font-semibold whitespace-nowrap';
    case 'High':
      return 'text-green-700 font-semibold whitespace-nowrap';
    case 'Very High':
      return 'text-blue-700 font-semibold whitespace-nowrap';
    default:
      return 'font-semibold whitespace-nowrap';
  }
};

const PersistenceCard = ({ predictor }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const persistenceScore = predictor?.overallPersistenceScore
    ? predictor.overallPersistenceScore.toFixed(2)
    : 'N/A';

  return (
    <div
      className={`relative min-h-[400px] w-full bg-white rounded shadow-md py-2 px-2
                  flex flex-col items-center justify-between
                  ${getBorderColorClass(predictor.prediction)}
                  hover:shadow-lg transition-transform transform hover:scale-103 duration-200`}
    >
      <button
        className="absolute top-2 right-4 text-blue-600 text-sm underline font-semibold hover:text-blue-700"
        onClick={() => navigate(`/Profile/${predictor.studentId}`)}
      >
        To Profile →
      </button>

      <div className="flex flex-col items-center mb-2">
        <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center mb-1 -mt-8 z-10">
          <span className="text-white font-bold">
            {getInitials(predictor.firstName, predictor.lastName)}
          </span>
        </div>
        <h2 className="text-xl font-bold text-gray-800 text-center">
          {predictor.firstName} {predictor.lastName}
        </h2>
        {predictor.major && (
          <p className="text-xs text-gray-500">{predictor.major}</p>
        )}
      </div>

      <hr className="w-full border-gray-500 mb-1" />

      <div className="flex w-full px-2 mb-1">
        {/* Opportunities Section */}
        <div className="group flex flex-col items-center justify-center w-1/2 cursor-pointer hover:bg-gray-300 hover:rounded-md px-2 py-1">
          {predictor.applicableEngagements && predictor.applicableEngagements.length > 0 ? (
            <button
              className="flex flex-col items-center w-full text-center bg-transparent border-none"
              onClick={() => setExpanded(!expanded)}
            >
              <span className="text-xl text-black font-semibold whitespace-nowrap">
                {predictor.applicableEngagements.length}
              </span>
              <span className="text-xs text-gray-500 whitespace-nowrap">
                Opportunities
              </span>
            </button>
          ) : (
            <Tooltip
              content={
                <div className="max-w-xs space-y-1">
                  <div>No Engagement Opportunities</div>
                </div>
              }
              placement="top"
              className="!bg-gray-200 !border !border-gray-300 !text-black p-2 rounded"
              style={{ "--tooltip-arrow-bg": "#d1d5db", "--tooltip-arrow-border": "#374151" }}
            >
              <button className="flex flex-col items-center w-full text-center bg-transparent border-none">
                <span className="text-xl text-black font-semibold whitespace-nowrap">
                  0
                </span>
                <span className="text-xs text-gray-500 whitespace-nowrap">
                  Opportunities
                </span>
              </button>
            </Tooltip>
          )}
        </div>

        {/* Persistence Prediction Section */}
        <div className="group flex flex-col items-center justify-center w-1/2 cursor-pointer hover:bg-gray-300 hover:rounded-md px-4 py-1 ml-3">
          <Tooltip
            content={
              <span className="text-black whitespace-nowrap">
                Persistence Score:{' '}
                <span className={`${getTextColorClass(predictor.prediction)}`}>
                  {persistenceScore}
                </span>
                /100
              </span>
            }
            placement="top"
            className="!bg-gray-200 !border !border-gray-300 !text-black p-2 rounded"
            style={{ "--tooltip-arrow-bg": "#d1d5db", "--tooltip-arrow-border": "#374151" }}
          >
            <button className="flex flex-col items-center w-full text-center bg-transparent border-none">
              <span
                className={`text-xl ${getTextColorClass(
                  predictor.prediction
                )} whitespace-nowrap`}
              >
                {predictor.prediction}
              </span>
              <span className="text-xs text-gray-500 whitespace-nowrap">
                Persistence Prediction
              </span>
            </button>
          </Tooltip>
        </div>
      </div>

      <hr className="w-full border-gray-500" />

      {predictor?.persistenceFactors && (
        <div
          className="mt-2 w-full px-2"
          style={{ maxHeight: '250px', overflowY: 'auto' }}
        >
          {/* Extra Text integrated within the same container */}
          {predictor.extraText && (
            <div className="block w-full bg-gray-100 text-gray-700 text-xs font-semibold py-1 px-2 rounded-sm mb-2">
              <strong>Additional Info:</strong>{' '}
              <span dangerouslySetInnerHTML={{ __html: predictor.extraText }}></span>
            </div>
          )}

          {/* Expanded Opportunities Section inside the same container */}
          {expanded &&
            predictor.applicableEngagements &&
            predictor.applicableEngagements.length > 0 && (
              <div className="w-full max-h-[150px] overflow-y-auto rounded-sm">
                {predictor.applicableEngagements.map((eng, idx) => (
                  <div key={idx} className="text-xs text-gray-700 py-1 px-2 bg-red-100 font-semibold mb-1.5">
                    {eng?.name || 'Unnamed Engagement'}
                  </div>
                ))}
              </div>
          )}

          {expanded && (<hr className="w-full border-gray-500 mb-1.5" />)}

          {/* Persistence Factors */}
          {['GPA', 'GPA_Trends', 'Engagement', 'Course_Progress', 'Tags'].flatMap(
            (key) => {
              const value = predictor.persistenceFactors[key];

              if (key === 'Tags' && typeof value === 'object') {
                return Object.entries(value).map(([tagKey, tagValue], tagIdx) => (
                  <div
                    key={`Tags-${tagIdx}`}
                    className="block w-full bg-gray-100 text-gray-700 text-xs font-semibold py-1 px-2 rounded-sm mb-2"
                  >
                    <strong>{tagKey.replace(/_/g, ' ')}:</strong>{' '}
                    <span dangerouslySetInnerHTML={{ __html: tagValue }}></span>
                  </div>
                ));
              }

              if (value) {
                return (
                  <div
                    key={key}
                    className="block w-full bg-gray-100 text-gray-700 text-xs font-semibold py-1 px-2 rounded-sm mb-2"
                  >
                    <strong>{key.replace(/_/g, ' ')}:</strong>{' '}
                    <span dangerouslySetInnerHTML={{ __html: value }}></span>
                  </div>
                );
              }

              return null;
            }
          )}
        </div>
      )}
    </div>
  );
};

export default PersistenceCard;
