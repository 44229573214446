// NoContactCard.jsx
import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  where,
  query,
} from 'firebase/firestore';
import { differenceInDays } from 'date-fns';
import DashboardCard from './DashboardCard';
import { useAuth } from '../../../../contexts/AuthContext';
import { toast } from 'react-toastify';

// Configuration Flag: Set to true to use fake data
const USE_FAKE_DATA = true;

// Total fake students
const TOTAL_FAKE_STUDENTS = 367;

// Predefined Fake Data
const generateFakeData = () => {
  // Example distribution
  const inContactCount = Math.floor(TOTAL_FAKE_STUDENTS * 0.6); // 60% contacted
  const noContactCount = TOTAL_FAKE_STUDENTS - inContactCount;

  // Distribute noContactCount across risk categories
  const riskDistribution = {
    'Very Low': Math.floor(noContactCount * 0.08),    // 8%
    'Low': Math.floor(noContactCount * 0.3),         // 30%
    'Moderate': Math.floor(noContactCount * 0.37),    // 37%
    'High': Math.floor(noContactCount * 0.15),        // 15%
    'Very High': noContactCount - (Math.floor(noContactCount * 0.08) + Math.floor(noContactCount * 0.3) + Math.floor(noContactCount * 0.37) + Math.floor(noContactCount * 0.15)), // Remaining
  };

  return {
    totalStudents: TOTAL_FAKE_STUDENTS,
    inContactCount,
    noContactCount,
    categoryData: RISK_CATEGORIES.map((rc) => ({
      ...rc,
      count: riskDistribution[rc.label] || 0,
    })),
  };
};

const RISK_CATEGORIES = [
  { label: 'Very Low', color: '#e53935' },
  { label: 'Low', color: '#fb8c00' },
  { label: 'Moderate', color: '#fdd835' },
  { label: 'High', color: '#43a047' },
  { label: 'Very High', color: '#1e88e5' },
];

const NoContactCard = forwardRef(({ onHide }, ref) => {
  const { currentUser } = useAuth();
  const db = getFirestore();

  // Toggling "my students" vs. "all students"
  const [myStudentsOnly, setMyStudentsOnly] = useState(true);

  // Let them choose a day threshold
  const [daysThreshold, setDaysThreshold] = useState(30);

  // Loading & Summaries
  const [isLoading, setIsLoading] = useState(true);
  const [totalStudents, setTotalStudents] = useState(0);

  // Tracking "in contact" and "no contact"
  const [inContactCount, setInContactCount] = useState(0);
  const [noContactCount, setNoContactCount] = useState(0);

  // Risk-level counts for the no-contact group
  const [categoryData, setCategoryData] = useState(
    RISK_CATEGORIES.map((rc) => ({ ...rc, count: 0 }))
  );

  // Expose the refresh method to parent via ref
  useImperativeHandle(ref, () => ({
    refresh: () => {
      fetchData();
    },
  }));

  // MAIN FETCH
  const fetchData = useCallback(async () => {
    setIsLoading(true);

    if (USE_FAKE_DATA) {
      // Use fake data
      const fakeData = generateFakeData();
      setTotalStudents(fakeData.totalStudents);
      setInContactCount(fakeData.inContactCount);
      setNoContactCount(fakeData.noContactCount);
      setCategoryData(fakeData.categoryData);
      console.log('[NoContactCard] Using fake data:', fakeData);
      setIsLoading(false);
      return;
    }

    if (!currentUser?.uid) {
      setIsLoading(false);
      return;
    }

    try {
      console.log(
        '[NoContactCard] Checking who is in contact vs. not =>',
        `myStudentsOnly=${myStudentsOnly}, daysThreshold=${daysThreshold}`
      );

      // 1) Query studentAdvisors
      const studentAdvRef = collection(db, 'studentAdvisors');
      let qRef;
      if (myStudentsOnly) {
        // Only docs that have currentUser in advisorIds
        qRef = query(
          studentAdvRef,
          where('advisorIds', 'array-contains', currentUser.uid)
        );
      } else {
        // All docs in 'studentAdvisors'
        qRef = studentAdvRef;
      }
      const snap = await getDocs(qRef);
      console.log(`[NoContactCard] Found ${snap.size} docs in studentAdvisors.`);

      const studentIds = snap.docs.map((docSnap) => docSnap.id);
      setTotalStudents(studentIds.length);

      if (studentIds.length === 0) {
        setInContactCount(0);
        setNoContactCount(0);
        setCategoryData(RISK_CATEGORIES.map((rc) => ({ ...rc, count: 0 })));
        setIsLoading(false);
        return;
      }

      // 2) Fetch all user docs in parallel
      const userDocPromises = studentIds.map((id) =>
        getDoc(doc(db, 'users', id))
      );
      const userDocs = await Promise.all(userDocPromises);

      // 3) Fetch all persistence docs in parallel
      const persistDocPromises = studentIds.map((id) =>
        getDoc(doc(db, 'Persistence', id))
      );
      const persistDocs = await Promise.all(persistDocPromises);

      // Initialize counters
      let contacted = 0;
      let notContacted = 0;
      const newCounts = {
        'Very Low': 0,
        Low: 0,
        Moderate: 0,
        High: 0,
        'Very High': 0,
      };

      // 4) Process each student
      for (let i = 0; i < studentIds.length; i++) {
        const studentId = studentIds[i];
        const userDocSnap = userDocs[i];
        const persistDocSnap = persistDocs[i];

        // lastContact for this advisor
        let lastContact = null;
        const studentAdvData = snap.docs[i].data();
        if (studentAdvData.lastContactTimestamps?.[currentUser.uid]) {
          lastContact = studentAdvData.lastContactTimestamps[
            currentUser.uid
          ].toDate();
        }

        let daysSince = Infinity;
        if (lastContact) {
          daysSince = differenceInDays(new Date(), lastContact);
        }

        // risk level from user doc
        let riskLevel = 'Moderate';
        if (userDocSnap.exists()) {
          const uData = userDocSnap.data();
          if (uData.riskLevel) {
            riskLevel = uData.riskLevel;
          }
        }

        // in contact = (daysSince <= threshold)
        if (daysSince <= daysThreshold) {
          contacted++;
        } else {
          // no contact => increment risk category
          notContacted++;
          if (newCounts[riskLevel] !== undefined) {
            newCounts[riskLevel]++;
          } else {
            console.log(
              `[NoContactCard] Unknown riskLevel: ${riskLevel} for student ${studentId}`
            );
          }
        }
      }

      // Build categoryData array for the no-contact group
      const mergedCategoryData = RISK_CATEGORIES.map((rc) => ({
        ...rc,
        count: newCounts[rc.label] || 0,
      }));

      setInContactCount(contacted);
      setNoContactCount(notContacted);
      setCategoryData(mergedCategoryData);

      console.log(
        `Total: ${studentIds.length}, InContact: ${contacted}, NoContact: ${notContacted}`
      );
      console.log('riskCounts =>', newCounts);
    } catch (err) {
      console.error('[NoContactCard] Error:', err);
      toast.error('Failed to fetch contact status.');
    }
    setIsLoading(false);
  }, [
    myStudentsOnly,
    daysThreshold,
    currentUser?.uid,
    db,
    // Removed totalStudents from dependencies to avoid infinite loop
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // The bar is for "contacted" portion
  const portion =
    totalStudents === 0 ? 0 : (inContactCount / totalStudents) * 100;

  // Handling day threshold change
  const handleDaysChange = (e) => setDaysThreshold(Number(e.target.value));

  // Toggle "my students" vs "all students"
  const handleToggleStudents = () => {
    setMyStudentsOnly((prev) => !prev);
  };

  return (
    <DashboardCard
      title={<span className="font-medium">Contact Status</span>}
      extraButtons={
        <button
          onClick={handleToggleStudents}
          className="text-sm font-medium text-blue-600 hover:text-blue-800"
        >
          {myStudentsOnly ? 'Show All Students' : 'Show My Students'}
        </button>
      }
      onHide={onHide} // Retain the onHide prop to include the hide button
      isLoading={isLoading}
    >
      {!isLoading && (
        <div className="p-4 space-y-4">
          {/* Title row */}
          <div className="flex items-center justify-between text-sm font-medium text-gray-700">
            {/* The threshold dropdown */}
            <span>
              Students contacted within{' '}
              <select
                className="underline text-blue-600 cursor-pointer bg-transparent"
                style={{ textUnderlineOffset: '2px' }}
                onChange={handleDaysChange}
                value={daysThreshold}
              >
                <option value={7}>7 days</option>
                <option value={14}>14 days</option>
                <option value={30}>30 days</option>
                <option value={60}>60 days</option>
                <option value={90}>90 days</option>
              </select>
            </span>
          </div>

          {/* Big top bar => portion = (inContact / total) */}
          <div className="relative h-6 bg-gray-200 rounded">
            <div
              className="absolute top-0 left-0 h-6 rounded bg-blue-700"
              style={{ width: `${portion}%` }}
            />
            <div className="absolute inset-0 flex items-center justify-between text-xs px-2 text-white font-medium">
              <span>Contacted: {inContactCount}</span>
              <span>Total: {totalStudents}</span>
            </div>
          </div>

          {/* Risk-level breakdown for NO-contact students */}
          <div className="text-sm font-medium text-gray-700 mt-2">
            No-Contact Students: {noContactCount}
          </div>
          <div className="space-y-2 pt-2">
            {categoryData.map((cat) => {
              // cat.count = how many no-contact in that risk
              // portion is cat.count / noContactCount
              const catPortion =
                noContactCount === 0
                  ? 0
                  : (cat.count / noContactCount) * 100;
              return (
                <div key={cat.label}>
                  <div className="flex items-center justify-between text-sm mb-1">
                    <span>{cat.label}</span>
                    <span className="text-gray-500">
                      {cat.count}/{noContactCount}
                    </span>
                  </div>
                  <div className="relative h-4 bg-gray-200 rounded">
                    <div
                      className="absolute top-0 left-0 h-4 rounded"
                      style={{
                        width: `${catPortion}%`,
                        backgroundColor: cat.color,
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </DashboardCard>
  );
});

export default NoContactCard;
