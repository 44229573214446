import React, { useState } from 'react';
import { Modal, Button } from 'flowbite-react';

const BreaksSection = ({ breaks, setBreaks, formatTime }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newBreak, setNewBreak] = useState({ name: "", days: [], start: "", end: "" });

  const DAYS = [
    { code: 'Mo', label: 'Monday' },
    { code: 'Tu', label: 'Tuesday' },
    { code: 'We', label: 'Wednesday' },
    { code: 'Th', label: 'Thursday' },
    { code: 'Fr', label: 'Friday' },
  ];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setNewBreak({ name: "", days: [], start: "", end: "" });
  };

  const handleAddBreak = () => {
    if (!newBreak.name || !newBreak.days.length || !newBreak.start || !newBreak.end) {
      alert("Please fill in all fields for the break.");
      return;
    }
    const [startHour, startMin] = newBreak.start.split(":").map(Number);
    const [endHour, endMin] = newBreak.end.split(":").map(Number);
    if (startHour * 60 + startMin >= endHour * 60 + endMin) {
      alert("Start time must be before end time.");
      return;
    }
    setBreaks([...breaks, { ...newBreak, selected: true }]);
    closeModal();
  };

  const toggleBreakSelection = (index) => {
    const updated = breaks.map((bk, i) => i === index ? { ...bk, selected: !bk.selected } : bk);
    setBreaks(updated);
  };

  const removeBreak = (index) => {
    setBreaks(breaks.filter((_, i) => i !== index));
  };

  const handleSelectAllBreaks = (checked) => {
    const updated = breaks.map(bk => ({ ...bk, selected: checked }));
    setBreaks(updated);
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-xl font-bold text-gray-800">Breaks</h2>
        <Button onClick={openModal} color="blue" size="sm">
          Add Break
        </Button>
      </div>

      <Modal show={isModalOpen} onClose={closeModal}>
        <Modal.Header>Add Break</Modal.Header>
        <Modal.Body className="bg-white">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Break Name</label>
              <input
                type="text"
                value={newBreak.name}
                onChange={(e) => setNewBreak({ ...newBreak, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="e.g. Gym, Work"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Select Days</label>
              <div className="flex space-x-2 mt-1">
                {DAYS.map(day => (
                  <label key={day.code} className="inline-flex items-center">
                    <input
                      type="checkbox"
                      value={day.code}
                      checked={newBreak.days.includes(day.code)}
                      onChange={() => {
                        const updated = newBreak.days.includes(day.code)
                          ? newBreak.days.filter(d => d !== day.code)
                          : [...newBreak.days, day.code];
                        setNewBreak({ ...newBreak, days: updated });
                      }}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                    />
                    <span className="ml-1 text-sm text-gray-700">{day.label}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Start Time</label>
                <input
                  type="time"
                  value={newBreak.start}
                  onChange={(e) => setNewBreak({ ...newBreak, start: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">End Time</label>
                <input
                  type="time"
                  value={newBreak.end}
                  onChange={(e) => setNewBreak({ ...newBreak, end: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={closeModal}
            className="text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleAddBreak}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5"
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>

      <div className="flex items-center mt-4">
        <input
          id="select-all-breaks"
          type="checkbox"
          checked={breaks.length > 0 && breaks.every(bk => bk.selected)}
          onChange={(e) => handleSelectAllBreaks(e.target.checked)}
          className="h-4 w-4 text-blue-600 border-gray-300 rounded"
        />
        <label htmlFor="select-all-breaks" className="ml-2 text-sm font-medium text-gray-700">
          Select All Breaks
        </label>
      </div>

      <div className="mt-2 border-t border-gray-200 pt-2 overflow-y-auto">
        {breaks.map((bk, index) => (
          <div key={index} className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <input
                id={`break-${index}`}
                type="checkbox"
                checked={bk.selected}
                onChange={() => toggleBreakSelection(index)}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
              <label htmlFor={`break-${index}`} className="ml-2 text-sm text-gray-700">
                {bk.name}: {bk.days.join(", ")} {formatTime(bk.start)} - {formatTime(bk.end)}
              </label>
            </div>
            <button
              onClick={() => removeBreak(index)}
              className="text-red-600 text-xs"
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BreaksSection;
