const findRequirementCourses = (majorCourses, courses, nonUsableCourses) => {
    // Create a set for faster lookup of non-usable courses
    const nonUsableSet = new Set(nonUsableCourses);

    // Filter out the requirements from majorCourses
    const requirements = majorCourses.filter(majorCourse =>
        ((majorCourse.courseType === 'Major Course' || majorCourse.courseType === 'Minor Course') && majorCourse.Requirements) ||
        majorCourse.courseType === 'Requirement'
    );

    // Map student's courses for quick access and exclude non-usable courses
    const studentCoursesMap = new Map();
    courses.forEach((course, index) => {
        if (!nonUsableSet.has(course.courseInfo_courseNumber)) {
            studentCoursesMap.set(course.courseInfo_courseNumber, { ...course, index });
        }
    });

    // Build the possible matches between requirements and student's courses
    const reqIndexToPossibleCourses = [];
    requirements.forEach((requirement, reqIndex) => {
        const possibleCourses = requirement.Possibilities.split(',').map(course => course.trim());
        reqIndexToPossibleCourses[reqIndex] = [];
        possibleCourses.forEach(courseNumber => {
            if (studentCoursesMap.has(courseNumber)) {
                reqIndexToPossibleCourses[reqIndex].push(studentCoursesMap.get(courseNumber).index);
            }
        });
    });

    // Initialize match arrays
    const matchToCourse = new Array(requirements.length).fill(-1); // requirement index -> course index
    const matchToRequirement = new Array(courses.length).fill(-1); // course index -> requirement index

    // Helper function for DFS in bipartite matching
    function bpm(u, seen) {
        for (const v of reqIndexToPossibleCourses[u]) {
            if (!seen[v]) {
                seen[v] = true;
                if (matchToRequirement[v] === -1 || bpm(matchToRequirement[v], seen)) {
                    matchToRequirement[v] = u;
                    matchToCourse[u] = v;
                    return true;
                }
            }
        }
        return false;
    }

    // Perform the matching
    for (let u = 0; u < requirements.length; u++) {
        const seen = new Array(courses.length).fill(false);
        bpm(u, seen);
    }

    // Extract the matched courses
    const largestValidArray = [];
    for (let u = 0; u < matchToCourse.length; u++) {
        if (matchToCourse[u] !== -1) {
            largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
        }
    }

    return largestValidArray;
}

export default findRequirementCourses