// AdvisingProfile.js
import React from 'react';
import { toast } from 'react-toastify';

// 1. Import the `useTranslation` hook
import { useTranslation } from 'react-i18next';

const AdvisingDisplay = ({ advisors, scheduledMeetings, openModal }) => {
  // 2. Initialize the translation function
  const { t, i18n } = useTranslation();

  return (
    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200 flex flex-col">
      <h2 className="text-gray-700 font-semibold mb-2">
        {t('advisingProfile.advisingTeam', 'Advising Team')}
      </h2>

      {/* Advisor Avatars with Scrollable Container */}
      <div className="flex items-center space-x-4 mb-2 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300">
        {advisors.slice(0, 3).map((advisor, index) => (
          <div key={index} className="flex flex-col items-center">
            <img
              className="w-12 h-12 rounded-full object-cover mb-1"
              src={advisor.profilePicture || '/default.jpg'}
              alt={
                t('advisingProfile.advisorAlt', '{{firstName}} {{lastName}}', {
                  firstName: advisor.firstName,
                  lastName: advisor.lastName,
                  defaultValue: `${advisor.firstName} ${advisor.lastName}`
                })
              }
            />
            <span className="text-sm font-medium text-gray-800 dark:text-white">
              {`${advisor.firstName} ${advisor.lastName}`}
            </span>
          </div>
        ))}
        {advisors.length > 3 && (
          <span className="text-sm text-blue-600 dark:text-blue-400">
            {t('advisingProfile.moreAdvisors', '+{{count}} more', {
              count: advisors.length - 3,
              defaultValue: `+${advisors.length - 3} more`
            })}
          </span>
        )}
      </div>

      {/* Upcoming Meetings */}
      <div className="mb-2">
        <h4 className="text-sm font-semibold text-gray-700 dark:text-gray-100 mb-1">
          {t('advisingProfile.upcomingMeetings', 'Upcoming Meetings')}
        </h4>
        {scheduledMeetings.length > 0 ? (
          <ul className="space-y-1 max-h-24 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
            {scheduledMeetings.slice(0, 5).map((meeting, index) => {
              // Convert Firestore timestamp to JS date
              const meetingDate = new Date(meeting.datetime.seconds * 1000);
              return (
                <li key={index} className="text-sm text-gray-600 dark:text-gray-300">
                  {meetingDate.toLocaleString(i18n.language, {
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}{' '}
                  {t('advisingProfile.withAdvisor', 'with')} {meeting.advisorName}
                </li>
              );
            })}
            {scheduledMeetings.length > 5 && (
              <li className="text-sm text-blue-600 dark:text-blue-400">
                {t('advisingProfile.moreMeetings', '+{{count}} more...', {
                  count: scheduledMeetings.length - 5,
                  defaultValue: `+${scheduledMeetings.length - 5} more...`
                })}
              </li>
            )}
          </ul>
        ) : (
          <p className="text-sm text-gray-500 dark:text-gray-400">
            {t('advisingProfile.noUpcomingMeetings', 'No upcoming meetings scheduled.')}
          </p>
        )}
      </div>

      {/* Schedule Meeting Button */}
      <button
        onClick={openModal}
        className="w-full text-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
      >
        {t('advisingProfile.scheduleMeeting', 'Schedule Meeting')}
      </button>
    </div>
  );
};

export default AdvisingDisplay;
