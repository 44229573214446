// src/components/Dashboard/Cards/DashboardCard.jsx
import React, { useState, useRef, useEffect } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import SkeletonCard from './SkeletonCard';

/**
 * A generic, reusable card container for the advisor dashboard.
 * Includes a three-dot button that opens a dropdown with "Hide card".
 *
 * Props:
 *  - title (JSX): Custom header content (e.g., title)
 *  - onHide (function): Called when user clicks "Hide card"
 *  - extraButtons (JSX): Additional buttons to be displayed alongside the hide button
 *  - className (string): Additional Tailwind classes if needed
 *  - children (JSX): Card content
 *  - isLoading (boolean): If true, display skeleton instead of content
 */
const DashboardCard = ({ title, onHide, extraButtons, className = '', children, isLoading = false }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  // Close the dropdown if clicking outside
  useEffect(() => {
    function handleClickOutside(e) {
      if (
        menuOpen &&
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [menuOpen]);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleHide = () => {
    setMenuOpen(false);
    onHide && onHide();
  };

  if (isLoading) {
    return <SkeletonCard className={className} />;
  }

  return (
    <div
      className={`bg-white dark:bg-gray-800 border border-gray-300 
                  dark:border-gray-700 rounded-lg shadow-md p-2 w-full
                  relative ${className}`}
    >
      {/* Header */}
      {title && (
        <div className="flex justify-between items-center">
          {title}
          {/* Buttons */}
          <div className="flex items-center space-x-2">
            {/* Render extra buttons passed via prop */}
            {extraButtons}
            {/* Hide button */}
            {onHide && (
              <button
                ref={buttonRef}
                onClick={toggleMenu}
                className="text-gray-400 hover:text-gray-600 
                           dark:text-gray-300 dark:hover:text-white"
                aria-label="Menu"
              >
                <BsThreeDotsVertical size={20} />
              </button>
            )}
          </div>
        </div>
      )}

      {/* Spacing under title */}
      {title && <div className="mt-2" />}

      {/* Card body */}
      {children}

      {/* Dropdown menu */}
      {menuOpen && onHide && (
        <div
          ref={menuRef}
          className="absolute right-2 top-12 z-50 bg-white border border-gray-200
                     rounded shadow p-1 w-24 dark:bg-gray-800 dark:border-gray-700"
        >
          <button
            onClick={handleHide}
            className="block w-full text-left px-2 py-1 text-sm
                       hover:bg-gray-100 dark:hover:bg-gray-700
                       dark:text-gray-100"
          >
            Hide Card
          </button>
        </div>
      )}
    </div>
  );
};

export default DashboardCard;
