export async function fetchSFUCourseDetails(year, term, department, courseNumber, section) {
  const baseURL = 'https://www.sfu.ca/bin/wcm/course-outlines';
  const url = `${baseURL}?${year}/${term}/${department}/${courseNumber}/${section}`;

  try {
    const response = await fetch(url, { headers: { Accept: 'application/json' } });
    if (!response.ok) {
      throw new Error(`SFU API error: status ${response.status}`);
    }
    const data = await response.json();
    console.log("Detailed course data:", data);
    return data; // expected to include courseSchedule, title, deliveryMethod, units, etc.
  } catch (err) {
    console.error('Error fetching SFU course details:', err);
    return null;
  }
}
