// findElectiveCourses.js

export function findElectiveCourses(majorCourses, courses) {
    // Filter out the elective requirements from majorCourses
    const electiveRequirements = majorCourses.filter(
        majorCourse => majorCourse.courseType === 'Elective'
    );

    // Build the possible matches between electives and student's courses
    const electiveIndexToPossibleCourses = [];
    electiveRequirements.forEach((electiveReq, electiveIndex) => {
        const electiveName = electiveReq.Possibilities;
        electiveIndexToPossibleCourses[electiveIndex] = [];

        // If the elective name is "Free-NonMajor-Elective", allow any course to fulfill it
        if (electiveName === "Free-NonMajor-Elective") {
            for (let i = 0; i < courses.length; i++) {
                electiveIndexToPossibleCourses[electiveIndex].push(i);
            }
        } else {
            // Otherwise, only include courses that match specific elective fulfillment criteria
            courses.forEach((studentCourse, courseIndex) => {
                if (
                    studentCourse.Elective_fulfillment &&
                    studentCourse.Elective_fulfillment.includes(electiveName)
                ) {
                    electiveIndexToPossibleCourses[electiveIndex].push(courseIndex);
                }
            });
        }
    });

    // Initialize match arrays
    const matchToCourse = new Array(electiveRequirements.length).fill(-1); // elective index -> course index
    const matchToElective = new Array(courses.length).fill(-1); // course index -> elective index

    // Helper function for DFS in bipartite matching
    function bpm(u, seen) {
        for (const v of electiveIndexToPossibleCourses[u]) {
            if (!seen[v]) {
                seen[v] = true;
                if (matchToElective[v] === -1 || bpm(matchToElective[v], seen)) {
                    matchToElective[v] = u;
                    matchToCourse[u] = v;
                    return true;
                }
            }
        }
        return false;
    }

    // Perform the matching
    for (let u = 0; u < electiveRequirements.length; u++) {
        const seen = new Array(courses.length).fill(false);
        bpm(u, seen);
    }

    // Extract the matched courses
    const largestValidArray = [];
    for (let u = 0; u < matchToCourse.length; u++) {
        if (matchToCourse[u] !== -1) {
            largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
        }
    }

    return largestValidArray;
}