// SchoolPortals.js
import React from 'react';
import { useAuth } from '../../../contexts/AuthContext';

// 1. Import the useTranslation hook
import { useTranslation } from 'react-i18next';

const SchoolPortals = () => {
  const {
    fetchedGeneralPortal,
    fetchedAdvisorPortal,
    fetchedCourseSearch,
    fetchedGeneralPicture,
    fetchedAdvisorPicture,
    fetchedSearchPicture
  } = useAuth();

  // 2. Initialize the translation function
  const { t } = useTranslation();

  return (
    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
      <h2 className="text-gray-700 font-semibold mb-2">
        {t('schoolPortals.title', 'School Portals')}
      </h2>
      <ul className="my-4 space-y-3">
        <li>
          <a
            href={fetchedGeneralPortal}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
          >
            <img
              src={fetchedGeneralPicture}
              alt={t('schoolPortals.generalProfileAlt', 'General Profile')}
              className="h-10 w-10 object-contain object-center"
            />
            <span className="flex-1 ms-3 whitespace-nowrap">
              {t('schoolPortals.generalPortal', 'General Portal')}
            </span>
          </a>
        </li>
        <li>
          <a
            href={fetchedAdvisorPortal}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
          >
            <img
              src={fetchedAdvisorPicture}
              alt={t('schoolPortals.advisorProfileAlt', 'Advisor Profile')}
              className="h-10 w-10 object-cover object-center"
            />
            <span className="flex-1 ms-3 whitespace-nowrap">
              {t('schoolPortals.advisorPortal', 'Advisor Portal')}
            </span>
          </a>
        </li>
        <li>
          <a
            href={fetchedCourseSearch}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
          >
            <img
              src={fetchedSearchPicture}
              alt={t('schoolPortals.courseProfileAlt', 'Course Profile')}
              className="h-10 w-10 object-cover object-center"
            />
            <span className="flex-1 ms-3 whitespace-nowrap">
              {t('schoolPortals.courseSearch', 'Course Search')}
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SchoolPortals;
