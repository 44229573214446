// src/components/AdvisorProfile/AdvisorProfile.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { useAuth } from '../../../contexts/AuthContext';
import { TextInput, Button, Spinner } from 'flowbite-react';
import { toast } from 'react-toastify';
import firebase from 'firebase/compat/app';
import CustomSidebar from '../Sidebar/Sidebar';
import AdvisorProfileHeading from './AdvisorProfileHeading';

const AdvisorProfile = () => {
  const { currentUser } = useAuth();

  // State to store the entire advisor document
  const [advisor, setAdvisor] = useState(null);
  const [meetingLink, setMeetingLink] = useState('');
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  // Fetch the advisor document from Firestore
  useEffect(() => {
    const fetchAdvisorData = async () => {
      if (!currentUser) return;

      try {
        const advisorDoc = await db.collection('advisors').doc(currentUser.uid).get();
        if (advisorDoc.exists) {
          const data = advisorDoc.data();

          // Store the whole advisor object
          setAdvisor(data);
          // Also set the local state for meetingLink
          setMeetingLink(data.meetingLink || '');
        }
      } catch (error) {
        console.error('Error fetching advisor data:', error);
        toast.error('Failed to fetch advisor data.');
      } finally {
        setLoading(false);
      }
    };

    fetchAdvisorData();
  }, [currentUser]);

  // Handler for updating the meeting link in Firestore
  const handleUpdateMeetingLink = async (e) => {
    e.preventDefault();
    setUpdating(true);

    try {
      await db.collection('advisors').doc(currentUser.uid).update({
        meetingLink,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      toast.success('Meeting link updated successfully!');
    } catch (error) {
      console.error('Error updating meeting link:', error);
      toast.error('Failed to update meeting link.');
    } finally {
      setUpdating(false);
    }
  };

  // If we are still fetching data, show a spinner
  if (loading) {
    return (
      <div className="flex justify-center items-center mt-20">
        <Spinner aria-label="Loading advisor profile" />
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto mt-8 px-4">
      {/* Sidebar if needed */}
      <CustomSidebar />

      {/* Heading section for advisor profile */}
      <AdvisorProfileHeading advisor={advisor} />

      {/* Section to update the meeting link */}
      <h2 className="text-xl font-semibold mb-4">Update Meeting Link</h2>
      <form onSubmit={handleUpdateMeetingLink}>
        <div className="mb-4">
          <label htmlFor="meetingLink" className="block text-sm font-medium text-gray-700 mb-1">
            Meeting Link (e.g., Zoom)
          </label>
          <TextInput
            id="meetingLink"
            type="url"
            value={meetingLink}
            onChange={(e) => setMeetingLink(e.target.value)}
            placeholder="https://zoom.us/j/1234567890"
            required
          />
        </div>
        <Button type="submit" color="info" disabled={updating}>
          {updating ? <Spinner size="sm" /> : 'Update Meeting Link'}
        </Button>
      </form>
    </div>
  );
};

export default AdvisorProfile;
