import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const EnrollmentBarChart = ({ data }) => {

  if (!data) {
    return null
  }

  const isShadedTerm = (term) => {
    const shadedTerms = ["FA24", "SP25", "SU25", "FA25", "SP26", "SU26", "FA26", "SP27", "SU27"];
    return shadedTerms.includes(term);
  };
  
  const chartData = {
    labels: data.map(item => item.term), // X-axis labels
    datasets: [
      {
        label: "Total Enrollment",
        data: data.map(item => item.Total), // Y-axis data
        backgroundColor: data.map(item => {
          if (isShadedTerm(item.term)) {
            return item.term.startsWith("SP") ? "rgba(136, 132, 216, 0.3)" :  // Spring color with 30% opacity
              item.term.startsWith("SU") ? "rgba(130, 202, 157, 0.3)" :  // Summer color with 30% opacity
              item.term.startsWith("FA") ? "rgba(255, 115, 0, 0.3)" : // Fall color with 30% opacity
              "rgba(136, 132, 216, 0.3)"; // Default color with 30% opacity
          }
          return item.term.startsWith("SP") ? "#8884d8" :  // Spring color
            item.term.startsWith("SU") ? "#82ca9d" :  // Summer color
            item.term.startsWith("FA") ? "#ff7300" : // Fall color
            "#8884d8"; // Default color
        }),
        hoverBackgroundColor: data.map(item => {
          if (isShadedTerm(item.term)) {
            return item.term.startsWith("SP") ? "rgba(136, 132, 216, 0.5)" :  // Spring color with 50% opacity
              item.term.startsWith("SU") ? "rgba(130, 202, 157, 0.5)" :  // Summer color with 50% opacity
              item.term.startsWith("FA") ? "rgba(255, 115, 0, 0.5)" : // Fall color with 50% opacity
              "rgba(136, 132, 216, 0.5)"; // Default color with 50% opacity
          }
          return item.term.startsWith("SP") ? "#6666cc" :  // Slightly darker Spring color
            item.term.startsWith("SU") ? "#6fbf85" :  // Slightly darker Summer color
            item.term.startsWith("FA") ? "#cc5f00" : // Slightly darker Fall color
            "#6666cc"; // Default slightly darker color
        }),
        borderColor: data.map(item => {
          if (isShadedTerm(item.term)) {
            return item.term.startsWith("SP") ? "rgba(136, 132, 216, 0.6)" :  // Spring color with 60% opacity
              item.term.startsWith("SU") ? "rgba(130, 202, 157, 0.6)" :  // Summer color with 60% opacity
              item.term.startsWith("FA") ? "rgba(255, 115, 0, 0.6)" : // Fall color with 60% opacity
              "rgba(136, 132, 216, 0.6)"; // Default color with 60% opacity
          }
          return item.term.startsWith("SP") ? "#8884d8" :  // Saturated Spring color
            item.term.startsWith("SU") ? "#82ca9d" :  // Saturated Summer color
            item.term.startsWith("FA") ? "#ff7300" : // Saturated Fall color
            "#8884d8"; // Default saturated color
        }),
        borderWidth: 1, // Add a consistent border width
        barThickness: 20, // Wider bars
      },
    ],
  };
  

  // Chart options to make the chart look better
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`; // Show the value in tooltips
          },
        },
      },
      legend: {
        position: 'top', // Place legend in the top-right corner
        labels: {
          boxWidth: 20, // Set box width for the circles
          usePointStyle: true, // Use points for legend items
          generateLabels: (chart) => {
            // Generate a custom legend with 3 specific colors for Fall, Spring, and Summer
            return [
              {
                text: 'Fall',
                fillStyle: '#ff7300',
                pointStyle: 'circle',
                strokeStyle: '#ff7300',
              },
              {
                text: 'Spring',
                fillStyle: '#8884d8',
                pointStyle: 'circle',
                strokeStyle: '#8884d8',
              },
              {
                text: 'Summer',
                fillStyle: '#82ca9d',
                pointStyle: 'circle',
                strokeStyle: '#82ca9d',
              },
            ];
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines for the X-axis
        },
        title: {
          display: true,
          text: 'Terms', // X-axis label
        },
        // Adjust bar spacing
        ticks: {
          autoSkip: false, // Ensure no tick labels are skipped
          maxRotation: 45, // Rotate the labels to avoid overlap
          minRotation: 0,
        },
        // Control the spacing between bars
        categoryPercentage: 0.7, // Reduce bar width to increase space between bars
        barPercentage: 1, // Adjust this to create more space between bars
      },
      y: {
        beginAtZero: true, // Start the Y-axis from zero
        title: {
          display: true,
          text: 'Enrollment', // Y-axis label
        },
      },
    },
  };

  return (
    <div className="w-full flex items-center justify-center" style={{ width: "900px", height: "350px" }}> {/* Even wider and shorter chart */}
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default EnrollmentBarChart;
