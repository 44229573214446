import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { HiMenu, HiX } from 'react-icons/hi';
import { PiStudent } from 'react-icons/pi';
import {
  BsMap,
  BsGraphUp,
  BsSignpost2,
  BsCalendarCheck,
  BsTable,
  BsMortarboard
} from 'react-icons/bs';
import { FaUserGroup, FaChartPie, FaUser } from 'react-icons/fa6';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { IoMdArrowBack } from 'react-icons/io';
import { TbMessage } from 'react-icons/tb';
import { useAuth } from '../../../contexts/AuthContext';


// Import our Portal component
import DropdownPortal from './DropdownPortal';

export default function CustomSidebar({ adjustment }) {
  // Sidebar open/close
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Dropdown open/close & position
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownPos, setDropdownPos] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  const navigate = useNavigate();

  const { currentUser, logout } = useAuth();

  // Placeholder user data/functions
  const userFullName = () => {
    if (currentUser && currentUser.firstName && currentUser.lastName) {
        return `${currentUser.firstName} ${currentUser.lastName}`;
    }
    return 'User';
  };

  const photoURL = currentUser.photoUrl || '/default.jpg';

  const handleLogout = async () => {
    try {
        await logout();
        navigate('/login');
    } catch (error) {
        console.error('Error during logout:', error);
    }
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownOpen &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownOpen]);


  // Whenever dropdown opens, measure the button's position
  useEffect(() => {
    if (dropdownOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      // Adjust position slightly up and left
      setDropdownPos({
        top: rect.top - 50, // Move up by 10px
        left: rect.right - rect.width + 65 // Move left by the button's width minus some offset
      });
    }
  }, [dropdownOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {/* Blue Banner */}
      <div
        className="fixed top-0 left-0 w-full h-7 bg-blue-200 z-50 ml-10 flex items-center justify-center px-4 text-sm font-black text-blue-800"
        style={{
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: "800", // Extra bold
          fontSize: "0.8rem", // Slightly larger for corporate feel
          textTransform: "uppercase", // All caps for a professional tone
        }}
      >
        {(adjustment === 'Student Plan' || adjustment === 'Student Profile') && (
          <Link to="/students" className="text-blue-800 mr-4 hover:underline">
            <IoMdArrowBack />
          </Link>
        )}
        <span>{adjustment || 'EDVISE'}</span>
      </div>

      {/* Sidebar Toggle Button (Visible on small screens) */}
      <button
        onClick={toggleSidebar}
        type="button"
        className="inline-flex items-center p-1 mt-1 text-xs text-white rounded-lg sm:hidden hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-200"
        aria-controls="logo-sidebar"
        aria-expanded={isSidebarOpen}
      >
        <span className="sr-only">Open sidebar</span>
        {isSidebarOpen ? (
          <HiX className="w-4 h-4" aria-hidden="true" />
        ) : (
          <HiMenu className="w-4 h-4" aria-hidden="true" />
        )}
      </button>

      {/* Sidebar */}
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-50 w-20 h-screen transition-transform bg-blue-800 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-2 py-3 overflow-y-auto">
          {/* Logo */}
          <Link
            to="/Profile"
            className="flex items-center justify-center mb-2 mx-auto"
          >
            <img
              src={`${process.env.PUBLIC_URL}/edviseicon.png`}
              className="h-10 me-1 sm:h-12"
              alt="Edvise Logo"
            />
          </Link>

          {/* Navigation Items */}
          <ul className="space-y-0 font-xs">
            {/* Dashboard */}
            <li>
              <Link
                to="/dashboard"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <MdOutlineDashboardCustomize className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Dashboard</span>
              </Link>
            </li>
            {/* Students */}
            <li>
              <Link
                to="/students"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <PiStudent className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Students</span>
              </Link>
            </li>
            {/* Pathways */}
            <li>
              <Link
                to="/add-pathways"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsMap className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Pathways</span>
              </Link>
            </li>
            {/* Analytics */}
            <li>
              <Link
                to="/analytics"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsGraphUp className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Analytics</span>
              </Link>
            </li>
            {/* Enrollment Dashboard */}
            <li>
              <Link
                to="/enrollment-dashboard"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <FaChartPie className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px] text-center">
                  Enrollment
                  <br />
                  Dashboard
                </span>
              </Link>
            </li>
            {/* Resources */}
            <li>
              <Link
                to="/resources"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsSignpost2 className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Resources</span>
              </Link>
            </li>
            {/* Staff */}
            <li>
              <Link
                to="/staff"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <FaUserGroup className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Staff</span>
              </Link>
            </li>
            {/* Meetings */}
            <li>
              <Link
                to="/meetings"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsCalendarCheck className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Meetings</span>
              </Link>
            </li>
            {/* Communication */}
            <li>
              <Link
                to="/communications"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <TbMessage className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Communication</span>
              </Link>
            </li>
            {/* Reports */}
            <li>
              <Link
                to="/reports"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsTable className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Reports</span>
              </Link>
            </li>

            <li>
              <Link
                to="/StudentPersistence"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsMortarboard className="w-5 h-5 text-gray-300 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Persistence</span>
              </Link>
            </li>

            {/* Profile dropdown trigger */}
            <li className="relative">
              <button
                ref={buttonRef}
                id="dropdownAvatarNameButton"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group w-full text-left"
                type="button"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <img
                  src={photoURL}
                  alt="Profile"
                  className="w-8 h-8 rounded-full object-cover text-gray-300 group-hover:text-white"
                />                
                <span className="mt-1 text-[10px]">Profile</span>
              </button>
            </li>
          </ul>
        </div>
      </aside>

      {/* Overlay for Sidebar on Mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black opacity-50 sm:hidden"
          onClick={toggleSidebar}
          aria-hidden="true"
        ></div>
      )}

      {/* Dropdown (Portal) */}
      {dropdownOpen && (
        <DropdownPortal>
        <div
          ref={dropdownRef}
          className="fixed z-[9999] bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
          style={{
            top: dropdownPos.top,
            left: dropdownPos.left
          }}
        >
            {/* User info */}
            <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
              <div className="font-medium">{userFullName()}</div>
              <div className="truncate">{currentUser.email}</div>
            </div>
            {/* Extra items */}
            {(
              <ul
                className="text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownAvatarNameButton"
              >
                <li>
                  <NavLink
                    to="/AdvisorProfile"
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Profile
                  </NavLink>
                </li>
              </ul>
            )}
            {/* Logout */}
            <div className="py-1">
              <button
                onClick={handleLogout}
                className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >
                Sign out
              </button>
            </div>
          </div>
        </DropdownPortal>
      )}
    </>
  );
}
