// Filename: StudentsPage.jsx
import React, { useState, useEffect, useCallback } from 'react';
import StudentListing from './StudentListing/StudentListing';
import StudentGroups from './Groups/StudentGroups';
import StudentInitiatives from './Initiatives/StudentInitiatives';
import CustomSidebar from '../Sidebar/Sidebar';
import { db, auth } from '../../firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

/**
 * This component:
 * 1. Fetches "advisors" from `advisors` collection -> array of advisor names.
 * 2. Fetches "students" (role=student) from `users` collection.
 * 3. For each student:
 *    - Fetch assignedAdvisorNames via `studentAdvisors/{studentId}` doc.
 *    - Fetch persistenceCategory from `persistence/persistenceCalculations` doc.
 * 4. Passes these to StudentListing for searching & filtering.
 */

const StudentsPage = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [advisors, setAdvisors] = useState([]);

  // Active tab: "listing" | "groups" | "initiatives"
  const [activeTab, setActiveTab] = useState('listing');

  useEffect(() => {
    /**
     * Fetch advisor docs -> build an array of "FirstName LastName" for use in the filter multi-select.
     */
    const fetchAdvisorsData = async () => {
      const snapshot = await getDocs(collection(db, 'advisors'));
      const advisorNamesArray = [];
      snapshot.forEach((docSnap) => {
        const data = docSnap.data();
        const fullName = `${data.firstName} ${data.lastName}`;
        advisorNamesArray.push(fullName);
      });
      setAdvisors(advisorNamesArray);
    };

    /**
     * Fetch students (role=student):
     *   1) user doc from "users" collection,
     *   2) assigned advisorIds from "studentAdvisors" doc,
     *   3) persistence doc from "persistence/persistenceCalculations".
     */
    const fetchStudentsData = async () => {
      // Query all users with role=student
      const snapshot = await getDocs(
        collection(db, 'users')
        // If you need a WHERE filter: .where('role', '==', 'student')
      );

      const rawStudents = [];
      snapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === 'student') {
          const {
            firstName,
            lastName,
            email,
            surveyAnswers,
            courseProgress,
            classesTaken,
            classesRegistered,
            lastLoginDate,
          } = data;

          const majors = surveyAnswers?.Majors || 'No Major';
          const minors = surveyAnswers?.Minors || 'No Minor';
          const planned = courseProgress?.planned || 0;
          const taken = courseProgress?.taken || 0;
          const total = courseProgress?.total || 0;

          rawStudents.push({
            id: docSnap.id,
            name: `${firstName} ${lastName}`,
            email: email || 'N/A',
            firstName,
            lastName,
            GPA: surveyAnswers?.GPA || '',
            surveyAnswers,
            major: majors,
            minor: minors,
            planned,
            taken,
            total,
            classesTaken: classesTaken || 0,
            classesRegistered: classesRegistered || 0,
            lastLoginDate,
          });
        }
      });

      /**
       * Next, build a map of advisorId->advisorName.
       * We'll use this map when merging assigned advisor names.
       */
      const advisorsSnapshot = await getDocs(collection(db, 'advisors'));
      const advisorMap = {};
      advisorsSnapshot.forEach((advDoc) => {
        const advData = advDoc.data();
        advisorMap[advDoc.id] = `${advData.firstName} ${advData.lastName}`;
      });

      /**
       * For each student, fetch:
       *   - `studentAdvisors/{studentId}` doc -> array of advisorIds
       *   - `persistence/persistenceCalculations` doc -> "prediction" field
       */
      const mergedPromises = rawStudents.map(async (student) => {
        // 1) StudentAdvisors
        const studentAdvisorsRef = doc(db, 'studentAdvisors', student.id);
        const studentAdvisorsSnap = await getDoc(studentAdvisorsRef);
        let assignedAdvisorNames = [];
        if (studentAdvisorsSnap.exists()) {
          const { advisorIds = [] } = studentAdvisorsSnap.data();
          assignedAdvisorNames = advisorIds.map(
            (aid) => advisorMap[aid] || 'Unknown Advisor'
          );
        }

        // 2) Persistence doc
        const persistenceRef = doc(
          db,
          'users',
          student.id,
          'persistence',
          'persistenceCalculations'
        );
        const persistenceSnap = await getDoc(persistenceRef);
        let persistenceCategory = '';
        if (persistenceSnap.exists()) {
          // Suppose the doc has { prediction: "Very Low" } etc.
          const { prediction } = persistenceSnap.data();
          // If it's already "Very Low", "Low", etc., just use it directly:
          persistenceCategory = prediction || '';
        }

        return {
          ...student,
          assignedAdvisorNames,
          persistenceCategory,
        };
      });

      const finalStudents = await Promise.all(mergedPromises);
      setStudents(finalStudents);
      setFilteredStudents(finalStudents);
    };

    const runAllFetches = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error('No authenticated user found.');
          return;
        }
        await fetchAdvisorsData();
        await fetchStudentsData();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    runAllFetches();
  }, []);

  /**
   * Basic search callback (just name-based for example).
   */
  const handleSearch = useCallback(
    (searchTerm) => {
      const filtered = students.filter((stu) =>
        stu.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredStudents(filtered);
    },
    [students]
  );

  /**
   * Basic filter callback (we do more advanced logic in StudentListing).
   */
  const handleFilter = useCallback(
    (filters) => {
      // We typically let StudentListing do the deeper filtering
      // but we'll store a shallow copy here for future usage.
      const copy = [...students];
      setFilteredStudents(copy);
    },
    [students]
  );

  // Render
  return (
    <div className="flex">
      {/* SIDEBAR */}
      <CustomSidebar adjustment="Student Search" />

      {/* MAIN CONTENT */}
      <div className="mt-4 min-h-[96vh] ml-[4vw] min-w-[94vw] max-w-[92vw] flex-1 px-3">
        {/* TAB BAR */}
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mb-3">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                onClick={() => setActiveTab('listing')}
                className={`inline-block py-2 px-4 border-b-2 rounded-t-lg ${
                  activeTab === 'listing'
                    ? 'text-blue-600 border-blue-600'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                }`}
              >
                Student Listing
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setActiveTab('groups')}
                className={`inline-block py-2 px-4 border-b-2 rounded-t-lg ${
                  activeTab === 'groups'
                    ? 'text-blue-600 border-blue-600'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                }`}
              >
                Student Groups
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setActiveTab('initiatives')}
                className={`inline-block py-2 px-4 border-b-2 rounded-t-lg ${
                  activeTab === 'initiatives'
                    ? 'text-blue-600 border-blue-600'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                }`}
              >
                Initiatives
              </button>
            </li>
          </ul>
        </div>

        {/* TAB CONTENT */}
        {activeTab === 'listing' && (
          <StudentListing
            students={filteredStudents}
            advisors={advisors}
            onSearch={handleSearch}
            onFilter={handleFilter}
          />
        )}

        {activeTab === 'groups' && (
          <StudentGroups students={students} advisors={advisors} />
        )}

        {activeTab === 'initiatives' && <StudentInitiatives />}
      </div>
    </div>
  );
};

export default StudentsPage;
