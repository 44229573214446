import React, { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../HS/FinalizedCourses.css';
import PossibilitiesModal from './PossibilitiesModal';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../firebase';
import { FaCheck, FaCheckDouble, FaPlus } from 'react-icons/fa';
import CourseRendering from './CourseRendering';
import { toast } from 'react-toastify';
import ElectiveSidebar from './ElectiveSidebar';

// 1) Import the useTranslation hook
import { useTranslation } from 'react-i18next';

const FinalizedCourse = ({
  course,
  onRemove,
  onKeepCourse,
  isPrereqMet,
  onCheckboxChange,
  semester,
  year,
  onPossibilityClick,
  selectedPossibility,
  selectedCourses,
  onShowPossibilitiesClick,
  onShowPossibilitiesClick1,
  onElectiveClick,
  isSelected,
  handleSaveMilestone,
  onAddPlaceholder,
}) => {
  // 2) Initialize the translation hook
  const { t } = useTranslation();

  const [isCardExpanded, setCardExpanded] = useState(false);
  const [showPossibilitiesButton, setShowPossibilitiesButton] = useState(false);

  // Show "Add Placeholder" button if course is an elective or requirement
  const showAddPlaceholderButton =
    course.courseType === 'Elective' || course.courseType === 'Requirement';

  const handleCardClick = () => {
    if (!course.isKept) {
      onCheckboxChange(course, !isSelected);
    }
  };

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setCardExpanded(!isCardExpanded);
    setShowPossibilitiesButton(!isCardExpanded);
  };


  const hasPossibilities =
    course.Possibilities && course.Possibilities.trim().length > 0;

  return (
    <div
      className={`relative flex justify-between card ${
        isSelected ? '!border-black' : ''
      } ${isPrereqMet ? '' : 'bg-orange-200'} ${
        isCardExpanded ? 'expanded' : ''
      } ${course.isKept ? 'bg-blue-100' : ''} ${
        course.courseType === 'Milestone' && course.milestoneExistsInPlan
          ? 'bg-blue-100'
          : ''
      } rounded-lg`}
      onClick={
        course.courseType === 'Elective'
          ? () => onElectiveClick(course.Possibilities, year, semester, course.Requirements)
          : course.courseType === 'Requirement'
          ? () => onShowPossibilitiesClick(course)
          : course.courseType === 'Milestone'
          ? () => handleSaveMilestone(year, course.Requirements, course.Requirements)
          : handleCardClick
      }
    >
      {/* "Add Placeholder" Button */}
      {showAddPlaceholderButton && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onAddPlaceholder(course, year, semester);
          }}
          className="absolute top-2 right-2 text-blue-500 hover:text-blue-700"
          title={t('finalizedCourses.addPlaceholder', 'Add Placeholder')}
        >
          <FaPlus />
        </button>
      )}

      {course.courseType === 'Elective' ? (
        /* Rendering for Elective courses */
        <div className="flex items-center justify-between p-2">
          <div className="w-1/5">
            <div className="course-number mr-4">
              <strong>{t('finalizedCourses.elective', 'Elective')}</strong>
            </div>
            <span
              className={`inline-flex ${
                course.isKept ? 'bg-white' : 'bg-blue-100'
              } text-blue-800 text-[9px] font-medium px-1 py-0.5 rounded mr-11`}
            >
              {course.Credits
                ? `${course.Credits} ${t('finalizedCourses.credits', 'Credits')}`
                : `3-5 ${t('finalizedCourses.credits', 'Credits')}`}
            </span>
          </div>
          <div className="flex-1 w-2/5 text-left pl-4 text-sm truncate">
            <span>{course.Requirements}</span>
          </div>
          <div className="flex flex-col items-start justify-center mr-5 text-left pl-2 text-sm">
            {course.degreeName && (
              <div className="flex items-center justify-start">
                <span className="text-green-500 text-xs bg-green-100 bg-opacity-50 rounded px-2 py-1 font-semibold max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {course.degreeName}
                </span>
              </div>
            )}
            {course.minorName && (
              <div className="flex items-center justify-start mt-1">
                <span className="text-yellow-500 text-xs bg-yellow-100 bg-opacity-50 rounded px-2 py-1 font-semibold max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {course.minorName}
                </span>
              </div>
            )}
          </div>
        </div>
      ) : course.courseType === 'Requirement' ? (
        /* Rendering for Requirement courses */
        <div className="flex items-center justify-between p-2">
          <div className="w-1/5">
            <div className="course-number mr-4">
              <strong>{t('finalizedCourses.requirement', 'Requirement')}</strong>
            </div>
            <span
              className={`inline-flex ${
                course.isKept ? 'bg-white' : 'bg-blue-100'
              } text-blue-800 text-[9px] font-medium px-1 py-0.5 rounded mr-11`}
            >
              {course.Credits
                ? `${course.Credits} ${t('finalizedCourses.credits', 'Credits')}`
                : `3-5 ${t('finalizedCourses.credits', 'Credits')}`}
            </span>
          </div>
          <div className="flex-1 w-2/5 text-left pl-4 text-sm truncate">
            <span>{course.Requirements}</span>
          </div>
          <div className="flex flex-col items-start justify-center mr-5 text-left pl-2 text-sm ">
            {course.degreeName && (
              <div className="flex items-center justify-start">
                <span className="text-green-500 text-xs bg-green-100 bg-opacity-50 rounded px-2 py-1 font-semibold max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {course.degreeName}
                </span>
              </div>
            )}
            {course.minorName && (
              <div className="flex items-center justify-start mt-1">
                <span className="text-yellow-500 text-xs bg-yellow-100 bg-opacity-50 rounded px-2 py-1 font-semibold max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {course.minorName}
                </span>
              </div>
            )}
          </div>
        </div>
      ) : course.courseType === 'Milestone' ? (
        /* Rendering for Milestone courses */
        <div
          className={`flex items-center justify-between py-2 ${
            course.milestoneExistsInPlan ? 'bg-blue-100' : ''
          }`}
        >
          <div className="w-1/5">
            <div className="course-number ml-2 mb-2 mt-1">
              <strong>{t('finalizedCourses.milestone', 'Milestone')}</strong>
            </div>
          </div>
          <div className="flex-1 w-2/5 text-left pl-4 text-sm truncate">
            <span>{course.Requirements}</span>
          </div>
          <div className="flex flex-col items-start justify-center mr-8 text-left text-sm">
            {course.degreeName && (
              <div className="flex items-center justify-start ml-2 ">
                <span className="text-green-500 text-xs bg-green-100 bg-opacity-50 rounded px-2 py-1 font-semibold max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {course.degreeName}
                </span>
              </div>
            )}
            {course.minorName && (
              <div className="flex items-center justify-start mt-1 ">
                <span className="text-yellow-500 text-xs bg-yellow-100 bg-opacity-50 rounded px-2 py-1 font-semibold max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {course.minorName}
                </span>
              </div>
            )}
          </div>
        </div>
      ) : (
        /* Rendering for other (standard) course types */
        <>
          <div className="flex items-center justify-between p-2">
            <div className="w-1/5">
              <div className="course-number mr-4">
                <strong>{course.courseInfo_courseNumber}</strong>
              </div>
              <span
                className={`inline-flex ${
                  course.isKept ? 'bg-white' : 'bg-blue-100'
                } text-blue-800 text-[9px] font-medium px-1 py-0.5 rounded mr-12`}
              >
                {course.Credits
                  ? `${course.Credits} ${t('finalizedCourses.credits', 'Credits')}`
                  : `3-5 ${t('finalizedCourses.credits', 'Credits')}`}
              </span>
            </div>
            <div
              className={`flex-1 w-2/5 text-left pl-4 text-sm font truncate ${
                isCardExpanded ? 'expanded' : ''
              }`}
            >
              <span>{course.courseInfo_courseName}</span>
            </div>
            <div className="flex flex-col items-start justify-center text-left pl-2 text-sm">
              {course.degreeName && (
                <div className="flex items-center justify-start">
                  <span className="text-green-500 text-xs bg-green-100 bg-opacity-50 rounded px-2 py-1 font-semibold max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {course.degreeName}
                  </span>
                </div>
              )}
              {course.minorName && (
                <div className="flex items-center justify-start mt-1">
                  <span className="text-yellow-500 text-xs bg-yellow-100 bg-opacity-50 rounded px-2 py-1 font-semibold max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {course.minorName}
                  </span>
                </div>
              )}
            </div>
            <div
              className={`cursor-pointer ${course.isKept ? 'text-gray-500' : ''}`}
              onClick={handleExpandClick}
            >
              {isCardExpanded ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 15.75l7.5-7.5 7.5 7.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              )}
            </div>
          </div>
          {isCardExpanded && (
            <div className={`expandable-content ${isCardExpanded ? 'expanded' : ''}`}>
              <div className="p-4 text-sm">
              <div className="mb-2 text-left">
                  <strong>{t('finalizedCourses.description', 'Description:')}</strong>
                  <p className="mt-1">{course.Description}</p>
                </div>
                {!isPrereqMet && (
                  <p className="font-bold">
                    {t(
                      'finalizedCourses.prereqsWarning',
                      'Warning: Prerequisites not met.'
                    )}
                  </p>
                )}
                {hasPossibilities &&
                  showPossibilitiesButton &&
                  course.courseInfo_courseNumber !== 'FNCE 3030' && (
                    <div className="mb-2">
                      <button
                        className="justify-start text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:scale-105 show-possibilities-button"
                        onClick={() => onShowPossibilitiesClick(course)}
                      >
                        {t('finalizedCourses.showPossibilities', 'Show Possibilities')}
                      </button>
                    </div>
                  )}
                {hasPossibilities && !showPossibilitiesButton && (
                  <div>
                    <strong>
                      {t('finalizedCourses.possibilitiesLabel', 'Possibilities:')}
                    </strong>
                    <ul className="list-disc pl-4">
                      {course.Possibilities.split(',').map((possibility, index) => (
                        <li key={index}>{possibility}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {course.courseInfo_courseNumber === 'FNCE 3030' && (
                  <div className="mb-2">
                    <button
                      className="text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:scale-105 show-possibilities-button"
                      onClick={() => onShowPossibilitiesClick1(course)}
                    >
                      {t('finalizedCourses.showPossibilities', 'Show Possibilities')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const FinalizedCoursesTwo = ({
  finalizedCourses,
  onRemove,
  prereqClasses,
  yearNumber,
  onCourseSelection,
  onKeepCourse,
  onKeepCourses,
  semester,
  fetchedCourseData,
  transformedMajorRecData,
  electiveData,
  onElectiveClick,
  tabs,
  activeTab,
  selectedMajor1,
  handleKeepElectiveCourse,
  majorRecData,
  finalizedCourses1,
}) => {
  const { currentUser } = useAuth();

  // 2) Initialize the translation hook in the parent as well
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [prerequisites, setPrerequisites] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]); // Array of course IDs
  const [selectedSemester, setSelectedSemester] = useState(semester);
  const [selectedYear, setSelectedYear] = useState(yearNumber);
  const [finalizedSchedule, setFinalizedSchedule] = useState({});
  const [selectedPossibility, setSelectedPossibility] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [finalizedSemesterMilestones, setFinalizedSemesterMilestones] = useState({});
  const [finalizedMilestones, setFinalizedMilestones] = useState({});
  const [selectedElectiveCategory, setSelectedElectiveCategory] = useState([]);
  const [electiveYear, setElectiveYear] = useState([]);
  const [electiveSemester, setElectiveSemester] = useState([]);
  const [electiveRequirement, setElectiveRequirement] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [userSemesterCourses, setUserSemesterCourses] = useState([]);
  const [updatedCourses, setUpdatedCourses] = useState(finalizedCourses);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedPrerequisites = prereqClasses;
        setPrerequisites(fetchedPrerequisites || []);

        const db = getFirestore();
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const userActiveTab = data.activeTab;
          const finalizedSchedule = data.schedules?.[userActiveTab];

          setFinalizedSchedule(finalizedSchedule || {});

          const courseIds =
            finalizedSchedule?.[`year${yearNumber}`]?.[`S${semester}`]?.courses || [];

          const fullCourseObjects = fetchedCourseData.filter((course) =>
            courseIds.includes(course.courseInfo_courseNumber)
          );

          setUserSemesterCourses(fullCourseObjects);

          // Fetch finalizedMilestones
          const fm = data.finalizedMilestones;
          setFinalizedMilestones(fm);

          setFinalizedSemesterMilestones(
            fm?.[`${userActiveTab}`]?.[`year${yearNumber}`]?.[`S${semester}`] || {}
          );
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser.uid, prereqClasses, fetchedCourseData, semester, yearNumber]);

  useEffect(() => {
    // Check and set updated courses with "isKept" logic
    const newUpdatedCourses = finalizedCourses.map((course) => {
      let prerequisiteMet = true; // or your actual logic
      let isKept = course?.isKept; // default from server

      if (course.courseType === 'Elective') {
        if (course.Possibilities === 'Free-NonMajor-Elective') {
          // Example logic for "Free-NonMajor-Elective"
          const majorCourseNumbers = finalizedCourses
            .filter((majorCourse) => majorCourse.courseType === 'Major Course')
            .map((majorCourse) => majorCourse.courseInfo_courseNumber);

          isKept = userSemesterCourses.some(
            (userCourse) => !majorCourseNumbers.includes(userCourse.courseInfo_courseNumber)
          );
        } else {
          // Default check for standard Elective
          isKept = userSemesterCourses.some((userCourse) =>
            userCourse.Elective_fulfillment?.includes(course.Possibilities)
          );
        }
      } else if (course.courseType === 'Requirement') {
        const possibilities = course.Possibilities?.split(',').map((p) => p.trim());
        isKept = userSemesterCourses.some((userCourse) =>
          possibilities?.includes(userCourse.courseInfo_courseNumber)
        );
      } else if (course.courseType === 'Milestone') {
        isKept = finalizedSemesterMilestones['milestones']?.some(
          (milestone) => milestone.Title === course.Requirements
        );
      }

      return {
        ...course,
        isPrereqMet: prerequisiteMet,
        isKept: isKept,
      };
    });

    setUpdatedCourses(newUpdatedCourses);
  }, [finalizedCourses, userSemesterCourses, finalizedSemesterMilestones]);

  const handleElectiveClick = (category, year, semester, requirement) => {
    setSelectedElectiveCategory(category);
    setElectiveYear(year);
    setElectiveSemester(semester);
    setElectiveRequirement(requirement);
    setShowSidebar(true);
  };

  const handleSaveMilestone = async (yearInput, title, description) => {
    let dueDate;
    if (semester === 1) {
      dueDate = `12-20-${2022 + yearInput}`;
    } else if (semester === 2) {
      dueDate = `5-10-${2023 + yearInput}`;
    } else {
      console.error('Invalid semester value. Semester must be 1 or 2.');
    }

    if (!title || !description || !dueDate || !semester || !yearInput) {
      console.error('Invalid input data');
      return;
    }

    const newMilestone = {
      Milestone_ID: `milestone_${Date.now()}`,
      Title: title,
      Description: description,
      DueDate: dueDate,
      Milestone_Year: yearInput,
      Milestone_Sem: semester == '1' ? 'S1' : 'S2',
      createdAt: new Date().toISOString(),
      completed: false,
    };

    try {
      if (currentUser && currentUser.uid) {
        const userDocRef = db.collection('users').doc(currentUser.uid);

        const userDoc = await userDocRef.get();
        if (!userDoc.exists) {
          console.log('No such document!');
          return;
        }
        const userData = userDoc.data();
        const activeTabValue = userData.activeTab;
        const yearKey = `year${yearInput}`;
        const semesterKey = semester == '1' ? 'S1' : 'S2';
        const semesterName = semesterKey === 'S1' ? 'Fall' : 'Spring';
        const yearNumber = parseInt(yearKey.charAt(4));
        const startYear = parseInt(userData?.surveyAnswers?.SchoolStart?.year);
        const specificYear =
          semesterName === 'Fall'
            ? startYear + yearNumber - 1
            : startYear + yearNumber;

        const existingMilestones =
          userData.finalizedMilestones?.[activeTabValue]?.[yearKey]?.[semesterKey]
            ?.milestones || [];

        // Check if a milestone with the same title already exists
        const milestoneExists = existingMilestones.some(
          (milestone) => milestone.Title === title
        );

        let updatedMilestones;

        if (!milestoneExists) {
          // Add new milestone
          updatedMilestones = [...existingMilestones, newMilestone];

          await userDocRef.set(
            {
              finalizedMilestones: {
                ...userData.finalizedMilestones,
                [activeTabValue]: {
                  ...userData.finalizedMilestones?.[activeTabValue],
                  [yearKey]: {
                    ...userData.finalizedMilestones?.[activeTabValue]?.[yearKey],
                    [semesterKey]: { milestones: updatedMilestones },
                  },
                },
              },
            },
            { merge: true }
          );

          toast.success(
            t('finalizedCourses.milestoneAdded', {
              defaultValue:
                'Successfully added Milestone "{{title}}" to your {{semesterName}} {{specificYear}} Plan.',
              title,
              semesterName,
              specificYear,
            })
          );
        } else {
          updatedMilestones = existingMilestones;

          toast.warning(
            t('finalizedCourses.milestoneAlreadyExists', {
              defaultValue:
                'Milestone "{{title}}" already exists in your {{semesterName}} {{specificYear}} Plan.',
              title,
              semesterName,
              specificYear,
            })
          );
        }

        // Update the component state
        setFinalizedMilestones((prev) => ({
          ...prev,
          [activeTabValue]: {
            ...prev?.[activeTabValue],
            [yearKey]: {
              ...prev?.[activeTabValue]?.[yearKey],
              [semesterKey]: { milestones: updatedMilestones },
            },
          },
        }));

        setFinalizedSemesterMilestones((prev) => ({
          ...prev,
          milestones: updatedMilestones,
        }));
      }
    } catch (error) {
      console.error('Error saving milestone:', error);
    }
  };

  const handleCheckboxChange = (course, isChecked) => {
    if (isChecked) {
      setSelectedCourses([...selectedCourses, course.courseInfo_courseNumber]);
    } else {
      setSelectedCourses(
        selectedCourses.filter((id) => id !== course.courseInfo_courseNumber)
      );
    }
  };

  const handleKeepCourseClick = (sel, yr, sem, keepAll = false) => {
    let coursesToKeep;

    if (keepAll) {
      // When keeping all, exclude Elective, Requirement, Milestone
      coursesToKeep = finalizedCourses.filter(
        (course) =>
          course.courseType !== 'Elective' &&
          course.courseType !== 'Requirement' &&
          course.courseType !== 'Milestone'
      );
    } else {
      // Map selected course IDs back to course objects
      coursesToKeep = finalizedCourses.filter((course) =>
        sel.includes(course.courseInfo_courseNumber)
      );
    }

    if (coursesToKeep.length > 0) {
      onKeepCourse(coursesToKeep, yr, sem);
    }

    setSelectedCourses([]);
  };

  const handleShowPossibilitiesClick = (course) => {
    setCurrentCourse(course);
    setShowModal(true);
  };

  const handleShowPossibilitiesClick1 = (course) => {
    setCurrentCourse(course);
    setShowModal1(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentCourse(null);
  };

  const handlePossibilitySelection = async (course, possibility) => {
    const db = getFirestore();
    const docRef = doc(db, 'users', currentUser.uid);

    const updatedSched = { ...finalizedSchedule };
    if (!updatedSched[`year${selectedYear}`]) {
      updatedSched[`year${selectedYear}`] = {};
    }
    if (!updatedSched[`year${selectedYear}`][`S${selectedSemester}`]) {
      updatedSched[`year${selectedYear}`][`S${selectedSemester}`] = { courses: [] };
    }

    updatedSched[`year${selectedYear}`][`S${selectedSemester}`].courses.push(possibility);

    await setDoc(docRef, { finalizedSchedule: updatedSched }, { merge: true });

    setFinalizedSchedule(updatedSched);

    const courseWithPossibility = {
      ...course,
      courseInfo_courseNumber: possibility,
      isKept: true,
    };

    onKeepCourse([courseWithPossibility], selectedYear, selectedSemester);
    setSelectedPossibility(null);
  };

  const handleAddPlaceholder = async (course, yr, sem) => {
    // Example localized toast messages, if needed:
    try {
      // ... the same logic for adding the placeholder

      toast.success(
        t('finalizedCourses.placeholderAdded', {
          defaultValue:
            'Added placeholder for "{{requirements}}" to your schedule.',
          requirements: course.Requirements,
        })
      );
    } catch (error) {
      toast.error(t('finalizedCourses.placeholderAddError', 'Error adding placeholder'));
      console.error('Error adding placeholder:', error);
    }
  };

  if (!finalizedCourses || finalizedCourses.length === 0) {
    return <p>{t('finalizedCourses.noCourses', 'No finalized courses available')}</p>;
  }

  // Define the sort order
  const getOrder = (courseType) => {
    if (!courseType || courseType === 'Major Course') {
      return 1; // Normal courses
    }
    switch (courseType) {
      case 'Elective':
        return 2;
      case 'Requirement':
        return 3;
      case 'Milestone':
        return 4;
      default:
        return 5;
    }
  };

  // Sort the courses
  updatedCourses.sort((a, b) => {
    return getOrder(a.courseType) - getOrder(b.courseType);
  });

  return (
    <div style={{ textAlign: 'center', marginTop: 'auto', padding: 0 }}>
      <div className="majorpage-courses">
        {updatedCourses.map((course, index) => (
          <FinalizedCourse
            key={index}
            course={course}
            onRemove={onRemove}
            isPrereqMet={course.isPrereqMet}
            onKeepCourse={handleKeepCourseClick}
            onCheckboxChange={handleCheckboxChange}
            selectedPossibility={selectedPossibility}
            finalizedSchedule={finalizedSchedule}
            selectedCourses={selectedCourses}
            onShowPossibilitiesClick={handleShowPossibilitiesClick}
            onShowPossibilitiesClick1={handleShowPossibilitiesClick1}
            year={yearNumber}
            semester={semester}
            isSelected={selectedCourses.includes(course.courseInfo_courseNumber)}
            onElectiveClick={handleElectiveClick}
            handleSaveMilestone={handleSaveMilestone}
            onAddPlaceholder={handleAddPlaceholder}
          />
        ))}
      </div>
      <div
        className="flex justify-between items-center mb-4"
        style={{ padding: 0, marginTop: 15 }}
      >
        <div className="flex items-center">
          <div className="mr-2">
            <select
              onChange={(e) => setSelectedSemester(parseInt(e.target.value))}
              className="form-select form-select-sm tutorial-dropdown-semester"
              value={selectedSemester}
            >
              <option value={1}>
                {t('finalizedCourses.semesterFall', 'Fall')}
              </option>
              <option value={2}>
                {t('finalizedCourses.semesterSpring', 'Spring')}
              </option>
            </select>
          </div>
          <div>
            <select
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              className="form-select form-select-sm tutorial-dropdown-year"
              value={selectedYear}
            >
              <option value={1}>{t('finalizedCourses.year1', 'Year 1')}</option>
              <option value={2}>{t('finalizedCourses.year2', 'Year 2')}</option>
              <option value={3}>{t('finalizedCourses.year3', 'Year 3')}</option>
              <option value={4}>{t('finalizedCourses.year4', 'Year 4')}</option>
            </select>
          </div>
        </div>

        <div className="flex justify-end items-center space-x-2">
          <button
            type="button"
            onClick={() =>
              handleKeepCourseClick(selectedCourses, selectedYear, selectedSemester)
            }
            disabled={selectedCourses.length === 0}
            className={`!text-blue-700 !hover:text-white border border-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-2 py-2 text-center transition-all duration-300 flex items-center justify-center ${
              selectedCourses.length === 0
                ? 'hover:bg-blue-200 cursor-not-allowed'
                : 'hover:bg-blue-700 hover:!text-white'
            }`}
          >
            <FaCheck className="w-3 h-3 mr-2" />
            {t('finalizedCourses.keepSelected', 'Keep Selected')}
          </button>
          <button
            type="button"
            onClick={() =>
              handleKeepCourseClick(selectedCourses, selectedYear, selectedSemester, true)
            }
            disabled={finalizedCourses.length === 0}
            className={`!text-blue-700 hover:!text-white border border-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:bg-blue-500 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-2 py-2 text-center transition-all duration-300 flex items-center justify-center ${
              finalizedCourses.length === 0
                ? 'border-gray-500 cursor-not-allowed'
                : 'border-gray-500 hover:!bg-blue-700'
            }`}
          >
            <FaCheckDouble className="w-3 h-3 mr-2" />
            {t('finalizedCourses.keepAll', 'Keep All')}
          </button>
        </div>
      </div>

      {showModal && currentCourse && (
        <PossibilitiesModal
          course={currentCourse}
          isOpen={true}
          onClose={handleCloseModal}
          onKeepCourse={handleKeepElectiveCourse}
          year={selectedYear}
          semester={selectedSemester}
          finalizedSchedule={finalizedSchedule}
          majorRecData={majorRecData}
          finalizedCourses={finalizedCourses1}
          handleSaveMilestone={handleSaveMilestone}
        />
      )}
      {showSidebar && (
        <ElectiveSidebar
          onClose={() => setShowSidebar(false)}
          showSidebar={showSidebar}
          currPath={tabs[activeTab]}
          elective={selectedElectiveCategory}
          electiveYear={electiveYear}
          electiveSemester={electiveSemester}
          courses={fetchedCourseData}
          onKeepElectiveCourse={handleKeepElectiveCourse}
          majorRecData={transformedMajorRecData}
          requirement={electiveRequirement}
        />
      )}
      {/* In case showModal1 is used for a second type of PossibilitiesModal */}
      {showModal1 && currentCourse && null /* or your second modal */}
    </div>
  );
};

export default FinalizedCoursesTwo;
