import React, { useEffect } from 'react';
import StudentAdvisorMeeting from './StudentAdvisorMeeting';

const ScheduleMeetingModal = ({ 
  isOpen, 
  onClose, 
  title, 
  advisors = [], 
  onMeetingScheduled 
}) => {
  // Close the modal when the Escape key is pressed
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
      onClick={onClose} // Close when clicking on the backdrop
    >
      <div
        className="relative w-full max-w-7xl h-full max-h-full"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700 flex flex-col h-full">
          {/* Modal header */}
          <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
              {title}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          {/* Modal body */}
          <div className="p-6 space-y-6 overflow-y-auto flex-grow bg-white dark:bg-gray-700">
            <div className="flex flex-col space-y-1">
              {advisors.length > 0 ? (
                <StudentAdvisorMeeting 
                  advisors={advisors} 
                  onClose={onClose} 
                  onMeetingScheduled={onMeetingScheduled}
                />
              ) : (
                <p className="text-gray-500">You have no assigned advisors.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleMeetingModal;
