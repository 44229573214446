import React, { useState, useEffect, useRef } from 'react';
import { db, auth } from '../firebase';
import { FaFilter, FaPlus, FaCheck, FaTimes } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';

const ElectiveSidebar = ({
  onClose,
  elective,
  showSidebar,
  courses,
  onKeepElectiveCourse,
  electiveYear,
  electiveSemester,
  majorRecData,
  possibilities,
  requirement,
  currPath
}) => {
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [keptCourses1, setKeptCourses1] = useState([]);
  const [showRecommendedCourses, setShowRecommendedCourses] = useState(false);
  const sidebarRef = useRef(null);
  const dropdownRef = useRef(null);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [userRecsData, setUserRecsData] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [levelFilter, setLevelFilter] = useState([]);
  const [showKeptCourses, setShowKeptCourses] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [surveyAnswers, setSurveyAnswers] = useState({});
  const { fetchedMajors, pathwayGroupingsData, pathwayData } = useAuth();

  // State to control the mounting/unmounting of the component
  const [isMounted, setIsMounted] = useState(false);
  // State to control the visibility (transition in/out)
  const [isVisible, setIsVisible] = useState(false);

  const levelFilterMap = {
    '1': '100',
    '2': '200',
    '3': '300',
    '4': '400',
    '5': '500',
    '6': '600',
    '7': '700',
    '8': '800',
  };

  const toggleInfo = () => {
    setShowInfo((prev) => !prev);
  };

  console.log('See this: ', elective, electiveYear, electiveSemester, requirement, currPath);

  // Fetch user data and set keptCourses1 once on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        setCurrentUser(user);
        if (user) {
          const userDocRef = db.collection('users').doc(user.uid);
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab;
            const keptCourseNumbers = getUserKeptCourses(userData.schedules[activeTab]);
            setKeptCourses1(keptCourseNumbers);

            const backendUserRecData = userData.recommendationRatings || {};
            setUserRecsData(backendUserRecData);

            const backendSurveyAnswers = userData.surveyAnswers || {};
            setSurveyAnswers(backendSurveyAnswers);

            // Do not calculate recommendedCourses here
          }
        }
      } catch (error) {
        console.error('User data not found');
      }
    };
    fetchUserData();
  }, []); 

  useEffect(() => {
    const filtered = courses.filter((course) => {
      // Elective fulfillment
      const electiveFulfillments = course.Elective_fulfillment
        ? course.Elective_fulfillment.split(", ")
        : [];
  
      // Search by course name
      const courseName = course.courseInfo_courseName || "";
      const matchesSearch = courseName
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
  
      // Extract numeric portion from courseInfo_courseNumber
      const courseNumber = course.courseInfo_courseNumber || "";
      const match = courseNumber.match(/\d+/);
      const numericPart = match ? match[0] : ""; // e.g. "101"
  
      // Level filter
      const matchesLevel =
        levelFilter.length > 0
          ? levelFilter.some((level) => numericPart.startsWith(level))
          : true;
  
      // Kept courses filter
      const matchesKept = showKeptCourses
        ? keptCourses1.includes(course.courseInfo_courseNumber)
        : true;
  
      // If it's a "Free-NonMajor-Elective", exclude courses matching the major’s prefix
      if (elective === "Free-NonMajor-Elective") {
        const pathway = pathwayData.find((p) => p.Major === currPath);
        const designation = pathway ? pathway.Designation : null;
        if (designation) {
          // The first part of courseNumber might be "ENGL" in "ENGL 101" or "ENGL101"
          // but let's do a more robust prefix check:
          const alphaPrefix = courseNumber.match(/[A-Za-z]+/)?.[0] || "";
  
          return (
            !alphaPrefix.startsWith(designation) &&
            matchesSearch &&
            matchesLevel &&
            matchesKept
          );
        }
      }
  
      // Otherwise, for normal electives
      return (
        electiveFulfillments.includes(elective) &&
        matchesSearch &&
        matchesLevel &&
        matchesKept
      );
    });
  
    setFilteredCourses(filtered);
  }, [
    searchQuery,
    levelFilter,
    showKeptCourses,
    elective,
    courses,
    keptCourses1,
    pathwayData,
    currPath
  ]);  
  
  // Calculate recommendedCourses whenever dependencies change
  useEffect(() => {
    if (currentUser && userRecsData && surveyAnswers && filteredCourses.length > 0) {
      const calculateRecScore = require('../CourseRecommendation');
      const recommendedCourses = filteredCourses
        .map((course) => {
          const recScore = calculateRecScore(
            majorRecData[course.Major],
            userRecsData,
            course,
            surveyAnswers // Replace with actual surveyAnswers if available
          );
          return { ...course, recScore };
        })
        .sort((a, b) => b.recScore - a.recScore);
      setRecommendedCourses(recommendedCourses);
    }
  }, [filteredCourses, majorRecData, userRecsData, currentUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        event.target.closest('#crud-modal') === null &&
        event.target.closest('#alert-additional-content-1') === null
      ) {
        onClose();
      }
    };

    if (showSidebar) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSidebar, onClose]);

  const handleResetFilters = () => {
    setShowRecommendedCourses(false);
    setSearchQuery('');
    setLevelFilter([]);
    setShowKeptCourses(false);
  };

  const handleShowRecommendations = async () => {
    try {
      setShowRecommendedCourses(!showRecommendedCourses);
    } catch (error) {
      console.error('Error fetching recommended courses: ', error);
    }
  };

  const getUserKeptCourses = (finalizedSchedule) => {
    const keptCourses = [];
    for (const year in finalizedSchedule) {
      for (const sem in finalizedSchedule[year]) {
        if (finalizedSchedule[year][sem].courses) {
          keptCourses.push(...finalizedSchedule[year][sem].courses);
        }
      }
    }
    return keptCourses;
  };

  const handleAddCourse = async (course) => {
    if (!keptCourses1.includes(course.courseInfo_courseNumber)) {
      onKeepElectiveCourse(course, electiveYear, electiveSemester);
      setKeptCourses1((prevKeptCourses) => [
        ...prevKeptCourses,
        course.courseInfo_courseNumber,
      ]);
      if (currentUser && currentUser.uid) {
        const userDocRef = db.collection('users').doc(currentUser.uid);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            transaction.update(userDocRef, {
              finalizedCat: elective,
            });
          }
        });
      }
    }
  };

  const getBackgroundGradient = (score) => {
    const green = [144, 238, 144];
    const yellow = [255, 255, 224];
    const red = [255, 182, 193];

    let startColor, endColor;
    let percentage;

    if (score >= 50) {
      startColor = yellow;
      endColor = green;
      percentage = (score - 50) / 50;
    } else {
      startColor = red;
      endColor = yellow;
      percentage = score / 50;
    }

    const interpolateColor = (start, end, factor) =>
      start.map((startComponent, index) =>
        Math.round(startComponent + factor * (end[index] - startComponent))
      );

    const [r, g, b] = interpolateColor(startColor, endColor, percentage);

    return `rgb(${r}, ${g}, ${b})`;
  };

  const renderFilterTags = () => {
    const tags = [];
    levelFilter.forEach((level) => {
      tags.push({
        label: `${levelFilterMap[level]} level`,
        type: 'level',
        value: level,
      });
    });
    if (showRecommendedCourses) {
      tags.push({ label: 'Recommended', type: 'recommended' });
    }
    if (showKeptCourses) {
      tags.push({ label: 'Kept', type: 'kept' });
    }

    return tags.map((tag) => (
      <div
        key={tag.type + tag.value}
        className="bg-blue-100 bg-opacity-50 text-blue-700 font-semibold rounded-lg text-sm px-2 py-1 mr-2 flex items-center"
      >
        {tag.label}
        <button
          onClick={() => {
            if (tag.type === 'level')
              setLevelFilter(levelFilter.filter((level) => level !== tag.value));
            if (tag.type === 'recommended') setShowRecommendedCourses(false);
            if (tag.type === 'kept') setShowKeptCourses(false);
          }}
          className="ml-2 text-blue-900 hover:text-blue-600"
        >
          <FaTimes />
        </button>
      </div>
    ));
  };

  const handleLevelChange = (e) => {
    const selectedLevel = e.target.value;
    if (!levelFilter.includes(selectedLevel)) {
      setLevelFilter([...levelFilter, selectedLevel]);
    }
  };

  // Effect to manage mounting and animation
  useEffect(() => {
    if (showSidebar) {
      setIsMounted(true);
      setTimeout(() => setIsVisible(true), 10); // Delay to ensure the animation runs
    } else {
      setIsVisible(false); // Trigger fade-out transition
      setTimeout(() => setIsMounted(false), 300); // Delay unmounting until transition ends
    }
  }, [showSidebar]);

  if (!isMounted) return null; // Don't render if not mounted

  return (
    <>
      <div
        className={`fixed top-0 right-0 z-50 h-screen p-2 elective-sidebar overflow-y-auto transition-transform transform ${
          isVisible ? 'translate-x-0' : 'translate-x-full'
        } bg-white w-1/3 shadow-lg`}
        tabIndex="-1"
        aria-labelledby="drawer-right-label"
        style={{ transition: 'transform 0.3s ease-in-out' }}
      >
        <div ref={sidebarRef} className="flex flex-col h-full">
          {/* Header with Info Icon and Close Button */}
          <div className="flex items-center justify-between mb-2">
            <button
              className="p-1 rounded text-gray-600"
              onClick={toggleInfo}
              aria-label="Show Info"
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </button>
            <button
              type="button"
              onClick={onClose}
              className="text-gray-400 hover:text-gray-900 rounded-lg text-sm w-6 h-6 inline-flex items-center justify-center"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"
                />
              </svg>
              <span className="sr-only">Close menu</span>
            </button>
          </div>

          {/* Elective Requirement */}
          {elective && (
            <>
              <div className="p-2 text-center">
                <h4 className="text-lg font-medium text-gray-700">
                  Elective Category:{' '}
                  <span className="text-lg font-light text-gray-600">
                    {requirement}
                  </span>
                </h4>
              </div>

              {/* Search Filters */}
              <div className="p-2 border-b border-gray-300 text-center">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search courses..."
                  className="w-full p-2 border border-gray-300 rounded mb-2"
                />
                <select
                  value=""
                  onChange={handleLevelChange}
                  className="w-full p-2 border border-gray-300 rounded mb-2"
                >
                  <option value="">Select Level</option>
                  <option value="1">100</option>
                  <option value="2">200</option>
                  <option value="3">300</option>
                  <option value="4">400</option>
                </select>
                <div className="flex items-center justify-end mt-2 relative">
                  <div className="flex items-center mr-2">
                    {renderFilterTags()}
                  </div>
                  <button
                    className="text-gray-500 hover:text-gray-900 focus:outline-none"
                    onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                  >
                    <FaFilter className="text-xl" />
                  </button>
                  {showFilterDropdown && (
                    <div
                      ref={dropdownRef}
                      className="absolute top-full right-0 p-2 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg"
                      style={{ zIndex: 1000 }}
                    >
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none"
                        onClick={() => setShowKeptCourses(!showKeptCourses)}
                      >
                        {showKeptCourses ? 'Show All Courses' : 'Show Kept Courses'}
                      </button>
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none"
                        onClick={handleShowRecommendations}
                      >
                        {showRecommendedCourses
                          ? 'Hide Recommendations'
                          : 'Show Recommendations'}
                      </button>
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none"
                        onClick={handleResetFilters}
                      >
                        Reset Filters
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {/* Course List */}
          <div className="flex-1 overflow-y-auto">
            {filteredCourses.length > 0 ? (
              <>
                {(showRecommendedCourses ? recommendedCourses : filteredCourses).map(
                  (course, index) => (
                    <div
                      key={course.courseInfo_courseNumber}
                      className={`flex justify-between p-4 mb-4 border border-gray-300 rounded-lg bg-white shadow-sm cursor-pointer hover:bg-gray-100 transition duration-200 ${
                        index === 0 ? 'mt-4' : ''
                      }`}
                      style={{ width: '95%', margin: '0 auto' }}
                    >
                      <div className="w-1/2">
                        <div className="flex justify-between items-center mb-2">
                          <div className="flex items-center">
                            <span className="font-medium text-lg">
                              {course.courseInfo_courseNumber}
                            </span>
                            {showRecommendedCourses && (
                              <span
                                style={{
                                  background: getBackgroundGradient(course.recScore),
                                }}
                                className="ml-3 px-2 py-1 rounded-md text-xs font-semibold"
                              >
                                {course.recScore}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-sm text-left text-gray-600 mb-2">
                          {course.courseInfo_courseName}
                        </div>
                        <div className="text-sm text-left text-gray-600">
                          <span className="font-bold">Credits: </span>
                          {course.Credits}
                        </div>
                      </div>
                      <button
                        onClick={() => handleAddCourse(course)}
                        className={`${
                          keptCourses1.includes(course.courseInfo_courseNumber)
                            ? 'text-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300'
                            : 'text-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300'
                        } font-medium rounded-lg text-sm px-w text-center`}
                        disabled={keptCourses1.includes(
                          course.courseInfo_courseNumber
                        )}
                      >
                        {keptCourses1.includes(course.courseInfo_courseNumber) ? (
                          <span className="inline-flex items-center justify-center w-14 h-14 bg-green-100 rounded-full text-green-700">
                            <FaCheck />
                          </span>
                        ) : (
                          <span className="inline-flex items-center justify-center w-14 h-14 bg-blue-100 rounded-full text-blue-700">
                            <FaPlus />
                          </span>
                        )}
                      </button>
                    </div>
                  )
                )}
              </>
            ) : (
              <p className="text-gray-600 italic text-center">
                No courses found for this category.
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Info Alert */}
      {showInfo && (
        <div
          id="alert-additional-content-1"
          className="fixed top-4 right-4 z-[9999] p-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50"
          role="alert"
          style={{ width: '25rem' }}
        >
          <div className="flex items-center">
            <svg
              className="flex-shrink-0 w-4 h-4 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 1 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium">Elective Sidebar Info</h3>
          </div>
          <div className="mt-2 mb-4 text-sm">
            See all the possible elective courses that fulfill the requirement for the selected elective category.
          </div>
          <div className="flex">
            <button
              type="button"
              className="text-blue-800 bg-blue-50 border border-blue-800 hover:bg-blue-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 text-center"
              onClick={toggleInfo}
              aria-label="Close"
            >
              Dismiss
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ElectiveSidebar;
