import React from 'react';
import { useTranslation } from 'react-i18next';

const InterestsList = ({ authUser }) => {
  // 1. Initialize the translation function
  const { t } = useTranslation();

  const RecPersonality = authUser?.surveyAnswers?.RecPersonality || [];
  const RecSkills = authUser?.surveyAnswers?.RecSkills || [];
  const RecInterests = authUser?.surveyAnswers?.RecInterests || [];

  return (
    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
      {/* Heading */}
      <h2 className="text-gray-700 font-semibold mb-2">
        {t('interestsList.title', 'Traits, Skills, and Interests')}
      </h2>

      <div className="overflow-y-auto max-h-[17rem]">
        <div className="flex flex-wrap">

          {/* Personality Traits Section */}
          <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">
            {t('interestsList.personalityTraits', 'Personality Traits')}
          </h3>
          {Array.isArray(RecPersonality) && RecPersonality.length > 0 ? (
            RecPersonality.map((trait, index) => (
              <span
                key={index}
                className="bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400 mb-2"
              >
                {trait}
              </span>
            ))
          ) : (
            <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">
              {t('interestsList.noPersonalityTraits', 'No Personality Traits')}
            </span>
          )}

          {/* Skills Section */}
          <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">
            {t('interestsList.skills', 'Skills')}
          </h3>
          {Array.isArray(RecSkills) && RecSkills.length > 0 ? (
            RecSkills.map((skill, index) => (
              <span
                key={index}
                className="bg-indigo-100 text-indigo-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400 mb-2"
              >
                {skill}
              </span>
            ))
          ) : (
            <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">
              {t('interestsList.noSkills', 'No Skills')}
            </span>
          )}

          {/* Interests Section */}
          <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">
            {t('interestsList.interests', 'Interests')}
          </h3>
          {Array.isArray(RecInterests) && RecInterests.length > 0 ? (
            RecInterests.map((interest, index) => (
              <span
                key={index}
                className="bg-pink-100 text-pink-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2"
              >
                {interest}
              </span>
            ))
          ) : (
            <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">
              {t('interestsList.noInterests', 'No Interests')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default InterestsList;
