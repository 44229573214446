// Staff.js
import React, { useState, useEffect } from 'react';
import CustomSidebar from '../Sidebar/Sidebar';
import UsersList from './UsersList';
import GroupsList from './GroupsList';
import AssignRole from './AssignRole'; // <-- Import your AssignRole component
import { db } from '../../firebase';

const Staff = () => {
  const [activeTab, setActiveTab] = useState('users'); // Manage active tab state
  const [userCount, setUserCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);

  useEffect(() => {
    // Fetch user count (Admins/Super Admins)
    const fetchUserCount = async () => {
      try {
        const snapshot = await db.collection('users').where('role', 'in', ['admin', 'super_admin']).get();
        setUserCount(snapshot.size);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    // Fetch group count
    const fetchGroupCount = async () => {
      try {
        const snapshot = await db.collection('groups').get();
        setGroupCount(snapshot.size);
      } catch (error) {
        console.error('Error fetching group count:', error);
      }
    };

    fetchUserCount();
    fetchGroupCount();
  }, []);

  return (
    <div className="flex min-h-screen">
      <CustomSidebar adjustment="Staff" />
      <div className="flex-1 p-4 ml-20 flex flex-col">
        {/* Enhanced Header Section */}
        <div className="text-center mb-6 mt-3">
          <h1 className="text-3xl font-bold mb-2">Staff Management</h1>
          <p className="text-base text-gray-600">
            Welcome to the Staff Management Dashboard. Here you can view and manage staff members, assign roles, 
            and oversee group allocations. Utilize the tabs below to navigate between Users, Groups, and Role Assignment.
          </p>
        </div>

        {/* Tab Navigation */}
        <div className="border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" role="tablist">
            {/* Users Tab */}
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors ${
                  activeTab === 'users'
                    ? 'text-blue-700 border-blue-700'
                    : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                }`}
                onClick={() => setActiveTab('users')}
                type="button"
                role="tab"
              >
                Users ({userCount})
              </button>
            </li>

            {/* Groups Tab */}
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors ${
                  activeTab === 'groups'
                    ? 'text-green-700 border-green-700'
                    : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                }`}
                onClick={() => setActiveTab('groups')}
                type="button"
                role="tab"
              >
                Groups ({groupCount})
              </button>
            </li>

            {/* Assign Roles Tab */}
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors ${
                  activeTab === 'assignRole'
                    ? 'text-purple-700 border-purple-700'
                    : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                }`}
                onClick={() => setActiveTab('assignRole')}
                type="button"
                role="tab"
              >
                Assign Roles
              </button>
            </li>
          </ul>
        </div>

        {/* Tab Content */}
        <div className="mt-6">
            {activeTab === 'users' && (
              <div role="tabpanel">
                <UsersList updateUserCount={setUserCount} />
              </div>
            )}

            {activeTab === 'groups' && (
              <div role="tabpanel">
                <GroupsList updateGroupCount={setGroupCount} />
              </div>
            )}

            {activeTab === 'assignRole' && (
              <div role="tabpanel">
                <AssignRole />
              </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default Staff;
