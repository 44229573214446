// StudentListing.jsx
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { db } from '../../../firebase';
import StudentItem from './StudentItem';
import SearchComponent from './SearchComponent';
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { FaTimes, FaPlus } from 'react-icons/fa';
import CampaignModal from './CampaignModal';
import TemplateModal from './TemplateModal';

// Helper for "veryHigh" => "Very High", etc. (if needed)
function formatPrediction(prediction) {
  switch (prediction) {
    case 'Very High':
    case 'High':
    case 'Moderate':
    case 'Low':
    case 'Very Low':
      return prediction;
    default:
      return 'Unknown';
  }
}

const StudentListing = ({
  students,
  adjustment,
  selectedResource,
  onClose,
  advisors,
}) => {
  const [filteredStudents, setFilteredStudents] = useState(students);
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const location = useLocation();
  const { filterType, filterValue } = location.state || {};
  const { currentUser } = useAuth();

  const [isCampaignModalOpen, setIsCampaignModalOpen] = useState(false);

  // Which fields we skip in the custom filter logic
  const excludeFields = ['id', 'surveyAnswers', 'name', 'email'];

  // We'll detect types just like before
  const fieldTypes = useMemo(() => {
    const types = {};
    if (students.length > 0) {
      const s0 = students[0];
      Object.keys(s0).forEach((field) => {
        if (!excludeFields.includes(field)) {
          const value = s0[field];
          if (Array.isArray(value)) {
            if (value.length > 0) {
              types[field] = `array-${typeof value[0]}`;
            } else {
              types[field] = 'array';
            }
          } else {
            types[field] = typeof value;
          }
        }
      });
    }
    return types;
  }, [students]);

  // On mount, fetch "selectedStudents" from Firestore
  useEffect(() => {
    const fetchSelectedStudents = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setSelectedStudents(data.selectedStudents || []);
        }
      }
    };
    fetchSelectedStudents();
  }, [currentUser]);

  // Called when the user checks/unchecks a student
  const handleCheckboxChange = useCallback(
    async (studentId, isChecked) => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        if (isChecked) {
          await updateDoc(userRef, {
            selectedStudents: arrayUnion(studentId),
          });
          setSelectedStudents((prev) => [...prev, studentId]);
        } else {
          await updateDoc(userRef, {
            selectedStudents: arrayRemove(studentId),
          });
          setSelectedStudents((prev) => prev.filter((id) => id !== studentId));
        }
      } catch (error) {
        console.error('Error updating selectedStudents:', error);
        toast.error('Failed to update selection. Please try again.');
      }
    },
    [currentUser]
  );

  // "Select All" button
  const handleSelectAll = useCallback(async () => {
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      if (filteredStudents.every((stu) => selectedStudents.includes(stu.id))) {
        // Unselect
        const updated = selectedStudents.filter(
          (id) => !filteredStudents.some((stu) => stu.id === id)
        );
        await updateDoc(userRef, { selectedStudents: updated });
        setSelectedStudents(updated);
      } else {
        // Select
        const allIds = filteredStudents.map((stu) => stu.id);
        await updateDoc(userRef, { selectedStudents: allIds });
        setSelectedStudents(allIds);
      }
    } catch (err) {
      console.error('Error selecting all:', err);
      toast.error('Failed to select all. Please try again.');
    }
  }, [currentUser, filteredStudents, selectedStudents]);

  // "Search" callback
  const handleSearch = useCallback((term) => {
    setSearchTerm(term);
  }, []);

  // "Filter" callback
  const handleFilter = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const toggleTemplateModalVisibility = () => {
    if (selectedStudents.length === 0) {
      toast.warning('Please select student(s) to add a template to.');
    } else {
      setIsTemplateModalVisible(!isTemplateModalVisible);
    }
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        setIsTemplateModalVisible(false);
        setIsCampaignModalOpen(false);
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  // Example: if "adjustment" === "Dashboard", filter only that advisor's students
  useEffect(() => {
    const fetchAdvisorStudents = async () => {
      if (currentUser && adjustment === 'Dashboard') {
        try {
          const studentAdvisorsRef = collection(db, 'studentAdvisors');
          const q = query(
            studentAdvisorsRef,
            where('advisorIds', 'array-contains', currentUser.uid)
          );
          const qsnap = await getDocs(q);
          const ids = qsnap.docs.map((doc) => doc.id);
          setFilteredStudents((prev) =>
            prev.filter((stu) => ids.includes(stu.id))
          );
        } catch (error) {
          console.error('Error fetching advisor students:', error);
          toast.error('Failed to fetch students.');
        }
      }
    };
    fetchAdvisorStudents();
  }, [currentUser, adjustment]);

  // Recommending a resource (if "adjustment === 'Resources'")
  const handleRecommendResource = useCallback(
    async (studentId) => {
      if (!selectedResource) return;
      try {
        const studentRef = doc(db, 'users', studentId);
        await updateDoc(studentRef, {
          Resources: arrayUnion(selectedResource.id),
        });
      } catch (error) {
        console.error('Error recommending resource:', error);
        toast.error('Failed to recommend resource. Please try again.');
      }
    },
    [selectedResource]
  );

  // Filter + search + sort
  useEffect(() => {
    let filtered = [...students];

    // 1) search
    if (searchTerm) {
      filtered = filtered.filter((stu) =>
        stu.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // 2) gradYear
    if (filters.gradYear && filters.gradYear.length > 0) {
      filtered = filtered.filter((stu) =>
        filters.gradYear.includes(stu.surveyAnswers?.SchoolEnd?.year)
      );
    }

    // 3) program
    if (filters.program && filters.program.length > 0) {
      filtered = filtered.filter((stu) => {
        if (Array.isArray(stu.major)) {
          return stu.major.some((prog) => filters.program.includes(prog));
        } else {
          return filters.program.includes(stu.major);
        }
      });
    }

    // 4) advisors
    if (filters.advisors && filters.advisors.length > 0) {
      filtered = filtered.filter((stu) =>
        stu.assignedAdvisorNames?.some((advName) =>
          filters.advisors.includes(advName)
        )
      );
    }

    // 5) NEW: persistence filter (replaces old performance block)
    if (filters.persistence && filters.persistence.length > 0) {
      filtered = filtered.filter((stu) => {
        // stu.persistenceCategory might be "Very Low", "Low", ...
        if (!stu.persistenceCategory) return false;
        return filters.persistence.includes(stu.persistenceCategory);
      });
    }

    // 6) customFilter
    if (
      filters.customFilter &&
      filters.customFilter.field &&
      filters.customFilter.operator &&
      filters.customFilter.value
    ) {
      const { field, operator, value } = filters.customFilter;
      const fType = fieldTypes[field];
      filtered = filtered.filter((stu) => {
        const val = stu[field];
        if (val == null) return false;
        if (operator === 'equals') {
          if (Array.isArray(val)) {
            return val.some(
              (v) =>
                String(v).trim().toLowerCase() === value.trim().toLowerCase()
            );
          } else {
            return (
              String(val).trim().toLowerCase() === value.trim().toLowerCase()
            );
          }
        } else if (operator === 'greater than') {
          if (fType === 'number') {
            return parseFloat(val) > parseFloat(value);
          } else if (fType?.startsWith('array-')) {
            return val.some((v) => parseFloat(v) > parseFloat(value));
          }
          return false;
        } else if (operator === 'less than') {
          if (fType === 'number') {
            return parseFloat(val) < parseFloat(value);
          } else if (fType?.startsWith('array-')) {
            return val.some((v) => parseFloat(v) < parseFloat(value));
          }
          return false;
        }
        return true; // unknown operator
      });
    }

    // 7) sort by name
    if (sortOrder) {
      filtered.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        if (aName < bName) return sortOrder === 'asc' ? -1 : 1;
        if (aName > bName) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setFilteredStudents(filtered);
  }, [students, searchTerm, filters, sortOrder, fieldTypes]);

  const everythingSelected = filteredStudents.every((stu) =>
    selectedStudents.includes(stu.id)
  );

  const selectedStudentsData = useMemo(() => {
    return students.filter((stu) => selectedStudents.includes(stu.id));
  }, [students, selectedStudents]);

  const handleCampaignClick = () => {
    if (selectedStudentsData.length === 0) {
      toast.warning('Please select at least one student.');
      return;
    }
    setIsCampaignModalOpen(true);
  };

  return (
    <div className="flex w-full">
      {adjustment !== 'Resources' && (
        <div className="w-1/3">
          <SearchComponent
            students={students}
            fieldTypes={fieldTypes}
            onSearch={handleSearch}
            onFilter={handleFilter}
            onSelectAll={handleSelectAll}
            selectedStudents={selectedStudents}
            filteredStudentsCount={filteredStudents.length}
            everythingSelected={everythingSelected}
            advisors={advisors}
            dashboardFilterType={filterType}
            dashboardFilterValue={filterValue}
          />
        </div>
      )}

      <div className="w-full ml-3">
        <div className="p-4 bg-gray-50 shadow-md border rounded-lg min-h-[70vh]">
          <div className="items-center justify-center mb-4 relative">
            {adjustment === 'Resources' ? (
              <>
                <h5 className="text-lg font-bold text-center">Select a Student</h5>
                <button
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                  onClick={onClose}
                >
                  <FaTimes size={20} />
                </button>
              </>
            ) : (
              <>
                <h5 className="text-lg font-bold text-center">Students</h5>
                <button
                  className={`absolute top-0 right-2 ${
                    selectedStudents.length === 0
                      ? 'bg-blue-300'
                      : 'bg-blue-500 hover:bg-blue-700'
                  } text-white text-sm py-2 px-3 rounded`}
                  onClick={handleCampaignClick}
                >
                  Send Campaign
                </button>
                <button
                  onClick={toggleTemplateModalVisibility}
                  className={`absolute top-0 right-40 px-3 py-2 rounded text-sm flex items-center space-x-2 justify-center ${
                    selectedStudents.length === 0
                      ? 'bg-blue-300'
                      : 'bg-blue-500 hover:bg-blue-700'
                  } text-white`}
                >
                  <FaPlus size={14} />
                  <span className="text-sm">Templates</span>
                </button>
              </>
            )}
          </div>

          {/* Column Headers */}
          <div className="grid grid-cols-7 font-semibold text-sm text-gray-500 mb-2">
            {adjustment !== 'Resources' && (
              <div className="col-span-1 ml-3 text-left">
                Select <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
              </div>
            )}
            <div
              className={`${
                adjustment !== 'Resources' ? 'col-span-1' : 'col-span-4'
              } text-center mr-10`}
            >
              Name <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
            </div>
            <div className="text-right mr-20 col-span-2">
              Major/Program <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
            </div>
            <div className="text-left ml-5 col-span-1">
              Grad Year <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
            </div>
            <div className="text-left col-span-1 ml-2">
              Last Login <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
            </div>
            <div className="text-center col-span-1 mr-5">
              Action <FontAwesomeIcon icon={faSort} style={{ fontSize: '10px' }} />
            </div>
          </div>

          {/* Students List */}
          <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 300px)' }}>
            {filteredStudents.map((student) => (
              <StudentItem
                key={student.id}
                student={student}
                isSelected={selectedStudents.includes(student.id)}
                onCheckboxChange={handleCheckboxChange}
                adjustment={adjustment}
                selectedResource={selectedResource}
                handleRecommendResource={handleRecommendResource}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Campaign Modal */}
      {isCampaignModalOpen && (
        <CampaignModal
          onClose={() => setIsCampaignModalOpen(false)}
          selectedStudents={selectedStudentsData}
        />
      )}
      {/* Template Modal */}
      <TemplateModal
        isTemplateModalVisible={isTemplateModalVisible}
        setIsTemplateModalVisible={setIsTemplateModalVisible}
        selectedStudents={selectedStudents}
      />
    </div>
  );
};

export default StudentListing;
