import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase';
import { Button, TextInput, Spinner, Table } from 'flowbite-react';
import CustomSidebar from '../Sidebar/Sidebar';
import debounce from 'lodash.debounce';
import { FaSearch, FaCalendarAlt } from 'react-icons/fa';
import { useAuth } from '../../../contexts/AuthContext';
import ScheduleMeetingModal from './ScheduleMeetingModal';
import AdvisorAvailabilityCalendar from './AdvisorAvailabilityCalendar';
import { toast } from 'react-toastify';
import { Tab } from '@headlessui/react';

const MeetingsPage = () => {
  const { currentUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [filteredArchivedMeetings, setFilteredArchivedMeetings] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [archivedMeetings, setArchivedMeetings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0); // 0: Students, 1: Upcoming Meetings, 2: Past Meetings

  // Debounced search handler using useCallback to memoize
  const handleSearch = useCallback(
    debounce(async (term, tabIndex) => {
      if (tabIndex === 0) { // Students Tab
        if (term.trim() === '') {
          setFilteredStudents([]);
          setError('');
          return;
        }

        setLoadingSearch(true);
        setError('');
        try {
          const snapshot = await db
            .collection('users')
            .where('role', '==', 'student')
            .get();

          const results = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((student) =>
              student.firstName &&
              student.firstName.toLowerCase().startsWith(term.toLowerCase())
            );

          if (results.length === 0) {
            setError('No students found matching your search.');
          }

          setFilteredStudents(results);
        } catch (error) {
          console.error('Error searching students:', error);
          setError('An error occurred while searching. Please try again.');
        } finally {
          setLoadingSearch(false);
        }
      } else if (tabIndex === 1) { // Upcoming Meetings Tab
        if (term.trim() === '') {
          setFilteredMeetings(meetings);
          return;
        }

        const filtered = meetings.filter((meeting) =>
          (meeting.studentName && meeting.studentName.toLowerCase().includes(term.toLowerCase())) ||
          (meeting.status && meeting.status.toLowerCase().includes(term.toLowerCase())) ||
          (meeting.meetingNotes && meeting.meetingNotes.toLowerCase().includes(term.toLowerCase()))
        );

        setFilteredMeetings(filtered);
      } else if (tabIndex === 2) { // Past Meetings Tab
        if (term.trim() === '') {
          setFilteredArchivedMeetings(archivedMeetings);
          return;
        }

        const filtered = archivedMeetings.filter((meeting) =>
          (meeting.studentName && meeting.studentName.toLowerCase().includes(term.toLowerCase())) ||
          (meeting.status && meeting.status.toLowerCase().includes(term.toLowerCase())) ||
          (meeting.meetingNotes && meeting.meetingNotes.toLowerCase().includes(term.toLowerCase()))
        );

        setFilteredArchivedMeetings(filtered);
      }
    }, 300),
    [meetings, archivedMeetings]
  );

  // Cleanup the debounce on unmount
  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  // Handle input change for search
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    handleSearch(term, activeTab);
  };

  // Fetch upcoming meetings for the advisor
  useEffect(() => {
    if (!currentUser) return;

    const unsubscribe = db
      .collection('meetings')
      .where('advisorId', '==', currentUser.uid)
      .onSnapshot(
        (snapshot) => {
          const meetingsData = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              datetime: data.datetime ? data.datetime.toDate() : null,
            };
          });
          setMeetings(meetingsData);
          // If currently on Upcoming Meetings tab and there's a search term, refilter
          if (activeTab === 1) {
            if (searchTerm.trim() === '') {
              setFilteredMeetings(meetingsData);
            } else {
              const filtered = meetingsData.filter((meeting) =>
                (meeting.studentName && meeting.studentName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (meeting.status && meeting.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (meeting.meetingNotes && meeting.meetingNotes.toLowerCase().includes(searchTerm.toLowerCase()))
              );
              setFilteredMeetings(filtered);
            }
          }
        },
        (error) => {
          console.error('Error fetching meetings:', error);
          toast.error('Failed to fetch upcoming meetings.');
        }
      );

    return () => unsubscribe();
  }, [currentUser, activeTab, searchTerm]);

  // Fetch archived meetings for the advisor
  useEffect(() => {
    if (!currentUser) return;

    const unsubscribe = db
      .collection('archivedMeetings')
      .where('advisorId', '==', currentUser.uid)
      .onSnapshot(
        (snapshot) => {
          const archivedData = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              datetime: data.datetime ? data.datetime.toDate() : null,
            };
          });
          setArchivedMeetings(archivedData);
          // If currently on Past Meetings tab and there's a search term, refilter
          if (activeTab === 2) {
            if (searchTerm.trim() === '') {
              setFilteredArchivedMeetings(archivedData);
            } else {
              const filtered = archivedData.filter((meeting) =>
                (meeting.studentName && meeting.studentName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (meeting.status && meeting.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (meeting.meetingNotes && meeting.meetingNotes.toLowerCase().includes(searchTerm.toLowerCase()))
              );
              setFilteredArchivedMeetings(filtered);
            }
          }
        },
        (error) => {
          console.error('Error fetching archived meetings:', error);
          toast.error('Failed to fetch past meetings.');
        }
      );

    return () => unsubscribe();
  }, [currentUser, activeTab, searchTerm]);

  // Handle schedule meeting button click
  const handleScheduleMeeting = (student) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  // Invoke handleSearch when activeTab or searchTerm changes
  useEffect(() => {
    handleSearch(searchTerm, activeTab);
  }, [activeTab, searchTerm, handleSearch]);

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <CustomSidebar adjustment={"Meetings"}/>

      {/* Main Content */}
      <div className="flex-1 p-4 ml-20 flex flex-col">
        {/* Header Section */}
        <div className="text-center mb-4 mt-3">
          <h1 className="text-3xl font-bold mb-2">Meetings</h1>
          <p className="text-base text-gray-600 mb-2">
          Schedule and manage meetings with your students efficiently.
          </p>
        </div>

        {/* Search Input */}
        <div className="mb-6 flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <TextInput
            type="text"
            placeholder={
              activeTab === 0
                ? "Search students by first name..."
                : activeTab === 1
                ? "Search upcoming meetings..."
                : "Search past meetings..."
            }
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full sm:w-1/3"
            icon={FaSearch}
            aria-label="Search"
          />
          {loadingSearch && <Spinner aria-label="Loading" />}
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-6 text-center text-red-500">
            {error}
          </div>
        )}

        {/* Tabs for Different Sections */}
        <Tab.Group onChange={(index) => setActiveTab(index)}>
          <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
            <Tab
              className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg
                ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
              }
            >
              Students
            </Tab>
            <Tab
              className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg
                ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
              }
            >
              Upcoming Meetings
            </Tab>
            <Tab
              className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg
                ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
              }
            >
              Past Meetings
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-4">
            {/* Students Tab */}
            <Tab.Panel className="bg-white rounded-xl p-4 shadow">
              {activeTab !== 0 && searchTerm.trim() !== '' ? (
                // If not on Students tab, don't display students
                <p className="text-gray-500 text-center">Switch to the Students tab to view student search results.</p>
              ) : filteredStudents.length > 0 ? (
                <div className="overflow-x-auto">
                  <Table>
                    <Table.Head>
                      <Table.HeadCell>Name</Table.HeadCell>
                      <Table.HeadCell>Email</Table.HeadCell>
                      <Table.HeadCell>Action</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {filteredStudents.map((student) => (
                        <Table.Row
                          key={student.id}
                          className="bg-white hover:bg-gray-50"
                        >
                          <Table.Cell>{`${student.firstName || ''} ${student.lastName || ''}`.trim() || 'N/A'}</Table.Cell>
                          <Table.Cell>{student.email || 'N/A'}</Table.Cell>
                          <Table.Cell>
                            <Button
                              size="sm"
                              color="blue"
                              onClick={() => handleScheduleMeeting(student)}
                              aria-label={`Schedule meeting with ${student.firstName || 'student'}`}
                            >
                              Schedule Meeting
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              ) : (
                <p className="text-gray-500 text-center">No students to display. Try searching for a student above.</p>
              )}
            </Tab.Panel>

            {/* Upcoming Meetings Tab */}
            <Tab.Panel className="bg-white rounded-xl p-4 shadow">
              {activeTab !== 1 && searchTerm.trim() !== '' ? (
                // If not on Upcoming Meetings tab, don't display meetings
                <p className="text-gray-500 text-center">Switch to the Upcoming Meetings tab to view meeting search results.</p>
              ) : filteredMeetings.length > 0 ? (
                <div className="overflow-x-auto">
                  <Table>
                    <Table.Head>
                      <Table.HeadCell>Student</Table.HeadCell>
                      <Table.HeadCell>Date &amp; Time</Table.HeadCell>
                      <Table.HeadCell>Status</Table.HeadCell>
                      <Table.HeadCell>Notes</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {filteredMeetings.map((meeting) => (
                        <Table.Row
                          key={meeting.id}
                          className="bg-white hover:bg-gray-50"
                        >
                          <Table.Cell>{meeting.studentName || 'N/A'}</Table.Cell>
                          <Table.Cell>
                            {meeting.datetime
                              ? meeting.datetime.toLocaleString()
                              : 'Invalid Date'}
                          </Table.Cell>
                          <Table.Cell>
                            <span
                              className={`px-2 py-1 text-xs font-semibold rounded-full ${
                                meeting.status === 'Scheduled'
                                  ? 'bg-green-100 text-green-800'
                                  : meeting.status === 'Completed'
                                  ? 'bg-blue-100 text-blue-800'
                                  : 'bg-yellow-100 text-yellow-800'
                              }`}
                            >
                              {meeting.status || 'N/A'}
                            </span>
                          </Table.Cell>
                          <Table.Cell>{meeting.meetingNotes || 'N/A'}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              ) : (
                <p className="text-gray-500 text-center">No upcoming meetings scheduled.</p>
              )}
            </Tab.Panel>

            {/* Past Meetings Tab */}
            <Tab.Panel className="bg-white rounded-xl p-4 shadow">
              {activeTab !== 2 && searchTerm.trim() !== '' ? (
                // If not on Past Meetings tab, don't display archived meetings
                <p className="text-gray-500 text-center">Switch to the Past Meetings tab to view meeting search results.</p>
              ) : filteredArchivedMeetings.length > 0 ? (
                <div className="overflow-x-auto">
                  <Table>
                    <Table.Head>
                      <Table.HeadCell>Student</Table.HeadCell>
                      <Table.HeadCell>Date &amp; Time</Table.HeadCell>
                      <Table.HeadCell>Status</Table.HeadCell>
                      <Table.HeadCell>Notes</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {filteredArchivedMeetings.map((meeting) => (
                        <Table.Row
                          key={meeting.id}
                          className="bg-white hover:bg-gray-50"
                        >
                          <Table.Cell>{meeting.studentName || 'N/A'}</Table.Cell>
                          <Table.Cell>
                            {meeting.datetime
                              ? meeting.datetime.toLocaleString()
                              : 'Invalid Date'}
                          </Table.Cell>
                          <Table.Cell>
                            <span
                              className={`px-2 py-1 text-xs font-semibold rounded-full ${
                                meeting.status === 'Completed'
                                  ? 'bg-blue-100 text-blue-800'
                                  : meeting.status === 'Cancelled'
                                  ? 'bg-red-100 text-red-800'
                                  : 'bg-gray-100 text-gray-800'
                              }`}
                            >
                              {meeting.status || 'N/A'}
                            </span>
                          </Table.Cell>
                          <Table.Cell>{meeting.meetingNotes || 'N/A'}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              ) : (
                <p className="text-gray-500 text-center">No past meetings archived yet.</p>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        {/* Advisor Availability Calendar */}
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Advisor Availability</h2>
          <AdvisorAvailabilityCalendar />
        </div>
      </div>

      {/* Schedule Meeting Modal */}
      {isModalOpen && selectedStudent && (
        <ScheduleMeetingModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          advisor={currentUser}
          student={selectedStudent}
        />
      )}
    </div>
  );
};

export default MeetingsPage;
