// StudentPersistence.jsx
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import CustomSidebar from '../Sidebar/Sidebar';
import EngagementOpportunities from './EngagementOpportunities';
import PersistenceCard from './PersistenceCard';
import EnhancedSearchComponent from './EnhancedSearchComponent'; // Adjust path accordingly
import PowerfulPredictors from './PowerfulPredictors';

// Helper functions
const formatPrediction = (prediction) => {
  switch (prediction) {
    case 'veryLow':
      return 'Very Low';
    case 'low':
      return 'Low';
    case 'moderate':
      return 'Moderate';
    case 'high':
      return 'High';
    case 'veryHigh':
      return 'Very High';
    default:
      return prediction?.charAt(0).toUpperCase() + prediction?.slice(1);
  }
};

const StudentPersistence = () => {
  const [predictors, setPredictors] = useState([]);
  const [activeTab, setActiveTab] = useState('predictors'); // Possible values: "predictors" | "engagement"
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});

  // -------------------------
  // Intersection Observer State
  // -------------------------
  const sentinelRef = useRef(null);

  // currentBatch = 1 means the first batch.
  // We'll treat the first batch as 20, and each additional batch as 12 more.
  const [currentBatch, setCurrentBatch] = useState(1);
  const firstBatchSize = 20;
  const subsequentBatchSize = 12;

  // -------------------------
  // Fetch Predictors (Firestore)
  // -------------------------
  useEffect(() => {
    const fetchPredictorsFromFirestore = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const predictorsList = await Promise.all(
          usersSnapshot.docs.map(async (userDocSnapshot) => {
            const userId = userDocSnapshot.id;
            const userData = userDocSnapshot.data();
            let predictorData = { id: userId, ...userData };

            // Attempt to pull sub-collection doc: persistence/persistenceCalculations
            try {
              const persistenceCalcRef = doc(
                db,
                'users',
                userId,
                'persistence',
                'persistenceCalculations'
              );
              const persistenceCalcSnap = await getDoc(persistenceCalcRef);
              if (persistenceCalcSnap.exists()) {
                const persistenceData = persistenceCalcSnap.data();
                predictorData = { ...predictorData, ...persistenceData };
              }
            } catch (persistenceError) {
              console.error(
                'Error fetching persistence data for user ID',
                userId,
                persistenceError
              );
            }

            // Convert an array of Majors into a single string (or store it differently if you prefer)
            const surveyAnswers = userData?.surveyAnswers;
            if (surveyAnswers?.Majors && Array.isArray(surveyAnswers.Majors)) {
              predictorData.major = surveyAnswers.Majors.join(' ');
            }

            return predictorData;
          })
        );

        setPredictors(predictorsList);
        localStorage.setItem(
          'studentPersistenceData',
          JSON.stringify(predictorsList)
        );
      } catch (error) {
        console.error('Error fetching predictors:', error);
      }
    };

    const localData = localStorage.getItem('studentPersistenceData');
    if (localData) {
      setPredictors(JSON.parse(localData));
    } else {
      fetchPredictorsFromFirestore();
    }
  }, []);

  // -------------------------
  // Filter Logic
  // -------------------------
  const filteredPredictors = useMemo(() => {
    let result = [...predictors];

    // 1. Apply persistence level filtering if criteria exist
    if (filterCriteria.persistence && filterCriteria.persistence.length > 0) {
      result = result.filter((p) =>
        filterCriteria.persistence.includes(formatPrediction(p.prediction))
      );
    }

    // 2. Apply search query filtering
    result = result.filter((p) => {
      const fullName = `${p.firstName} ${p.lastName}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    });

    // 3. Apply graduation year filter
    if (filterCriteria.gradYear && filterCriteria.gradYear.length > 0) {
      result = result.filter((p) =>
        filterCriteria.gradYear.includes(p.surveyAnswers?.SchoolEnd?.year)
      );
    }

    // 4. Apply program filter
    if (filterCriteria.program && filterCriteria.program.length > 0) {
      result = result.filter((p) => {
        if (Array.isArray(p.major)) {
          return p.major.some((prog) => filterCriteria.program.includes(prog));
        } else {
          return filterCriteria.program.includes(p.major);
        }
      });
    }

    // 5. Apply advisor filter
    if (filterCriteria.advisors && filterCriteria.advisors.length > 0) {
      result = result.filter((p) => filterCriteria.advisors.includes(p.advisor));
    }

    // 6. Apply performance (GPA) filter
    if (filterCriteria.performance && filterCriteria.performance.length > 0) {
      result = result.filter((p) => {
        const gpa = parseFloat(p.surveyAnswers?.GPA);
        return filterCriteria.performance.some((range) => {
          switch (range) {
            case '> 4.0':
              return gpa > 4.0;
            case '3.5 - 4.0':
              return gpa >= 3.5 && gpa <= 4.0;
            case '3.0 - 3.5':
              return gpa >= 3.0 && gpa < 3.5;
            case '2.5 - 3.0':
              return gpa >= 2.5 && gpa < 3.0;
            case '2.0 - 2.5':
              return gpa >= 2.0 && gpa < 2.5;
            case '< 2.0':
              return gpa < 2.0;
            default:
              return false;
          }
        });
      });
    }

    // 7. Apply custom filters
    if (filterCriteria.customFilters && filterCriteria.customFilters.length > 0) {
      filterCriteria.customFilters.forEach(({ field, operator, value }) => {
        result = result.filter((p) => {
          const studentVal = p[field];
          if (studentVal == null) return false;

          if (operator === 'equals') {
            if (Array.isArray(studentVal)) {
              return studentVal.some(
                (val) =>
                  String(val).trim().toLowerCase() === value.trim().toLowerCase()
              );
            } else {
              return (
                String(studentVal).trim().toLowerCase() ===
                value.trim().toLowerCase()
              );
            }
          } else if (operator === 'greater than') {
            return parseFloat(studentVal) > parseFloat(value);
          } else if (operator === 'less than') {
            return parseFloat(studentVal) < parseFloat(value);
          }
          return true;
        });
      });
    }

    return result;
  }, [predictors, filterCriteria, searchQuery]);

  // -------------------------
  // Intersection Observer Logic
  // -------------------------

  // Calculate how many total we should show based on currentBatch
  // - Batch 1 => 20 items
  // - Batch 2 => 20 + 12 = 32
  // - Batch 3 => 44, etc.
  const totalToShow = useMemo(() => {
    if (currentBatch === 1) {
      return firstBatchSize; // 20
    }
    // If beyond the first batch, it's 20 + (currentBatch - 1) * 12
    return firstBatchSize + (currentBatch - 1) * subsequentBatchSize;
  }, [currentBatch]);

  // Slice the filtered data for display
  const displayedPredictors = useMemo(() => {
    return filteredPredictors
      .filter((p) => p.role === 'student')
      .sort((a, b) => a?.overallPersistenceScore - b?.overallPersistenceScore)
      .slice(0, totalToShow);
  }, [filteredPredictors, totalToShow]);

  // The function that loads the next batch
  const loadMoreData = useCallback(() => {
    setCurrentBatch((prev) => prev + 1);
  }, []);

  // Whenever displayedPredictors changes, we re-attach the Intersection Observer
  useEffect(() => {
    if (activeTab !== 'predictors') return;

    const observer = new IntersectionObserver(
      (entries) => {
        // If sentinel is visible AND there's still more data to load
        if (
          entries[0].isIntersecting &&
          displayedPredictors.length < filteredPredictors.length
        ) {
          loadMoreData();
        }
      },
      {
        threshold: 0,
        rootMargin: '100px', // Trigger a bit before hitting the exact bottom
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [
    activeTab,
    displayedPredictors,
    filteredPredictors,
    loadMoreData,
    sentinelRef
  ]);

  // -------------------------
  // Search & Filter Handlers
  // -------------------------
  const handleSearch = (term) => {
    setSearchQuery(term);
    // Reset to first batch on new search
    setCurrentBatch(1);
  };

  const handleFilter = (filters) => {
    setFilterCriteria(filters);
    // Reset to first batch on new filters
    setCurrentBatch(1);
  };

  // -------------------------
  // Render
  // -------------------------
  return (
    <div className="flex">
      {/* SIDEBAR */}
      <CustomSidebar adjustment="PERSISTENCE PREDICTIONS" />

      {/* MAIN CONTENT AREA */}
      <div className="mt-4 min-h-[96vh] ml-[4vw] min-w-[94vw] max-w-[92vw] flex-1 px-3">
        {/* TOP TAB BAR: styled similarly to StudentsPage.jsx */}
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mb-3">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                onClick={() => setActiveTab('predictors')}
                className={`inline-block py-2 px-4 border-b-2 rounded-t-lg ${
                  activeTab === 'predictors'
                    ? 'text-blue-600 border-blue-600'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                }`}
              >
                Persistence Predictors
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setActiveTab('engagement')}
                className={`inline-block py-2 px-4 border-b-2 rounded-t-lg ${
                  activeTab === 'engagement'
                    ? 'text-blue-600 border-blue-600'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                }`}
              >
                Engagement Opportunities
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setActiveTab('powerfulPredictors')}
                className={`inline-block py-2 px-4 border-b-2 rounded-t-lg ${
                  activeTab === 'powerfulPredictors'
                    ? 'text-blue-600 border-blue-600'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300'
                }`}
              >
                Powerful Predictors
              </button>
            </li>
          </ul>
        </div>

        {/* TAB CONTENT */}
        {activeTab === 'predictors' && (
          <div className="mt-4 p-2">
            <h2 className="text-2xl font-light mb-4">
              Student Persistence Predictions
            </h2>

            {/* Integrated EnhancedSearchComponent for filters */}
            <EnhancedSearchComponent
              onSearch={handleSearch}
              onFilter={handleFilter}
              students={predictors}
              onSelectAll={() => {}}
              filteredStudentsCount={filteredPredictors.length}
              everythingSelected={false}
              fieldTypes={{}}    // Provide actual fieldTypes if available
              advisors={[]}      // Provide actual advisors if available
              dashboardFilterType={null}
              dashboardFilterValue={null}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {displayedPredictors.map((predictor) => (
                <PersistenceCard key={predictor.id} predictor={predictor} />
              ))}
            </div>

            {/* Sentinel: An empty div we observe to load more when scrolled into view */}
            <div ref={sentinelRef} className="w-full h-4 mt-4" />
          </div>
        )}

        {activeTab === 'engagement' && (
          <div className="p-2">
            <EngagementOpportunities />
          </div>
        )}

        {activeTab === 'powerfulPredictors' && (
        <div className="p-2">
          <PowerfulPredictors />
        </div>
        )}

      </div>
    </div>
  );
};

export default StudentPersistence;
