// GroupPersistenceChart.jsx

import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import dayjs from 'dayjs';

/**
 * Expects an array of objects like:
 *   [
 *     { date: Timestamp, average: number },
 *     { date: Timestamp, average: number },
 *     ...
 *   ]
 *
 * We'll plot them in ascending date order.
 */
const GroupPersistenceChart = ({ historyArray }) => {
  if (!Array.isArray(historyArray) || historyArray.length === 0) {
    return <p className="text-sm text-gray-500">No history data available.</p>;
  }

  // 1) Sort by date ascending
  const sorted = [...historyArray].sort((a, b) => {
    if (a.date?.toMillis && b.date?.toMillis) {
      return a.date.toMillis() - b.date.toMillis();
    }
    // fallback
    return 0;
  });

  // 2) Build chart labels + data
  const labels = sorted.map((entry) => {
    // Convert Firestore Timestamp to JS Date, format as "YYYY-MM-DD"
    if (entry.date?.toDate) {
      return dayjs(entry.date.toDate()).format('YYYY-MM-DD');
    }
    return 'Unknown';
  });

  const dataValues = sorted.map((entry) => entry.average || 0);

  // 3) Prepare the dataset
  const data = {
    labels,
    datasets: [
      {
        label: 'Group Persistence Average',
        data: dataValues,
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1, // a bit of smoothing
      },
    ],
  };

  // 4) ChartJS options (feel free to customize)
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 100, // assuming scores are 0-100
      },
    },
  };

  return (
    <div className="my-2">
      <Line data={data} options={options} />
    </div>
  );
};

export default GroupPersistenceChart;
