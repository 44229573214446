import React from 'react';

/**
 * A header component that displays a circular avatar
 * with the student's initials, the student's name, and Firestore ID.
 */
const PlanPageHeader = ({ studentName, studentId }) => {
  // Simple helper to extract initials from the student's name (e.g., "John Doe" -> "JD")
  const getInitials = (fullName = '') => {
    const names = fullName.trim().split(' ');
    if (!names.length) return '';
    // Get first letter of the first name, and first letter of the last name
    const initials = names.length === 1
      ? names[0].charAt(0)
      : names[0].charAt(0) + names[names.length - 1].charAt(0);
    return initials.toUpperCase();
  };

  const initials = getInitials(studentName);

  return (
    <div className="w-full bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 p-4">
      <div className="flex items-center space-x-4">
        {/* Avatar placeholder */}
        <div className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600">
          <span className="font-semibold text-white text-sm uppercase">
            {initials}
          </span>
        </div>

        {/* Name and ID */}
        <div className="font-medium dark:text-white">
          <div className="text-gray-900 dark:text-white text-base font-semibold">
            {studentName || 'Student Name'}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">{studentId}</div>
        </div>
      </div>
    </div>
  );
};

export default PlanPageHeader;
