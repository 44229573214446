import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '../../contexts/AuthContext';
import { ClassProvider } from '../../contexts/ClassContext';
import ProtectedRoutes from './ProtectedRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App({ offeredDegrees }) {
  // Optionally disable console.log in production
  // if (process.env.REACT_APP_TYPE === 'PROD') {
  //   console.log = function () {}; 
  // }

  return (
    <AuthProvider>
      <ClassProvider>
        <Router>
          {/* Place ToastContainer here, outside of any layout divs */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              minHeight: '100vh',
              maxWidth: '100vw',
              width: '100vw',
              paddingLeft: '0px',
              paddingRight: '0px',
              marginLeft: '0px',
              marginRight: '0px',
              display: 'flex',
            }}
          >
            <ProtectedRoutes offeredDegrees={offeredDegrees} />
          </div>
        </Router>
      </ClassProvider>
    </AuthProvider>
  );
}

export default App;
