// src/components/ProfilePageComponents/RecommendedResources.js
import React from 'react';
import { Button, Spinner } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

const RecommendedResources = ({ recommendedResources, resourcesLoading, resourcesError }) => {
  // 1. Initialize the translation function
  const { t } = useTranslation();

  return (
    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
      <h2 className="text-gray-700 font-semibold mb-4">
        {t('recommendedResources.title', 'Recommended Resources')}
      </h2>
      {resourcesLoading ? (
        <div className="flex justify-center items-center">
          <Spinner aria-label={t('recommendedResources.loadingAria', 'Loading recommended resources')} />
        </div>
      ) : resourcesError ? (
        <div className="text-red-500">
          {resourcesError}
        </div>
      ) : recommendedResources.length > 0 ? (
        <div className="flex flex-col space-y-4">
          {recommendedResources.map((resource) => (
            <div
              key={resource.id}
              className="flex flex-col sm:flex-row bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
            >
              {/* Resource Image */}
              <img
                className="w-48 h-48 sm:w-56 sm:h-56 flex-shrink-0 rounded-t-lg sm:rounded-l-lg object-cover"
                src={resource.image || 'https://via.placeholder.com/150'}
                alt={resource.name}
              />
              {/* Resource Details */}
              <div className="p-4 flex flex-col justify-between w-full">
                <div>
                  <h5 className="text-lg font-bold text-gray-900 dark:text-white">
                    {resource.name}
                  </h5>
                  <p className="text-sm text-gray-700 dark:text-gray-400 mt-1">
                    {resource.description}
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                    {t('recommendedResources.department', 'Department:')} {resource.department}
                  </p>
                </div>
                {resource.link ? (
                  <Button
                    color="gray"
                    size="xs"
                    className="mt-2 self-start"
                    onClick={() => window.open(resource.link, '_blank')}
                  >
                    {t('recommendedResources.learnMore', 'Learn More')}
                  </Button>
                ) : (
                  <span className="text-xs text-gray-500 mt-2">
                    {t('recommendedResources.noLink', 'No link available')}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-gray-500">
          {t('recommendedResources.noneAvailable', 'No recommended resources available.')}
        </div>
      )}
    </div>
  );
};

export default RecommendedResources;
