import React, { useState, useEffect, useCallback, useMemo, useRef, forwardRef} from 'react';
import NavBar from '../../NavBar';
import CourseRendering from '../CourseRendering';
import '../PlanPage.css';
import { db } from '../../firebase';
import { collection, getDoc, collectionGroup, query, where, onSnapshot, updateDoc, doc, getDocs, writeBatch } from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MilestoneModal from '../../Milestones/MilestoneModal';
import { createPDFDocument } from '../ExportData/exportDegreePlan';
import PDFPreview from '../ExportData/PDFPreview';
import { useAuth } from '../../../contexts/AuthContext';
import { FaPlus, FaMagic, FaTrash, FaMinus, FaComments } from 'react-icons/fa';
import MilestoneDrawer from '../../Milestones/MilestoneDrawer';
import PlanJoyrideTour from '../Archive/PlanJoyrideTour';
import ElectiveSidebar from '../../Degree Plans/ElectiveSidebar';
import EvaluationModal from '../EvaluationModal/EvaluationModal';
import { BsThreeDots } from "react-icons/bs";
import { FaStar, FaRegStar, FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { GrFormAdd, GrFormClose } from "react-icons/gr";
import PlanPageHeader from '../PlanPageHeader';
import { CiGrid2V, CiGrid41 } from "react-icons/ci";
import { LuGoal } from 'react-icons/lu';
import { toast } from 'react-toastify';
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import TransferDataModal from '../Transfer/TransferDataModal';
import IncompletePrereqsWarningModal from '../IncompletePrereqsWarningModal';
import UpdatedDroppableSemester from './UpdatedDroppableSemester'
import DegreeAuditModal from '../DegreeAudit/DegreeAuditModal';
import StudentChat from '../StudentChat';
import CustomSidebar from '../../Admin/Sidebar/Sidebar';
import useUpdatedAutofill from './UpdatedHooks/useUpdatedAutofill';
import useUpdatedMoveCourse from './UpdatedHooks/useUpdatedMoveCourse';

import { useTranslation } from 'react-i18next';

const UpdatedTempPlanPage = () => {

  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState({});
  const dropdownRefs = useRef({});
  const buttonRefs = useRef([]);
  const [numYears, setNumYears] = useState(null);
  const [finalizedCourses, setFinalizedCourses] = useState([]);
  const [finalizedCourseIds, setFinalizedCourseIds] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
  const [newScheduleName, setNewScheduleName] = useState('');
  const [activeTab, setActiveTab] = useState('defaultTab'); // Set a default value for activeTab
  const [primaryTab, setPrimaryTab] = useState('defaultTab');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(''); // State for managing errors
  const [dataFetchComplete, setDataFetchComplete] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [hideCompletedYears, setHideCompletedYears] = useState(false);
  const yearRefs = useRef({});  // Create a ref to hold references for each year container
  const [isProcessing, setIsProcessing] = useState(false);
  const [showCommentsChat, setShowCommentsChat] = useState(false);

  const [showModal, setShowModal] = useState(false);  // Add this line
  const [editingMilestone, setEditingMilestone] = useState(null); // New state for editing
  const [showDrawer, setShowDrawer] = useState(false);
  const [currentSemester, setCurrentSemester] = useState({ year: null, semester: null });
  const [showAllMilestonesDrawer, setShowAllMilestonesDrawer] = useState(false);
  const [editingTab, setEditingTab] = useState(null);
  const [newTabName, setNewTabName] = useState('');
  const [transferCreditsData, setTransferCreditsData] = useState([]);
  const [showIncompletePrereqsWarningModal, setShowIncompletePrereqsWarningModal] = useState(false);

  const [selectedPlaceholderCategory, setSelectedPlaceholderCategory] = useState([]);
  const [selectedPlaceholderYear, setSelectedPlaceholderYear] = useState([]);
  const [selectedPlaceholderSemester, setSelectedPlaceholderSemester] = useState([]);
  const [selectedPlaceholderRequirement, setSelectedPlaceholderRequirement] = useState([]);
  const [showPlaceholderSidebar, setShowPlaceholderSidebar] = useState(false);

  const maxCreditsPerSemester = 50;

  const { currentUser, isUserDataFetched, fetchUserData, fetchedCourseData, fetchedPrereqData, majorData, fetchedMajorRecs, fetchedMinors, fetchedMajors, pathwayGroupingsData, fetchedTerms } = useAuth();
  function fixToJSON(str) {
    // This example assumes keys are numeric and unquoted; use with caution.
    return str.replace(/(\{|,)\s*([0-9]+)\s*:/g, '$1 "$2":');
  }

  let termMap = {};
  console.log('Terms type', typeof fetchedTerms);
  const nonStandardStr = fetchedTerms;
  const validJSONString = fixToJSON(nonStandardStr);
  console.log(validJSONString); // '{"1": "Fall", "2":"Spring"}'
  
  const obj = JSON.parse(validJSONString);
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      // Convert the key to an integer and store it in the Map
      const intKey = parseInt(key, 10);
      termMap[intKey] = obj[key];
    }
  }
  const termKeys = termMap
  ? Object.keys(termMap).sort((a, b) => Number(a) - Number(b))
  : [];
  

  console.log('Terms: ', fetchedTerms, termMap);
  // Create an initial schedule state for a given number of years.
  const generateInitialState = (numYears) => {
    const state = {};
    for (let i = 1; i <= numYears; i++) {
      const yearKey = `year${i}`;
      state[yearKey] = {};
      termKeys.forEach((tk) => {
        state[yearKey][tk] = { courses: [] };
      });
    }
    return state;
  };

  const lastCalledUpdateCourseProgress = useRef(0);

    const [finalizedOrganizedCourses, setFinalizedOrganizedCourses] = useState(generateInitialState(numYears));

  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [cumulativeCourseIds, setCumulativeCourseIds] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [majorCourses, setMajorCourses] = useState([]);
  const [minorCourses, setMinorCourses] = useState([]);

  const [allCoursesByYearAndSemester, setAllCoursesByYearAndSemester] = useState({});
  const [middleschoolClasses, setMiddleschoolClasses] = useState([]);
  const [prereqs, setPrereqs] = useState([]);
  const [majorPathwayData, setMajorPathwayData] = useState({});
  const [majorElectives, setMajorElectives] = useState(generateInitialState(numYears));
  const [majorRequirementCourses, setMajorRequirementCourses] = useState(generateInitialState(numYears));
  const [minorRequirementCourses, setMinorRequirementCourses] = useState(generateInitialState(numYears));
  const [finalizedMilestones, setFinalizedMilestones] = useState([]);
  const [milestonesByYearAndSemester, setMilestonesByYearAndSemester] = useState(generateInitialState(numYears));
  const [pdfBytes, setPdfBytes] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [highlightOption, setHighlightOption] = useState(null);
  const [completedSemesters, setCompletedSemesters] = useState({});
  const [userRecsData, setUserRecsData] = useState({});
  const [transformedMajorRecData, setTransformedMajorRecData] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedElectiveCategory, setSelectedElectiveCategory] = useState([]);
  const [electiveYear, setElectiveYear] = useState([]);
  const [electiveSemester, setElectiveSemester] = useState([]);
  const [isEvaluationModalOpen, setEvaluationModalOpen] = useState(false);
  const [isTransferDataModalOpen, setTransferDataModalOpen] = useState(false);
  const [isDegreeAuditModalOpen, setIsDegreeAuditModalOpen] = useState(false);
  const [allUserMajorPathways, setAllUserMajorPathways] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [viewMode, setViewMode] = useState('vertical');
  const [isAutofilling, setIsAutofilling] = useState(false);
  const [hasAutofilledAll, setHasAutofilledAll] = useState(false);
  const [areAnyPrereqsNotMet, setAreAnyPrereqsNotMet] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [selectedAdvisorId, setSelectedAdvisorId] = useState(null);
  const [unreadCounts, setUnreadCounts] = useState({});
  const { userId } = useParams();
  const effectiveUserId = useParams().studentId || currentUser?.uid;

  // Function to mark messages as read for the active chat
  const handleChatOpen = async () => {
    if (!selectedAdvisorId || !activeTab) return;

    const chatId = `${activeTab}_${effectiveUserId}_${selectedAdvisorId}`;
    const messagesRef = collection(db, 'chats', chatId, 'messages');
    const q = query(
      messagesRef,
      where('senderId', '!=', effectiveUserId),
      where('read', '==', false)
    );

    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);

    querySnapshot.forEach((docSnap) => {
      batch.update(doc(db, 'chats', chatId, 'messages', docSnap.id), { read: true });
    });

    await batch.commit();

    // The unreadCount will automatically update via the onSnapshot listener
  };

  // Listen for unread messages across all advisor chats
  useEffect(() => {
    if (!currentUser) return;

    const q = query(
      collectionGroup(db, 'messages'),
      where('senderId', '!=', effectiveUserId),
      where('read', '==', false)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newUnreadCounts = {};
      querySnapshot.forEach((docSnap) => {
        // Each message doc path looks like: chats/{chatId}/messages/{messageId}
        // Extract the chatId from the parent of the parent:
        const chatId = docSnap.ref.parent.parent.id;
        // chatId format: "activeTab_studentId_advisorId"
        const [planId] = chatId.split('_'); // Extract the plan (activeTab)

        if (!newUnreadCounts[planId]) {
          newUnreadCounts[planId] = 0;
        }
        newUnreadCounts[planId] += 1;
      });
      setUnreadCounts(newUnreadCounts);
    }, (error) => {
      console.error('Error fetching unread messages:', error);
    });

    return () => unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    if (fetchedMajorRecs) {
      const transformedMajorRecData = fetchedMajorRecs.reduce((acc, curr) => {
        const { Major, ...categories } = curr;
        acc[Major] = categories;
        return acc;
      }, {});
      setTransformedMajorRecData(transformedMajorRecData);
    }
  }, [fetchedMajorRecs]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownKeys = Object.keys(dropdownRefs.current);
      let isClickInside = false;
  
      // Check if the click is inside any of the dropdowns
      for (let key of dropdownKeys) {
        if (dropdownRefs.current[key]?.contains(event.target)) {
          isClickInside = true;
          break;
        }
      }
  
      // Check if the click is inside any of the buttons
      for (let button of buttonRefs.current) {
        if (button?.contains(event.target)) {
          isClickInside = true;
          break;
        }
      }
  
      // Check if the event target is the "Remove" button or its parent container
      if (event.target.closest('.remove-button')) {
        isClickInside = true;
      }
  
      // Check if the event target is the "Requisite" button or its parent container
      if (event.target.closest('.requisite-button')) {
        isClickInside = true;
      }
  
      // If the click was outside and the dropdown is not already closed
      if (!isClickInside) {
        if (Object.keys(dropdownOpen).length > 0) {
          setDropdownOpen({});
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);  

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowAddScheduleModal(false); // Close the modal
    }
  };

  useEffect(() => {
    if (showAddScheduleModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showAddScheduleModal]);

  useEffect(() => {
      const hasAnyUnmetPrereqs = Object.entries(finalizedOrganizedCourses).some(([year, semesters]) =>
        Object.entries(semesters).some(([semester, index1]) => {
          // Map semester names to keys
          const semesterHasAnyUnmetPrereqs = finalizedOrganizedCourses[year]?.[semester].some((course, index2) => {
            if (!course.isPrereqMet) {
            }
            return !course.isPrereqMet
          })
          return semesterHasAnyUnmetPrereqs;
        })
      );

      setAreAnyPrereqsNotMet(hasAnyUnmetPrereqs);

  }, [finalizedOrganizedCourses]);
  

  const organizeAllCoursesByYearAndSemester = useCallback((allCourses) => {
    const coursesByYearAndSemester = {};
  
    allCourses.forEach((course) => {
      // Use course.courseYear as the key for the year.
      // If not defined, you can label it "Uncategorized".
      const year = course.courseYear || 'Uncategorized';
  
      // course.CourseSem is assumed now to be a term key (e.g. "1", "2", etc.)
      const termKey = course.CourseSem || 'Uncategorized';
  
      // Initialize the year object if needed.
      if (!coursesByYearAndSemester[year]) {
        coursesByYearAndSemester[year] = {};
        // For each term defined in fetchedTerms, initialize an empty array.
        Object.keys(termMap).forEach((tk) => {
          // Use the friendly name as the key (e.g. "Fall", "Spring", etc.)
          coursesByYearAndSemester[year][termMap[tk]] = [];
        });
      }
  
      // Convert the course's term key to its friendly name.
      const friendlyName = termMap[termKey] || 'Uncategorized';
  
      // Only add the course if we have a valid term.
      if (friendlyName !== 'Uncategorized') {
        coursesByYearAndSemester[year][friendlyName].push(course);
      }
    });
  
    return coursesByYearAndSemester;
  }, [fetchedTerms]);
  

  const organizeMajorCoursesByYearAndSemester = useCallback((majorCourses) => {
    const coursesByYearAndSemester = {};
  
    majorCourses.forEach((course) => {
      // Use course.courseYear as the year key; if not available, fallback to 'Uncategorized'
      const year = course.courseYear || 'Uncategorized';
  
      // Assume course.CourseSem now holds a term key (e.g., "1", "2", etc.)
      const termKey = course.CourseSem || 'Uncategorized';
  
      // If we haven't initialized this year, create an object with each friendly term name from fetchedTerms
      if (!coursesByYearAndSemester[year]) {
        coursesByYearAndSemester[year] = {};
        Object.keys(termMap).forEach((tk) => {
          coursesByYearAndSemester[year][termMap[tk]] = [];
        });
      }
  
      // Convert the course's term key to its friendly name
      const friendlyName = termMap[termKey] || 'Uncategorized';
  
      // Only add the course if we have a valid term
      if (friendlyName !== 'Uncategorized') {
        coursesByYearAndSemester[year][friendlyName].push(course);
      }
    });
  
    return coursesByYearAndSemester;
  }, [fetchedTerms]);

  const hasUncompletedCourses = useMemo(() => {
    return Object.entries(finalizedOrganizedCourses).some(([year, termGroups]) =>
      Object.entries(termGroups).some(([friendlyName, courses]) => {
        // Convert the friendly term name (e.g. "Fall") back into its term key (e.g. "1")
        const termKey = Object.keys(termMap).find(
          key => termMap[key] === friendlyName
        );
        return !completedSemesters[year]?.[termKey] && courses.length > 0;
      })
    );
  }, [finalizedOrganizedCourses, completedSemesters, fetchedTerms]);

  const organizeCoursesByYearAndSemester = useCallback(
    (courseIds, allCourses, prereqs, cumulativeIds) => {
      const coursesByYearAndSemester = {};
      console.log('Course IDS', courseIds);
  
      // Loop over each year (e.g. "year1", "year2", …)
      for (const yearKey in courseIds) {
        const yearObj = courseIds[yearKey];
        console.log('YearObj: ', yearObj
        )
  
        // Initialize the year in our organized object.
        // Here we initialize every term from fetchedTerms with an empty array.
        if (!coursesByYearAndSemester[yearKey]) {
          coursesByYearAndSemester[yearKey] = {};
          Object.keys(termMap).forEach((tk) => {
            coursesByYearAndSemester[yearKey][termMap[tk]] = [];
          });
        }
        // console.log('Courses by year and sem: ', coursesByYearAndSemester);
  
        // Process each term (key) in the year object.
        for (const termKey in yearObj) {
          const semesterCourseIds = yearObj[termKey].courses || [];
          const semesterPlaceholders = yearObj[termKey].Placeholders || [];
          let semesterClasses = [];
  
          // Process Courses: for each course ID in the term.
          semesterCourseIds.forEach((idOrPlaceholder) => {
            if (typeof idOrPlaceholder === 'string') {
              // Find the corresponding course in allCourses.
              const semesterCourse = allCourses.find(
                (course) => course.courseInfo_courseNumber === idOrPlaceholder
              );
              if (semesterCourse) {
                // If found, update its prerequisites/corequisites.
                const courseInfo = prereqs.find(
                  (prereq) => prereq.Course === semesterCourse.courseInfo_courseNumber
                );
                if (courseInfo) {
                  semesterCourse.Prerequisites = parsePrerequisites(courseInfo.Prerequisites);
                  semesterCourse.Corequisites = parseCorequisites(courseInfo.Corequisites);
                }
                // Check whether prerequisites are met.
                semesterCourse.isPrereqMet = checkPrerequisites(
                  semesterCourse,
                  yearKey,
                  termKey,
                  cumulativeIds
                );
                semesterClasses.push(semesterCourse);
              }

            }
            // If it's not a string, it is handled as a placeholder elsewhere.
          });
  
          // Process Placeholders (if any)
          if (Array.isArray(semesterPlaceholders)) {
            semesterClasses.push(...semesterPlaceholders);
          }
  
          // Map the term key to its friendly name (e.g. "1" -> "Fall")
          const semesterName = termKey || 'Uncategorized';
  
          // Only add courses if we have a valid friendly name.
          if (semesterName !== 'Uncategorized') {
            // Ensure the term key exists in our organized object.
            if (!coursesByYearAndSemester[yearKey][semesterName]) {
              coursesByYearAndSemester[yearKey][semesterName] = [];
            }
            coursesByYearAndSemester[yearKey][semesterName].push(...semesterClasses);
          }
        }
      }
  
      return coursesByYearAndSemester;
    },
    [fetchedTerms]
  );
  

  const organizeMilestonesByYearAndSemester = useCallback((milestones = []) => {
    // Ensure milestones is an array.
    if (!Array.isArray(milestones)) {
      console.error('Milestones is not an array:', milestones);
      return {};
    }
  
    const milestonesByYearAndSemester = {};
  
    // Initialize milestonesByYearAndSemester for each year using the dynamic term keys.
    for (let i = 1; i <= numYears; i++) {
      const yearKey = `year${i}`;
      milestonesByYearAndSemester[yearKey] = {};
      // For every term defined in fetchedTerms, create an empty array.
      Object.keys(termMap).forEach((termKey) => {
        const friendlyName = termMap[termKey];
        milestonesByYearAndSemester[yearKey][friendlyName] = [];
      });
    }
  
    // Iterate through milestones and organize them by year and term.
    milestones.forEach((milestone) => {
      if (milestone) {
        const year = `year${milestone.Milestone_Year}`;
        // Assume milestone.Milestone_Sem now holds a dynamic term key (e.g. "1", "2", etc.)
        const friendlyName = termMap[milestone.Milestone_Sem] || 'Uncategorized';
        if (milestonesByYearAndSemester[year] && milestonesByYearAndSemester[year][friendlyName]) {
          milestonesByYearAndSemester[year][friendlyName].push(milestone);
        }
      }
    });
  
    return milestonesByYearAndSemester;
  }, [numYears, fetchedTerms]);

  const [numYears2, setNumYears2] = useState([]);
  const [fullName, setFullName] = useState([]);

const fetchUserDataLocal = useCallback(async () => {
  console.log('Fetching user data...');
  try {
    if (currentUser) {
      const userDocRef = db.collection('users').doc(effectiveUserId);
      const userDoc = await userDocRef.get();

      if (userDoc.exists) {
        const userData = userDoc.data();
        console.log('User Data: ', userData);

        const fullName =
          userData?.firstName && userData?.lastName
            ? `${userData.firstName} ${userData.lastName}`
            : 'Student';
        setFullName(fullName);

        // Step 1: Filter pathway groupings to only the ones that are "Major"
        const allMajorPathways = pathwayGroupingsData.filter(
          (major) => major.pathwayType === 'Major'
        );

        // Step 2: Match user's first major from surveyAnswers
        const userMajorName = userData.surveyAnswers?.Majors?.[0];
        const userMajorPathwayData =
          allMajorPathways.find(
            (major) => major.pathwayName === userMajorName
          ) || {};
        setMajorPathwayData(userMajorPathwayData);

        const courseIds = userData.schedules?.[activeTab] || []; // Use activeTab safely
        setFinalizedCourseIds(courseIds);

        const middleschoolcourseIds = userData.middleschoolClasses || [];
        setMiddleschoolClasses(middleschoolcourseIds);

        const backendSurveyAnswers = userData.surveyAnswers || [];
        setSurveyAnswers(backendSurveyAnswers);

        const backendUserRecData = userData.recommendationRatings || {};
        setUserRecsData(backendUserRecData);

        const backendTransferCredits = userData.transferData || {};
        setTransferCreditsData(backendTransferCredits);

        const calculateRecScore = require('../../CourseRecommendation');

        const allCourses = fetchedCourseData;
        const prereqs = fetchedPrereqData;

        let transformedMajorRecs = {};
        for (let i = 0; i < fetchedMajorRecs.length; i++) {
          const curr = fetchedMajorRecs[i];
          const { Major, ...categories } = curr;
          transformedMajorRecs[Major] = categories;
        }

        const allCoursesWithRecScore = allCourses.map((course) => {
          const recScore = calculateRecScore(
            transformedMajorRecs[course.Major],
            userData.recommendationRatings,
            course,
            userData.surveyAnswers
          );
          return {
            ...course,
            recScore,
          };
        });

        // Assume surveyAnswers['Majors'] and surveyAnswers['Minors'] are arrays.
        const majorNames = backendSurveyAnswers['Majors'];
        const minorNames = backendSurveyAnswers['Minors'] || [];

        // Objects to store structured data.
        const majorCoursesData = {};
        const majorElectivesData = {};
        const majorRequirementsData = {};

        const minorCoursesData = {};
        const minorRequirementsData = {};

        // Function to calculate maximum year (from course.courseYear).
        const calculateMaxYears = (courses) =>
          Math.max(...courses.map((course) => course.courseYear));

        // Instead of a fixed semesterMap, use the dynamic mapping from fetchedTerms.
        // Process each major.
        let combinedMajorPathways = {};

        majorNames.forEach((majorName) => {
          const majorCoursesFromBackend = Object.values(fetchedMajors[majorName]);
          combinedMajorPathways[majorName] = majorCoursesFromBackend;

          const majorCoursesMap = new Map();
          const majorRequirementsMap = {};
          const electiveCoursesMap = {};

          const maxMajorYears = calculateMaxYears(majorCoursesFromBackend);
        console.log('Fetched Terms: ', termMap[1], Object.keys(termMap));
          // Initialize the per-year objects using the dynamic term keys.
          for (let i = 1; i <= maxMajorYears; i++) {
            const yearKey = `year${i}`;
            majorRequirementsMap[yearKey] = {};
            electiveCoursesMap[yearKey] = {};
            Object.keys(termMap).forEach((termKey) => {
              const friendly = termKey;
            //   console.log('Fetched Terms: ', fetchedTerms[termKey]);
              majorRequirementsMap[yearKey][friendly] = [];
              electiveCoursesMap[yearKey][friendly] = [];
            });
          }
          console.log('ElectiveCoursesMap: ', electiveCoursesMap);

          majorCoursesFromBackend.forEach((course) => {
            if (course.courseType === 'Major Course') {
              if (course.Requirements === '') {
                // Record basic mapping.
                majorCoursesMap.set(course.courseInfo_courseNumber, {
                  courseYear: course.courseYear,
                  courseSemester: course.CourseSem,
                });
              } else {
                if (course.courseYear <= maxMajorYears) {
                  const friendly =
                  termMap[course.CourseSem] || course.CourseSem;
                  majorRequirementsMap[`year${course.courseYear}`][friendly].push(course);
                }
              }
            } else if (course.courseType === 'Elective') {
              if (course.courseYear <= maxMajorYears) {

                const friendly = termMap[course.CourseSem == 'S1' ? 1: 2] || course.CourseSem == 'S1' ? 1: 2;
                  console.log('PushTo: ', electiveCoursesMap, [`year${course.courseYear}`], friendly, course.courseYear[friendly]);
                electiveCoursesMap[`year${course.courseYear}`][friendly].push(course);
              }
            } else if (course.courseType === 'Requirement') {
              if (course.courseYear <= maxMajorYears) {
                const friendly =
                termMap[course.CourseSem] || course.CourseSem;
                majorRequirementsMap[`year${course.courseYear}`][friendly].push(course);
              }
            }
          });

          const allCoursesWithRecScoreMajor = allCourses.map((course) => {
            const recScore = calculateRecScore(
              transformedMajorRecs[course.Major],
              userData.recommendationRatings,
              course,
              userData.surveyAnswers
            );
            const majorCourseNumbers = majorCoursesFromBackend.map(
              (majorCourse) => majorCourse.courseInfo_courseNumber
            );
            const courseType = majorCourseNumbers.includes(
              course.courseInfo_courseNumber
            )
              ? 'Major Course'
              : 'Elective';

            return {
              ...course,
              recScore,
              courseType,
            };
          });

          const majorCoursesInfo = allCoursesWithRecScoreMajor
            .filter((course) =>
              majorCoursesMap.has(course.courseInfo_courseNumber)
            )
            .map((course) => {
              const { courseYear, courseSemester } = majorCoursesMap.get(
                course.courseInfo_courseNumber
              );
              return {
                ...course,
                courseYear,
                CourseSem: courseSemester,
              };
            });

          majorCoursesData[majorName] = majorCoursesInfo;
          majorElectivesData[majorName] = electiveCoursesMap;
          majorRequirementsData[majorName] = majorRequirementsMap;
        });

        setAllUserMajorPathways(combinedMajorPathways);

        // Process each minor.
        minorNames.forEach((minorName) => {
          const minorCoursesFromBackend = Object.values(fetchedMinors[minorName]);

          const minorCoursesMap = {};
          const minorRequirementsMap = {};

          const maxMinorYears = calculateMaxYears(minorCoursesFromBackend);

          for (let i = 1; i <= maxMinorYears; i++) {
            const yearKey = `year${i}`;
            minorCoursesMap[yearKey] = {};
            minorRequirementsMap[yearKey] = {};
            Object.keys(termMap).forEach((termKey) => {
              const friendly = termMap[termKey];
              minorCoursesMap[yearKey][friendly] = [];
              minorRequirementsMap[yearKey][friendly] = [];
            });
          }
          console.log('minorReqs: ', minorRequirementsMap)
          const friendly =
                termMap[1] || 1;
                console.log('minorReqsLog', minorRequirementsMap[`year1`][friendly]);

          minorCoursesFromBackend.forEach((course) => {
            if (course.Requirements === '') {
              if (course.courseYear <= maxMinorYears) {
                const friendly =
                termMap[course.CourseSem == 'S1' ? 1: 2] || course.CourseSem == 'S1' ? 1: 2;
                minorCoursesMap[`year${course.courseYear}`][friendly].push(course);
                console.log('MinorCoursesMap: ', minorCoursesMap);
              }
            } else {
              if (course.courseYear <= maxMinorYears) {
                const friendly = termMap[course.CourseSem == 'S1' ? 1: 2];
                console.log('friendlyDemo: ', termMap, course.CourseSem == 'S1' ? 1: 2, friendly);
                minorRequirementsMap[`year${course.courseYear}`][friendly].push(course);
                console.log('minorRequirementsMap: ', minorRequirementsMap);
              }
            }
          });

          minorCoursesData[minorName] = minorCoursesMap;
          minorRequirementsData[minorName] = minorRequirementsMap;


        });

        // Set state for major and minor courses.
        setMajorCourses(majorCoursesData);
        setMajorElectives(majorElectivesData);
        setMajorRequirementCourses(majorRequirementsData);

        setMinorCourses(minorCoursesData);
        setMinorRequirementCourses(minorRequirementsData);

        setAllCourses(allCoursesWithRecScore);

        // Set number of years from surveyAnswers.
        setNumYears2(
          userData?.surveyAnswers?.SchoolEnd?.year -
            userData?.surveyAnswers?.SchoolStart?.year
        );
        setNumYears(userData?.schedules?.[activeTab]?.numYears);

        const cumulativeIds = calculateCumulativeIds(courseIds);
        setCumulativeCourseIds(cumulativeIds);

        const organizedCourses = organizeCoursesByYearAndSemester(
          courseIds,
          allCoursesWithRecScore,
          prereqs,
          cumulativeIds
        );
        console.log('OrganizedCourses: ', organizedCourses, courseIds);
        setFinalizedOrganizedCourses(organizedCourses);

        const allOrganizedCourses = organizeAllCoursesByYearAndSemester(
          allCoursesWithRecScore
        );
        setAllCoursesByYearAndSemester(allOrganizedCourses);

        const milestonesData = userData.finalizedMilestones?.[activeTab] || {};
        const finalizedMilestonesArray = Object.values(milestonesData).reduce(
          (acc, yearData) => {
            Object.values(yearData).forEach((termData) => {
              acc = acc.concat(termData.milestones);
            });
            return acc;
          },
          []
        );
        setFinalizedMilestones(finalizedMilestonesArray);

        const organizedMilestones = organizeMilestonesByYearAndSemester(
          finalizedMilestonesArray
        );
        setMilestonesByYearAndSemester(organizedMilestones);
      }
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
}, [
  fetchedCourseData,
  fetchedPrereqData,
  fetchedMajors,
  majorData,
  fetchedMajorRecs,
  fetchedMinors,
  currentUser,
  activeTab,
  tabs,
  fetchedTerms
]);
  
  useEffect(() => {
    if (activeTab) {
      fetchUserDataLocal();
    }
  }, [fetchUserDataLocal, activeTab]);
  
  useEffect(() => {
    fetchUserDataLocal();
  }, [fetchUserDataLocal]);

  useEffect(() => {
    const fetchScheduleData = async () => {
      try {
        if (currentUser) {
          const userDocRef = db.collection('users').doc(effectiveUserId);
          const userDoc = await userDocRef.get();
  
          if (userDoc.exists) {
            const userData = userDoc.data();
            const scheduleArray = Object.keys(userData.schedules || {}).map((tabName) => ({
              name: tabName,
              data: userData.schedules[tabName],
            })).sort((a, b) => a.name.localeCompare(b.name)); // Optional: Sort alphabetically or by some other criteria
  
            setTabs(scheduleArray);
            const initialTab = userData.activeTab || scheduleArray[0].name;
            setActiveTab(initialTab);

            const primaryTabFromBackend = userData?.primaryTab || scheduleArray[0].name;
            setPrimaryTab(primaryTabFromBackend)

            if (!userData?.primaryTab){
              await userDocRef.update({
                primaryTab: primaryTabFromBackend
              });
            }

            // Ensure the courses for the initial active tab are loaded
            const courseIds = userData.schedules[initialTab] || [];
            setFinalizedCourseIds(courseIds);
  
            const cumulativeIds = calculateCumulativeIds(courseIds);
            setCumulativeCourseIds(cumulativeIds);
  
            const organizedCourses = organizeCoursesByYearAndSemester(courseIds, allCourses, prereqs, cumulativeIds);
            setFinalizedOrganizedCourses(organizedCourses);
  
          } else {
            setShowAddScheduleModal(true);
          }
        }
      } catch (error) {
        console.error('Error fetching schedule data', error);
      }
    };
  
    fetchScheduleData();
  }, [currentUser]); // Ensure this runs when the user, allCourses, or prereqs changes
  

  useEffect(() => {
    if (currentUser) {
      const fetchCompletedSemesters = async () => {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        const userDoc = await userDocRef.get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          setCompletedSemesters(userData.completedSemesters || {});
        }
      };
      fetchCompletedSemesters();
    }
  }, [currentUser]);

  const handleToggleSemesterComplete = async (year, semester) => {
    // Assume fetchedTerms is available in scope.
    // Determine the sorted array of term keys (as strings).
    const termKeys = Object.keys(termMap).sort((a, b) => Number(a) - Number(b));
    
    // Convert the friendly semester name (e.g. "Fall") to its term key (e.g. "1")
    const termKey = Object.keys(termMap).find(
      (key) => termMap[key] === semester
    );
    if (!termKey) {
      console.error("No matching term key found for", semester);
      return;
    }
  
    // Determine the index of the current term in the sorted list.
    const currentTermIndex = termKeys.indexOf(termKey);
  
    const yearKey = `year${year}`;
    const currentStatus = completedSemesters[yearKey]?.[termKey];
    const newStatus = !currentStatus;
  
    // Update the completedSemesters object for the current year.
    const updatedCompletedSemesters = {
      ...completedSemesters,
      [yearKey]: {
        ...completedSemesters[yearKey],
        [termKey]: newStatus,
      },
    };
  
    // If the term is being marked incomplete, clear this term and all subsequent terms.
    if (!newStatus) {
      // For each year from the current year to numYears:
      for (let i = year; i <= numYears; i++) {
        const yKey = `year${i}`;
        updatedCompletedSemesters[yKey] = {};
        termKeys.forEach((tk, index) => {
          if (i === year) {
            // For the current year, preserve completion status for earlier terms;
            // mark the current term and any later term as incomplete.
            updatedCompletedSemesters[yKey][tk] = index < currentTermIndex ? true : false;
          } else {
            // For subsequent years, mark all terms as incomplete.
            updatedCompletedSemesters[yKey][tk] = false;
          }
        });
      }
    }
  
    // Update local state.
    setCompletedSemesters(updatedCompletedSemesters);
  
    // Now update Firestore.
    if (currentUser) {
      const userDocRef = db.collection('users').doc(effectiveUserId);
      try {
        const userDoc = await userDocRef.get();
  
        if (userDoc.exists) {
          const userData = userDoc.data();
          const allPlans = Object.keys(userData.schedules || {});
          // Use the active tab from the document (falling back to the first plan)
          const activeTab = userData.activeTab || allPlans[0];
  
          // Fetch courses from the active tab for the completed term.
          // (Using termKey instead of a hardcoded value)
          const coursesToCopy =
            userData.schedules[activeTab][`year${year}`]?.[termKey]?.courses || [];
  
          // Prepare batch updates.
          const batch = db.batch();
  
          // Include the update to completedSemesters in the batch.
          batch.update(userDocRef, { completedSemesters: updatedCompletedSemesters });
  
          // Overwrite the courses in the completed term in each plan.
          allPlans.forEach((plan) => {
            // Copy courses for the current year and term.
            const updatedSchedule = {
              ...userData.schedules[plan],
              [`year${year}`]: {
                ...userData.schedules[plan][`year${year}`],
                [termKey]: {
                  ...userData.schedules[plan][`year${year}`]?.[termKey],
                  courses: coursesToCopy,
                },
              },
            };
  
            batch.update(userDocRef, {
              [`schedules.${plan}`]: updatedSchedule,
            });
          });
  
          // Commit all updates.
          await batch.commit();
  
          console.log('Semester status updated and courses synchronized across all plans.');
        }
      } catch (error) {
        console.error('Error updating completed semesters:', error);
      }
    }
  };

  const shouldShowMarkCompleteButton = (year, friendlyTerm) => {
    // Create a sorted array of friendly term names (e.g. ["Fall", "Spring", "Summer"])
    // by sorting the term keys numerically.
    const sortedFriendlyTerms = Object.keys(termMap)
      .sort((a, b) => Number(a) - Number(b))
      .map(key => termMap[key]);
  
    // Find the index of the current term friendly name.
    const index = sortedFriendlyTerms.indexOf(friendlyTerm);
    if (index === -1) {
      // If the term isn't found, default to showing the button.
      return true;
    }
  
    // If this is the very first term overall, no prerequisite is needed.
    if (year === 1 && index === 0) {
      return true;
    }
  
    let prevYear, prevFriendlyTerm;
    if (index === 0) {
      // The current term is the first term in this year's order.
      // Then, the previous term is the last term of the previous year.
      prevYear = year - 1;
      prevFriendlyTerm = sortedFriendlyTerms[sortedFriendlyTerms.length - 1];
    } else {
      // Otherwise, the previous term is in the same year.
      prevYear = year;
      prevFriendlyTerm = sortedFriendlyTerms[index - 1];
    }
  
    // Get the underlying term key for the previous term.
    const prevTermKey = Object.keys(termMap).find(
      key => termMap[key] === prevFriendlyTerm
    );
    const prevYearKey = `year${prevYear}`;
  
    // Return true if the previous term is marked complete.
    return !!(completedSemesters[prevYearKey]?.[prevTermKey]);
  };

const handlePlaceholderSelection = (category, yr, sem, req) => {
  setSelectedPlaceholderCategory(category);
  setSelectedPlaceholderYear(yr);
  setSelectedPlaceholderSemester(sem);
  setSelectedPlaceholderRequirement(req);
  setShowPlaceholderSidebar(true);
}

const shouldShowRemoveYearButton = (year) => {
    const yearKey = `year${year}`;
    const completed = completedSemesters[yearKey];
    // If there's no data for this year, then no term is complete.
    if (!completed) return true;
    
    // Check if any term in this year is marked complete.
    const anyTermComplete = Object.keys(termMap).some((termKey) => completed[termKey]);
    return !anyTermComplete;
  };

  const calculateCumulativeIds = (courseIds) => {
    let cumulativeIds = {};
    let prevYearsCourses = [];
  
    // Get sorted term keys (assumed to be strings representing numbers)
    const termKeys = Object.keys(termMap).sort((a, b) => Number(a) - Number(b));
  
    // Loop over each year as defined by courseIds.numYears
    for (let i = 1; i <= courseIds.numYears; i++) {
      const year = `year${i}`;
      let yearCourses = {};
      // Start with the courses accumulated from previous years.
      let cumulativeCourses = [...prevYearsCourses];
  
      // Loop over each dynamic term key
      termKeys.forEach((termKey) => {
        if (courseIds && courseIds[year] && courseIds[year][termKey]) {
          // Concatenate the courses from this term (assumed to be in courseIds[year][termKey].courses)
          cumulativeCourses = cumulativeCourses.concat(courseIds[year][termKey].courses);
        }
        // Remove duplicates
        cumulativeCourses = Array.from(new Set(cumulativeCourses));
        // Save the cumulative course list for this term in the current year.
        yearCourses[termKey] = [...cumulativeCourses];
      });
  
      // Update the courses accumulated from previous years.
      prevYearsCourses = Array.from(new Set([...prevYearsCourses, ...cumulativeCourses]));
      cumulativeIds[year] = yearCourses;
    }
  
    return cumulativeIds;
  };

  const parsePrerequisites = (prerequisites) => {
    let arrayOfPrerequisites = prerequisites.split("&");
    arrayOfPrerequisites = arrayOfPrerequisites.map(element => element.replace(/\(|\)/g, ''));
    return arrayOfPrerequisites.map(element => element.split("||"));
  };

  const parseCorequisites = (corequisites) => {
    let arrayOfCorequisites = corequisites.split("&");
    arrayOfCorequisites = arrayOfCorequisites.map(element => element.replace(/\(|\)/g, ''));
    return arrayOfCorequisites.map(element => element.split("||"));
  };

  const checkPrerequisites = (course, yearKey, semesterKey, cumulativeIds) => {
    // Assume termMap is available in scope.
    // Get a sorted array of the dynamic term keys.
    const sortedTermKeys = Object.keys(termMap).sort((a, b) => Number(a) - Number(b));
    // Find the index of the current term in this sorted order.
    const currentIndex = sortedTermKeys.indexOf(semesterKey);
    
    let isPrereqMet = true;
    let isCoreqMet = true;
  
    // Check prerequisites if they exist.
    if (course.Prerequisites && course.Prerequisites[0] && course.Prerequisites[0][0]) {
      isPrereqMet = course.Prerequisites.every(prereqGroup =>
        prereqGroup.some(prereq => {
          // Case 1: For the very first term of the very first year,
          // prerequisites are assumed to be fulfilled by courses from year0.
          if (yearKey === 'year1' && currentIndex === 0) {
            return cumulativeIds['year0'] && cumulativeIds['year0'].includes(prereq);
          }
          // Case 2: If this is the first term of any subsequent year,
          // check the last term of the previous year.
          else if (currentIndex === 0) {
            const prevYearNum = parseInt(yearKey.replace('year', '')) - 1;
            const prevYearKey = `year${prevYearNum}`;
            const lastTermKey = sortedTermKeys[sortedTermKeys.length - 1];
            return (
              cumulativeIds[prevYearKey] &&
              cumulativeIds[prevYearKey][lastTermKey] &&
              cumulativeIds[prevYearKey][lastTermKey].includes(prereq)
            );
          }
          // Case 3: Otherwise (i.e. not in the first term),
          // check the courses from the first term of the current year.
          else {
            const firstTermKey = sortedTermKeys[0];
            return (
              cumulativeIds[yearKey] &&
              cumulativeIds[yearKey][firstTermKey] &&
              cumulativeIds[yearKey][firstTermKey].includes(prereq)
            );
          }
        })
      );
    }
  
    // Check corequisites if they exist.
    if (course.Corequisites && course.Corequisites[0] && course.Corequisites[0][0]) {
      // For corequisites, we use the courses available in the current term.
      const coreqTermKey = semesterKey; // you may modify this if you want different logic
      isCoreqMet = course.Corequisites.some(coreqGroup =>
        coreqGroup.some(coreq => {
          return (
            cumulativeIds[yearKey] &&
            cumulativeIds[yearKey][coreqTermKey] &&
            cumulativeIds[yearKey][coreqTermKey].includes(coreq)
          );
        })
      );
    }
  
    return isPrereqMet && isCoreqMet;
  };
  
  const recheckPrerequisites = useCallback((updatedOrganizedCourses) => {
    let cumulativeIds = { year0: [] };
    let prevYearsCourses = [];
  
    // Obtain a sorted array of dynamic term keys (e.g. ["1", "2", "3"]) 
    // based on numeric order.
    const termKeys = Object.keys(termMap).sort((a, b) => Number(a) - Number(b));
  
    // Loop over each year.
    for (let i = 1; i <= numYears; i++) {
      const yearKey = `year${i}`;
      let yearCourses = {};
      // Start with the courses from previous years.
      let cumulativeCourses = [...prevYearsCourses];
  
      // Loop over each dynamic term key.
      termKeys.forEach((termKey) => {
        // Look up the friendly name for this term.
        const friendlyName = termMap[termKey];
        if (
          updatedOrganizedCourses &&
          updatedOrganizedCourses[yearKey] &&
          updatedOrganizedCourses[yearKey][friendlyName]
        ) {
          // Concatenate course IDs from the current term.
          cumulativeCourses = cumulativeCourses.concat(
            updatedOrganizedCourses[yearKey][friendlyName].map(
              (course) => course.courseInfo_courseNumber
            )
          );
        }
        // Remove duplicates.
        cumulativeCourses = Array.from(new Set(cumulativeCourses));
        // Save the cumulative list for this term (using the dynamic term key).
        yearCourses[termKey] = [...cumulativeCourses];
      });
  
      // Update the cumulative courses for subsequent years.
      prevYearsCourses = Array.from(new Set([...prevYearsCourses, ...cumulativeCourses]));
      cumulativeIds[yearKey] = yearCourses;
    }
  
    // Create a new object for updated courses.
    const updateObject = { ...updatedOrganizedCourses };
  
    // Now, for every year and every term (using dynamic keys/friendly names),
    // update each course's prerequisites information.
    for (const yearKey in updateObject) {
      termKeys.forEach((termKey) => {
        // Use the friendly name from fetchedTerms.
        const friendlyName = termMap[termKey];
        const semesterClassesObject = updateObject[yearKey][friendlyName] || [];
  
        updateObject[yearKey][friendlyName] = semesterClassesObject.map((course) => {
          const courseInfo = fetchedPrereqData.find(
            (prereq) => prereq.Course === course.courseInfo_courseNumber
          );
  
          if (courseInfo) {
            course.Prerequisites = parsePrerequisites(courseInfo.Prerequisites);
            course.Corequisites = parseCorequisites(courseInfo.Corequisites);
          }
  
          // Pass the dynamic term key to checkPrerequisites.
          course.isPrereqMet = checkPrerequisites(course, yearKey, termKey, cumulativeIds);
  
          return { ...course };
        });
      });
    }
  
    setFinalizedOrganizedCourses(updateObject);
  }, [numYears, fetchedTerms, fetchedPrereqData, parsePrerequisites, parseCorequisites, checkPrerequisites]);

  const handleUpdateCourseProgress = async () => {
    try {
      if (currentUser && effectiveUserId) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        const userDoc = await userDocRef.get();
  
        if (userDoc.exists) {
          const userData = userDoc.data();
          const primaryTab = userData?.primaryTab;
          const finalizedSchedule = userData?.schedules[primaryTab];
          const userCompletedSemesters = userData.completedSemesters || {};
  
          let courseArray = [];
          // Instead of a fixed 4 years and 2 semesters, we use the number of years in the plan (or 4 as default)
          const totalYears = 4; // or use a dynamic value if available
  
          // Get a sorted array of term keys from termMap (e.g. ["1", "2", "3"])
          const sortedTermKeys = Object.keys(termMap).sort((a, b) => Number(a) - Number(b));
  
          // Iterate over each year
          for (let i = 1; i <= totalYears; i++) {
            const year = `year${i}`;
            // Iterate over each dynamic term key
            sortedTermKeys.forEach((termKey) => {
              const friendlyName = termMap[termKey];
              if (
                finalizedSchedule &&
                finalizedSchedule[year] &&
                finalizedSchedule[year][friendlyName]
              ) {
                courseArray.push(...finalizedSchedule[year][friendlyName].courses);
              }
            });
          }
  
          const studentCourses = fetchedCourseData
            ? fetchedCourseData.filter(course =>
                courseArray.includes(course.courseInfo_courseNumber)
              )
            : [];
  
          const major = userData.surveyAnswers?.Majors[0] || 'N/A';
          const majorCourses = Object.values(fetchedMajors[major]);
  
          const totalCourses = majorCourses
            ? majorCourses.filter(majorCourse => majorCourse.courseType !== 'Milestone')
                .length
            : 0;
  
          // Build the set of taken courses from completed semesters.
          const takenCoursesSet = new Set();
          Object.keys(userCompletedSemesters).forEach((yearKey) => {
            // userCompletedSemesters is assumed to be keyed by year, with inner keys equal to dynamic term keys.
            Object.keys(userCompletedSemesters[yearKey]).forEach((termKey) => {
              if (userCompletedSemesters[yearKey][termKey]) {
                // Look up the friendly name for the term key.
                const friendlyName = termMap[termKey];
                const completedCourses =
                  finalizedSchedule[yearKey] &&
                  finalizedSchedule[yearKey][friendlyName] &&
                  finalizedSchedule[yearKey][friendlyName].courses
                    ? finalizedSchedule[yearKey][friendlyName].courses
                    : [];
                completedCourses.forEach((courseId) => {
                  takenCoursesSet.add(courseId);
                });
              }
            });
          });
          const takenCoursesArray = Array.from(takenCoursesSet);
          const takenCourses = fetchedCourseData.filter(course =>
            takenCoursesArray.includes(course.courseInfo_courseNumber)
          );
  
          // Helper function: Find requirement courses (unchanged)
          function findRequirementCourses(majorCourses, courses, nonUsableCourses) {
            const nonUsableSet = new Set(nonUsableCourses);
            const requirements = majorCourses.filter(
              majorCourse =>
                (majorCourse.courseType === 'Major Course' && majorCourse.Requirements) ||
                majorCourse.courseType === 'Requirement'
            );
            const studentCoursesMap = new Map();
            courses.forEach((course, index) => {
              if (!nonUsableSet.has(course.courseInfo_courseNumber)) {
                studentCoursesMap.set(course.courseInfo_courseNumber, { ...course, index });
              }
            });
            const reqIndexToPossibleCourses = [];
            requirements.forEach((requirement, reqIndex) => {
              const possibleCourses = requirement.Possibilities.split(',')
                .map(course => course.trim());
              reqIndexToPossibleCourses[reqIndex] = [];
              possibleCourses.forEach(courseNumber => {
                if (studentCoursesMap.has(courseNumber)) {
                  reqIndexToPossibleCourses[reqIndex].push(
                    studentCoursesMap.get(courseNumber).index
                  );
                }
              });
            });
            const matchToCourse = new Array(requirements.length).fill(-1);
            const matchToRequirement = new Array(courses.length).fill(-1);
            function bpm(u, seen) {
              for (const v of reqIndexToPossibleCourses[u]) {
                if (!seen[v]) {
                  seen[v] = true;
                  if (matchToRequirement[v] === -1 || bpm(matchToRequirement[v], seen)) {
                    matchToRequirement[v] = u;
                    matchToCourse[u] = v;
                    return true;
                  }
                }
              }
              return false;
            }
            for (let u = 0; u < requirements.length; u++) {
              const seen = new Array(courses.length).fill(false);
              bpm(u, seen);
            }
            const largestValidArray = [];
            for (let u = 0; u < matchToCourse.length; u++) {
              if (matchToCourse[u] !== -1) {
                largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
              }
            }
            return largestValidArray;
          }
  
          // Helper function: Find elective courses (unchanged)
          function findElectiveCourses(majorCourses, courses) {
            const electiveRequirements = majorCourses.filter(
              majorCourse => majorCourse.courseType === 'Elective'
            );
            const electiveIndexToPossibleCourses = [];
            electiveRequirements.forEach((electiveReq, electiveIndex) => {
              const electiveName = electiveReq.Possibilities;
              electiveIndexToPossibleCourses[electiveIndex] = [];
              if (electiveName === "Free-NonMajor-Elective") {
                for (let i = 0; i < courses.length; i++) {
                  electiveIndexToPossibleCourses[electiveIndex].push(i);
                }
              } else {
                courses.forEach((studentCourse, courseIndex) => {
                  if (
                    studentCourse.Elective_fulfillment &&
                    studentCourse.Elective_fulfillment.includes(electiveName)
                  ) {
                    electiveIndexToPossibleCourses[electiveIndex].push(courseIndex);
                  }
                });
              }
            });
            const matchToCourse = new Array(electiveRequirements.length).fill(-1);
            const matchToElective = new Array(courses.length).fill(-1);
            function bpm(u, seen) {
              for (const v of electiveIndexToPossibleCourses[u]) {
                if (!seen[v]) {
                  seen[v] = true;
                  if (matchToElective[v] === -1 || bpm(matchToElective[v], seen)) {
                    matchToElective[v] = u;
                    matchToCourse[u] = v;
                    return true;
                  }
                }
              }
              return false;
            }
            for (let u = 0; u < electiveRequirements.length; u++) {
              const seen = new Array(courses.length).fill(false);
              bpm(u, seen);
            }
            const largestValidArray = [];
            for (let u = 0; u < matchToCourse.length; u++) {
              if (matchToCourse[u] !== -1) {
                largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
              }
            }
            return largestValidArray;
          }
  
          // Calculate taken courses
          const takenMajorCoursesArray = takenCourses.filter(course => {
            return majorCourses.some(
              majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
            );
          });
          const takenElectiveCoursesArray = findElectiveCourses(
            majorCourses.filter(course => course.courseType === 'Elective'),
            takenCourses
          );
          const takenRequirementCoursesArray = findRequirementCourses(
            majorCourses.filter(course =>
              (course.courseType === 'Major Course' && course.Requirements) ||
              course.courseType === 'Requirement'
            ),
            takenCourses,
            takenElectiveCoursesArray
          );
  
          // Calculate all courses for progress tracking
          const allMajorCoursesArray = studentCourses.filter(course =>
            majorCourses.some(
              majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
            )
          );
          const allElectiveCoursesArray = findElectiveCourses(
            majorCourses.filter(course => course.courseType === 'Elective'),
            studentCourses
          );
          const allRequirementCoursesArray = findRequirementCourses(
            majorCourses.filter(course =>
              (course.courseType === 'Major Course' && course.Requirements) ||
              course.courseType === 'Requirement'
            ),
            studentCourses,
            allElectiveCoursesArray
          );
  
          const takenCoursesLengths = takenMajorCoursesArray.length +
            takenElectiveCoursesArray.length +
            takenRequirementCoursesArray.length;
  
          const allCoursesLengths = allMajorCoursesArray.length +
            allElectiveCoursesArray.length +
            allRequirementCoursesArray.length -
            takenCoursesLengths;
  
          const courseProgressObj = {
            taken: takenCoursesLengths,
            planned: allCoursesLengths,
            total: totalCourses
          };
  
          await userDocRef.update({
            courseProgress: courseProgressObj
          });
  
          console.log("Course Progress Updated Successfully");
        }
      }
    } catch (error) {
      console.error('Error updating course progress:', error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (activeTab === primaryTab) {
      const now = Date.now();
      const FIVE_SECONDS = 5000;
      
      if (now - lastCalledUpdateCourseProgress.current >= FIVE_SECONDS) {
        handleUpdateCourseProgress();
        lastCalledUpdateCourseProgress.current = now; // Update the last called time
      }
    }
  }, [primaryTab, activeTab, finalizedOrganizedCourses, completedSemesters]);


  useEffect(() => {
    const checkShownPrereqsWarningStatus = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', effectiveUserId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          let hasSeenPrereqsWarning = userDoc.data().hasSeenPrereqsWarning || false;
          if (!hasSeenPrereqsWarning) {
            setShowIncompletePrereqsWarningModal(true);
          }
        }
      }
    };

    checkShownPrereqsWarningStatus();
  }, [currentUser]);

  const handleRemove = useCallback(async (classToRemove, year, semFriendly) => {
    console.log('classToRemove: ', classToRemove);
    const removedCourseNumber = classToRemove.courseInfo_courseNumber;
  
    try {
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
  
        // Convert the passed friendly term name (e.g. "Fall") to its underlying term key.
        const termKey = Object.keys(termMap).find(
          (key) => termMap[key] === semFriendly
        );
        if (!termKey) {
          console.error('No term key found for semester:', semFriendly);
          return;
        }
  
        // In our Firestore schedules, we assume the schedule is stored per year,
        // with each year having keys equal to the friendly names of the terms.
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
          const userData = userDoc.data();
          const previousFinalizedSchedule = userData.schedules[activeTab] || {};
          // Create a copy of the current schedule.
          const updateObj = { ...previousFinalizedSchedule };
  
          const yearKey = `year${year}`;
          // If the year does not exist yet, initialize it with every term using termMap.
          if (!updateObj[yearKey]) {
            updateObj[yearKey] = {};
            Object.keys(termMap).forEach((tk) => {
              // Use the friendly name as key.
              updateObj[yearKey][termMap[tk]] = { courses: [], Placeholders: [] };
            });
          }
          // If the specific term (by friendly name) is not initialized, do so.
          if (!updateObj[yearKey][semFriendly]) {
            updateObj[yearKey][semFriendly] = { courses: [], Placeholders: [] };
          }
  
          if (removedCourseNumber === "Placeholder") {
            // Remove from Placeholders
            const existingPlaceholders = updateObj[yearKey][semFriendly].Placeholders || [];
            const newPlaceholders = existingPlaceholders.filter(
              (placeholder) => placeholder.PlaceholderID !== classToRemove.PlaceholderID
            );
            updateObj[yearKey][semFriendly].Placeholders = newPlaceholders;
            console.log('Updated placeholders:', newPlaceholders);
            console.log(`Removed placeholder with pid: ${classToRemove.PlaceholderID} from ${yearKey} ${semFriendly}`);
          } else {
            // Remove from courses
            const existingCourses = updateObj[yearKey][semFriendly].courses || [];
            const newCourses = existingCourses.filter(
              (courseId) => courseId !== removedCourseNumber
            );
            updateObj[yearKey][semFriendly].courses = newCourses;
            console.log(`Removed course: ${removedCourseNumber} from ${yearKey} ${semFriendly}`);
          }
  
          // Update the Firestore schedule for the active tab.
          transaction.update(userRef, {
            [`schedules.${activeTab}`]: updateObj
          }, { lastUpdateTime: userDoc.updateTime });
  
          // Update local state. Note that here our local state is assumed to use the friendly term names.
          setFinalizedOrganizedCourses(prev => {
            const newCoursesObj = { ...prev };
            // If the year is defined and contains the term (friendly name),
            // remove the course from that array.
            if (newCoursesObj[yearKey] && newCoursesObj[yearKey][semFriendly]) {
              newCoursesObj[yearKey][semFriendly] = newCoursesObj[yearKey][semFriendly].filter(
                course => course.courseInfo_courseNumber !== removedCourseNumber
              );
            }
            // Recalculate prerequisites after removal.
            recheckPrerequisites(newCoursesObj);
            return newCoursesObj;
          });
        }).catch(error => {
          console.error('Transaction failed:', error);
          throw error;
        });
      } else {
        console.error('No authenticated user found.');
      }
    } catch (error) {
      console.error('Error removing class:', error);
      // Optionally, display an error notification to the user.
    }
  }, [currentUser, activeTab, recheckPrerequisites, fetchedTerms]);

  const updateCourseOrderInFirestore = async (year, semester, updatedCourseNumbers) => {
    try {
      const userId = currentUser?.uid;
      const userRef = db.collection('users').doc(userId);
  
      const semesterCode = semester === 'Fall' ? 'S1' : 'S2';
  
      await userRef.update({
        [`schedules.${activeTab}.year${year}.${semesterCode}.courses`]: updatedCourseNumbers,
      });
  
      console.log('Course order updated in Firestore');
    } catch (error) {
      console.error('Error updating course order in Firestore:', error);
    }
  };

  const { handleAutofillCourse } = useUpdatedAutofill({
    currentUser,
    effectiveUserId,
    activeTab,
    recheckPrerequisites,
    setFinalizedOrganizedCourses,
    allCourses,
    majorCourses,
    organizeMajorCoursesByYearAndSemester,
    majorElectives,
    majorRequirementCourses,
    minorCourses,
    minorRequirementCourses,
    finalizedOrganizedCourses,
    maxCreditsPerSemester,
    fetchedTerms
  });

  const handleAutofillAllCourses = useCallback(async () => {
    // Show loading toast
    const toastId = toast.loading("Autofilling all courses...", { position: 'top-center' });
    
    setIsAutofilling(true);
    setHasAutofilledAll(true);
    
    try {
      // Determine which years to process.
      const years =
        activePlanNumYears >= 4
          ? [1, 2, 3, 4]
          : Array.from({ length: numYears }, (_, index) => index + 1);
      
      // Build a dynamic array of friendly term names based on fetchedTerms.
      // This sorts the underlying term keys numerically and maps to their friendly names.
      const semesters = Object.keys(termMap)
        .sort((a, b) => Number(a) - Number(b))
        .map((termKey) => termMap[termKey]);
      
      // Helper function to delay execution.
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        
      // Loop through each year and each dynamic term.
      for (const yearNumber of years) {
        for (const semester of semesters) {
          // Check completion status using the friendly term name.
          const isCompleted =
            completedSemesters &&
            completedSemesters[`year${yearNumber}`] &&
            completedSemesters[`year${yearNumber}`][semester] === true;
          
          if (!isCompleted) {
            await handleAutofillCourse(yearNumber, semester);
            await delay(150); // Add a short delay (150ms)
          }
        }
      }
    
      // Show success toast after autofill is completed.
      toast.update(toastId, {
        render: "All courses autofilled successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
        position: 'top-center',
      });
    
    } catch (error) {
      console.error('Error autofilling all courses:', error);
      // Show error toast if there's a failure.
      toast.update(toastId, {
        render: "Error autofilling all courses!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
        position: 'top-center',
      });
    } finally {
      await handleUpdateCourseProgress();
      setIsAutofilling(false);
    }
  }, [
    handleAutofillCourse,
    numYears,
    completedSemesters,
    fetchedTerms,
    handleUpdateCourseProgress,
  ]);


  const organizePrereqCourses = useCallback(() => {
    const prereqCourses = {};
    for (let i = 1; i <= numYears; i++) {
      if (!prereqCourses[i]) {
        prereqCourses[i] = [];
      }
      prereqCourses[i].push(...middleschoolClasses);
      finalizedCourses.forEach((course) => {
        const year = course.courseYear || 'Uncategorized';
        if (year < i) {
          prereqCourses[i].push(course.courseInfo_courseNumber);
        }
      });
    }
    return prereqCourses;
  }, [middleschoolClasses, finalizedCourses, numYears]);

  const prereqClasses = organizePrereqCourses();

  const getTotalCreditsForSemester = useCallback((courses) => {
    if (!courses) {
      return 0;
    }
    return courses.reduce((total, course) => {
      const credits = course.Credits === "N/A" ? 0 : course.Credits;
      return total + credits;
    }, 0);
  }, []);



  const getTotalCreditsForSchedule = useCallback((allOrganizedCourses) => {

    let studentCourses = [];
    let totalCreditsCount = 0;
    let upperDivisionCreditsCount = 0;

    for (const year in allOrganizedCourses) {
      if (allOrganizedCourses.hasOwnProperty(year)) {
        const semesters = allOrganizedCourses[year];
        for (const semester in semesters) {
          if (semesters.hasOwnProperty(semester)) {
            const courses = semesters[semester];
            studentCourses = studentCourses.concat(courses);
            const semesterCreditsCount = courses.reduce((total, course) => {
              return total + (course.Credits === 'N/A' || course.Credits === undefined ? 0 : Number(course.Credits));
            }, 0);          
            totalCreditsCount += semesterCreditsCount;
            const upperDivisionSemesterCreditsCount = courses
              .filter(course => {
                // Match the first sequence of digits in the course number
                const match = course.courseInfo_courseNumber.match(/(\d+)/);
                const courseNumber = match ? parseInt(match[1], 10) : 100; 
                // Decide if it's upper division (e.g., 300+)
                return courseNumber >= 300;
              })
              .reduce((total, course) => {
                return total + (course.Credits === 'N/A' || course.Credits === undefined ? 0 : Number(course.Credits));
              }, 0);
            upperDivisionCreditsCount += upperDivisionSemesterCreditsCount;
          }
        }
      }
    }
  
    return {totalCredits: totalCreditsCount, majorRequiredCredits: majorPathwayData.OverallCR, upperDivisionCredits: upperDivisionCreditsCount, majorRequiredUpperDivisionCredits: majorPathwayData.UDCR};

  }, [currentUser, activeTab]);


  const CreditsOverview = ({ finalizedOrganizedCourses, onOpen }) => {
    const { t } = useTranslation();

    const [finalizedOrganizedCoursesCreditsOverview, setFinalizedOrganizedCoursesCreditsOverview] = useState(finalizedOrganizedCourses);

    useEffect(() => {
      setFinalizedOrganizedCoursesCreditsOverview(finalizedOrganizedCourses);
    }, [finalizedOrganizedCourses]);

    let {
      totalCredits,
      majorRequiredCredits,
      upperDivisionCredits,
      majorRequiredUpperDivisionCredits
    } = getTotalCreditsForSchedule(finalizedOrganizedCoursesCreditsOverview);
    
    return (
      <div className="p-2 max-w-full mx-auto bg-whites rounded-lg">
        {/*<h2 className="text-lg font-semibold mb-4">Credit Overview</h2>*/}
        <div className="flex flex-wrap justify-between gap-2">
          <p className="text-sm">
            <button
              onClick={() => onOpen(true)}
              className={`bg-opacity-50 text-sm font-semibold rounded-lg px-2 py-1 mr-2 flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                totalCredits >= majorRequiredCredits
                  ? 'bg-green-100 text-green-700'
                  : totalCredits / majorRequiredCredits > 0.4
                  ? 'bg-yellow-100 text-yellow-700'
                  : 'bg-red-100 text-red-700'
              }`}
            >
              {t('planPage.totalCredits', 'Total Credits')}: {totalCredits}/{majorRequiredCredits}
            </button>
          </p>

          {/*<p className="text-sm">
            <span className="font-medium">Counting Towards Major:</span> {creditsTowardsMajor}
          </p>*/}

          <p className="text-sm">
            <span
              className={`bg-opacity-50 text-sm font-semibold rounded-lg px-2 py-1 mr-2 flex items-center ${
                upperDivisionCredits >= majorRequiredUpperDivisionCredits
                  ? 'bg-green-100 text-green-700'
                  : upperDivisionCredits / majorRequiredUpperDivisionCredits > 0.4
                  ? 'bg-yellow-100 text-yellow-700'
                  : 'bg-red-100 text-red-700'
              }`}
            >
              {t('planPage.upperDivisionCredits', 'Upper Division Credits')}:{' '}
              {upperDivisionCredits}/{majorRequiredUpperDivisionCredits}
            </span>
          </p>

          {hasUncompletedCourses && (
            <p className="text-sm">
              <span
                className={`bg-opacity-50 text-sm font-semibold rounded-lg px-2 py-1 mr-2 flex items-center ${
                  areAnyPrereqsNotMet ? 'bg-orange-100 text-orange-700' : 'bg-green-100 text-green-700'
                }`}
              >
                {areAnyPrereqsNotMet
                  ? t('planPage.warningRequisitesUnmet', 'Warning: Requisites Unmet')
                  : t('planPage.allRequisitesMet', 'All Requisites Met')}
              </span>
            </p>
          )}
        </div>
      </div>
    );
  };

  const { moveCourse } = useUpdatedMoveCourse({
    currentUser,
    effectiveUserId,
    finalizedOrganizedCourses,
    setFinalizedOrganizedCourses,
    recheckPrerequisites,
    activeTab,
  });

  const handleAddMilestone = (year, semester) => {
    console.log('Adding milestone for:', { year, semester });
    setCurrentSemester({ year, semester });
    setShowDrawer(true);
  };

  // New handler to edit a milestone
  const handleEditMilestone = (milestone) => {
    console.log('Editing milestone:', milestone);
    setEditingMilestone(milestone);
    setCurrentSemester({
      year: milestone.Milestone_Year,
      // Convert the dynamic term key (e.g. "1", "2", etc.) into its friendly name.
      semester: termMap[milestone.Milestone_Sem] || milestone.Milestone_Sem,
    });
    setShowModal(true);
  };
  const handleOpenAllMilestonesDrawer = () => {
    setShowAllMilestonesDrawer(true);
  };  

  const handleSaveMilestone = async (title, description, dueDate, milestoneID = null) => {
    console.log('Saving milestone with currentSemester:', currentSemester);
    if (
      !title ||
      !description ||
      !dueDate ||
      !currentSemester ||
      !currentSemester.year ||
      !currentSemester.semester
    ) {
      console.error('Invalid currentSemester:', currentSemester);
      return;
    }
  
    // Convert the friendly semester (e.g. "Fall") into its underlying term key (e.g. "1")
    const milestoneSemKey =
      Object.keys(termMap).find(key => termMap[key] === currentSemester.semester) ||
      currentSemester.semester;
  
    // Construct newMilestone object with dynamic term key
    const newMilestone = {
      Milestone_ID: milestoneID || `milestone_${Date.now()}`,
      Title: title,
      Description: description,
      DueDate: dueDate,
      Milestone_Year: currentSemester.year,
      Milestone_Sem: milestoneSemKey,
      // Only set createdAt and completed if adding a new milestone
      ...(milestoneID ? {} : { createdAt: new Date().toISOString(), completed: false }),
    };
  
    try {
      if (currentUser && effectiveUserId) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || 'default'; // Use activeTab to store under the correct plan/tab
            const yearKey = `year${currentSemester.year}`;
            // We'll use the underlying term key for milestone storage
            const termKey = newMilestone.Milestone_Sem;
  
            // Get the existing milestones for this plan, year, and term.
            let updatedMilestones =
              userData.finalizedMilestones?.[activeTab]?.[yearKey]?.[termKey]?.milestones || [];
  
            if (milestoneID) {
              // Editing an existing milestone
              const index = updatedMilestones.findIndex((m) => m.Milestone_ID === milestoneID);
              if (index !== -1) {
                updatedMilestones[index] = {
                  ...updatedMilestones[index],
                  ...newMilestone,
                };
              }
            } else {
              // Adding a new milestone
              updatedMilestones = [...updatedMilestones, newMilestone];
            }
  
            // Update the milestone structure in Firestore.
            transaction.set(
              userDocRef,
              {
                finalizedMilestones: {
                  ...userData.finalizedMilestones,
                  [activeTab]: {
                    ...userData.finalizedMilestones?.[activeTab],
                    [yearKey]: {
                      ...userData.finalizedMilestones?.[activeTab]?.[yearKey],
                      [termKey]: { milestones: updatedMilestones },
                    },
                  },
                },
              },
              { merge: true }
            );
          }
        });
  
        // Update local state
        if (milestoneID) {
          // Update the edited milestone in local state.
          setFinalizedMilestones((prev) =>
            prev.map((m) =>
              m.Milestone_ID === milestoneID ? { ...m, ...newMilestone } : m
            )
          );
        } else {
          // Add the new milestone to local state.
          setFinalizedMilestones((prev) => [...prev, newMilestone]);
        }
  
        setShowModal(false);
        setEditingMilestone(null); // Reset editing state
      }
    } catch (error) {
      console.error('Error saving milestone:', error);
    }
  };

  const handleRemoveMilestone = async (milestoneToRemove) => {
    try {
      if (currentUser && effectiveUserId) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || 'default';
            const year = `year${milestoneToRemove.Milestone_Year}`;
            // Use the dynamic term key directly.
            const semester = milestoneToRemove.Milestone_Sem;
  
            // Retrieve the existing milestones array for the specified plan, year, and term.
            const currentMilestones =
              userData.finalizedMilestones?.[activeTab]?.[year]?.[semester]?.milestones || [];
  
            // Filter out the milestone that needs to be removed.
            const updatedMilestones = currentMilestones.filter(
              (milestone) => milestone.Milestone_ID !== milestoneToRemove.Milestone_ID
            );
  
            if (updatedMilestones.length === 0) {
              // If there are no milestones left in this term, remove the term key.
              delete userData.finalizedMilestones[activeTab][year][semester];
  
              // If the year object becomes empty, remove the year.
              if (Object.keys(userData.finalizedMilestones[activeTab][year]).length === 0) {
                delete userData.finalizedMilestones[activeTab][year];
              }
  
              // If the activeTab object becomes empty, remove it as well.
              if (Object.keys(userData.finalizedMilestones[activeTab]).length === 0) {
                delete userData.finalizedMilestones[activeTab];
              }
            } else {
              // Otherwise, update the milestones for that term.
              userData.finalizedMilestones[activeTab][year][semester].milestones = updatedMilestones;
            }
  
            transaction.update(userDocRef, {
              finalizedMilestones: userData.finalizedMilestones,
            });
  
            // Update local state: Remove the milestone from the overall list
            setFinalizedMilestones((prev) =>
              prev.filter(
                (milestone) => milestone.Milestone_ID !== milestoneToRemove.Milestone_ID
              )
            );
            // Also update the milestones organized by year and term.
            setMilestonesByYearAndSemester((prev) => ({
              ...prev,
              [year]: {
                ...prev[year],
                [semester]: updatedMilestones,
              },
            }));
          }
        });
      }
    } catch (error) {
      console.error('Error removing milestone:', error);
    }
  };

  const handleToggleComplete = async (milestone, event) => {
    event.stopPropagation();
    try {
      const updatedMilestone = { ...milestone, completed: !milestone.completed };
  
      if (currentUser && effectiveUserId) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || 'default';
            const year = `year${milestone.Milestone_Year}`;
            // Use the dynamic term key directly instead of converting (e.g., no longer checking for 'S1'/'S2')
            const semester = milestone.Milestone_Sem;
  
            // Find the index of the milestone in the corresponding semester's milestone array.
            const milestoneIndex = userData.finalizedMilestones[activeTab][year][semester].milestones.findIndex(
              (m) => m.Milestone_ID === milestone.Milestone_ID
            );
  
            if (milestoneIndex > -1) {
              userData.finalizedMilestones[activeTab][year][semester].milestones[milestoneIndex] = updatedMilestone;
  
              transaction.update(userDocRef, { finalizedMilestones: userData.finalizedMilestones });
  
              // Update the overall milestone state.
              setFinalizedMilestones((prev) =>
                prev.map((m) =>
                  m.Milestone_ID === milestone.Milestone_ID ? updatedMilestone : m
                )
              );
              // Also update the organized milestones by year and term.
              setMilestonesByYearAndSemester((prev) => ({
                ...prev,
                [year]: {
                  ...prev[year],
                  [semester]: userData.finalizedMilestones[activeTab][year][semester].milestones,
                },
              }));
            }
          }
        });
      }
    } catch (error) {
      console.error('Error toggling milestone completion:', error);
    }
  };

  useEffect(() => {
    const organizedMilestones = organizeMilestonesByYearAndSemester(finalizedMilestones);
    setMilestonesByYearAndSemester(organizedMilestones);
  }, [finalizedMilestones, organizeMilestonesByYearAndSemester]);

  const handleExportPlan = async () => {
    try {
      // Reset isUserDataFetched to allow fetchUserData to run
      if (isUserDataFetched && isUserDataFetched.current !== undefined) {
        isUserDataFetched.current = false;
      } else {
        console.error('isUserDataFetched is undefined or null');
      }
  
      // Call fetchUserData and wait for it to complete
      await fetchUserData(currentUser);
  
      // Set dataFetchComplete to true after data is fetched
      setDataFetchComplete(true);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    // Check if data fetching is complete before generating the PDF
    if (dataFetchComplete) {
      const generatePDF = async () => {
        try {
          const pdfBytes = await createPDFDocument(finalizedOrganizedCourses, currentUser);
          setPdfBytes(pdfBytes);
          setShowPdfModal(true);
        } catch (error) {
          console.error('Error generating PDF:', error);
        } finally {
          // Reset dataFetchComplete to prevent repeated exports
          setDataFetchComplete(false);
        }
      };
  
      generatePDF();
    }
  }, [dataFetchComplete, finalizedOrganizedCourses, currentUser]);  
  
  const closePdfModal = () => {
    setShowPdfModal(false);
    setPdfBytes(null);
  };

  const handleRemoveSemesterCourses = async (year, semester) => {
    if (currentUser && effectiveUserId) {
      const userRef = db.collection('users').doc(effectiveUserId);
  
      try {
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const updatedFinalizedSchedule = { ...userData.schedules[activeTab] };
          const yearKey = `year${year}`;
  
          // Ensure that the schedule for the given year and term exists.
          if (!updatedFinalizedSchedule[yearKey]) {
            updatedFinalizedSchedule[yearKey] = {};
            // Optionally initialize all terms here if needed.
          }
          if (!updatedFinalizedSchedule[yearKey][semester]) {
            updatedFinalizedSchedule[yearKey][semester] = { courses: [], Placeholders: [] };
          }
  
          // Remove all courses from the specified term.
          updatedFinalizedSchedule[yearKey][semester].courses = [];
  
          // Update Firestore with the new schedule.
          transaction.update(userRef, { [`schedules.${activeTab}`]: updatedFinalizedSchedule });
  
          // Update the completedSemesters object for this year/term:
          const updatedCompletedSemesters = {
            ...completedSemesters,
            [yearKey]: {
              ...completedSemesters[yearKey],
              [semester]: false,
            },
          };
          transaction.update(userRef, { completedSemesters: updatedCompletedSemesters });
          setCompletedSemesters(updatedCompletedSemesters);
        });
  
        // Update local state for finalizedOrganizedCourses using the friendly term name.
        setFinalizedOrganizedCourses((prev) => {
          const yearKey = `year${year}`;
          const newCourses = {
            ...prev,
            [yearKey]: {
              ...prev[yearKey],
              [semester]: [],
            },
          };
  
          // Re-check prerequisites with the updated schedule.
          recheckPrerequisites(newCourses);
          return newCourses;
        });
  
        console.log(`Courses for Year ${year} ${semester} removed successfully`);
      } catch (error) {
        console.error('Failed to remove semester courses:', error);
      }
    } else {
      console.error('Error: currentUser or effectiveUserId is not defined');
    }
  };

  const handleRemoveAllCourses = async () => {
    if (currentUser && effectiveUserId) {
      const userRef = db.collection('users').doc(effectiveUserId);
      try {
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
          const userData = userDoc.data();
          const updatedFinalizedSchedule = { ...userData.schedules[activeTab] };
          const numYearsValue = updatedFinalizedSchedule.numYears; // Preserve numYears value
  
          // Get dynamic term names from fetchedTerms (sorted numerically)
          const sortedTermKeys = Object.keys(termMap).sort((a, b) => Number(a) - Number(b));
          
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            updatedFinalizedSchedule[yearKey] = {};
            // For each term, initialize using the friendly name from fetchedTerms
            sortedTermKeys.forEach(termKey => {
              const friendlyTerm = termMap[termKey];
              // Optionally check if this term is completed to decide whether to keep its courses
              const termCompleted = completedSemesters[yearKey]?.[friendlyTerm];
              updatedFinalizedSchedule[yearKey][friendlyTerm] = termCompleted
                ? userData.schedules[activeTab][yearKey]?.[friendlyTerm] || { courses: [], Placeholders: [] }
                : { courses: [], Placeholders: [] };
            });
          }
          console.log('Updated Finalized Schedule: ', updatedFinalizedSchedule);
  
          // Preserve numYears
          updatedFinalizedSchedule.numYears = numYearsValue;
  
          transaction.update(userRef, { [`schedules.${activeTab}`]: updatedFinalizedSchedule });
          // Update local state as needed...
        });
      } catch (error) {
        console.error('Failed to remove courses from incomplete semesters:', error);
      }
    } else {
      console.error('Error: currentUser or effectiveUserId is not defined');
    }
  };

  const handleReshuffleCourses= async () => {
    if (currentUser && effectiveUserId) {
      const userRef = db.collection('users').doc(effectiveUserId);
  
      try {
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          /*const userData = userDoc.data();
          const updatedFinalizedSchedule = { ...userData.schedules[activeTab] };
          const numYearsValue = updatedFinalizedSchedule.numYears; // Save the numYears value
  
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            const fallCompleted = completedSemesters[yearKey]?.S1;
            const springCompleted = completedSemesters[yearKey]?.S2;
  
            updatedFinalizedSchedule[yearKey] = {
              S1: fallCompleted ? userDoc.data().schedules[activeTab][yearKey]?.S1 : { courses: [] },
              S2: springCompleted ? userDoc.data().schedules[activeTab][yearKey]?.S2 : { courses: [] },
            };
          }
  
          updatedFinalizedSchedule.numYears = numYearsValue; // Re-assign the numYears value to ensure it is not removed
  
          transaction.update(userRef, { [`schedules.${activeTab}`]: updatedFinalizedSchedule });
  
          const updatedFinalizedCourses = {};
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            const fallCompleted = completedSemesters[yearKey]?.S1;
            const springCompleted = completedSemesters[yearKey]?.S2;
  
            updatedFinalizedCourses[yearKey] = {
              Fall: fallCompleted ? finalizedOrganizedCourses[yearKey]?.Fall : [],
              Spring: springCompleted ? finalizedOrganizedCourses[yearKey]?.Spring : [],
            };
          }
  
          setFinalizedOrganizedCourses(updatedFinalizedCourses);*/

          console.log('Clicked reshuffle')
  
          console.log('Courses removed successfully from incomplete semesters.');
        });
      } catch (error) {
        console.error('Failed to remove courses from incomplete semesters:', error);
      }
    } else {
      console.error('Error: currentUser or effectiveUserId is not defined');
    }
  };  

  const handleAddYear = async () => {
    if (isProcessing) return; // Prevent concurrent execution
    setIsProcessing(true);
    try {
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
    
          const userData = userDoc.data();
          const activeTabIndex = tabs.findIndex(tab => tab.name === activeTab);
          if (activeTabIndex === -1) return; // No active tab found
    
          const activeTabData = tabs[activeTabIndex];
          const activePlanNumYears = numYears;
          const updatedNumYears = activePlanNumYears + 1;
          console.log('Updated num years: ', activeTabData, activeTabData.numYears, updatedNumYears);
    
          // Define new year key.
          const newYearKey = `year${updatedNumYears}`;
    
          // Build an object for the new year by iterating over your dynamic terms.
          // We create a sorted list of friendly term names:
          const sortedFriendlyTerms = Object.keys(termMap)
            .sort((a, b) => Number(a) - Number(b))
            .map((termKey) => termMap[termKey]);
    
          // Build a new object with each term initialized.
          const newYearSchedule = {};
          sortedFriendlyTerms.forEach((friendlyTerm) => {
            newYearSchedule[friendlyTerm] = { courses: [], Placeholders: [] };
          });
    
          // Build updated tab data for the active plan.
          const updatedTab = {
            ...activeTabData,
            numYears: updatedNumYears,
            [newYearKey]: newYearSchedule,
          };
    
          // Prepare Firestore update data.
          const updateData = {
            [`schedules.${activeTab}.numYears`]: updatedNumYears,
            [`schedules.${activeTab}.${newYearKey}`]: newYearSchedule,
          };
    
          // Update Firestore document.
          transaction.update(userRef, updateData);
    
          // Update the tabs array in state.
          const updatedTabs = [...tabs];
          updatedTabs[activeTabIndex] = updatedTab;
          setTabs(updatedTabs);
    
          // Update local state for finalizedOrganizedCourses using dynamic term keys.
          setFinalizedOrganizedCourses(prev => ({
            ...prev,
            [newYearKey]: newYearSchedule,
          }));
    
          // Update numYears to trigger re-render.
          setNumYears(updatedNumYears);
    
          // Scroll to the newly added year after it's rendered.
          setTimeout(() => {
            if (yearRefs.current[`year${updatedNumYears}`]) {
              yearRefs.current[`year${updatedNumYears}`].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          }, 100);
    
          console.log('New year added to finalizedSchedule');
        });
      } else {
        console.error('Error: currentUser or effectiveUserId is not defined');
      }
    } catch (error) {
      console.error('Failed to add new year:', error);
    } finally {
      setIsProcessing(false);
    }
  };
  
  const handleRemoveYear = async () => {
    if (isProcessing) return; // Prevent concurrent execution
    setIsProcessing(true);
    try {
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const activeTabIndex = tabs.findIndex(tab => tab.name === activeTab);
          if (activeTabIndex === -1) return; // No active tab found
  
          const activeTabData = tabs[activeTabIndex];
          const activePlanNumYears = numYears;
  
          if (activePlanNumYears <= 1) {
            console.error('Cannot remove the last remaining year.');
            return; // Prevent removing the last year
          }
  
          const updatedNumYears = activePlanNumYears - 1;
          console.log('Updated Num Years: ', updatedNumYears);
  
          // Get the last year key (e.g., "year4" if there are 4 years)
          const lastYearKey = `year${activePlanNumYears}`;
  
          // Build a removedCourses object dynamically using fetchedTerms
          const sortedTermKeys = Object.keys(termMap).sort((a, b) => Number(a) - Number(b));
          const removedCourses = {};
          sortedTermKeys.forEach(termKey => {
            const friendlyTerm = termMap[termKey];
            removedCourses[friendlyTerm] = finalizedOrganizedCourses[lastYearKey]?.[friendlyTerm] || [];
          });
          // (removedCourses is available if you later want to use or move these courses.)
  
          // Remove the milestones for the last year from the user's data
          const updatedMilestones = { ...userData.finalizedMilestones };
          if (updatedMilestones[activeTab] && updatedMilestones[activeTab][lastYearKey]) {
            delete updatedMilestones[activeTab][lastYearKey]; // Remove milestones for the year being deleted
          }
  
          // Update Firestore document with both schedules and milestones.
          // Here we remove the entire last year from the active plan.
          const updatedSchedules = {
            ...userData.schedules,
            [activeTab]: {
              ...userData.schedules[activeTab],
              numYears: updatedNumYears,
            },
          };
          delete updatedSchedules[activeTab][lastYearKey];
  
          transaction.update(userRef, {
            schedules: updatedSchedules,
            finalizedMilestones: updatedMilestones, // Update milestones in Firestore
          });
  
          // Update the tabs array in local state
          const updatedTabs = [...tabs];
          updatedTabs[activeTabIndex] = {
            ...activeTabData,
            numYears: updatedNumYears,
          };
          console.log('Tabs: ', updatedTabs);
          setTabs(updatedTabs);
  
          // Update finalizedOrganizedCourses in local state by removing the last year
          setFinalizedOrganizedCourses(prev => {
            const newCourses = { ...prev };
            delete newCourses[lastYearKey];
            return newCourses;
          });
  
          // Also update finalizedMilestones in local state to remove milestones for the removed year
          setFinalizedMilestones(prev => {
            const newMilestones = { ...prev };
            if (newMilestones[activeTab]) {
              delete newMilestones[activeTab][lastYearKey];
            }
            return newMilestones;
          });
  
          setNumYears(updatedNumYears);  // Ensure state update triggers re-render
  
          // Optionally, scroll to the new last year if its reference exists
          setTimeout(() => {
            if (yearRefs.current[`year${updatedNumYears}`]) {
              yearRefs.current[`year${updatedNumYears}`].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          }, 100);
  
          console.log('Last year removed from finalizedSchedule, milestones and courses preserved/handled');
        });
      } else {
        console.error('Error: currentUser or effectiveUserId is not defined');
      }
    } catch (error) {
      console.error('Failed to remove last year:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const getYearAndSemesterName = useMemo(() => {
    if (!surveyAnswers?.SchoolStart?.year) return () => '';
  
    const startYear = Number(surveyAnswers.SchoolStart.year);
  
    const getYearName = (yearNumber) => {
      if (yearNumber < 1) return '';
      return startYear + (yearNumber - 1);
    };
  
    return getYearName;
  }, [surveyAnswers?.SchoolStart?.year]);

  // Updating the rendering logic to use the numYears for the active plan
  const activePlanNumYears = tabs[activeTab]?.numYears;

  const hasCoursesInSemester = (yearNumber, termKey) => {
    const friendlyTerm = termMap[termKey] || termKey;
    return (finalizedOrganizedCourses[`year${yearNumber}`]?.[friendlyTerm]?.length || 0) > 0;
  };

  if (loading || numYears === null) {
    return (
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  const handleDropdownToggle = (year, semester) => {
    const key = `${year}-${semester}`;
    setDropdownOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleAlternativesClick = (category, year, semester) => {
    setSelectedElectiveCategory(category);
    setElectiveYear(year);
    
    // Convert the friendly term (e.g. "Fall") to its term key (e.g. "1") using fetchedTerms
    const termKey = Object.keys(termMap).find(
      key => termMap[key] === semester
    );
    
    // If a matching term key is found, use it; otherwise, fallback to the original value.
    setElectiveSemester(termKey || semester);
    
    setShowSidebar(true);
  };

  const handleKeepElectiveCourse = async (course, yr, sem) => {
    try {
      if (currentUser && effectiveUserId) {
        const userDocRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const previousFinalizedSchedule = userDoc.data().schedules[activeTab] || {};
            const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};
  
            // Convert the provided sem to its friendly name.
            // If sem is a dynamic key (like "1", "2", etc.), fetchedTerms[sem] will return the friendly term.
            // Otherwise, if sem is already a friendly name, it will fall back to sem.
            const termFriendly = termMap[sem] || sem;
  
            const updatedFinalizedSchedule = {
              ...updateObj,
              [`year${yr}`]: {
                ...updateObj[`year${yr}`],
                [termFriendly]: {
                  ...(updateObj[`year${yr}`] && updateObj[`year${yr}`][termFriendly]
                    ? updateObj[`year${yr}`][termFriendly]
                    : {}),
                  courses: (updateObj[`year${yr}`] &&
                    updateObj[`year${yr}`][termFriendly] &&
                    updateObj[`year${yr}`][termFriendly].courses
                    ? updateObj[`year${yr}`][termFriendly].courses.concat([
                        course.courseInfo_courseNumber,
                      ])
                    : [course.courseInfo_courseNumber]),
                },
              },
            };
  
            transaction.update(userDocRef, {
              [`schedules.${activeTab}`]: updatedFinalizedSchedule,
            });
          } else {
            console.error('User data not found for the given userId:', effectiveUserId);
            throw new Error('User data not found');
          }
        });
      } else {
        console.error('Error: currentUser or effectiveUserId is not defined');
      }
    } catch (error) {
      console.error('Error keeping elective course:', error);
    }
  };
  

  const handleEvaluate = (course) => {
    setSelectedCourse(course);
    setEvaluationModalOpen(true);
  };

  const handleCloseEvaluationModal = () => {
    setEvaluationModalOpen(false);
    setSelectedCourse(null);
  };

  const handleCloseTransferDataModal = () => {
    setTransferDataModalOpen(false);
  };

  const handleCloseDegreeAuditModal = () => {
    setIsDegreeAuditModalOpen(false);
  };

  const handleOpenAddScheduleModal = () => {
    setShowAddScheduleModal(true);
  };

  const handleCloseAddScheduleModal = () => {
    setShowAddScheduleModal(false);
    setNewScheduleName('');
    setError(''); // Clear the error message when the modal is closed
  };  

  const handleDisplayTransferData = () => {
    setTransferDataModalOpen(true);
  }

  const handleCloseIncompletePrereqsWarningModal = async () => {
    setShowIncompletePrereqsWarningModal(false)
    const userDocRef = doc(db, 'users', effectiveUserId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      await updateDoc(userDocRef, {'hasSeenPrereqsWarning': true});
    }
  }

  const handleAddPlan = async (event) => {
    event.preventDefault(); // Prevent page reload
  
    if (!newScheduleName) {
      setError('Please enter a name for the new plan.');
      return;
    }
  
    if (tabs.some(tab => tab.name === newScheduleName)) {
      setError('A plan with this name already exists.');
      return;
    }
  
    try {
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const newSchedule = {
            numYears: numYears2,
          };
  
          // Get the dynamic term keys (e.g., "1", "2", "3", etc.) and sort them numerically.
          const sortedTermKeys = Object.keys(termMap).sort((a, b) => Number(a) - Number(b));
  
          // Loop over each year for the new plan
          for (let i = 1; i <= numYears2; i++) {
            const yearKey = `year${i}`;
            newSchedule[yearKey] = {};
            // Initialize each term using its friendly name from fetchedTerms.
            sortedTermKeys.forEach(termKey => {
              const friendlyTerm = termMap[termKey];
              newSchedule[yearKey][friendlyTerm] = { courses: [] };
            });
  
            // Copy completed semesters' courses from the current active schedule
            // For each term, if that term is marked as complete, copy its courses.
            sortedTermKeys.forEach(termKey => {
              const friendlyTerm = termMap[termKey];
              if (completedSemesters[yearKey]?.[friendlyTerm]) {
                newSchedule[yearKey][friendlyTerm].courses =
                  (userData.schedules[activeTab] &&
                   userData.schedules[activeTab][yearKey] &&
                   userData.schedules[activeTab][yearKey][friendlyTerm] &&
                   userData.schedules[activeTab][yearKey][friendlyTerm].courses) || [];
              }
            });
          }
  
          // Prepare the new tab data by adding the new schedule to the existing schedules
          const updatedSchedules = {
            ...userData.schedules,
            [newScheduleName]: newSchedule,
          };
  
          // Update Firestore with the new schedules object and set the new active tab
          transaction.update(userRef, {
            schedules: updatedSchedules,
            activeTab: newScheduleName,
          });
  
          // Update local state: add the new plan (tab) and set it active.
          setTabs([...tabs, { name: newScheduleName, ...newSchedule }]);
          setActiveTab(newScheduleName);
          setError(''); // Clear the error message
          handleCloseAddScheduleModal();
        });
      } else {
        console.error('Error: currentUser or effectiveUserId is not defined');
      }
    } catch (error) {
      console.error('Error adding new schedule:', error);
      setError('An error occurred while adding the new plan.');
    }
  };
  
  const handleRemovePlan = async (planNameToRemove) => {
    if (!currentUser || !effectiveUserId) {
        console.error('Error: currentUser or effectiveUserId is not defined');
        return;
    }

    if (tabs.length <= 1) {
        setError('Cannot remove the last plan.');
        return;
    }

    try {
        const userRef = db.collection('users').doc(effectiveUserId);
        const batch = db.batch(); // Create a new batch instance

        const userDoc = await userRef.get();
        if (!userDoc.exists) {
            throw new Error('User data not found');
        }

        const userData = userDoc.data();

        // Remove the plan from schedules
        const updatedSchedules = tabs.filter(tab => tab.name !== planNameToRemove);

        if (updatedSchedules.length === tabs.length) {
            throw new Error('Plan not found');
        }

        const updatedSchedulesObject = updatedSchedules.reduce((acc, tab) => {
            acc[tab.name] = { ...userData.schedules[tab.name] }; // Correctly copy the existing tab data
            return acc;
        }, {});

        // Remove the associated milestones
        const updatedFinalizedMilestones = { ...userData.finalizedMilestones };
        if (updatedFinalizedMilestones && updatedFinalizedMilestones[planNameToRemove]) {
            delete updatedFinalizedMilestones[planNameToRemove];
        }

        let newActiveTab = activeTab;
        if (activeTab === planNameToRemove) {
            const removedTabIndex = tabs.findIndex(tab => tab.name === planNameToRemove);
            if (removedTabIndex > 0 && removedTabIndex === tabs.length - 1) {
                newActiveTab = tabs[removedTabIndex - 1].name;
            } else if (removedTabIndex > 0) {
                newActiveTab = tabs[removedTabIndex + 1].name;
            } else {
                newActiveTab = updatedSchedules[0].name;
            }
        }

        // Check if the removed plan is the primary tab
        if (primaryTab === planNameToRemove) {
            // Set the first available tab as the new primary if the primary one is removed
            const newPrimaryTab = updatedSchedules.length > 0 ? updatedSchedules[0].name : '';
            setPrimaryTab(newPrimaryTab);

            // Update Firestore with the new primary tab
            batch.update(userRef, {
                primaryTab: newPrimaryTab,
            });
        }

        // Prepare the batch update
        const batchUpdateData = {
            schedules: updatedSchedulesObject,
            activeTab: newActiveTab,
            finalizedMilestones: updatedFinalizedMilestones, // Include updated milestones
        };

        batch.update(userRef, batchUpdateData);

        // Commit the batch
        await batch.commit();

        // Update local state to reflect changes
        setTabs(updatedSchedules);
        setActiveTab(newActiveTab);

        // Update finalizedMilestones state
        setFinalizedMilestones(prev => {
            const { [planNameToRemove]: removed, ...rest } = prev;
            return rest;
        });

        setError('');

        console.log(`Plan "${planNameToRemove}" removed and active tab updated successfully`);
    } catch (error) {
        console.error('Error removing plan:', error);
        setError(`An error occurred while removing the plan: ${error.message}`);
    }
};
  
  const handleTabClick = async (tabName) => {
    try {
      setActiveTab(tabName);
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await userRef.update({
          activeTab: tabName
        });
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          const courseIds = userData.schedules[tabName] || [];
          setFinalizedCourseIds(courseIds);
  
          const cumulativeIds = calculateCumulativeIds(courseIds);
          setCumulativeCourseIds(cumulativeIds);
  
          const organizedCourses = organizeCoursesByYearAndSemester(courseIds, allCourses, prereqs, cumulativeIds);
          setFinalizedOrganizedCourses(organizedCourses);
        }
      }
    } catch (error) {
      console.error('Error setting active tab:', error);
    }
  };  

  const handleSwitchPrimaryTab = async (newPrimaryTab) => {
    try {
      setPrimaryTab(newPrimaryTab);
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await userRef.update({
          primaryTab: newPrimaryTab
        });
      }
    } catch (error) {
      console.error('Error setting primary tab:', error);
    }
  };
  
  const handleTabDoubleClick = (tab) => {
    setEditingTab(tab);
    setNewTabName(tab);
  };
  
  const handleRenameTab = async (oldTabName, newTabName) => {
    if (!newTabName || newTabName === oldTabName) {
      setEditingTab(null);
      return;
    }
  
    if (tabs.some(tab => tab.name === newTabName)) {
      alert('A plan with this name already exists.');
      return;
    }
  
    try {
      if (currentUser && effectiveUserId) {
        const userRef = db.collection('users').doc(effectiveUserId);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
  
          // Rename the schedule in Firestore
          const newSchedules = { ...userData.schedules };
          newSchedules[newTabName] = newSchedules[oldTabName];
          delete newSchedules[oldTabName];
  
          // Rename in finalizedMilestones if applicable
          const newFinalizedMilestones = { ...userData.finalizedMilestones };
          if (newFinalizedMilestones[oldTabName]) {
            newFinalizedMilestones[newTabName] = newFinalizedMilestones[oldTabName];
            delete newFinalizedMilestones[oldTabName];
          }
  
          // Prepare the data to update
          const updateData = {
            schedules: newSchedules,
            finalizedMilestones: newFinalizedMilestones,
          };
  
          // Update activeTab if necessary
          if (userData.activeTab === oldTabName) {
            updateData.activeTab = newTabName;
          }
  
          // Update primaryTab if necessary
          if (userData.primaryTab === oldTabName) {
            updateData.primaryTab = newTabName;
          }
  
          transaction.update(userRef, updateData);
  
          // Update local state after transaction
          const newTabs = tabs.map(tab =>
            tab.name === oldTabName ? { ...tab, name: newTabName } : tab
          );
          setTabs(newTabs);
  
          if (activeTab === oldTabName) {
            setActiveTab(newTabName);
          }
          if (primaryTab === oldTabName) {
            setPrimaryTab(newTabName);
          }
  
          setEditingTab(null); // Moved here
        });
      } else {
        console.error('Error: currentUser or effectiveUserId is not defined');
      }
    } catch (error) {
      console.error('Error renaming schedule:', error);
      alert(`An error occurred while renaming the plan: ${error.message}`);
    }
  };
  
  const handleRenameSubmit = (event) => {
    if (event.key === 'Enter' || event.type === 'blur') {
      if (newTabName.length <= 65) { // Ensure the name is within the allowed limit
        handleRenameTab(editingTab, newTabName);
      } else {
        alert('Tab name is too long. Please use 65 characters or fewer.');
      }
    }
  };

  const sortedTermKeys = Object.keys(termMap).sort((a, b) => Number(a) - Number(b));
  const dynamicTerms = sortedTermKeys.map(termKey => termMap[termKey]);
  console.log('DynamicTerms: ', dynamicTerms, completedSemesters, numYears);
  console.log('FinOrg: ', finalizedOrganizedCourses);

  return (
    <DndProvider backend={HTML5Backend}>
      {/* <PlanJoyrideTour /> */}
      <div className={`w-11/12 max-w-8xl ${viewMode === 'horizontal' ? 'mb-20 mt-20' : 'mt-20 mb-0'}`}>
        <div className="flex border-b border-gray-200 dark:border-gray-700 mb-2 items-center">
          {effectiveUserId !== currentUser.uid && (
            <PlanPageHeader studentName={fullName} studentId={effectiveUserId} />
          )}
          {/* Tabs and Add Plan Button */}
          <div className="flex items-center flex-1 overflow-x-auto custom-scrollbar space-x-2">
            {/* Tabs Section */}
            <ul className="flex flex-nowrap">
              {tabs.map((tab, index) => (
                <li key={index} className="relative mr-2 flex items-center flex-shrink-0" role="presentation">
                  {editingTab === tab.name ? (
                    <input
                      type="text"
                      value={newTabName}
                      onChange={(e) => setNewTabName(e.target.value)}
                      onBlur={handleRenameSubmit}
                      onKeyDown={(e) => { if (e.key === 'Enter') handleRenameSubmit(e); }}
                      className="inline-block py-2 px-3 border-b-2 rounded-t-lg focus:outline-none"
                      style={{ width: `${Math.max(newTabName.length, 1) * 7 + 35}px` }}
                      maxLength={65}
                      autoFocus
                    />
                  ) : (
                    <button
                      className={`inline-block py-2 px-3 border-b-2 rounded-t-lg flex justify-between items-center ${
                        activeTab === tab.name
                          ? 'border-blue-500 text-blue-500'
                          : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                      }`}
                      onClick={() => handleTabClick(tab.name)}
                      onDoubleClick={() => handleTabDoubleClick(tab.name)}
                      type="button"
                      role="tab"
                      aria-controls={`${tab.name}-content`}
                      aria-selected={activeTab === tab.name ? 'true' : 'false'}
                    >
                      {/* Bookmark Icon */}
                      <button
                        className="mr-2 focus:outline-none"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSwitchPrimaryTab(tab.name);
                        }}
                      >
                        {tab.name === primaryTab ? (
                          <FaBookmark className="text-red-700 text-sm" />
                        ) : (
                          <FaRegBookmark className="text-red-700 text-sm hover:text-black hover:text-md" />
                        )}
                      </button>
                      <span className="truncate">{tab.name}</span>
                      {tabs.length > 1 && (
                        <button
                          className="ml-2 text-black hover:text-red-700"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemovePlan(tab.name);
                          }}
                        >
                          &times;
                        </button>
                      )}
                    </button>
                  )}
                </li>
              ))}
            </ul>
            {/* Add Plan Button */}
            <button
              onClick={handleOpenAddScheduleModal}
              className="text-black hover:text-gray-700 text-lg p-0 flex items-center mr-4"
            >
              <GrFormAdd className="text-s" />
            </button>
          </div>
          {/* Action Buttons Section */}
          <div className="flex items-center space-x-2 ml-auto">
            <button
              onClick={() => setShowIncompletePrereqsWarningModal(true)}
              className="text-red-600 hover:text-red-800 text-lg font-semibold ml-auto p-0 flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                  d="M10.29 3.86l-8.46 14.7c-.7 1.22.17 2.44 1.53 2.44h16.88c1.36 0 2.23-1.22 1.53-2.44l-8.46-14.7a1.6 1.6 0 00-2.76 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                  d="M12 9v4m0 4h.01" />
              </svg>
            </button>
            {/* View Toggle Button */}
            <button
              onClick={() => setViewMode(viewMode === 'horizontal' ? 'vertical' : 'horizontal')}
              className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700"
            >
              {viewMode === 'horizontal' ? (
                <CiGrid2V className="w-5 h-5" />
              ) : (
                <CiGrid41 className="w-5 h-5" />
              )}
            </button>
            {/* Export and View All Milestones Buttons */}
            <div className="inline-flex rounded-md shadow-sm" role="group">
              <button
                type="button"
                onClick={handleOpenAllMilestonesDrawer}
                className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
              >
                <LuGoal className="w-3 h-3 me-2" />
                {t('planPage.viewAllMilestones', 'View All Milestones')}
              </button>
              <button
                type="button"
                onClick={handleExportPlan}
                className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-r-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
              >
                <svg
                  className="w-3 h-3 me-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                  <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                </svg>
                {t('planPage.exportPlan', 'Export Plan')}
              </button>
            </div>
          </div>
        </div>
  
        {effectiveUserId === currentUser.uid && <NavBar />}
        {effectiveUserId !== currentUser.uid && <CustomSidebar />}
  
        <div className="w-full">
          {showSidebar && (
            <ElectiveSidebar
              onClose={() => setShowSidebar(false)}
              elective={selectedElectiveCategory}
              electiveYear={electiveYear}
              electiveSemester={electiveSemester}
              courses={fetchedCourseData}
              onKeepElectiveCourse={handleKeepElectiveCourse}
            />
          )}
          {showIncompletePrereqsWarningModal && (
            <IncompletePrereqsWarningModal onClose={handleCloseIncompletePrereqsWarningModal} />
          )}
  
          {viewMode === 'horizontal' ? (
            // Horizontal View: Render each year with a dynamic number of term columns.
            <div className="grid grid-cols-1 gap-y-4 w-full">
              {/* Header Section: Total Credits and Control Buttons */}
              <div className="flex justify-between items-center mb-0">
                <div className="flex items-center">
                  <CreditsOverview finalizedOrganizedCourses={finalizedOrganizedCourses} onOpen={setIsDegreeAuditModalOpen} />
                </div>
                <div className="ml-auto flex space-x-2 items-center">
                  <button
                    onClick={handleDisplayTransferData}
                    disabled={isAutofilling}
                    className={`${isAutofilling ? 'disabled' : ''} text-green-700 hover:text-white border border-red-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                  >
                    {t('planPage.transferCredits', 'Transfer Credits')}
                  </button>
                  {hasUncompletedCourses && (
                    <button
                      onClick={handleRemoveAllCourses}
                      disabled={isAutofilling}
                      className={`${isAutofilling ? 'disabled' : ''} text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                    >
                      {t('planPage.removeAllCourses', 'Remove All Courses')}
                    </button>
                  )}
                  <button
                    onClick={handleAutofillAllCourses}
                    className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5"
                  >
                    {t('planPage.autofillPlan', 'Autofill Plan')}
                  </button>
                  {Object.values(completedSemesters).some((year) =>
                    Object.values(year).every((semester) => semester)
                  ) && (
                    <button
                      type="button"
                      className="flex items-center py-1.5 px-3 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      onClick={() => setHideCompletedYears(!hideCompletedYears)}
                    >
                      {hideCompletedYears
                        ? t('planPage.showCompletedYears', 'Show Completed Years')
                        : t('planPage.hideCompletedYears', 'Hide Completed Years')}
                    </button>
                  )}
                </div>
              </div>
              {/* Render each year */}
              {Array.from({ length: numYears }, (_, i) => i + 1).map((yearNumber) => {
                // If all terms in the year are completed and we're hiding completed years, skip this year.
                const isYearCompleted = dynamicTerms.every(term => completedSemesters[`year${yearNumber}`]?.[term]);
                if (hideCompletedYears && isYearCompleted) return null;
                return (
                  <div key={yearNumber} ref={(el) => (yearRefs.current[`year${yearNumber}`] = el)} className="w-full">
                    {/* Use a grid with dynamic columns */}
                    <div
                      className="grid gap-3 w-full"
                      style={{ gridTemplateColumns: `repeat(${dynamicTerms.length}, minmax(200px, 1fr))` }}
                    >
                      {dynamicTerms.map((term) => (
                        <div key={term} className="flex-1 min-w-[200px]">
                          <UpdatedDroppableSemester
                            yearNumber={yearNumber}
                            semester={term}
                            courses={finalizedOrganizedCourses[`year${yearNumber}`]?.[term] || []}
                            isCompleted={completedSemesters[`year${yearNumber}`]?.[term]}
                            onRemove={(classToRemove) => handleRemove(classToRemove, yearNumber, term)}
                            moveCourse={moveCourse}
                            onAlternativesClick={handleAlternativesClick}
                            onEvaluate={handleEvaluate}
                            setShowSidebar={handlePlaceholderSelection}
                            viewMode={viewMode}
                            prereqClasses={prereqClasses}
                            getTotalCreditsForSemester={getTotalCreditsForSemester}
                            handleDropdownToggle={handleDropdownToggle}
                            dropdownOpen={dropdownOpen}
                            completedSemesters={completedSemesters}
                            getYearAndSemesterName={getYearAndSemesterName}
                            hasCoursesInSemester={hasCoursesInSemester}
                            shouldShowMarkCompleteButton={shouldShowMarkCompleteButton}
                            handleToggleSemesterComplete={handleToggleSemesterComplete}
                            handleAddMilestone={handleAddMilestone}
                            handleRemoveSemesterCourses={handleRemoveSemesterCourses}
                            handleAutofillCourse={handleAutofillCourse}
                            handleRemove={handleRemove}
                            buttonRefs={buttonRefs}
                            activeTab={activeTab}
                            dropdownRefs={dropdownRefs}
                            isDragging={isDragging}
                            isAutofilling={isAutofilling}
                            setIsAutofilling={setIsAutofilling}
                            yearRefs={yearRefs}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
              {/* Add and Remove Year Buttons */}
              <div className="flex justify-center mt-4 mb-10 space-x-4">
                {/* Chat Icon with Notification Badge */}
                <div className="relative">
                  <button
                    onClick={() => setShowCommentsChat(true)}
                    className="text-gray-700 bg-gray-200 bg-opacity-60 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full w-10 h-10 flex items-center justify-center"
                    aria-label={t('planPage.openComments','Open Comments')}
                  >
                    <FaComments />
                  </button>
                  {unreadCounts[activeTab] && unreadCounts[activeTab] > 0 && (
                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2">
                      {unreadCounts[activeTab] > 99 ? '99+' : unreadCounts[activeTab]}
                    </div>
                  )}
                </div>
                {numYears > 1 && shouldShowRemoveYearButton(numYears) && (
                  <button
                    onClick={handleRemoveYear}
                    className="text-red-700 bg-red-200 bg-opacity-60 hover:bg-red-300 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-full w-10 h-10 flex items-center justify-center"
                    aria-label={t('planPage.removeYear','Remove Year')}
                  >
                    <FaMinus />
                  </button>
                )}
                <button
                  onClick={handleAddYear}
                  className="text-blue-700 bg-blue-200 bg-opacity-60 hover:bg-blue-300 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full w-10 h-10 flex items-center justify-center"
                  aria-label={t('planPage.addYear','Add Year')}
                >
                  <FaPlus />
                </button>
              </div>
            </div>
          ) : (
            // Vertical View: Render a horizontally scrollable row of term columns for each year.
            <div className="w-full flex flex-col p-0 m-0">
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <CreditsOverview finalizedOrganizedCourses={finalizedOrganizedCourses} onOpen={setIsDegreeAuditModalOpen} />
                </div>
                <div className="ml-auto flex space-x-2 items-center">
                  <button
                    onClick={handleDisplayTransferData}
                    disabled={isAutofilling}
                    className={`${isAutofilling ? 'disabled' : ''} text-green-700 hover:text-white border border-red-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                  >
                    {t('planPage.transferCredits', 'Transfer Credits')}
                  </button>
                  {hasUncompletedCourses && (
                    <button
                      onClick={handleRemoveAllCourses}
                      disabled={isAutofilling}
                      className={`${isAutofilling ? 'disabled' : ''} text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                    >
                      {t('planPage.removeAllCourses', 'Remove All Courses')}
                    </button>
                  )}
                  <button
                    onClick={handleAutofillAllCourses}
                    className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5"
                  >
                    {t('planPage.autofillPlan', 'Autofill Plan')}
                  </button>
                  {Object.values(completedSemesters).some((year) =>
                    Object.values(year).some((semester) => semester)
                  ) && (
                    <button
                      type="button"
                      className="flex items-center py-1.5 px-3 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      onClick={() => setHideCompleted(!hideCompleted)}
                    >
                      {hideCompleted
                        ? t('planPage.showCompletedTerms', 'Show Completed Terms')
                        : t('planPage.hideCompletedTerms', 'Hide Completed Terms')}
                    </button>
                  )}
                </div>
              </div>
              <div
                className="flex w-full h-full overflow-x-auto custom-scrollbar"
                style={{
                  padding: '0',
                  margin: '0',
                  alignItems: 'flex-start',
                  gap: '0.5rem',
                  overflowX: 'auto',
                }}
              >
                {Array.from({ length: numYears }, (_, i) => i + 1).map((yearNumber) => (
                  <div key={yearNumber} className="flex flex-col">
                    <div
                      className="flex"
                      style={{ gridTemplateColumns: `repeat(${dynamicTerms.length}, minmax(200px, 1fr))` }}
                    >
                      {dynamicTerms.map(term => (
                        <div key={`${term}-${yearNumber}`} className="flex flex-col min-w-[200px]">
                          <UpdatedDroppableSemester
                            yearNumber={yearNumber}
                            semester={term}
                            courses={finalizedOrganizedCourses[`year${yearNumber}`]?.[term] || []}
                            isCompleted={completedSemesters[`year${yearNumber}`]?.[term]}
                            onRemove={(classToRemove) => handleRemove(classToRemove, yearNumber, term)}
                            moveCourse={moveCourse}
                            onAlternativesClick={handleAlternativesClick}
                            onEvaluate={handleEvaluate}
                            viewMode={viewMode}
                            setShowSidebar={handlePlaceholderSelection}
                            prereqClasses={prereqClasses}
                            getTotalCreditsForSemester={getTotalCreditsForSemester}
                            handleDropdownToggle={handleDropdownToggle}
                            dropdownOpen={dropdownOpen}
                            completedSemesters={completedSemesters}
                            getYearAndSemesterName={getYearAndSemesterName}
                            hasCoursesInSemester={hasCoursesInSemester}
                            shouldShowMarkCompleteButton={shouldShowMarkCompleteButton}
                            handleToggleSemesterComplete={handleToggleSemesterComplete}
                            handleAddMilestone={handleAddMilestone}
                            handleRemoveSemesterCourses={handleRemoveSemesterCourses}
                            handleAutofillCourse={handleAutofillCourse}
                            handleRemove={handleRemove}
                            buttonRefs={buttonRefs}
                            activeTab={activeTab}
                            dropdownRefs={dropdownRefs}
                            isDragging={isDragging}
                            isAutofilling={isAutofilling}
                            setIsAutofilling={setIsAutofilling}
                            yearRefs={yearRefs}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-between mt-1 pr-4 space-x-4 w-full">
                <div className="relative">
                  <button
                    onClick={() => setShowCommentsChat(true)}
                    className="text-gray-700 bg-gray-200 bg-opacity-60 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full w-10 h-10 flex items-center justify-center"
                    aria-label={t('planPage.openComments','Open Comments')}
                  >
                    <FaComments />
                  </button>
                  {unreadCounts[activeTab] && unreadCounts[activeTab] > 0 && (
                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2">
                      {unreadCounts[activeTab] > 99 ? '99+' : unreadCounts[activeTab]}
                    </div>
                  )}
                </div>
                <div className="flex ml-auto space-x-2">
                  {numYears > 1 && shouldShowRemoveYearButton(numYears) && (
                    <button
                      onClick={() => handleRemoveYear(numYears)}
                      className="text-red-700 bg-red-200 bg-opacity-60 hover:bg-red-300 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-full w-10 h-10 flex items-center justify-center"
                      aria-label={t('planPage.removeYear','Remove Year')}
                    >
                      <FaMinus />
                    </button>
                  )}
                  <button
                    onClick={handleAddYear}
                    className="text-blue-700 bg-blue-200 bg-opacity-60 hover:bg-blue-300 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full w-10 h-10 flex items-center justify-center"
                    aria-label={t('planPage.addYear','Add Year')}
                  >
                    <FaPlus />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {showPdfModal && (
          <PDFPreview
            pdfBytes={pdfBytes}
            onClose={closePdfModal}
            firstName={currentUser?.firstName || 'Unknown'}
            lastName={currentUser?.lastName || 'Unknown'}
            numYears={currentUser?.schedules?.[activeTab]?.numYears || 4}
          />
        )}
        {showDrawer && currentSemester.year && currentSemester.semester && (
          <MilestoneDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            milestones={finalizedMilestones.filter(
              (milestone) =>
                milestone.Milestone_Year === currentSemester.year &&
                milestone.Milestone_Sem === (currentSemester.semester === 'Fall' ? 'S1' : 'S2')
            )}
            handleRemoveMilestone={handleRemoveMilestone}
            handleToggleComplete={handleToggleComplete}
            handleAddMilestone={handleAddMilestone}
            handleEditMilestone={handleEditMilestone}
            showAllMilestones={false}
            setShowModal={setShowModal}
            currentSemester={currentSemester}
          />
        )}
        {showAllMilestonesDrawer && (
          <MilestoneDrawer
            showDrawer={showAllMilestonesDrawer}
            setShowDrawer={setShowAllMilestonesDrawer}
            milestones={finalizedMilestones}
            handleRemoveMilestone={handleRemoveMilestone}
            handleToggleComplete={handleToggleComplete}
            handleAddMilestone={() => setShowModal(true)}
            handleEditMilestone={handleEditMilestone}
            showAllMilestones={true}
            setShowModal={setShowModal}
          />
        )}
        {showPlaceholderSidebar && (
          <ElectiveSidebar
            onClose={() => setShowPlaceholderSidebar(false)}
            showSidebar={showPlaceholderSidebar}
            currPath={"Finance"}
            elective={selectedPlaceholderCategory}
            electiveYear={selectedPlaceholderYear}
            electiveSemester={selectedPlaceholderSemester}
            courses={fetchedCourseData}
            onKeepElectiveCourse={handleKeepElectiveCourse}
            majorRecData={transformedMajorRecData}
            requirement={selectedPlaceholderRequirement}
          />
        )}
        <MilestoneModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleSaveMilestone={handleSaveMilestone}
          initialData={editingMilestone}
        />
        <EvaluationModal
          isOpen={isEvaluationModalOpen}
          onClose={handleCloseEvaluationModal}
          classData={selectedCourse}
        />
        <TransferDataModal
          showModal={isTransferDataModalOpen}
          onClose={handleCloseTransferDataModal}
          transferClassesData={transferCreditsData}
        />
        <DegreeAuditModal
          showModal={isDegreeAuditModalOpen}
          onClose={handleCloseDegreeAuditModal}
          plannedCourses={finalizedOrganizedCourses}
          pathwayCourses={allUserMajorPathways}
          fetchedCourseData={fetchedCourseData}
        />
        {showCommentsChat && (
          <StudentChat
            currentUser={currentUser}
            activeTab={activeTab}
            onClose={() => setShowCommentsChat(false)}
            onChatOpen={handleChatOpen}
          />
        )}
        {showAddScheduleModal && (
          <div
            id="authentication-modal"
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300 ease-in-out"
          >
            <div className="relative p-4 w-full max-w-md max-h-2xl" ref={modalRef}>
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    {t('planPage.addNewPlan', 'Add New Plan')}
                  </h3>
                  <button
                    type="button"
                    className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={handleCloseAddScheduleModal}
                    data-modal-hide="authentication-modal"
                    aria-label={t('planPage.closeModal','Close modal')}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">{t('planPage.closeModal','Close modal')}</span>
                  </button>
                </div>
                <div className="p-4 md:p-5">
                  <form className="space-y-4">
                    <div>
                      <label
                        htmlFor="schedule-name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t('planPage.planName','Plan Name')}
                      </label>
                      <input
                        type="text"
                        id="schedule-name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        placeholder={t('planPage.enterScheduleName','Enter schedule name')}
                        value={newScheduleName}
                        onChange={(e) => setNewScheduleName(e.target.value)}
                        maxLength={65}
                        required
                      />
                    </div>
                    {error && (
                      <div className="flex justify-center mb-4">
                        <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
                          {error}
                        </span>
                      </div>
                    )}
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        onClick={handleAddPlan}
                      >
                        {t('planPage.addNewPlan','Add New Plan')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default UpdatedTempPlanPage;