import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Home from '../HS/Home';
import LandingPage from '../HS/LandingPage';

// auth
import Signup from '../authentication/signup';
import Login from '../authentication/Login';
import RoleSelection from '../authentication/RoleSelection';
import ClassSearch from '../ClassSearch/ClassSearch.js';
import Credits from '../HS/Credits';

// HS
//import ClassSearch from '../HS/HSClassSearch/ClassSearch.js';
import Forms from '../HS/HSCounselorForms/Forms';

// volunteer
import VolunteerValidationPage from '../HS/HSVolunteer/VolunteerValidationPage';
import MyCommitmentsPage from '../HS/HSVolunteer/MyCommitmentsPage';
import VolunteerStatsPage from '../HS/HSVolunteer/VolunteerStatsPage';
import OpportunitiesPage from '../HS/HSVolunteer/OpportunitiesPage';
// extracurricular
import ExtracurricularPage from '../HS/HSExtracurricular/ExtracurricularPage';

// admin
import StaffAdditionPage from '../Admin/AddOpportunity/StaffAdditionPage.js';
import Dashboard from '../Admin/Dashboard/Dashboard.js';
import Analytics from '../Admin/Analytics/Analytics.js';
import RunCalculateAnalytics from '../Admin/Analytics/RunCalculateAnalytics';
import StudentsPage from '../Admin/Students/StudentsPage.js';
import Pathways from '../Admin/Pathways/Pathways.js';
import Messages from '../Admin/Messages/Messages.js';
import EditCourseData from '../Admin/Courses/EditCourseData.js';
import ChatComponent from '../Messaging/ChatComponent.js';
// import AdvisorReviewPage from '../Admin/Students/Advisor Review/AdvisorReviewPage.js';
import Staff from '../Admin/Staff/Staff.js';
import ResourcePage from '../Admin/Resources/ResourcesPage.js';
import Reports from '../Admin/Reports/Reports.js';
import MeetingsPage from '../Admin/Meetings/MeetingsPage.js';
import AdvisorReviewStudentProfile from '../Admin/Students/Advisor Review/AdvisorReviewStudentProfile.js';
import AdvisorReviewPlanPage from '../Admin/Students/Advisor Review/AdvisorReviewPlanPage.js';
import AdvisorProfile from '../Admin/Profile/AdvisorProfile.js';
import EnrollmentDashboard from '../Admin/Enrollment/EnrollmentDashboard.js';
import CommunicationsPage from '../Admin/Communication/CommunicationsPage.js';

import YearDetailsPage from '../HS/YearDetailsPage';
import CourseRecommendationPage from '../HS/CourseRecommendations(HS)';
import PlanPage from '../Plan/PlanPage.js';
import SemesterDetails from '../Plan/Archive/SemesterDetails.js';
import SettingsPage from '../HS/Settings';
import SurveyPage from '../Survey/SurveyPage.js';
import StudentProfile from '../Profile/StudentProfile';
import SchedulePage from '../Schedule/SchedulePage.js';

import MajorPage from '../Degree Plans/MajorPage';
import CourseDetails from '../Admin/Courses/CourseDetails.js';
import AddCourse from '../Admin/Courses/AddCourse.js';
import UpdatedTempPlanPage from '../Plan/UpdatedTerms/UpdatedTempPlanPage.js';

import StudentPersistence from '../Admin/StudentPersistence/StudentPersistence.js'
//Engagement Resources
// import EngagementOpportunities from '../Admin/Resources/Archive/EngagementOpportunities.js';
import AssignRole from '../Admin/Staff/AssignRole';

const ProtectedRoutes = ({ offeredDegrees }) => {
  const { currentUser, authLoading, roleLoading, userRole, setUserRole } = useAuth();
  const [isRoleLoading, setIsRoleLoading] = useState(true);
  // const isDemo = process.env.REACT_APP_TYPE === 'DEMO';
  const isDemo = false;

  const otherUser = {
    id: '654321',
    name: 'Bob',
    email: 'bob@example.com',
    photoUrl: 'https://talkjs.com/images/avatar-5.jpg',
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      if (!currentUser || !currentUser._delegate) {
         console.log('No currentUser found or it does not have _delegate. Role loading stopped.');
         setIsRoleLoading(false);
         return;
      }
   
      try {
         console.log('Fetching user role...');
         const tokenResult = await currentUser._delegate.getIdTokenResult(true);
         const role = tokenResult.claims.role || '';
         setUserRole(role);
         console.log('User Role:', role);
      } catch (error) {
         console.error('Error fetching role:', error);
      } finally {
         setIsRoleLoading(false);
      }
   };   
  
    if (currentUser) {
      fetchUserRole();
    } else {
      setIsRoleLoading(false);
    }
  }, [currentUser, setUserRole]);  

  // Debug log to track loading states
  // console.log('Loading states - Auth loading:', authLoading, 'Role loading:', isRoleLoading);

  if (authLoading || isRoleLoading || roleLoading) {
    return (
      <div role="status" className="flex justify-center items-center min-h-screen">
        {/* Loading spinner */}
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  const isAuthenticated = currentUser !== null && userRole !== '';

  // Log user authentication status
  // console.log('User authenticated:', isAuthenticated, 'Current User:', currentUser, 'User Role:', userRole);

  return (
    <Routes>
      {/* Unauthenticated routes for demo mode */}
      {isDemo && !isAuthenticated ? (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      ) : (
        <>
          {/* Non-demo routes */}
          <Route path="/" element={<RoleSelection />} />
          <Route path="/signup/student" element={<Signup />} />
          <Route path="/signup/staff" element={<Signup />} />
          <Route path="/login" element={<Login />} />
        </>
      )}

      {/* Protect routes that require authentication */}
      {isAuthenticated ? (
        <>
          {/* Student Routes */}
          {userRole === 'student' && (
            <>
              <Route path="/Home" element={<Home />} />
              <Route path="/LandingPage" element={<LandingPage />} />
              <Route path="/ClassSearch" element={<ClassSearch />} />
              <Route path='/UTPlan' element={<UpdatedTempPlanPage />} />
              <Route path="/Search" element={<ClassSearch />} />
              <Route path="/Forms" element={<Forms />} />
              <Route path="/Credits" element={<Credits />} />
              <Route path="/VolunteerValidationPage" element={<VolunteerValidationPage />} />
              <Route path="/my-commitments" element={<MyCommitmentsPage />} />
              <Route path="/VolunteeringStats" element={<VolunteerStatsPage />} />
              <Route path="/find-opportunities" element={<OpportunitiesPage />} />
              <Route path="/Extracurricular" element={<ExtracurricularPage />} />
              <Route path="/Profile" element={<StudentProfile />} />
              <Route path="/CourseRecommendations" element={<CourseRecommendationPage />} />
              <Route path="/Plan" element={<PlanPage />} />
              {/* <Route path="/SemesterDetails/:yearNumber/:semester" element={<SemesterDetails />} /> */}
              <Route path="/Survey" element={<SurveyPage />} />
              <Route path="/Settings" element={<SettingsPage />} />
              <Route path="/Pathways" element={<MajorPage offeredDegrees={offeredDegrees} />} loader={async ({ params }) => { const { majorName } = params; return { majorName }; }} />
              <Route path="/Credits/:yearNumber" element={<YearDetailsPage />} />
              <Route path="/Schedule" element={<SchedulePage />} />
              {/* //temp role */}
              <Route path="/roles" element={<AssignRole />} />
              {/* Redirect if student tries to access admin paths */}
              <Route path="*" element={<Navigate to="/Profile" />} />
            </>
          )}

          {/* Advisor Routes */}
          {userRole === 'advisor' && (
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/runCalculateAnalytics" element={<RunCalculateAnalytics />} />
              <Route path="/students" element={<StudentsPage />} />
              <Route path="/add-pathways" element={<Pathways />} />
              <Route path="/messages" element={<Messages />} />
              {/* <Route path="/students/advisor-review/:studentId" element={<AdvisorReviewPage />} /> */}
              <Route path="/Courses" element={<EditCourseData />} />
              <Route path='/UTPlan' element={<UpdatedTempPlanPage />} />
              <Route path="/Courses/:courseId" element={<CourseDetails />} />
              <Route path="/add-course" element={<AddCourse />} />
              <Route path="/chat" element={<ChatComponent currentUser={currentUser} otherUser={otherUser} />} />
              <Route path="/addOpportunity" element={<StaffAdditionPage />} />
              <Route path="/Pathways" element={<MajorPage offeredDegrees={offeredDegrees} />} loader={async ({ params }) => { const { majorName } = params; return { majorName }; }} />
              <Route path="/staff" element={<Staff />} />
              <Route path="/resources" element={<ResourcePage />} />
              <Route path="/resources/:studentId" element={<ResourcePage />} />
              <Route path="/meetings" element={<MeetingsPage />} />
              <Route path="/roles" element={<AssignRole />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/advisorprofile" element={<AdvisorProfile />} />
              <Route path="/profile/:studentId" element={<AdvisorReviewStudentProfile />} />
              <Route path="/plan/:studentId" element={<PlanPage/>} />
              <Route path="/Schedule" element={<SchedulePage />} />
              <Route path="/enrollment-dashboard" element={<EnrollmentDashboard/>} />
              <Route path="/communication/:studentId" element={<CommunicationsPage/>} />
              <Route path="/communications" element={<CommunicationsPage />} />
              {/* <Route path="/enop" element={<EngagementOpportunities />} /> */}
              <Route path="/StudentPersistence" element={<StudentPersistence />} />
              {/* Redirect if advisor tries to access non-permitted paths */}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </>
          )}

          {/* Admin and Super Admin Routes */}
          {(userRole === 'admin' || userRole === 'super_admin') && (
            <>
              <Route path="/Home" element={<Home />} />
              <Route path="/LandingPage" element={<LandingPage />} />
              <Route path="/ClassSearch" element={<ClassSearch />} />
              <Route path="/Search" element={<ClassSearch />} />
              <Route path="/Forms" element={<Forms />} />
              <Route path="/Credits" element={<Credits />} />
              <Route path='/UTPlan' element={<UpdatedTempPlanPage />} />
              <Route path="/VolunteerValidationPage" element={<VolunteerValidationPage />} />
              <Route path="/my-commitments" element={<MyCommitmentsPage />} />
              <Route path="/VolunteeringStats" element={<VolunteerStatsPage />} />
              <Route path="/find-opportunities" element={<OpportunitiesPage />} />
              <Route path="/Extracurricular" element={<ExtracurricularPage />} />
              <Route path="/Profile" element={<StudentProfile />} />
              <Route path="/CourseRecommendations" element={<CourseRecommendationPage />} />
              <Route path="/Plan" element={<PlanPage />} />
              <Route path="/Schedule" element={<SchedulePage />} />
              {/* <Route path="/SemesterDetails/:yearNumber/:semester" element={<SemesterDetails />} /> */}
              <Route path="/Survey" element={<SurveyPage />} />
              <Route path="/Settings" element={<SettingsPage />} />
              <Route path="/Pathways" element={<MajorPage offeredDegrees={offeredDegrees} />} loader={async ({ params }) => { const { majorName } = params; return { majorName }; }} />
              <Route path="/Credits/:yearNumber" element={<YearDetailsPage />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/runCalculateAnalytics" element={<RunCalculateAnalytics />} />
              <Route path="/students" element={<StudentsPage />} />
              <Route path="/add-pathways" element={<Pathways />} />
              <Route path="/messages" element={<Messages />} />
              {/* <Route path="/students/advisor-review/:studentId" element={<AdvisorReviewPage />} /> */}
              <Route path="/Courses" element={<EditCourseData />} />
              <Route path="/Courses/:courseId" element={<CourseDetails />} />
              <Route path="/staff" element={<Staff />} />
              <Route path="/add-course" element={<AddCourse />} />
              <Route path="/chat" element={<ChatComponent currentUser={currentUser} otherUser={otherUser} />} />
              <Route path="/addOpportunity" element={<StaffAdditionPage />} />
              <Route path="/roles" element={<AssignRole />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/resources" element={<ResourcePage />} />
              <Route path="/resources/:studentId" element={<ResourcePage />} />
              <Route path="/meetings" element={<MeetingsPage />} />
              <Route path="/advisorprofile" element={<AdvisorProfile />} />
              <Route path="/Profile/:studentId" element={<AdvisorReviewStudentProfile />} />
              <Route path="/Plan/:studentId" element={<PlanPage/>} />
              <Route path="/enrollment-dashboard" element={<EnrollmentDashboard/>} />
              <Route path="/communication/:studentId" element={<CommunicationsPage/>} />
              <Route path="/communications" element={<CommunicationsPage />} />
              {/* <Route path="/enop" element={<EngagementOpportunities />} /> */}
              <Route path="/StudentPersistence" element={<StudentPersistence />} />
              {/* Redirect if admin tries to access non-permitted paths */}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </>
          )}
        </>
      ) : (
        <>
          {/* Unauthenticated Default Fallback */}
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      )}
    </Routes>
  );
};

export default ProtectedRoutes;