import React, { useRef, useEffect, useState } from 'react';

// Example complete mapping with placeholders for BOTH the course number and the course name
// Adjust the 'courseName' strings as needed for real equivalencies
const transferMappings = {
  "University of British Columbia": {
    "ADMN170": {
      "courseNumber": "COMM 101",
      "courseName": "Business Fundamentals"
    },
    "COMM240": {
      "courseNumber": "COMM 293",
      "courseName": "Introduction to Financial Accounting"
    },
    "ECON106": {
      "courseNumber": "ECON 102",
      "courseName": "Principles of Macroeconomics"
    },
    "ADMN181": {
      "courseNumber": "COMM 296",
      "courseName": "Introduction to Marketing"
    },
    "COMM241": {
      "courseNumber": "COMM 294",
      "courseName": "Managerial Accounting"
    },
    "ECON107": {
      "courseNumber": "ECON 101",
      "courseName": "Principles of Microeconomics"
    },
    "ADMN250": {
      "courseNumber": "COMM 354",
      "courseName": "Cost Accounting"
    },
    "ADMN251": {
      "courseNumber": "COMM 450",
      "courseName": "Intermediate Financial Accounting I"
    },
    "ADMN252": {
      "courseNumber": "COMM 370",
      "courseName": "Introduction to Finance"
    },
    "ADMN253": {
      "courseNumber": "COMM 355",
      "courseName": "Income Taxation"
    },
    "ADMN269": {
      "courseNumber": "COMM 205",
      "courseName": "Introduction to Management Information Systems"
    },
    "ADMN254": {
      "courseNumber": "COMM 451",
      "courseName": "Intermediate Financial Accounting II"
    },
    "ADMN255": {
      "courseNumber": "COMM 371",
      "courseName": "Corporate Finance"
    },
    "ADMN257": {
      "courseNumber": "COMM 455",
      "courseName": "Advanced Taxation"
    },
    "ADMN272": {
      "courseNumber": "COMM 393",
      "courseName": "Commercial Law"
    },
    "ADMN297": {
      "courseNumber": "COMM 466",
      "courseName": "New Venture Design"
    },
    "BIOL101": {
        "courseNumber": "BIOL 1XX",
        "courseName": "100-Level BIOL Course"
      },
      "BIOL104": {
        "courseNumber": "BIOL 1XX",
        "courseName": "100-Level BIOL Course"
      },
      "BIOL106": {
        "courseNumber": "BIOL 1XX",
        "courseName": "100-Level BIOL Course"
      },
      "BIOL200": {
        "courseNumber": "BIOL 2XX",
        "courseName": "200-Level BIOL Course"
      },
      "BIOL202": {
        "courseNumber": "BIOL 2XX",
        "courseName": "200-Level BIOL Course"
      },
      "BIOL206": {
        "courseNumber": "BIOL 2XX",
        "courseName": "200-Level BIOL Course"
      },
      "CHEM110": {
        "courseNumber": "CHEM 121",
        "courseName": "Structural Chemistry"
      },
      "CHEM122": {
        "courseNumber": "CHEM 121",
        "courseName": "Structural Chemistry"
      },
      "CHEM125": {
        "courseNumber": "CHEM 123",
        "courseName": "Thermodynamics, Kinetics and Organic Chemistry"
      },
      "CHEM212": {
        "courseNumber": "CHEM 233",
        "courseName": "Organic Chemistry for the Biological Sciences"
      },
      "CHEM213": {
        "courseNumber": "CHEM 235",
        "courseName": "Organic Chemistry Laboratory"
      },
      "CHEM225": {
        "courseNumber": "CHEM 2XX",
        "courseName": "200-Level CHEM Course"
      },
      "CPSC100": {
        "courseNumber": "CPSC 1XX",
        "courseName": "100-Level CPSC Course"
      },
      "CPSC101": {
        "courseNumber": "CPSC 110",
        "courseName": "Computation, Programs, and Programming"
      },
      "CRWT100": {
        "courseNumber": "ENGL 1XX",
        "courseName": "100-Level ENGL Course"
      },
      "CRWT200": {
        "courseNumber": "ENGL 2XX",
        "courseName": "200-Level ENGL Course"
      },
      "ENGL110": {
        "courseNumber": "ENGL 112",
        "courseName": "Strategies for University Writing"
      },
      "ENGL111": {
        "courseNumber": "ENGL 1XX",
        "courseName": "100-Level ENGL Course"
      },
      "ENGL200": {
        "courseNumber": "ENGL 2XX",
        "courseName": "200-Level ENGL Course"
      },
      "ENGR100": {
        "courseNumber": "APSC 100",
        "courseName": "Introduction to Engineering I"
      },
      "ENGR101": {
        "courseNumber": "APSC 101",
        "courseName": "Introduction to Engineering II"
      },
      "FREN102": {
        "courseNumber": "FREN 101",
        "courseName": "Elementary French I"
      },
      "FREN103": {
        "courseNumber": "FREN 102",
        "courseName": "Elementary French II"
      },
      "GEOG130": {
        "courseNumber": "GEOG 103",
        "courseName": "Our Changing Environment: Climate and Ecosystems"
      },
      "GEOG232": {
        "courseNumber": "GEOB 103",
        "courseName": "Introduction to Physical Geography"
      },
      "GEOL132": {
        "courseNumber": "EOSC 110",
        "courseName": "The Solid Earth: Its Dynamic History"
      },
      "GEOL142": {
        "courseNumber": "EOSC 210",
        "courseName": "Earth Science for Engineers"
      },
      "HIST100": {
        "courseNumber": "HIST 1XX",
        "courseName": "100-Level HIST Course"
      },
      "HIST203": {
        "courseNumber": "HIST 2XX",
        "courseName": "200-Level HIST Course"
      },
      "INDG100": {
        "courseNumber": "FNIS 100",
        "courseName": "Introduction to First Nations and Indigenous Studies"
      },
      "MATH101": {
        "courseNumber": "MATH 101",
        "courseName": "Integral Calculus with Applications"
      },
      "MATH140": {
        "courseNumber": "MATH 104",
        "courseName": "Differential Calculus with Applications"
      },
      "MATH181": {
        "courseNumber": "MATH 1XX",
        "courseName": "100-Level MATH Course"
      },
      "MATH221": {
        "courseNumber": "MATH 221",
        "courseName": "Matrix Algebra"
      },
      "PHIL100": {
        "courseNumber": "PHIL 100",
        "courseName": "Introduction to Philosophy"
      },
      "PHIL210": {
        "courseNumber": "PHIL 2XX",
        "courseName": "200-Level PHIL Course"
      },
      "PHYS102": {
        "courseNumber": "PHYS 101",
        "courseName": "Energy and Waves"
      },
      "PHYS103": {
        "courseNumber": "PHYS 102",
        "courseName": "Electricity, Light and Radiation"
      },
      "PHYS104": {
        "courseNumber": "PHYS 100",
        "courseName": "Introductory Physics"
      },
      "PHYS105": {
        "courseNumber": "PHYS 101",
        "courseName": "Energy and Waves"
      },
      "PHYS200": {
        "courseNumber": "PHYS 2XX",
        "courseName": "200-Level PHYS Course"
      },
      "SPAN102": {
        "courseNumber": "SPAN 101",
        "courseName": "Beginners' Spanish I"
      },
      "STAT105": {
        "courseNumber": "STAT 200",
        "courseName": "Elementary Statistics for Applications"
      },
      "STAT206": {
        "courseNumber": "STAT 241",
        "courseName": "Introductory Probability and Statistics"
      },
      "TWC164": {
        "courseNumber": "COMM 1XX",
        "courseName": "100-Level COMM Course"
      }
  },
  "Simon Fraser University": {
    "ADMN170": {
      "courseNumber": "BUS 201",
      "courseName": "Introduction to Business"
    },
    "COMM240": {
      "courseNumber": "BUS 251",
      "courseName": "Financial Accounting I"
    },
    "ECON106": {
      "courseNumber": "ECON 105",
      "courseName": "Principles of Macroeconomics"
    },
    "ADMN181": {
      "courseNumber": "BUS 343",
      "courseName": "Introduction to Marketing"
    },
    "COMM241": {
      "courseNumber": "BUS 254",
      "courseName": "Managerial Accounting I"
    },
    "ECON107": {
      "courseNumber": "ECON 103",
      "courseName": "Principles of Microeconomics"
    },
    "ADMN250": {
      "courseNumber": "BUS 254",
      "courseName": "Managerial Accounting I"
    },
    "ADMN251": {
      "courseNumber": "BUS 320",
      "courseName": "Financial Accounting: Assets"
    },
    "ADMN252": {
      "courseNumber": "BUS 312",
      "courseName": "Introduction to Finance"
    },
    "ADMN253": {
      "courseNumber": "BUS 420",
      "courseName": "Canadian Income Taxation I"
    },
    "ADMN269": {
      "courseNumber": "BUS 237",
      "courseName": "Introduction to Business Technology Management"
    },
    "ADMN254": {
      "courseNumber": "BUS 321",
      "courseName": "Financial Accounting: Equities"
    },
    "ADMN255": {
      "courseNumber": "BUS 313",
      "courseName": "Financial Management"
    },
    "ADMN257": {
      "courseNumber": "BUS 421",
      "courseName": "Canadian Income Taxation II"
    },
    "ADMN272": {
      "courseNumber": "BUS 393",
      "courseName": "Commercial Law"
    },
    "ADMN297": {
      "courseNumber": "BUS 477",
      "courseName": "New Venture Planning"
    },
    "MATH125": {
      "courseNumber": "MATH 157",
      "courseName": "Calculus I for the Social Sciences"
    },
    "BIOL101": {
        "courseNumber": "BISC 100",
        "courseName": "Introduction to Biology"
      },
      "BIOL104": {
        "courseNumber": "BISC 101",
        "courseName": "General Biology"
      },
      "BIOL106": {
        "courseNumber": "BISC 102",
        "courseName": "Introduction to Biology"
      },
      "BIOL200": {
        "courseNumber": "BISC 204",
        "courseName": "Introduction to Ecology"
      },
      "BIOL202": {
        "courseNumber": "BISC 202",
        "courseName": "Genetics"
      },
      "BIOL206": {
        "courseNumber": "MBB 2XX",
        "courseName": "200-Level MBB Course"
      },
      "CHEM110": {
        "courseNumber": "CHEM 121",
        "courseName": "General Chemistry and Laboratory I"
      },
      "CHEM122": {
        "courseNumber": "CHEM 121",
        "courseName": "General Chemistry and Laboratory I"
      },
      "CHEM125": {
        "courseNumber": "CHEM 122",
        "courseName": "General Chemistry and Laboratory II"
      },
      "CHEM212": {
        "courseNumber": "CHEM 281",
        "courseName": "Organic Chemistry I"
      },
      "CHEM213": {
        "courseNumber": "CHEM 282",
        "courseName": "Organic Chemistry II"
      },
      "CHEM225": {
        "courseNumber": "CHEM 360",
        "courseName": "Introduction to Inorganic Chemistry"
      },
      "CPSC100": {
        "courseNumber": "CMPT 1XX",
        "courseName": "100-Level CMPT Course"
      },
      "CPSC101": {
        "courseNumber": "CMPT 120",
        "courseName": "Introduction to Computing I"
      },
      "CRWT100": {
        "courseNumber": "GE 1XX",
        "courseName": "100-Level GE Course"
      },
      "CRWT200": {
        "courseNumber": "ENGL 1XX",
        "courseName": "100-Level ENGL Course"
      },
      "ENGL110": {
        "courseNumber": "ENGL 199",
        "courseName": "Introduction to University Writing"
      },
      "ENGL111": {
        "courseNumber": "ENGL 111",
        "courseName": "Literature and Culture"
      },
      "ENGL200": {
        "courseNumber": "ENGL 2XX",
        "courseName": "200-Level ENGL Course"
      },
      "ENGR100": {
        "courseNumber": "ENSC 100",
        "courseName": "Engineering, Science and Society"
      },
      "ENGR101": {
        "courseNumber": "ENSC 100",
        "courseName": "Engineering, Science and Society"
      },
      "FREN102": {
        "courseNumber": "FREN 1XX",
        "courseName": "100-Level FREN Course"
      },
      "FREN103": {
        "courseNumber": "FREN 1XX",
        "courseName": "100-Level FREN Course"
      },
      "GEOG130": {
        "courseNumber": "GEOG 111",
        "courseName": "Weather and Climate"
      },
      "GEOG232": {
        "courseNumber": "GEOG 213",
        "courseName": "Introduction to Geomorphology"
      },
      "GEOL132": {
        "courseNumber": "EASC 101",
        "courseName": "The Earth and Its Surficial Processes"
      },
      "GEOL142": {
        "courseNumber": "EASC 210",
        "courseName": "Introduction to Mineralogy"
      },
      "HIST100": {
        "courseNumber": "HIST 1XX",
        "courseName": "100-Level HIST Course"
      },
      "HIST203": {
        "courseNumber": "HIST 1XX",
        "courseName": "100-Level HIST Course"
      },
      "INDG100": {
        "courseNumber": "FNST 1XX",
        "courseName": "100-Level FNST Course"
      },
      "MATH101": {
        "courseNumber": "MATH 152",
        "courseName": "Calculus II"
      },
      "MATH140": {
        "courseNumber": "MATH 157",
        "courseName": "Calculus I for the Social Sciences"
      },
      "MATH181": {
        "courseNumber": "MATH 1XX",
        "courseName": "100-Level MATH Course"
      },
      "MATH221": {
        "courseNumber": "MATH 232",
        "courseName": "Applied Linear Algebra"
      },
      "PHIL100": {
        "courseNumber": "PHIL 1XX",
        "courseName": "100-Level PHIL Course"
      },
      "PHIL210": {
        "courseNumber": "PHIL 1XX",
        "courseName": "100-Level PHIL Course"
      },
      "PHYS102": {
        "courseNumber": "PHYS 101",
        "courseName": "Physics for the Life Sciences I"
      },
      "PHYS103": {
        "courseNumber": "PHYS 102",
        "courseName": "Physics for the Life Sciences II"
      },
      "PHYS104": {
        "courseNumber": "PHYS 120",
        "courseName": "Mechanics and Modern Physics"
      },
      "PHYS105": {
        "courseNumber": "PHYS 121",
        "courseName": "Optics, Electricity and Magnetism"
      },
      "PHYS200": {
        "courseNumber": "PHYS 2XX",
        "courseName": "200-Level PHYS Course"
      },
      "SPAN102": {
        "courseNumber": "SPAN 1XX",
        "courseName": "100-Level SPAN Course"
      },
      "STAT105": {
        "courseNumber": "STAT 205",
        "courseName": "Introduction to Statistics"
      },
      "STAT206": {
        "courseNumber": "STAT 270",
        "courseName": "Introduction to Probability and Statistics"
      },
      "TWC164": {
        "courseNumber": "BUS 1XX",
        "courseName": "100-Level BUS Course"
      },
  }
};

const TransferIntoModal = ({ showModal, onClose, plannedCourses }) => {
  const modalRef = useRef(null);

  // State for which school is selected
  const [selectedSchool, setSelectedSchool] = useState("Simon Fraser University");

  // Dropdown state
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  // Possible schools array
  const schools = [
    "University of British Columbia",
    "Simon Fraser University",
  ];

  // Toggle dropdown
  const handleSelectProgramButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Set school and close dropdown
  const handleProgramChange = (school) => {
    setSelectedSchool(school);
    setDropdownVisible(false);
  };

  useEffect(() => {
    // Close the modal if user clicks outside the modal content & outside dropdown
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        onClose();
      }
    }

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal, onClose]);

  if (!showModal) {
    return null;
  }

  // Gather courses from plannedCourses, preserving order
  const allCourses = [];
  Object.keys(plannedCourses).forEach((yearKey) => {
    const semesters = plannedCourses[yearKey];
    Object.keys(semesters).forEach((semesterKey) => {
      const coursesArray = semesters[semesterKey];
      coursesArray.forEach((course) => {
        allCourses.push(course);
      });
    });
  });

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      {/* Modal box WITHOUT overall scrolling */}
      <div
        ref={modalRef}
        className="relative bg-white rounded-lg shadow-lg w-auto min-h-[80vh] max-w-6xl min-w-[65vw] max-w-[65vw] p-4"
      >
        <div className="flex justify-between items-center border-b pb-3">
          {/* Header area: Title + Dropdown */}
          <div className="flex items-center">
            <h2 className="text-2xl font-semibold mr-1">
              Transfer Equivalents for
            </h2>

            {/* Dropdown Section */}
            <div className="relative" ref={dropdownRef}>
              <button
                className="ml-1 px-2 py-1 bg-gray-200 rounded transition-colors hover:bg-gray-300"
                onClick={handleSelectProgramButtonClick}
              >
                <span className="text-lg">{selectedSchool || "N/A"}</span>
              </button>

              {dropdownVisible && (
                <div className="absolute left-0 mt-1 bg-white border border-gray-200 rounded shadow-lg z-10">
                  <ul className="py-1">
                    {schools.map((school, index) => (
                      <li key={`school-${index}`}>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                          onClick={() => handleProgramChange(school)}
                        >
                          {school}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 focus:outline-none text-3xl"
            aria-label="Close Modal"
          >
            &times;
          </button>
        </div>

        <hr className="border-gray-300 mb-3" />

        {allCourses.length === 0 ? (
          // Render a red alert bubble if no courses are available
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong className="font-bold">No courses to transfer!</strong>
            <span className="block sm:inline">
              {" "}
              Please add courses to view transfer equivalents.
            </span>
          </div>
        ) : (
          // Make only the table's content scrollable with a max height leaving 50px from the bottom
          <div
            className="relative overflow-x-auto shadow-md sm:rounded-lg"
            style={{ maxHeight: "calc(70vh - 20px)", overflowY: "auto" }}
          >
            <table className="w-full text-sm text-left text-gray-500">
              {/* Table head */}
              <thead
                className="text-xs text-gray-700 uppercase bg-gray-50"
                style={{ position: "sticky", top: 0, zIndex: 1 }}
              >
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Original Course
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Equivalent Course
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody>
                {allCourses.map((course, index) => {
                  const originalNumber = course.courseInfo_courseNumber;
                  const originalName =
                    course.courseInfo_courseName || "Untitled Course";

                  // Attempt to look up the mapped course
                  const mapped =
                    transferMappings[selectedSchool][originalNumber] || null;

                  // Determine if an equivalent exists
                  const isNoEquivalent = !mapped;
                  const statusColor = isNoEquivalent
                    ? "text-red-500"
                    : "text-green-500";
                  const statusLabel = isNoEquivalent ? "No Transfer" : "Transfers";

                  return (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      {/* Original Course */}
                      <td className="px-5 py-3 whitespace-nowrap">
                        <p className="font-bold text-sm text-gray-900">
                          {originalNumber}
                        </p>
                        <p className="text-xs text-gray-800">
                          {originalName}
                        </p>
                      </td>

                      {/* Equivalent Course */}
                      <td className="px-5 py-3 whitespace-nowrap">
                        {mapped ? (
                          <>
                            <p className="font-bold text-sm text-gray-900">
                              {mapped.courseNumber}
                            </p>
                            <p className="text-xs text-gray-800">
                              {mapped.courseName}
                            </p>
                          </>
                        ) : (
                          <p className="text-md mt-1 font-semibold text-gray-800">
                            No equivalent found
                          </p>
                        )}
                      </td>

                      {/* Status */}
                      <td className="px-5 py-4 whitespace-nowrap">
                        <span className={`${statusColor} font-semibold`}>
                          {statusLabel}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

      </div>
    </div>
  );
};

export default TransferIntoModal;
