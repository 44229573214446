// src/components/AdvisorProfile/AdvisorProfileHeading.js
import React from 'react';

const AdvisorProfileHeading = ({ advisor }) => {
  // Use a default picture if none is provided
  const photoURL = advisor?.photoURL || '/default.jpg';

  console.log(advisor, 'hiasdf')

  return (
    <div className="flex items-center mb-6">
      <img
        className="w-[160px] h-[160px] mb-4 mr-5 rounded-full object-cover"
        src={photoURL}
        alt="Advisor Profile"
      />
      <div className="flex-grow max-w-[calc(100%-180px)]">
        {/* Title or name, e.g., "John Doe, Senior Undergraduate Academic Advisor" */}
        <h1 className="text-2xl font-bold mb-1">
          {advisor?.title ?? 'Advisor'}
        </h1>

        {/* Optionally display the advisor's email */}
        {advisor?.email && (
          <p className="text-sm mb-2 text-gray-600">{advisor.email}</p>
        )}

        {/* Degrees, e.g. ["B.A. in Computer Science", "M.S. in Education"] */}
        {advisor?.degrees && advisor.degrees.length > 0 && (
          <p className="text-sm mb-2">
            <strong>Degrees:</strong> {advisor.degrees.join(', ')}
          </p>
        )}

        {/* Location, e.g. "New York, NY" */}
        {advisor?.location && (
          <p className="text-sm mb-2">
            <strong>Location:</strong> {advisor.location}
          </p>
        )}

        {/* Bio, e.g. a short paragraph about the advisor */}
        {advisor?.bio && (
          <p className="text-sm text-gray-800">{advisor.bio}</p>
        )}
      </div>
    </div>
  );
};

export default AdvisorProfileHeading;
