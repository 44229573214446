// Filename: StudentInitiatives.jsx
import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import {
  collection,
  getDocs,
  addDoc,
  doc,
  getDoc,
  Timestamp,
} from 'firebase/firestore';
import { useAuth } from '../../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// We import this chart for the "expanded" view
import GroupPersistenceChart from './GroupPersistenceChart';

dayjs.extend(relativeTime);

/**
 * Hardcoded sample initiatives (placeholders) — always "completed"
 * so you have some examples. Merge them with Firestore data below.
 */
const placeholderInitiatives = [
  {
    id: 'placeholder-1',
    persistenceChange: +5.8,
    persistenceConfidence: 0.4,
    isSignificant: true,
    title: 'First-Generation Student Mentorship Program',
    participantsCount: 5075,
    description:
      'Connecting first-gen students with faculty and peer mentors to improve academic performance and retention.',
    startTerm: 'Fall 2024',
    endTerm: 'Spring 2025',
    addedDate: 'January 12, 2025',
    lastModifiedBy: 'Dr. Jane Smith',
    status: 'completed',
    timeAgo: '3 days ago',
  },
  {
    id: 'placeholder-2',
    persistenceChange: +1.8,
    persistenceConfidence: null,
    isSignificant: false,
    title: 'STEM Peer Tutoring Initiative',
    participantsCount: 2457,
    description:
      'Providing group and 1-on-1 tutoring for core STEM courses to help students stay on track.',
    startTerm: 'Spring 2024',
    endTerm: 'Fall 2024',
    addedDate: 'February 1, 2025',
    lastModifiedBy: 'Eric McIntosh',
    status: 'completed',
    timeAgo: '2 weeks ago',
  },
  {
    id: 'placeholder-3',
    persistenceChange: +4.9,
    persistenceConfidence: 3.2,
    isSignificant: true,
    title: 'Campus Wellness Program',
    participantsCount: 3267,
    description:
      'Comprehensive mental health and wellness services aimed at improving student satisfaction and persistence.',
    startTerm: 'Summer 2024',
    endTerm: 'Spring 2025',
    addedDate: 'January 20, 2025',
    lastModifiedBy: 'Impact User',
    status: 'completed',
    timeAgo: '5 days ago',
  },
  {
    id: 'placeholder-4',
    persistenceChange: +0.0,
    persistenceConfidence: 1.2,
    isSignificant: false,
    title: 'International Student Orientation Enhancement',
    participantsCount: 5556,
    description:
      'Expanding orientation programming for international students, focusing on academic and social integration.',
    startTerm: 'Fall 2024',
    endTerm: 'Fall 2025',
    addedDate: 'February 3, 2025',
    lastModifiedBy: 'Grace Gee',
    status: 'completed',
    timeAgo: '1 day ago',
  },
  {
    id: 'placeholder-5',
    persistenceChange: -8.3,
    persistenceConfidence: null,
    isSignificant: true,
    title: 'Undergraduate Research Opportunities Expansion',
    participantsCount: 1397,
    description:
      'Incentivizing faculty-mentored research for undergraduates; studying the effect on retention.',
    startTerm: 'Spring 2024',
    endTerm: 'Fall 2024',
    addedDate: 'January 29, 2025',
    lastModifiedBy: 'Grace Gee',
    status: 'completed',
    timeAgo: '4 hours ago',
  },
  {
    id: 'placeholder-6',
    persistenceChange: +2.4,
    persistenceConfidence: 0.5,
    isSignificant: true,
    title: 'Online Academic Advising Pilot',
    participantsCount: 29225,
    description:
      'Testing virtual advising sessions for remote and non-traditional students to see if it improves persistence.',
    startTerm: 'Fall 2024',
    endTerm: 'Spring 2025',
    addedDate: 'January 10, 2025',
    lastModifiedBy: 'Grace Gee',
    status: 'completed',
    timeAgo: '3 weeks ago',
  },
];

/**
 * Helper to apply a single custom filter. 
 * Matches what you do in StudentGroups => StudentGroups.js => applyCustomFilter
 */
function applyCustomFilter(list, { field, operator, value }) {
  let newList = [...list];
  newList = newList.filter((s) => {
    // For "tags", your code looks in s.surveyAnswers?.Tags
    // Otherwise, we look in s[field] directly.
    const studentVal =
      field === 'tags' ? s.surveyAnswers?.Tags : s[field];
    if (studentVal == null) return false;

    if (operator === 'equals') {
      if (Array.isArray(studentVal)) {
        return studentVal.some(
          (elem) =>
            String(elem).trim().toLowerCase() ===
            String(value).trim().toLowerCase()
        );
      } else {
        return (
          String(studentVal).trim().toLowerCase() ===
          String(value).trim().toLowerCase()
        );
      }
    } else if (operator === 'greater than') {
      return parseFloat(studentVal) > parseFloat(value);
    } else if (operator === 'less than') {
      return parseFloat(studentVal) < parseFloat(value);
    }

    return true; // if unknown operator, let it pass
  });

  return newList;
}

const StudentInitiatives = () => {
  const { currentUser } = useAuth();

  // 1) We'll store the groups from Firestore in this local state
  const [availableGroups, setAvailableGroups] = useState([]);

  // Sub-tab filter
  const [activeSubTab, setActiveSubTab] = useState('completed');

  // Firestore initiatives from "studentInitiatives"
  const [firestoreInitiatives, setFirestoreInitiatives] = useState([]);

  // Show/hide chart
  const [expandedInitiativeId, setExpandedInitiativeId] = useState(null);

  // State for the "Add Initiative" modal
  const [showAddModal, setShowAddModal] = useState(false);

  // Form fields for new initiative
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [groupId, setGroupId] = useState('');
  const [startTerm, setStartTerm] = useState('');
  const [endTerm, setEndTerm] = useState('');
  const [status, setStatus] = useState('pending');

  /**
   * NEW FIELD:
   * Whether to "lock" participants at creation or
   * always recalc from the group. We'll store as 'locked' or 'dynamic'.
   */
  const [participantTracking, setParticipantTracking] = useState('locked');

  // ================================
  // A) Fetch groups + initiatives
  // ================================
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const snap = await getDocs(collection(db, 'studentGroups'));
        const loaded = snap.docs.map((d) => ({
          id: d.id,
          ...d.data(),
        }));
        setAvailableGroups(loaded);
      } catch (error) {
        console.error('Error fetching studentGroups:', error);
        toast.error('Failed to load groups.');
      }
    };

    const fetchInitiatives = async () => {
      try {
        const snap = await getDocs(collection(db, 'studentInitiatives'));
        const loaded = snap.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setFirestoreInitiatives(loaded);
      } catch (err) {
        console.error('Error fetching studentInitiatives:', err);
        toast.error('Failed to load initiatives.');
      }
    };

    fetchGroups();
    fetchInitiatives();
  }, []);

  // ================================
  // B) Combine placeholders + real data
  // ================================
  const combinedInitiatives = [
    ...placeholderInitiatives,
    ...firestoreInitiatives,
  ];

  // Filter by sub-tab
  const filteredInitiatives = combinedInitiatives.filter(
    (init) => init.status === activeSubTab
  );

  // ================================
  // C) "Add Initiative"
  // ================================
  const handleSaveInitiative = async () => {
    if (!currentUser) {
      toast.error('You must be logged in to create an initiative.');
      return;
    }
    if (!title.trim() || !groupId) {
      toast.warning('Please provide a title and select a group.');
      return;
    }

    try {
      // 1) Gather the group's members at creation time
      //    (if participantTracking === 'locked') => freeze them
      //    otherwise, we do NOT freeze them, they can change daily
      const {
        average: baselineAvg,
        count: baselineCount,
        studentIds: groupMemberIds,
      } = await computeAveragePersistenceForGroup(groupId);

      // If participantTracking === 'locked', store these students
      const frozenStudentIds =
        participantTracking === 'locked' ? groupMemberIds : [];

      // 2) Build the new doc
      const nowTs = Timestamp.now();
      const newInitiative = {
        title: title.trim(),
        description: description.trim(),
        groupId,
        participantTracking, // 'locked' or 'dynamic'
        baselinePersistence: baselineAvg,
        latestGroupPersistence: baselineAvg,
        persistenceChange: 0, // at creation => difference = 0
        groupPersistenceHistory: [
          {
            date: nowTs,
            average: baselineAvg,
            participantsCount: baselineCount,
          },
        ],
        participantsCount: baselineCount, // store initial count
        // If locked, store the actual members
        frozenStudentIds,
        startTerm,
        endTerm,
        status,
        isSignificant: false, // default
        persistenceConfidence: null, // default
        addedDate: nowTs,
        lastModifiedBy: currentUser.displayName || 'Anonymous',
        createdAt: nowTs,
        createdBy: currentUser.uid,
      };

      // 3) Save to Firestore
      const docRef = await addDoc(collection(db, 'studentInitiatives'), newInitiative);

      // 4) Update local state
      setFirestoreInitiatives((prev) => [
        ...prev,
        { ...newInitiative, id: docRef.id },
      ]);

      toast.success('Initiative created successfully!');
      setShowAddModal(false);

      // reset fields
      setTitle('');
      setDescription('');
      setGroupId('');
      setStartTerm('');
      setEndTerm('');
      setStatus('pending');
      setParticipantTracking('locked');
    } catch (error) {
      console.error('Error creating initiative:', error);
      toast.error('Failed to create initiative.');
    }
  };

  /**
   * D) Compute average for the group.
   *
   * We replicate the same filter logic as in StudentGroups' "applyDynamicFilterToGroup."
   * We'll build an array of "user objects" from Firestore, including:
   *   - Basic user doc fields
   *   - assignedAdvisorNames
   *   - persistenceCategory
   *   - overallPersistenceScore
   *
   * Then we'll filter them according to groupData.filterDefinition, 
   * and compute the average of "overallPersistenceScore".
   */
  async function computeAveragePersistenceForGroup(gid) {
    try {
      const groupSnap = await getDoc(doc(db, 'studentGroups', gid));
      if (!groupSnap.exists()) {
        return { average: 0, count: 0, studentIds: [] };
      }

      const groupData = groupSnap.data();

      // If static => just use groupData.studentIds
      // If dynamic => we must apply the full filterDefinition
      if (!groupData.isDynamic) {
        const staticIds = Array.isArray(groupData.studentIds)
          ? groupData.studentIds
          : [];
        // Now compute the average from those "frozen" IDs:
        return await computeFromSpecificUserIds(staticIds);
      } else {
        // DYNAMIC => replicate your filter logic
        const fDef = groupData.filterDefinition || {};

        // 1) Load all users with role=student
        const allUsersSnap = await getDocs(collection(db, 'users'));
        const allRawUsers = allUsersSnap.docs
          .map((docSnap) => {
            const ud = docSnap.data();
            return {
              uid: docSnap.id,
              ...ud,
            };
          })
          .filter((u) => u.role === 'student');

        // 2) For each user, also fetch:
        //    - studentAdvisors => assignedAdvisorNames
        //    - persistence/persistenceCalculations => persistenceCategory, overallPersistenceScore
        // We'll do this in a loop, but you could do it in parallel if needed.
        const userObjects = [];
        for (const u of allRawUsers) {
          // (a) advisor names
          let assignedAdvisorNames = [];
          try {
            const studAdvSnap = await getDoc(doc(db, 'studentAdvisors', u.uid));
            if (studAdvSnap.exists()) {
              const { advisorIds = [] } = studAdvSnap.data();
              // You also need an advisorMap. Let's build it on the fly or cache it:
              // We'll do a quick fetch of the advisor doc for each ID:
              const names = [];
              for (const aid of advisorIds) {
                const advDoc = await getDoc(doc(db, 'advisors', aid));
                if (advDoc.exists()) {
                  const advData = advDoc.data();
                  names.push(`${advData.firstName} ${advData.lastName}`);
                } else {
                  names.push('Unknown Advisor');
                }
              }
              assignedAdvisorNames = names;
            }
          } catch (error) {
            console.error('Error fetching studentAdvisors for user', u.uid, error);
          }

          // (b) persistence doc => category + overallPersistenceScore
          let persistenceCategory = '';
          let overallPersistenceScore = 0;
          try {
            const persSnap = await getDoc(
              doc(db, 'users', u.uid, 'persistence', 'persistenceCalculations')
            );
            if (persSnap.exists()) {
              const { prediction, overallPersistenceScore: score } = persSnap.data();
              persistenceCategory = prediction || '';
              overallPersistenceScore = typeof score === 'number' ? score : 0;
            }
          } catch (error) {
            console.error('Error fetching persistence for user', u.uid, error);
          }

          userObjects.push({
            ...u,
            assignedAdvisorNames,
            persistenceCategory,
            overallPersistenceScore,
          });
        }

        // 3) Apply the actual filters from groupData.filterDefinition
        //    as shown in your StudentGroups code (gradYear, program, advisors, etc.)
        let results = [...userObjects];

        // (i) Grad Year
        if (Array.isArray(fDef.gradYear) && fDef.gradYear.length > 0) {
          results = results.filter((s) =>
            fDef.gradYear.includes(s.surveyAnswers?.SchoolEnd?.year)
          );
        }

        // (ii) Program (majors)
        if (Array.isArray(fDef.program) && fDef.program.length > 0) {
          results = results.filter((s) => {
            const userMajors = Array.isArray(s.surveyAnswers?.Majors)
              ? s.surveyAnswers.Majors
              : [];
            // Keep user if ANY major is in fDef.program
            return userMajors.some((m) => fDef.program.includes(m));
          });
        }

        // (iii) Advisors
        if (Array.isArray(fDef.advisors) && fDef.advisors.length > 0) {
          results = results.filter((s) =>
            s.assignedAdvisorNames?.some((advName) =>
              fDef.advisors.includes(advName)
            )
          );
        }

        // (iv) Persistence Category
        if (Array.isArray(fDef.persistence) && fDef.persistence.length > 0) {
          results = results.filter((s) =>
            fDef.persistence.includes(s.persistenceCategory)
          );
        }

        // (v) Tags
        if (Array.isArray(fDef.tags) && fDef.tags.length > 0) {
          results = results.filter((s) => {
            const studentTags = s.surveyAnswers?.Tags || [];
            return studentTags.some((tag) => fDef.tags.includes(tag));
          });
        }

        // (vi) Custom Filter(s)
        if (fDef.customFilter?.field) {
          results = applyCustomFilter(results, fDef.customFilter);
        } else if (Array.isArray(fDef.customFilters) && fDef.customFilters.length > 0) {
          fDef.customFilters.forEach((cf) => {
            results = applyCustomFilter(results, cf);
          });
        }

        // 4) Now we have the final membership => compute average from overallPersistenceScore
        if (results.length === 0) {
          return { average: 0, count: 0, studentIds: [] };
        }

        let totalScore = 0;
        let count = 0;
        const finalIds = [];

        for (const mem of results) {
          if (typeof mem.overallPersistenceScore === 'number') {
            totalScore += mem.overallPersistenceScore;
            count++;
          }
          finalIds.push(mem.uid);
        }

        if (count === 0) {
          return { average: 0, count: 0, studentIds: [] };
        }

        const avg = parseFloat((totalScore / count).toFixed(2));
        return { average: avg, count, studentIds: finalIds };
      }
    } catch (error) {
      console.error('Error in computeAveragePersistenceForGroup:', error);
      return { average: 0, count: 0, studentIds: [] };
    }
  }

  /**
   * E) If we have a simple array of userIds (for static groups), compute the
   *    average from those users only.
   */
  async function computeFromSpecificUserIds(userIds) {
    if (!Array.isArray(userIds) || userIds.length === 0) {
      return { average: 0, count: 0, studentIds: [] };
    }
    let total = 0;
    let count = 0;
    for (const uid of userIds) {
      try {
        const persSnap = await getDoc(
          doc(db, 'users', uid, 'persistence', 'persistenceCalculations')
        );
        if (persSnap.exists()) {
          const { overallPersistenceScore } = persSnap.data();
          if (typeof overallPersistenceScore === 'number') {
            total += overallPersistenceScore;
            count++;
          }
        }
      } catch (err) {
        console.error('Error fetching persistence doc for uid:', uid, err);
      }
    }
    if (count === 0) return { average: 0, count: 0, studentIds: [] };
    const avg = parseFloat((total / count).toFixed(2));
    return { average: avg, count, studentIds: userIds };
  }

  // Toggle chart expansion
  const toggleExpandInitiative = (id) => {
    setExpandedInitiativeId((prev) => (prev === id ? null : id));
  };

  // ================================
  // RENDER
  // ================================

  // Icons for + / - / flat
  const UpArrowIcon = (
    <svg
      className="w-4 h-4 inline-block text-green-600"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M3.293 12.293a1 1 0 001.414 0L10 7l5.293 5.293a1 1 0 001.414-1.414l-6-6a1 1 0 00-1.414 0l-6 6a1 1 0 000 1.414z" />
    </svg>
  );

  const DownArrowIcon = (
    <svg
      className="w-4 h-4 inline-block text-red-600"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M16.707 7.707a1 1 0 00-1.414-1.414L10 11.586 4.707 6.293a1 1 0 00-1.414 1.414l6 6a1 1 0 001.414 0l6-6z" />
    </svg>
  );

  const FlatLineIcon = (
    <svg
      className="w-4 h-4 inline-block text-gray-600"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <rect x="3" y="9" width="14" height="2" rx="1" />
    </svg>
  );

  function renderTimeAgo(tsOrString) {
    if (!tsOrString) return 'N/A';
    // If it's a Firestore timestamp
    if (typeof tsOrString === 'object' && tsOrString.toDate) {
      return dayjs(tsOrString.toDate()).fromNow();
    }
    // Otherwise assume a plain string (for placeholders)
    return tsOrString;
  }

  return (
    <div className="w-full px-2 py-3">
      {/* Sub-tabs */}
      <div className="flex items-center justify-between mb-2 border-b border-gray-200 pb-1">
        <div>
          <button
            className={`mr-6 px-3 py-1 text-sm font-semibold focus:outline-none ${
              activeSubTab === 'completed'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveSubTab('completed')}
          >
            Completed
          </button>
          <button
            className={`mr-6 px-3 py-1 text-sm font-semibold focus:outline-none ${
              activeSubTab === 'pending'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveSubTab('pending')}
          >
            Pending
          </button>
          <button
            className={`mr-6 px-3 py-1 text-sm font-semibold focus:outline-none ${
              activeSubTab === 'archived'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveSubTab('archived')}
          >
            Archived
          </button>
        </div>

        <button
          onClick={() => setShowAddModal(true)}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 
                     focus:ring-blue-300 font-medium rounded-lg text-sm px-3 
                     py-2.5"
        >
          Add Initiative
        </button>
      </div>

      {/* Filtered results */}
      {filteredInitiatives.length === 0 ? (
        <p className="text-gray-500 italic">No initiatives in this status.</p>
      ) : (
        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {filteredInitiatives.map((item) => {
            const diff = item.persistenceChange || 0;
            const absChange = Math.abs(diff).toFixed(2);

            // Are we significant or not?
            const isSignificant = !!item.isSignificant;

            // We'll define styling based on significance + direction
            let topBarColor, arrowIcon, textColor, significanceBadge, labelText;

            if (!isSignificant) {
              // NOT SIGNIFICANT => all gray, flat line
              topBarColor = 'bg-gray-400';
              arrowIcon = FlatLineIcon;
              textColor = 'text-gray-700';
              significanceBadge = (
                <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-gray-500">
                  Not significant
                </span>
              );

              if (diff > 0) {
                labelText = `${absChange}% Lift in Persistence`;
              } else if (diff < 0) {
                labelText = `${absChange}% Drop in Persistence`;
              } else {
                labelText = '0.00% Change in Persistence';
              }
            } else {
              // SIGNIFICANT => color depends on sign
              if (diff > 0) {
                topBarColor = 'bg-green-500';
                arrowIcon = UpArrowIcon;
                textColor = 'text-green-600';
                significanceBadge = (
                  <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-green-400">
                    Significant Lift
                  </span>
                );
                labelText = `${absChange}% Lift in Persistence`;
              } else if (diff < 0) {
                topBarColor = 'bg-red-500';
                arrowIcon = DownArrowIcon;
                textColor = 'text-red-700';
                significanceBadge = (
                  <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-red-400">
                    Significant Drop
                  </span>
                );
                labelText = `${absChange}% Drop in Persistence`;
              } else {
                // zero => "Significant (No Change)"
                topBarColor = 'bg-gray-400';
                arrowIcon = FlatLineIcon;
                textColor = 'text-gray-700';
                significanceBadge = (
                  <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-gray-500">
                    Significant (No Change)
                  </span>
                );
                labelText = '0.00% Change in Persistence';
              }
            }

            // If you want to show the "±confidence" next to the label:
            const confidenceString =
              item.persistenceConfidence !== null &&
              item.persistenceConfidence !== undefined
                ? ` ±${item.persistenceConfidence}%`
                : '';

            // Find group name if available
            let groupLabel = '';
            if (item.groupId) {
              const grp = availableGroups.find((g) => g.id === item.groupId);
              groupLabel = grp ? grp.name : '(Unknown Group)';
            }

            const timeAgoStr = renderTimeAgo(item.addedDate);

            return (
              <div
                key={item.id}
                className="relative border border-gray-200 bg-white rounded-md shadow-sm p-4 hover:shadow-md transition-shadow"
              >
                {/* colored bar at top */}
                <div
                  className={`absolute top-0 left-0 right-0 h-1 ${topBarColor} rounded-t-md`}
                />

                {/* top-right "time ago" */}
                <div className="absolute top-2 right-2 z-10">
                  <span className="bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded border border-gray-500">
                    {timeAgoStr}
                  </span>
                </div>

                {/* Persistence row */}
                <div className="flex items-center mb-1 mt-2">
                  {arrowIcon}
                  <span className={`ml-2 font-semibold ${textColor} text-sm`}>
                    {labelText}
                    {confidenceString && (
                      <span className="text-xs text-gray-500 ml-2">
                        {confidenceString}
                      </span>
                    )}
                  </span>
                </div>

                {/* Significance badge */}
                <div className="mb-2">{significanceBadge}</div>

                {/* Title */}
                <h3
                  className="flex items-center justify-between text-lg font-semibold mb-1 line-clamp-2 cursor-pointer transition-colors duration-200 hover:text-blue-600"
                  onClick={() => toggleExpandInitiative(item.id)}
                >
                  {item.title}
                </h3>

                {/* Group name (optional) */}
                {groupLabel && (
                  <div className="text-xs text-gray-600 mb-2 italic">
                    Group: {groupLabel}
                  </div>
                )}

                {/* # of participants, italic, smaller */}
                <p className="text-sm text-gray-600 mb-1 italic">
                  {(item.participantsCount || 0).toLocaleString()} analyzed participants
                </p>

                {/* Description */}
                <p className="text-sm text-gray-700 mb-3 line-clamp-3">
                  {item.description}
                </p>

                {/* Terms */}
                <div className="text-xs text-gray-500 mb-0.5">
                  {item.startTerm} – {item.endTerm}
                </div>

                {/* "Created by ..." */}
                <div className="text-xs text-gray-500">
                  Created by {item.lastModifiedBy || 'N/A'}
                </div>

                {/* Expandable chart */}
                {expandedInitiativeId === item.id && (
                  <div className="mt-3 p-2 border-t">
                    <h4 className="text-sm font-semibold mb-2">
                      Persistence History
                    </h4>
                    {Array.isArray(item.groupPersistenceHistory) &&
                    item.groupPersistenceHistory.length > 0 ? (
                      <GroupPersistenceChart
                        historyArray={item.groupPersistenceHistory}
                      />
                    ) : (
                      <p className="text-gray-500 text-xs">
                        No history data available.
                      </p>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {/* ===== "Add Initiative" Modal ===== */}
      {showAddModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* backdrop */}
          <div
            className="absolute inset-0 bg-black bg-opacity-50"
            onClick={() => setShowAddModal(false)}
          ></div>

          {/* modal content */}
          <div className="relative bg-white rounded shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-semibold mb-4">Add New Initiative</h2>

            {/* Title */}
            <div className="mb-3">
              <label className="block text-sm font-medium">Title:</label>
              <input
                type="text"
                className="w-full border border-gray-300 rounded px-2 py-1"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            {/* Description */}
            <div className="mb-3">
              <label className="block text-sm font-medium">Description:</label>
              <textarea
                className="w-full border border-gray-300 rounded px-2 py-1"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            {/* Group Select */}
            <div className="mb-3">
              <label className="block text-sm font-medium">Select Group:</label>
              <select
                value={groupId}
                onChange={(e) => setGroupId(e.target.value)}
                className="w-full border border-gray-300 rounded px-2 py-1"
              >
                <option value="">-- Select a group --</option>
                {availableGroups.map((g) => (
                  <option key={g.id} value={g.id}>
                    {g.name} ({g.isDynamic ? 'Dynamic' : 'Static'})
                  </option>
                ))}
              </select>
            </div>

            {/* Start / End Term */}
            <div className="flex gap-2 mb-3">
              <div className="w-1/2">
                <label className="block text-sm font-medium">Start Term:</label>
                <input
                  type="text"
                  placeholder="e.g. Fall 2024"
                  className="w-full border border-gray-300 rounded px-2 py-1"
                  value={startTerm}
                  onChange={(e) => setStartTerm(e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <label className="block text-sm font-medium">End Term:</label>
                <input
                  type="text"
                  placeholder="e.g. Spring 2025"
                  className="w-full border border-gray-300 rounded px-2 py-1"
                  value={endTerm}
                  onChange={(e) => setEndTerm(e.target.value)}
                />
              </div>
            </div>

            {/* Participant Tracking */}
            <div className="mb-3">
              <label className="block text-sm font-medium">
                Participant Tracking:
              </label>
              <div className="mt-1 flex items-center gap-3">
                <label className="flex items-center text-sm">
                  <input
                    type="radio"
                    name="participantTracking"
                    value="locked"
                    checked={participantTracking === 'locked'}
                    onChange={() => setParticipantTracking('locked')}
                    className="mr-1"
                  />
                  Lock participants at creation
                </label>
                <label className="flex items-center text-sm">
                  <input
                    type="radio"
                    name="participantTracking"
                    value="dynamic"
                    checked={participantTracking === 'dynamic'}
                    onChange={() => setParticipantTracking('dynamic')}
                    className="mr-1"
                  />
                  Track as group changes
                </label>
              </div>
            </div>

            {/* Status */}
            <div className="mb-3">
              <label className="block text-sm font-medium">Status:</label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="w-full border border-gray-300 rounded px-2 py-1"
              >
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
                <option value="archived">Archived</option>
              </select>
            </div>

            {/* Buttons */}
            <div className="flex justify-end gap-2 mt-4">
              <button
                className="px-4 py-2 border border-gray-300 rounded text-gray-700"
                onClick={() => setShowAddModal(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded"
                onClick={handleSaveInitiative}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentInitiatives;
