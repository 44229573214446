// CourseProgressFunctions/calculateProgressForMinor.js

import findRequirementCourses from './FindRequirementCourses';
import { findElectiveCourses } from './FindElectiveCourses';

const calculateProgressForMinor = (
  minor,
  fetchedMinors,
  fetchedCourseData,
  finalizedSchedule,
  completedSemesters
) => {
  if (!minor || !fetchedMinors) {
    return {
      takenCoursesCount: 0, // Total taken courses count
      planCoursesCount: 0, // Remaining planned courses count
      totCoursesCount: 0, // Total courses count for the minor
    };
  }

  // Aggregate courses from all years and semesters
  let courseArray = [];
  for (let i = 1; i <= 5; i++) { // Adjusted to handle up to fifth year if needed
    let year = `year${i}`;
    for (let j = 1; j <= 2; j++) {
      let semester = `S${j}`;
      if (finalizedSchedule[year] && finalizedSchedule[year][semester]) {
        courseArray.push(...finalizedSchedule[year][semester]['courses']);
      }
    }
  }

  // Get the student courses
  const studentCourses = fetchedCourseData
    ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber))
    : [];

  // Get the minor courses and total courses count
  const minorCourses = Object.values(fetchedMinors[minor]);
  const totalCourses = minorCourses.filter(minorCourse => minorCourse.courseType !== 'Milestone').length;

  // Taken Courses Logic
  const takenCoursesSet = new Set();
  Object.keys(completedSemesters).forEach(year => {
    Object.keys(completedSemesters[year]).forEach(semesterKey => {
      if (completedSemesters[year][semesterKey]) {
        const completedCourses = finalizedSchedule[year]?.[semesterKey]?.courses || [];
        completedCourses.forEach(course => {
          takenCoursesSet.add(course);
        });
      }
    });
  });
  const takenCoursesArray = Array.from(takenCoursesSet);
  const takenCourses = fetchedCourseData.filter(course => takenCoursesArray.includes(course.courseInfo_courseNumber));

  // Identify taken minor courses
  const minorRequiredSpecificCourses = minorCourses.filter(minorCourse => minorCourse.courseType === 'Minor Course' || minorCourse.Requirements === '');
  const takenMinorCoursesArray = takenCourses.filter(course => {
    return minorRequiredSpecificCourses.some(minorCourse => minorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
  });

  // Identify taken elective courses
  const takenElectiveCoursesArray = findElectiveCourses(
    minorCourses.filter(course => course.courseType === 'Elective'),
    takenCourses
  );

  // Identify taken requirement courses
  const takenRequirementCoursesArray = findRequirementCourses(
    minorCourses.filter(course => (course.Requirements) || course.courseType === 'Requirement'),
    takenCourses,
    takenElectiveCoursesArray
  );

  // Calculate all minor, elective, and requirement courses
  const allMinorCoursesArray = studentCourses.filter(course => {
    return minorRequiredSpecificCourses.some(minorCourse => minorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
  });

  const allElectiveCoursesArray = findElectiveCourses(
    minorCourses.filter(course => course.courseType === 'Elective'),
    studentCourses
  );

  const allRequirementCoursesArray = findRequirementCourses(
    minorCourses.filter(course => (course.Requirements) || course.courseType === 'Requirement'),
    studentCourses,
    allElectiveCoursesArray
  );

  // Calculate counts
  let takenCoursesLengths = takenMinorCoursesArray.length + takenElectiveCoursesArray.length + takenRequirementCoursesArray.length;
  let allCoursesLengths = allMinorCoursesArray.length + allElectiveCoursesArray.length + allRequirementCoursesArray.length - takenCoursesLengths;

  return {
    takenCoursesCount: takenCoursesLengths, // Total taken courses count
    planCoursesCount: allCoursesLengths, // Remaining planned courses count
    totCoursesCount: totalCourses, // Total courses count for the minor
  };
};

export default calculateProgressForMinor;
