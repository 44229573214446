// heading.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faMapMarkerAlt, faBook, faTag } from '@fortawesome/free-solid-svg-icons';
import TagList from './TagList';

// 1. Import the useTranslation hook
import { useTranslation } from 'react-i18next';

const Heading = ({ authUser }) => {
  // 2. Initialize the translation function
  const { t } = useTranslation();
  const [clickCount, setClickCount] = useState(0);

  const photoURL = authUser.photoUrl || '/default.jpg';
  const navigate = useNavigate();

  const userFullName = () => {
    if (authUser?.firstName && authUser?.lastName) {
      return `${authUser.firstName} ${authUser.lastName}`;
    }
    // 3. Use translation key for "User"
    return t('heading.defaultUser', 'User');
  };

  const handleNameClick = () => {
    setClickCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount === 4) {
        navigate('/roles');
      }
      return newCount;
    });
  };

  // For "N/A" fallback
  const NA = t('heading.na', 'N/A');

  const year = authUser?.surveyAnswers?.Year || NA;
  const facultiesArr = authUser?.surveyAnswers?.Faculties || [];
  const facultiesJoined = facultiesArr.length > 0 ? facultiesArr.join(', ') : NA;

  // For "Start" and "End" dates
  const startMonth = authUser?.surveyAnswers?.SchoolStart?.month;
  const startYear = authUser?.surveyAnswers?.SchoolStart?.year;
  const endMonth = authUser?.surveyAnswers?.SchoolEnd?.month;
  const endYear = authUser?.surveyAnswers?.SchoolEnd?.year;
  const startDate = startMonth && startYear ? `${startMonth} ${startYear}` : NA;
  const endDate = endMonth && endYear ? `${endMonth} ${endYear}` : NA;

  const majors = authUser?.surveyAnswers?.Majors || [];
  const minors = authUser?.surveyAnswers?.Minors || [];
  const campus = authUser?.surveyAnswers?.Campus || null;
  const pronouns = authUser?.surveyAnswers?.Pronouns || null;
  const tags = authUser?.surveyAnswers?.Tags || [];

  return (
    <div className="flex items-center mb-1">
      <img
        className="w-[160px] h-[160px] mb-4 mr-5 rounded-full"
        src={photoURL}
        alt={t('heading.profilePictureAlt', 'Profile Picture')}
      />
      <div className="flex-grow max-w-[calc(100%-100px)]">
        <h1 className="text-2xl font-bold mb-1" onClick={handleNameClick}>
          {userFullName()}
        </h1>

        {/* Email */}
        {authUser?.email && <p className="text-sm mb-1">{authUser.email}</p>}

        {/* Year - Faculties */}
        {year !== NA || facultiesArr.length > 0 ? (
          <p className="text-sm mb-1">
            {/* Example of combining dynamic values in a single string 
                or just do it with a dash like you have: {year} - {facultiesJoined} */}
            {t('heading.yearFaculties', {
              year,
              faculties: facultiesJoined,
            })}
          </p>
        ) : null}

        {/* Majors */}
        {majors.length > 0 && (
          <p className="text-sm">
            <FontAwesomeIcon icon={faBook} className="mr-1" />
            {/* Conditionally show "Major in" vs "Majors in" */}
            {majors.length === 1
              ? t('heading.majorIn', 'Major in')
              : t('heading.majorsIn', 'Majors in')}{' '}
            {majors.join(', ')}
          </p>
        )}

        {/* Minors */}
        {minors.length > 0 && (
          <p className="text-sm mb-1">
            <FontAwesomeIcon icon={faBook} className="mr-1" />
            {minors.length === 1
              ? t('heading.minorIn', 'Minor in')
              : t('heading.minorsIn', 'Minors in')}{' '}
            {minors.join(', ')}
          </p>
        )}

        {/* Campus */}
        {campus && (
          <p className="text-sm mb-1">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
            {t('heading.campus', { campus })}
          </p>
        )}

        {/* School Start-End Dates */}
        {(startDate !== NA || endDate !== NA) && (
          <p className="text-sm mb-1">
            <FontAwesomeIcon icon={faGraduationCap} className="mr-1" />
            {t('heading.schoolDates', {
              start: startDate,
              end: endDate,
            })}
          </p>
        )}

        {/* Pronouns + Tags */}
        {(pronouns || tags.length > 0) && (
          <div className="flex items-center text-sm">
            <FontAwesomeIcon icon={faTag} className="mr-1 mb-1" />
            {pronouns && (
              <span className="mr-2 mb-1">
                {t('heading.pronouns', { pronouns })}
              </span>
            )}
            <TagList tags={tags} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Heading;
