// src/components/Dashboard/Cards/QuickActionsCard.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { PiStudent } from 'react-icons/pi';
import { BsCalendarCheck, BsGraphUp, BsTable } from 'react-icons/bs';
import DashboardCard from './DashboardCard';

const QuickActionsCard = ({ onHide }) => {
  return (
    <DashboardCard title={<span className="font-medium">Quick Actions</span>} onHide={onHide} isLoading={false}>
      <ul className="space-y-3">
        <li className="flex items-center">
          <PiStudent className="w-5 h-5 text-gray-500 mr-2" />
          <Link to="/students" className="text-blue-600 hover:underline">
            Manage Students
          </Link>
        </li>
        <li className="flex items-center">
          <BsCalendarCheck className="w-5 h-5 text-gray-500 mr-2" />
          <Link to="/meetings" className="text-blue-600 hover:underline">
            Schedule a Meeting
          </Link>
        </li>
        <li className="flex items-center">
          <BsGraphUp className="w-5 h-5 text-gray-500 mr-2" />
          <Link to="/analytics" className="text-blue-600 hover:underline">
            View Analytics
          </Link>
        </li>
        <li className="flex items-center">
          <BsTable className="w-5 h-5 text-gray-500 mr-2" />
          <Link to="/reports" className="text-blue-600 hover:underline">
            Generate a Report
          </Link>
        </li>
      </ul>
    </DashboardCard>
  );
};

export default QuickActionsCard;
