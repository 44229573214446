import React from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import calculateAnalyticsData from './Course/CalculateAnalytics/CalculateAnalytics';
import CustomSidebar from '../Sidebar/Sidebar';
import { toast } from 'react-toastify'; // Import toast from react-toastify
import { httpsCallable } from 'firebase/functions'; // Import httpsCallable
import { functions } from '../../firebase'; // Adjust the path as needed for your project

const RunCalculateAnalytics = () => {
  const { fetchedCourseData } = useAuth();

  const handleRunAnalytics = async () => {
    const toastId = toast.loading("Calculating analytics...");

    try {
      await calculateAnalyticsData(fetchedCourseData);

      toast.update(toastId, {
        render: "Analytics calculated successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000
      });
    } catch (error) {
      toast.update(toastId, {
        render: "Error running analytics!",
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    }
  };

  const handleFillFirestoreUserData = async () => {
    const toastId = toast.loading("Filling Firestore user data...");

    try {
      const fillFirestoreUserData = httpsCallable(functions, 'fillFirestoreUserData');
      await fillFirestoreUserData();

      toast.update(toastId, {
        render: "Firestore user data filled successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000
      });
    } catch (error) {
      toast.update(toastId, {
        render: "Error filling Firestore user data!",
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    }
  };

  const handleUpdateAllPersistence = async () => {
    const toastId = toast.loading("Updating persistence...");

    try {
      const updateAllPersistenceFunction = httpsCallable(functions, 'manualUpdatePersistence');
      await updateAllPersistenceFunction();

      toast.update(toastId, {
        render: "Persistence updated successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000
      });
    } catch (error) {
      toast.update(toastId, {
        render: "Error updating persistence!",
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    }
  };

  const handleGeneratePersistenceHistory = async () => {
    const toastId = toast.loading("Generating persistence history...");

    try {
      const generatePersistenceHistory = httpsCallable(functions, 'generatePersistenceHistory');
      await generatePersistenceHistory();

      toast.update(toastId, {
        render: "Persistence history generated successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000
      });
    } catch (error) {
      toast.update(toastId, {
        render: "Error generating persistence history!",
        type: "error",
        isLoading: false,
        autoClose: 3000
      });
    }
  };

  return (
    <div className="assign-role p-6 bg-white rounded-lg shadow-md">
      <CustomSidebar />
      <h2 className="text-xl font-semibold mb-4">Run Functions</h2>
      <button 
        onClick={handleRunAnalytics} 
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        Run Analytics
      </button>
      <button 
        onClick={handleFillFirestoreUserData} 
        className="mt-4 ml-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
      >
        Fill Firestore User Data
      </button>
      <button 
        onClick={handleUpdateAllPersistence} 
        className="mt-4 ml-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
      >
        Update All Persistence
      </button>
      <button 
        onClick={handleGeneratePersistenceHistory} 
        className="mt-4 ml-4 px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
      >
        Generate Persistence History
      </button>
    </div>
  );
};

export default RunCalculateAnalytics;
